import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import _, { Dictionary } from "lodash";
import { useMemo } from "react";
import { GoogleMeetRecordingRecord } from "types/backend/google_meet";
import { RecordingDownloadTask } from "types/backend/zoom_download_task";

export const useGetGoogleDownloadTasksQuery = (
    googleMeetingRecords: Array<GoogleMeetRecordingRecord> | undefined,
) => {
    const fileIds = useMemo(() => {
        if (googleMeetingRecords && googleMeetingRecords?.length > 0) {
            return googleMeetingRecords.map((v) => v.driveDestination.file);
        }
        return undefined;
    }, [googleMeetingRecords]);

    const query = useQuery<Dictionary<RecordingDownloadTask>>({
        queryKey: [Q.GET_ZOOM_DOWNLOAD_TASKS],
        queryFn: async () => {
            const { data } = await api.post<Array<RecordingDownloadTask>>(
                `/google-meets/tasks`,
                {
                    fileIds,
                },
            );
            return _.keyBy(
                data,
                (task) => task.recordingFileId,
            ) as Dictionary<RecordingDownloadTask>;
        },
        enabled: !!fileIds,
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
    });
    return query;
};
