import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { Media } from "types/backend/media";

export function useGetMediaQuery(id: string | undefined) {
    const query = useQuery({
        queryKey: [Q.GET_MEDIA, id],
        queryFn: async ({ queryKey }) => {
            const [, id] = queryKey;
            const { data } = await api.get<Media>(`/media/${id}`);

            return data;
        },
        gcTime: 30 * 60 * 1000,
        staleTime: 15 * 60 * 1000,
        enabled: !!id,
    });

    return query;
}
