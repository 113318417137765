import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { SalesforceInstanceUrl } from "types/backend/resources";

async function getSalesforceInstanceUrl() {
    const { data } = await api.get<SalesforceInstanceUrl>(
        `/resource-config/salesforce-url`,
    );
    return data;
}

export function useSalesforceInstanceUrl(enable = true) {
    return useQuery({
        queryKey: [Q.GET_SALESFORCE_INSTANCE_URL],
        queryFn: () => getSalesforceInstanceUrl(),

        enabled: enable,
    });
}
