import { Button } from "@component-ui/utility/Button";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@component-ui/utility/Table";
import { ChevronLeft } from "lucide-react";
import { Link } from "react-router-dom";

export type NavigatableTableProps = {
    back: string;
    caption: string;
    fileUploader?: React.ReactNode;
    heads: {
        id: string;
        content: React.ReactNode;
    }[];
    rows: { id: string; content: React.ReactNode }[];
    fallbackFooter: string;
};

const NavigatableTable = ({
    back,
    caption,
    fileUploader,
    heads,
    rows,
    fallbackFooter,
}: NavigatableTableProps) => {
    return (
        <div className="h-full p-12 overflow-y-auto">
            <div>
                <Link to={back}>
                    <Button size="icon" variant="ghost">
                        <ChevronLeft />
                    </Button>
                </Link>
                {fileUploader}
            </div>
            <Table className="bg-white rounded-lg my-4">
                <TableCaption>{caption}</TableCaption>
                <TableHeader>
                    <TableRow className="text-center">
                        {heads.map((h) => (
                            <TableHead key={h.id}>{h.content}</TableHead>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {rows.map((r) => (
                        <TableRow key={r.id}>{r.content}</TableRow>
                    ))}
                </TableBody>
                {heads.length <= 0 ||
                    (rows.length <= 0 && (
                        <TableFooter>
                            <TableRow>
                                <TableCell
                                    colSpan={6}
                                    className="text-center text-gray-400 tracking-wide"
                                >
                                    {fallbackFooter}
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    ))}
            </Table>
        </div>
    );
};

export default NavigatableTable;
