import { Avatar, AvatarFallback } from "@component-ui/utility/Avatar";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import { cn, wholeStringToColour } from "@libs/utils";
import { ComponentPropsWithoutRef, FC } from "react";

type EmailAvatarProps = ComponentPropsWithoutRef<typeof Avatar> & {
    email: string;
};

const EmailAvatar: FC<EmailAvatarProps> = ({ email, className, ...rest }) => {
    return (
        <TooltipProvider delayDuration={200}>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Avatar className={cn("mx-1 cursor-default", className)} {...rest}>
                        <AvatarFallback
                            style={{
                                background: wholeStringToColour(email) + "4D",
                            }}
                        >
                            {email.slice(0, 2).toUpperCase()}
                        </AvatarFallback>
                    </Avatar>
                </TooltipTrigger>
                <TooltipContent>{email}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export default EmailAvatar;
