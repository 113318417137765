import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { SummarizingResult } from "types/backend/summarizing_result";

export default function useSummarizingResult(
    mediaId: string,
    summarizingSystemMessageId: string,
) {
    const query = useQuery({
        queryKey: [Q.GET_SUMMARIZE, mediaId, summarizingSystemMessageId],
        queryFn: async ({ queryKey }) => {
            const [, mediaId, summarizingSystemMessageId] = queryKey;
            const { data } = await api.get<SummarizingResult>(
                `/summarize/${mediaId}/${summarizingSystemMessageId}`,
            );
            return data;
        },
        enabled: !!mediaId && !!summarizingSystemMessageId,
        refetchInterval: ({ state }) => {
            const { data } = state;
            if (!data) return 5000;
            if (data.status === "progressing") return 5000;
            return false;
        },
        retry: false,
    });
    return query;
}
