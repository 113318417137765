import { FC } from "react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "./Tooltip";

export const ChildrenWithHover: FC<{
    description: string;
    children: JSX.Element;
}> = ({ description, children }) => {
    return (
        <TooltipProvider delayDuration={200}>
            <Tooltip>
                <TooltipTrigger asChild>{children}</TooltipTrigger>
                <TooltipContent side="bottom">{description}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};
