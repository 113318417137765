import { Button } from "@component-ui/utility/Button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@component-ui/utility/Dialog";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";
import { FORM_KEY } from "@constant/form";
import useCreateDocumentTemplateMutate from "@hooks/template/useCreateDocumentTemplateMutate";
import { Loader2 } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { FC, FormEvent, ReactElement, useEffect, useState } from "react";
import { Tour } from '@frigade/react'
import { FRIGADE_FLOW_ID } from "@constant/frigade-flows";

type CreateDocumentTemplateDialogProps = {
    ButtonElement?: ReactElement;
};

const CreateDocumentTemplateDialogCapture: FC = () => {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("$pageview", { pageName: "/templates/new" });
    }, []);

    return null;
};

const CreateDocumentTemplateDialog: FC<CreateDocumentTemplateDialogProps> = ({
    ButtonElement = <Button className="w-full">Create new template</Button>,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { mutate: createTemplate, isPending: isCreating } =
        useCreateDocumentTemplateMutate();

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        createTemplate(formData, {
            onSuccess: () => {
                setIsOpen(false);
            },
        });
    }

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild id="create-template-button">{ButtonElement}</DialogTrigger>
            <Tour flowId={FRIGADE_FLOW_ID.CREATE_DOCUMENT_TEMPLATE} />
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Create new template</DialogTitle>
                    <CreateDocumentTemplateDialogCapture />
                </DialogHeader>
                <form onSubmit={handleSubmit}>
                    <Label htmlFor={FORM_KEY.DOCUMENT_TEMPLATE.TITLE}>
                        Template Title
                    </Label>
                    <Input
                        id={FORM_KEY.DOCUMENT_TEMPLATE.TITLE}
                        name={FORM_KEY.DOCUMENT_TEMPLATE.TITLE}
                        type="text"
                    />
                    <Button
                        type="submit"
                        disabled={isCreating}
                        className="w-full"
                    >
                        {isCreating && (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Create
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default CreateDocumentTemplateDialog;
