import { Button } from "@component-ui/utility/Button";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";
import { SEARCH_PARAMS } from "@constant/searchParams";
import { useAuth } from "@context/auth";
import { usePopupNotification } from "@context/popup-notification";
import useLogin from "@hooks/user/useLogin";
import { Loader2 } from "lucide-react";
import { FC, FormEvent } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const Authentication: FC = () => {
    const { push } = usePopupNotification();
    const { mutate: login, isPending } = useLogin();
    const { setIsAuth, setIsActive } = useAuth();
    const [search] = useSearchParams()
    const navigate = useNavigate();
    const redirect = search.get(SEARCH_PARAMS.OAUTH_REDIRECT)
    const platform = search.get(SEARCH_PARAMS.OAUTH_PLATFORM)
    const code = search.get(SEARCH_PARAMS.OAUTH_CODE)

    function handleLogin(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        try {
            const formData = new FormData(e.currentTarget);
            const email = formData.get("email") as string;
            const password = formData.get("password") as string;
            login(
                { email, password },
                {
                    onSuccess: (data) => {
                        setIsAuth(true);
                        const { isActive } = data;

                        if (isActive) {
                            setIsActive(true);
                        } else {
                            setIsActive(false);
                        }
                        if (redirect && platform && code) {
                            navigate(`/oauth/${platform}?code=${code}`)
                        }
                        navigate("/");
                    },
                },
            );
        } catch (err) {
            push({
                type: "destructive",
                title: "Cannot login",
                description: "Unexpected error, please contact admin",
            });
        } finally {
            e.currentTarget.reset();
        }
    }

    return (
        <div className="w-full h-full grid place-items-center">
            <div className="w-full max-w-lg flex flex-col justify-center items-center p-4 rounded-md border-2 border-gray-400">
                <h1 className="text-2xl">Login</h1>
                <h1 className="text-sm text-gray-400">
                    Enter your email and password below
                </h1>
                <form className="w-full" onSubmit={handleLogin}>
                    <Label className="mb-10" htmlFor="email">
                        Email
                    </Label>
                    <Input
                        id="email"
                        name="email"
                        type="text"
                        placeholder="your email"
                        required
                    />
                    <Label htmlFor="password">Password</Label>
                    <Input
                        id="password"
                        name="password"
                        type="password"
                        placeholder="your password"
                        required
                    />
                    <div className="flex justify-end flex-col">
                        <Link
                            to="/sign_up"
                            className="mt-2 text-end text-blue-500 hover:text-blue-800 text-sm"
                        >
                            Sign up
                        </Link>
                        <Link
                            to="/forgot_password"
                            className="mt-2 text-end text-blue-500 hover:text-blue-800 text-sm"
                        >
                            Forgot password?
                        </Link>
                    </div>
                    <Button
                        className="w-full mt-4"
                        type="submit"
                        disabled={isPending}
                    >
                        {isPending && (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Login
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default Authentication;
