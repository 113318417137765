import { create } from "zustand";
import { createSelectors } from "./helper";

type OnboardProgressStatusState = {
    displayCheckList: boolean;
    setDisplayCheckList: () => void
};

export const useOnboardProgressStatusStore = createSelectors(
    create<OnboardProgressStatusState>((set) => ({
        displayCheckList: false,
        setDisplayCheckList: () =>
            set((state) => ({ displayCheckList: !state.displayCheckList })),
    })),
);
