import { FORM_KEY } from "@constant/form";
import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";
import { CustomerTag } from "types/backend/customer_tags";

function validateForm(formData: FormData) {
    const name = formData.get(FORM_KEY.CREATE_CUSTOMER_TAG.NAME) as string;
    const description = formData.get(
        FORM_KEY.CREATE_CUSTOMER_TAG.DESCRIPTION,
    ) as string;
    if (!name) throw new Error("Invalid tag name");
    return { name, description };
}

export default function useCreateOrUpdateCustomerTagMutate(customerTagId?: string) {
    const queryClient = useQueryClient();
    const { push } = usePopupNotification();
    const posthog = usePostHog()
    
    const mutation = useMutation({
        mutationKey: [M.UPSERT_CUSTOMER_TAG, customerTagId],
        mutationFn: async (formData: FormData) => {
            posthog?.capture(customerTagId ? 'customer_tag_updated' : 'customer_tag_created')
            const { name, description } = validateForm(formData);
            if (customerTagId) {
                const { data } = await api.patch<CustomerTag>("/customer-tags/" + customerTagId, {
                    name,
                    description
                })
                return data
            } else {
                const { data } = await api.post<CustomerTag>("/customer-tags/", {
                    name,
                    description,
                });
                return data;
            }
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [Q.GET_CUSTOMER_TAG] });
            queryClient.invalidateQueries({ queryKey: [Q.GET_MEETINGS] });
            queryClient.invalidateQueries({ queryKey: [Q.GET_ALL_DOCUMENT] });
            queryClient.invalidateQueries({
                queryKey: [Q.GET_ALL_DOCUMENT_TEMPLATE],
            });
            push({
                title: `Successfully ${customerTagId ? 'updated' : 'created'} tag ${data.name}`,
                description:
                    "You can use the tag to tag documents, meetings and templates",
                type: "success",
            });
        },
        onError: (error) => {
            const errorMessage = error instanceof AxiosError ? error.response?.data.message : error.message
            push({
                title: `Failed to ${customerTagId ? 'update' : 'create'} tag`,
                description: `Error occurred due to ${errorMessage}`,
                type: "destructive",
            });
        },
    });
    return mutation;
}
