import { forwardRef } from "react";

type AudioContainerProps = {
    src: string;
};

const AudioContainer = forwardRef<HTMLAudioElement, AudioContainerProps>(
    ({ src }, ref) => {
        return (
            <audio key={src} controls className="mx-auto" ref={ref}>
                <source src={`${src}`} />
            </audio>
        );
    },
);

AudioContainer.displayName = "AudioContainer";

export default AudioContainer;
