import { clsx, type ClassValue } from "clsx";
import { Location } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { UserType } from "types/backend";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function isVisible(el: Element, container: Element) {
    const rect = el.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    // Check if the element is within the visible area of the scrollable div
    return (
        rect.top >= containerRect.top &&
        rect.bottom <= containerRect.bottom &&
        rect.left >= containerRect.left &&
        rect.right <= containerRect.right
    );
}

export function convertTextToColour(text: string) {
    const firstLetter = text.charAt(0).toLowerCase();
    const ascii = firstLetter.charCodeAt(0);
    const asciiThrice = ascii.toString() + ascii.toString() + ascii.toString();
    const num = Math.round(0xffffff * parseInt(asciiThrice, 10));
    const [h, s, l] = rgbToHsl(
        (num >> 16) & 255,
        (num >> 8) & 255,
        num & 255,
    ).number;

    return hslToHex(h, Math.min(50, s), l);
}

export function hslToHex(h: number, s: number, l: number) {
    l /= 100;

    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n: number) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

        return Math.round(255 * color)
            .toString(16)
            .padStart(2, "0");
    };

    return `#${f(0)}${f(8)}${f(4)}`;
}

export function rgbToHsl(r: number, g: number, b: number) {
    r /= 255;
    g /= 255;
    b /= 255;

    const cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin;
    let h = 0,
        s = 0,
        l = 0;

    if (delta === 0) {
        h = 0;
    }
    //
    else if (cmax === r) {
        h = ((g - b) / delta) % 6;
    }
    //
    else if (cmax === g) {
        h = (b - r) / delta + 2;
    }
    //
    else {
        h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60);

    if (h < 0) {
        h += 360;
    }

    l = (cmax + cmin) / 2;

    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return {
        number: [h, s, l],
        string: "hsl(" + h + "," + s + "%," + l + "%)",
    };
}

export const wholeStringToColour = (str: string) => {
    let hash = 0;
    str.split("").forEach((char) => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let colour = "#";
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        colour += value.toString(16).padStart(2, "0");
    }
    return colour;
};

export function getFilenameByKey(key: string | undefined) {
    if (!key) return;
    const [, filenameWithTimestamp] = key.split("/");
    const [, filename] = filenameWithTimestamp.split("-");
    return filename;
}

export function getUserType(location: Location): UserType {
    const { pathname } = location;
    for (const name of pathname.split("/")) {
        if (name === "share") {
            return "shared-user";
        }
        if (name === "guest") {
            return "guest";
        }
    }
    return "tenant-user";
}

export async function reTryCatch(
    callback: () => void | Promise<void>,
    times = 1,
) {
    try {
        return await callback();
    } catch (error) {
        if (times > 0) {
            return await reTryCatch(callback, times - 1);
        } else {
            throw error;
        }
    }
}
