import { ComponentPropsWithoutRef } from "react";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@component-ui/utility/Select";
import { cn } from "@libs/utils";

export type Option = {
    label: string;
    value: string;
};

type BasicSelectProps = {
    name: string;
    placeholder: string;
    options?: readonly Option[];
    disabled?: boolean;
    triggerClassName?: ComponentPropsWithoutRef<
        typeof SelectTrigger
    >["className"];
} & React.ComponentPropsWithoutRef<typeof Select>;

const BasicSelect = ({
    name,
    placeholder,
    options = [],
    disabled = false,
    triggerClassName = "",
    ...rest
}: BasicSelectProps) => {
    return (
        <Select name={name} disabled={disabled} {...rest}>
            <SelectTrigger className={cn(triggerClassName)}>
                <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            {options.length > 0 && (
                <SelectContent>
                    {options.map((option, i) => (
                        <SelectItem key={i + option.value} value={option.value}>
                            {option.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            )}
        </Select>
    );
};

export default BasicSelect;
