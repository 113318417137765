import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";

export interface RecordingFile {
    id: string;
    meeting_id: string;
    recording_start: string;
    recording_end: string;
    file_type: string;
    file_extension: string;
    file_size: number;
    play_url: string;
    download_url: string;
    status: string;
    recording_type: string;
}

export interface ZoomMeetingRecord {
    uuid: string;
    id: number;
    account_id: string;
    host_id: string;
    topic: string;
    type: number;
    start_time: string;
    timezone: string;
    host_email: string;
    duration: number;
    total_size: number;
    recording_count: number;
    share_url: string;
    recording_files: Array<RecordingFile>;
    password: string;
    recording_play_passcode: string;
    download_access_token: string;
}

export function useGetZoomMeetingRecordsQuery(
    zoomMeetingId: string | undefined,
) {
    const query = useQuery<ZoomMeetingRecord>({
        queryKey: [Q.GET_ZOOM_MEETING_RECORDINGS, zoomMeetingId],
        queryFn: async () => {
            const { data } = await api.get<ZoomMeetingRecord>(
                `/zoom/${zoomMeetingId}/recordings`,
            );
            return data;
        },
        enabled: !!zoomMeetingId,
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
    });
    return query;
}