import { Button } from "@component-ui/utility/Button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@component-ui/utility/Dialog";
import useRemoveCustomerTagMutate from "@hooks/customer-tag/useRemoveCustomerTagMutate";
import { Loader2 } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import {
    Dispatch,
    FC,
    MutableRefObject,
    SetStateAction,
    useEffect,
} from "react";
import { CustomerTag } from "types/backend/customer_tags";

type ConfirmRemoveCustomerTagDialogProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    removingTagRef: MutableRefObject<CustomerTag | null>;
};

const ConfirmRemoveCustomerTagDialogCapture: FC = () => {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("$pageview", { pageName: "/tag/delete" });
    }, []);

    return null;
};

const ConfirmRemoveCustomerTagDialog: FC<
    ConfirmRemoveCustomerTagDialogProps
> = ({ open, setOpen, removingTagRef }) => {
    const { mutate: removeCustomerTag, isPending } =
        useRemoveCustomerTagMutate();

    const confirmDelete = () => {
        if (!removingTagRef.current) return;
        removeCustomerTag(removingTagRef.current.id, {
            onSuccess: () => {
                setOpen(false);
                removingTagRef.current = null;
            },
        });
    };

    return (
        <Dialog
            open={open}
            onOpenChange={(event) => {
                setOpen(event);
                if (!event) {
                    removingTagRef.current = null;
                }
            }}
        >
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        Removing {removingTagRef.current?.name}
                    </DialogTitle>
                    <ConfirmRemoveCustomerTagDialogCapture />
                    <DialogDescription>
                        Are you sure you want to remove the tag? This action
                        cannot be undone.
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button>Cancel</Button>
                    </DialogClose>
                    <Button onClick={confirmDelete} disabled={isPending}>
                        {isPending && (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Confirm
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmRemoveCustomerTagDialog;
