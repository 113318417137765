import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { useGetZoomMeetingsQuery } from "@hooks/zoom/useGetZoomMeetingsQuery";
import ZoomMeetingListItem from "./ZoomMeetingListItem";
import { useParams } from "react-router-dom";
import { MessageSquareMore } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { PageError } from "@component-layout/PageError";

const ZoomMeetingList: React.FC = () => {
    const { isLoading, data, isError, isSuccess } = useGetZoomMeetingsQuery();
    const { zoomMeetingId } = useParams();

    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture("zoom_meeting_list");
        posthog?.capture("$pageview", { pageName: "/zoom/meetings" });
    }, []);

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (isError || !isSuccess) {
        return (
            <PageError description="please contact us" page="ZoomMeetingList" />
        );
    }

    if (!data) {
        return null;
    }

    return (
        <aside className="h-full w-full grid grid-rows-[max-content,1fr,max-content] gap-2 p-2">
            <span className="inline-flex items-center gap-2">
                <MessageSquareMore className="min-w-[40px] w-10 h-10" />
                <p className="text-2xl font-medium leading-none">
                    Zoom Recordings
                </p>
            </span>
            <ul className="h-full overflow-auto">
                {data.map((zoomMeeting, index) => (
                    <li key={index}>
                        <ZoomMeetingListItem
                            item={zoomMeeting}
                            currentZoomMeetingId={Number(zoomMeetingId)}
                        />
                    </li>
                ))}
                <li>
                    <div className="grid grid-flow-row w-full items-center rounded-md mb-2 p-2 hover:bg-gray-100/90 overflow-hidden shadow-sm">
                        <div className="flex flex-col justify-center items-center gap-x-6 py-1">
                            <span> Only showing Zoom recordings</span>
                            <span> from last 90 days</span>
                        </div>
                    </div>
                </li>
            </ul>
        </aside>
    );
};

export default ZoomMeetingList;
