import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { SummarizingSystemMessage } from "types/backend/summarizing_system_message";

export default function useGetSummarizeSystemMessagesQuery() {
    const query = useQuery({
        queryKey: [Q.GET_SUMMARIZE_SYSTEM_MESSAGES],
        queryFn: async () => {
            const { data } = await api.get<SummarizingSystemMessage[]>(
                "/summarizing-system-messages",
            );
            return data;
        },
    });
    return query;
}
