import api from "@libs/api";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";
import { TenantUserSelfInformation } from "types/backend/tenant-user";

export default function useResetPassword() {
    const posthog = usePostHog()

    const mutation = useMutation<
        TenantUserSelfInformation,
        AxiosError,
        { resetPasswordToken: string; password: string; id: string }
    >({
        mutationFn: async ({ resetPasswordToken, password, id }) => {
            const { data } = await api.post<TenantUserSelfInformation>(
                `tenant-users/${id}/reset_password`,
                {
                    resetPasswordToken,
                    password,
                },
            );
            return data;
        },
        onSuccess: () => {
            posthog?.capture('reset_password_submitted')
        }
    });

    return mutation;
}
