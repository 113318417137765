import { Mark, Range, mergeAttributes } from "@tiptap/core";
import { Mark as PMMark } from "@tiptap/pm/model";

export type CommentOptions = {
    HTMLAttributes: Record<string, any>;
    onCommentActivated: (commentId: string) => void;
};

declare module "@tiptap/core" {
    interface Commands<ReturnType> {
        comment: {
            /**
             * Set a comment (add)
             */
            setThread: (threadId: string) => ReturnType;
            /**
             * Unset a comment (remove)
             */
            unsetThread: (threadId: string) => ReturnType;
        };
    }
}

export type MarkWithRange = {
    mark: PMMark;
    range: Range;
};

export const Thread = Mark.create<CommentOptions>({
    name: "thread",
    inclusive: false,

    addOptions() {
        return {
            HTMLAttributes: {
                class: "bg-yellow-200/60 hover:bg-yellow-300/60 cursor-pointer border-b-2 border-yellow-300",
            },
            onCommentActivated: (threadId: string) => {},
        };
    },

    parseHTML() {
        return [
            {
                tag: "span",
                getAttrs: (node) =>
                    (node as HTMLSpanElement).getAttribute("threadId") !== null && null,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            "span",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            0,
        ];
    },

    addAttributes() {
        return {
            threadId: {
                default: null,
            },
        };
    },

    onSelectionUpdate() {
        const { $from } = this.editor.state.selection;

        const marks = $from.marks();

        if (!marks.length) {
            this.storage.activeCommentId = null;
            this.options.onCommentActivated(this.storage.activeCommentId);
            return;
        }

        const commentMark = this.editor.schema.marks.comment;

        const activeCommentMark = marks.find(
            (mark) => mark.type === commentMark,
        );

        this.storage.activeCommentId =
            activeCommentMark?.attrs.commentId || null;

        this.options.onCommentActivated(this.storage.activeCommentId);
    },

    addStorage() {
        return {
            activeCommentId: null,
        };
    },

    addCommands() {
        return {
            setThread:
                (threadId: string) =>
                ({ commands }) => {
                    if (!threadId) return false;
                    commands.setMark(this.name, { threadId });
                    return true;
                },
            unsetThread:
                (threadId: string) =>
                ({ tr, dispatch }) => {
                    if (!threadId) return;
                    const commentMarksWithRange: MarkWithRange[] = [];

                    tr.doc.descendants((node, pos) => {
                        const commentMark = node.marks.find(
                            (mark) =>
                                mark.type.name === this.name &&
                                mark.attrs.threadId === threadId,
                        );

                        if (!commentMark) return;

                        commentMarksWithRange.push({
                            mark: commentMark,
                            range: {
                                from: pos,
                                to: pos + node.nodeSize,
                            },
                        });
                    });

                    commentMarksWithRange.forEach(({ mark, range }) => {
                        tr.removeMark(range.from, range.to, mark);
                    });

                    return dispatch?.(tr);
                },
        };
    },
});
