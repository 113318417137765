import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@component-ui/utility/Command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@component-ui/utility/Popover";
import {
    CRM_QUERY_VARIANT,
    RESOURCE_PLATFORM,
    RESOURCE_TYPE,
} from "@constant/resource";
import useCrmAssociatedResourceOptionsQuery from "@hooks/resource-mapping/useCrmAssociatedResourceOptionsQuery";
import useCrmResourceOptionsQuery from "@hooks/resource-mapping/useCrmResourceOptionsQuery";
import useDebounce from "@hooks/useDebounce";
import { cn } from "@libs/utils";
import { CommandLoading } from "cmdk";
import _ from "lodash";
import { Check, ChevronsUpDown } from "lucide-react";
import {
    Dispatch,
    FC,
    HTMLAttributes,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import {
    ResourceOption,
    ResourcePlatform,
    ResourceType,
} from "types/backend/resources";

type ResourceOptionsComboBoxProps = {
    resourcePlatform: ResourcePlatform;
    resourceType: ResourceType | undefined;
    resourceOption: ResourceOption | undefined;
    setResourceOption: Dispatch<SetStateAction<ResourceOption | undefined>>;
    className?: HTMLAttributes<"button">["className"];
};

type ResourceOptionsProps = {
    resourcePlatform: ResourcePlatform;
    resourceType: ResourceType;
    searchString: string;
    resourceOption: ResourceOption | undefined;
    setResourceOption: (option: ResourceOption) => void;
};

type AssociatedResourceOptionsProps = {
    resourcePlatform: ResourcePlatform;
    resourceType: ResourceType;
    resourceId: string;
    resourceOption: ResourceOption | undefined;
    setResourceOption: (option: ResourceOption) => void;
};

const ResourceOptionsGroup: FC<ResourceOptionsProps> = ({
    resourcePlatform,
    resourceType,
    searchString,
    resourceOption,
    setResourceOption,
}) => {
    const {
        data: resourceOptions,
        isLoading,
        isFetching,
        isError,
        isSuccess,
        refetch,
    } = useCrmResourceOptionsQuery(
        resourcePlatform,
        resourceType,
        searchString,
        CRM_QUERY_VARIANT.FILTER_OBJECT_DATA,
    );

    if (isLoading) {
        return (
            <CommandLoading>
                <div className="h-8">
                    <LoadingSpinnerCenter size={"sm"} />
                </div>
            </CommandLoading>
        );
    }

    if (isError || !isSuccess) {
        return (
            <CommandGroup heading={resourceType}>
                <CommandItem onSelect={() => refetch()}>
                    Failed to get resources. Click to retry
                </CommandItem>
            </CommandGroup>
        );
    }

    return (
        <>
            <CommandGroup heading={resourceType}>
                {isFetching && (
                    <CommandLoading>
                        <div className="h-8">
                            <LoadingSpinnerCenter size={"sm"} />
                        </div>
                    </CommandLoading>
                )}
                {resourceOptions.slice(0, 10).map((option) => (
                    <CommandItem
                        key={option.value}
                        value={option.value}
                        onSelect={() => setResourceOption(option)}
                        className="truncate"
                    >
                        <Check
                            className={cn(
                                "mr-2 h-4 w-4",
                                resourceOption &&
                                    resourceOption.value === option.value
                                    ? "opacity-100"
                                    : "opacity-0",
                            )}
                        />
                        {option.label ||
                            `${_.capitalize(resourceType)} ID: ${option.value}`}
                    </CommandItem>
                ))}
            </CommandGroup>
            {resourceType !== RESOURCE_TYPE.HUBSPOT.DEAL &&
                resourceOptions.length > 0 &&
                resourceOptions.length <= 5 && (
                    <AssociatedResourceOptionsGroup
                        resourcePlatform={resourcePlatform}
                        resourceType={resourceType}
                        resourceId={resourceOptions[0].value}
                        resourceOption={resourceOption}
                        setResourceOption={setResourceOption}
                    />
                )}
        </>
    );
};

const AssociatedResourceOptionsGroup: FC<AssociatedResourceOptionsProps> = ({
    resourcePlatform,
    resourceType,
    resourceId,
    resourceOption,
    setResourceOption,
}) => {
    const {
        data: associatedResourceOptions,
        isLoading,
        isFetching,
        isError,
        isSuccess,
        refetch,
    } = useCrmAssociatedResourceOptionsQuery(
        resourcePlatform,
        resourceType,
        resourceId,
        resourcePlatform === RESOURCE_PLATFORM.HUBSPOT
            ? RESOURCE_TYPE.HUBSPOT.DEAL
            : RESOURCE_TYPE.SALESFORCE.OPPORTUNITY,
    );
    const heading = `${resourcePlatform === RESOURCE_PLATFORM.HUBSPOT ? "deals" : "opportunities"} associated to the first ${resourceType} result`;

    if (isLoading) {
        return (
            <CommandGroup heading={heading}>
                <CommandLoading>
                    <div className="h-8">
                        <LoadingSpinnerCenter size={"sm"} />
                    </div>
                </CommandLoading>
            </CommandGroup>
        );
    }

    if (isError || !isSuccess) {
        return (
            <CommandGroup heading={heading}>
                <CommandItem onSelect={() => refetch()}>
                    Failed to get resources. Click to retry
                </CommandItem>
            </CommandGroup>
        );
    }

    return (
        <CommandGroup heading={heading}>
            {isFetching && (
                <CommandLoading>
                    <div className="h-8">
                        <LoadingSpinnerCenter size={"sm"} />
                    </div>
                </CommandLoading>
            )}
            {associatedResourceOptions.slice(0, 10).map((option) => (
                <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={() => setResourceOption(option)}
                    className="truncate"
                >
                    <Check
                        className={cn(
                            "mr-2 h-4 w-4",
                            resourceOption &&
                                resourceOption.value === option.value
                                ? "opacity-100"
                                : "opacity-0",
                        )}
                    />
                    {option.label ||
                        `${_.capitalize(option.resourceType)} ID: ${option.value}`}
                </CommandItem>
            ))}
        </CommandGroup>
    );
};

const ResourceOptionsComboBox: FC<ResourceOptionsComboBoxProps> = ({
    resourcePlatform,
    resourceType,
    resourceOption,
    setResourceOption,
    className = "",
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchString, setSearchString] = useState("");
    const search = useDebounce(searchString);

    function handleSelectResourceOption(option: ResourceOption) {
        setResourceOption({ ...option });
        setIsOpen(false);
    }

    // FIXME: re-organize life cycle to prevent this useEffect
    useEffect(() => setSearchString(""), [resourceType]);

    if (!resourceType) {
        return (
            <Button
                variant={"outline"}
                role="combobox"
                aria-expanded={isOpen}
                className={cn("w-full justify-between", className)}
                disabled
            >
                Please select result
                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
        );
    }

    return (
        <>
            <Popover open={isOpen} onOpenChange={setIsOpen} modal>
                <PopoverTrigger asChild>
                    <Button
                        variant={"outline"}
                        role="combobox"
                        aria-expanded={isOpen}
                        className={cn("w-full justify-between overflow-hidden", className)}
                    >
                        <span className="truncate">
                        {resourceOption
                            ? resourceOption.label ||
                              `${_.capitalize(resourceOption.resourceType)} ID: ${resourceOption.value}`
                            : `Search ${resourceType}..`}
                        </span>
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-96 p-0" align="start">
                    <Command
                        label="Crm resources"
                        shouldFilter={false}
                        filter={() => 0}
                    >
                        <CommandInput
                            className="focus:outline-none"
                            placeholder={`Search ${resourceType}`}
                            value={searchString}
                            onValueChange={setSearchString}
                        />
                        <CommandList className="max-h-80 w-full">
                            <ResourceOptionsGroup
                                resourcePlatform={resourcePlatform}
                                resourceType={resourceType}
                                searchString={search}
                                resourceOption={resourceOption}
                                setResourceOption={handleSelectResourceOption}
                            />
                        </CommandList>
                        <CommandEmpty>No result found</CommandEmpty>
                    </Command>
                </PopoverContent>
            </Popover>
        </>
    );
};

export default ResourceOptionsComboBox;
