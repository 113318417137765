import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import useDocumentQuery from "@hooks/document/useDocumentQuery";
import { humanizeDate } from "@libs/duration";
import { FC, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import { useCurrentDocumentStore } from "@store/currentDocumentStore";
import TenantUserAvatar from "@component-func/TenantUserAvatar";
import { usePostHog } from "posthog-js/react";
import UpdateDocumentDialog from "./UpdateDocumentDialog";
import ItemCustomerTagList from "@component-func/customer-tag/ItemCustomerTagList";
import ItemResourceMappingList from "@component-func/resource-mapping/ItemResourceMappingList";
import LinkedMediaList from "./LinkedMediaList";
import useDocumentRawDataQuery from "@hooks/document/useDocumentRawDataQuery";
import { TiptapEditor } from "@component-func/tiptap/TiptapEditor";

const DocumentDetail: FC = () => {
    const params = useParams();
    const {
        data: document,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useDocumentQuery(params.documentId || "");

    const { isLoading: rawDataIsLoading, yjsDoc } = useDocumentRawDataQuery(
        params.documentId,
        "document",
    );
    const updateCurrentDocumentId = useCurrentDocumentStore(
        (state) => state.update,
    );
    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture("$pageview", { pageName: "/document" });
        posthog?.capture("document_detail_page_navigated");
    }, [params.documentId]);

    if (!params.documentId) {
        return <Navigate to="/documents" />;
    }

    if (isLoading || rawDataIsLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return (
            <div className="h-full grid place-items-center">
                <Button onClick={() => refetch()}>Retry</Button>
            </div>
        );
    }

    updateCurrentDocumentId(document.id);

    return (
        <div className="h-full grid grid-rows-[max-content,max-content,1fr] gap-2 pt-2 pb-8 px-4">
            <Button size={"icon"} variant={"ghost"}>
                <Link to={`..`} relative="path">
                    <ChevronLeft />
                </Link>
            </Button>
            <div className="max-w-screen-md w-full mx-auto p-2">
                <div className="flex gap-2 items-center mb-2">
                    <h1 className="text-2xl font-bold">{document.title}</h1>
                    <UpdateDocumentDialog document={document} />
                </div>
                <div className="flex gap-2 items-center my-2">
                    <span className="text-nowrap">Connected Meeting:</span>
                    {document.meetingDocumentRelations &&
                        document.meetingDocumentRelations.length > 0 && (
                            <Link
                                to={`/meeting-transcripts/${document.meetingDocumentRelations[0].meetingId}`}
                                className="hover:underline"
                            >
                                {
                                    document.meetingDocumentRelations[0].meeting
                                        ?.label
                                }
                            </Link>
                        )}
                </div>
                <div className="flex gap-2 items-center my-1">
                    <span className="text-nowrap">Linked Media:</span>
                    <LinkedMediaList
                        linkedMedia={document.linkedMedia}
                        documentId={document.id}
                    />
                </div>
                <div className="flex gap-2 items-center my-1">
                    <span className="text-nowrap">Linked to:</span>
                    <ItemResourceMappingList
                        itemInfo={{
                            itemType: "document",
                            itemId: document.id,
                        }}
                        resourceMappingRelations={
                            document.documentResourceMappingRelations
                        }
                    />
                </div>
                <div className="flex gap-2 items-center my-1">
                    Tags:
                    <ItemCustomerTagList
                        itemInfo={{
                            itemType: "document",
                            itemId: document.id,
                        }}
                        customerTags={document.customerTags}
                    />
                </div>

                <div className="mt-4 w-full text-xs truncate">
                    <span className="flex items-center">
                        Created by{" "}
                        <TenantUserAvatar
                            tenantUser={document.createdByTenantUser}
                            className="h-6 w-6"
                        />{" "}
                        at {humanizeDate(document.createdAt)}
                    </span>
                </div>
            </div>
            <div className="max-w-screen-md w-full mx-auto bg-slate-50 overflow-y-auto px-4 py-2">
                {yjsDoc && <TiptapEditor document={yjsDoc} />}
            </div>
        </div>
    );
};

export default DocumentDetail;
