import { PUSHER_EVENT, PUSHER_EVENT_DATA } from "@constant/pusher-events";
import { CloserQueryKey } from "@constant/query-key";
import { useQueryClient } from "@tanstack/react-query";
import Pusher from "pusher-js";
import { FC, useEffect, useRef } from "react";
import { TenantUser } from "types/backend";

type PusherSocketProps = {
    user: TenantUser;
};

const PusherSocket: FC<PusherSocketProps> = ({ user }) => {
    const pusher = useRef<Pusher>();
    const queryClient = useQueryClient();

    useEffect(() => {
        const p = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
            cluster: "sa1",
        });
        const channel = p.subscribe(`${user.id}.event-channel`);
        channel.bind(
            PUSHER_EVENT.INVALIDATE_QUERY,
            (data: PUSHER_EVENT_DATA["INVALIDATE_QUERY"]) => {
                queryClient.invalidateQueries({
                    queryKey: data.queryKey,
                    exact: data.exact,
                });
            },
        );
        channel.bind(
            PUSHER_EVENT.INVALIDATE_QUERY,
            (data: [CloserQueryKey, ...string[]]) => {
                queryClient.invalidateQueries({
                    queryKey: data,
                    exact: true,
                });
            },
        );

        pusher.current = p;

        return () => {
            p.disconnect();
            pusher.current = undefined;
        };
    }, []);

    return null;
};

export default PusherSocket;
