import { M } from "@constant/mutation-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation } from "@tanstack/react-query";

export default function useResourcePermissionSendEmail() {
    const { push } = usePopupNotification();
    const { mutateAsync } = useMutation({
        mutationKey: [M.SHARE_PERMISSION_SEND_EMAIL],
        mutationFn: async (
            body: {
                userId: string;
                userType: "TenantUser" | "Guest";
            } & Record<string, string>,
        ) => {
            await api.post("/resource-permissions/email", body);
        },
        onSuccess: () => {
            push({
                title: "Invitation email send",
                description:
                    "Guest can check their mailbox to get the preview link",
                type: "success",
            });
        },
    });
    return mutateAsync;
}
