import TenantUserAvatar from "@component-func/TenantUserAvatar";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@component-ui/utility/Table";
import { SEARCH_PARAMS } from "@constant/searchParams";
import { useDocumentTemplatesQuery } from "@hooks/template/useDocumentTemplatesQuery";
import { humanizeDate } from "@libs/duration";
import { usePostHog } from "posthog-js/react";
import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DocumentTemplate } from "types/backend/document_template";
import UpdateDocumentTemplateDialog from "./UpdateDocumentTemplateDialog";
import ItemCustomerTagList from "@component-func/customer-tag/ItemCustomerTagList";
import { ChevronRight } from "lucide-react";

const DocumentTemplateTitle: FC<DocumentTemplate> = (template) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <TableCell
            className="min-w-72"
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <div className="grid grid-cols-[1fr,max-content] items-center min-w-72 w-full">
                {template.title}
                {isHover && (
                    <UpdateDocumentTemplateDialog
                        documentTemplate={template}
                        onClose={() => setIsHover(false)}
                    />
                )}
            </div>
        </TableCell>
    );
};

const DocumentTemplateTable: FC = () => {
    const [search] = useSearchParams();
    const tagId = search.get(SEARCH_PARAMS.CURRENT_TAG);
    const {
        data: templates,
        isFetching,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useDocumentTemplatesQuery(tagId || "");
    const navigate = useNavigate();
    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture("document_template_table_page_navigated");
        posthog?.capture("$pageview", {
            pageName: tagId ? "/templates/with-tags" : "/templates",
        });
    }, [tagId]);

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return (
            <div className="h-full grid place-items-center">
                <Button onClick={() => refetch()}>Retry</Button>
            </div>
        );
    }

    return (
        <div className="h-full p-12 overflow-y-auto">
            {isFetching ? (
                <LoadingSpinnerCenter />
            ) : (
                <Table>
                    <TableCaption>List of Document Templates</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="flex justify-start items-center min-w-72 w-full">
                                Name
                            </TableHead>
                            <TableHead className="w-60">Tags</TableHead>
                            <TableHead className="text-center w-[120px]">
                                Created by
                            </TableHead>
                            <TableHead className="text-right w-[240px]">
                                Created at
                            </TableHead>
                            <TableHead className="w-12" />
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {templates.map((template) => (
                            <TableRow
                                key={template.id}
                                className="cursor-pointer"
                                onClick={() =>
                                    navigate(
                                        `/document-templates/${template.id}/`,
                                    )
                                }
                            >
                                <DocumentTemplateTitle {...template} />
                                <TableCell className="w-60">
                                    <ItemCustomerTagList
                                        itemInfo={{
                                            itemType: "document-template",
                                            itemId: template.id,
                                        }}
                                        customerTags={template.customerTags}
                                    />
                                </TableCell>
                                <TableCell>
                                    <div className="flex justify-center items-center">
                                        <TenantUserAvatar
                                            tenantUser={
                                                template.createdByTenantUser
                                            }
                                        />
                                    </div>
                                </TableCell>
                                <TableCell className="text-right">
                                    {humanizeDate(template.createdAt)}
                                </TableCell>
                                <TableCell className="text-right">
                                    <ChevronRight />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    {templates.length <= 0 && (
                        <TableFooter>
                            <TableRow>
                                <TableCell
                                    colSpan={6}
                                    className="text-center text-gray-400 tracking-wide"
                                >
                                    Do not have meeting yet, you can create a
                                    meeting and view it here
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
            )}
        </div>
    );
};

export default DocumentTemplateTable;
