import { Badge } from "@component-ui/utility/Badge";
import { Button } from "@component-ui/utility/Button";
import { RESOURCE_PLATFORM, RESOURCE_TYPE } from "@constant/resource";
import { useHubSpotDetail } from "@hooks/resource-mapping/useHubSpotDetailQuery";
import useRemoveResourceMappingRelationMutate from "@hooks/resource-mapping/useRemoveResourceMappingRelationMutate";
import { useSalesforceInstanceUrl } from "@hooks/resource-mapping/useSalesforceInstanceUrlQuery";
import { cn } from "@libs/utils";
import { Loader2, X } from "lucide-react";
import { FC, MouseEvent, useCallback, useMemo } from "react";
import {
    ResourceMappingItemInfo,
    ResourceMappingRelation,
} from "types/backend/resources";

type ResourceMappingBadgeProps = ResourceMappingRelation & {
    itemInfo?: ResourceMappingItemInfo;
};
type RemoveDocumentResourceMappingRelationButtonProps = {
    resourceMappingId: string;
    itemInfo: ResourceMappingItemInfo;
};

const RemoveDocumentResourceMappingRelationButton: FC<
    RemoveDocumentResourceMappingRelationButtonProps
> = (props) => {
    const { mutate: remove, isPending } =
        useRemoveResourceMappingRelationMutate(props.itemInfo.itemType);
    return (
        <Button
            variant={"ghost"}
            size={"xsIcon"}
            className="flex items-center justify-center ml-1"
            disabled={isPending}
            onClick={(event) => {
                event.stopPropagation();
                remove(props);
            }}
        >
            {isPending ? (
                <Loader2 className="w-3 h-3 animate-spin" />
            ) : (
                <X className="w-3 h-3" />
            )}
        </Button>
    );
};

const HubSpotResourceContent: FC<ResourceMappingBadgeProps> = ({
    resourceMapping: { resourceType, resourceName, resourceId, id },
    itemInfo,
}) => {
    const { data } = useHubSpotDetail();

    const navigateToResource = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            if (!data) return;
            const { uiDomain, portalId } = data;
            window.open(
                `https://${uiDomain}/contacts/${portalId}/${resourceType}/${resourceId}`,
                "_blank",
            );
        },
        [data],
    );

    const resourceTypeBackground = useMemo(() => {
        switch (resourceType) {
            case RESOURCE_TYPE.HUBSPOT.DEAL:
                return "bg-blue-100 hover:bg-blue-200";
            case RESOURCE_TYPE.HUBSPOT.CONTACT:
                return "bg-yellow-100 hover:bg-yellow-200";
            case RESOURCE_TYPE.HUBSPOT.COMPANY:
                return "bg-green-100 hover:bg-green-200";
            default:
                return "hidden";
        }
    }, [resourceType]);

    return (
        <Badge
            className={cn(
                "text-black cursor-pointer w-fit text-nowrap font-normal",
                resourceTypeBackground,
                itemInfo ? "h-6 pr-1" : "",
            )}
            onClick={navigateToResource}
        >
            {resourceType.toLocaleUpperCase()}: {resourceName}
            {itemInfo && (
                <RemoveDocumentResourceMappingRelationButton
                    resourceMappingId={id}
                    itemInfo={itemInfo}
                />
            )}
        </Badge>
    );
};

const SalesforceResourceContent: FC<ResourceMappingBadgeProps> = ({
    resourceMapping: { resourceType, resourceName, resourceId, id },
    itemInfo,
}) => {
    const { data } = useSalesforceInstanceUrl();

    const navigateToResource = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            if (!data) return;
            const { instanceUrl } = data;
            window.open(`${instanceUrl}/${resourceId}`, "_blank");
        },
        [data],
    );
    const resourceTypeBackground = useMemo(() => {
        switch (resourceType) {
            case RESOURCE_TYPE.SALESFORCE.OPPORTUNITY:
                return "bg-blue-100 hover:bg-blue-200";
            case RESOURCE_TYPE.SALESFORCE.CONTACT:
                return "bg-yellow-100 hover:bg-yellow-200";
            case RESOURCE_TYPE.SALESFORCE.ACCOUNT:
                return "bg-green-100 hover:bg-green-200";
            default:
                return "hidden";
        }
    }, [resourceType]);

    return (
        <Badge
            className={cn(
                "text-black cursor-pointer w-fit text-nowrap font-normal",
                resourceTypeBackground,
                itemInfo ? "h-6 pr-1" : "",
            )}
            onClick={navigateToResource}
        >
            {resourceType.toLocaleUpperCase()}: {resourceName}
            {itemInfo && (
                <RemoveDocumentResourceMappingRelationButton
                    resourceMappingId={id}
                    itemInfo={itemInfo}
                />
            )}
        </Badge>
    );
};

const ResourceMappingBadge: FC<ResourceMappingBadgeProps> = (props) => {
    return (
        <>
            {props.resourceMapping.resourcePlatform ===
                RESOURCE_PLATFORM.HUBSPOT && (
                <HubSpotResourceContent {...props} />
            )}
            {props.resourceMapping.resourcePlatform ===
                RESOURCE_PLATFORM.SALESFORCE && (
                <SalesforceResourceContent {...props} />
            )}
        </>
    );
};

export default ResourceMappingBadge;
