import { Button } from "@component-ui/utility/Button";
import { LOCALSTORAGE } from "@constant/localstorage-key";
import { useAuth } from "@context/auth";
import api from "@libs/api";
import { setUser } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { ComponentProps, FC } from "react";

type LogoutButtonProps = {
    className?: ComponentProps<"div">["className"];
};

const LogoutButton: FC<LogoutButtonProps> = ({ className }) => {
    const { setIsAuth, setIsActive } = useAuth();
    const queryClient = useQueryClient();
    const posthog = usePostHog();

    const logout = () => {
        window.localStorage.removeItem(LOCALSTORAGE.CLOSER_JWT);
        window.localStorage.removeItem(LOCALSTORAGE.CLOSER_ACCOUNT_ACTIVE);
        window.localStorage.removeItem(LOCALSTORAGE.CLOSER_CURRENT_TEAM);
        setIsAuth(false);
        api.defaults.headers["Authorization"] = "";
        setIsActive(false);
        queryClient.clear();
        window.$chatwoot.reset();
        setUser(null);
        posthog?.capture("user_logged_out");
        posthog?.reset();
    };

    return (
        <Button onClick={logout} className={className}>
            Logout
        </Button>
    );
};

export default LogoutButton;
