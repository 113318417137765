import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { useParams } from "react-router-dom";
import { Loader2, MessageSquareMore } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { PageError } from "@component-layout/PageError";
import { useGetGoogleMeetingsQuery } from "@hooks/google-meet/useGetGoogleMeetingsQuery";
import GoogleMeetingListItem from "./GoogleMeetingListItem";

const GoogleMeetingList: React.FC = () => {
    const {
        isLoading,
        data,
        isError,
        isSuccess,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useGetGoogleMeetingsQuery();
    const { googleMeetingId } = useParams();

    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture("google_meeting_list");
        posthog?.capture("$pageview", { pageName: "/google/meetings" });  
    }, []);

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (isError || !isSuccess) {
        return (
            <PageError description="please contact us" page="ZoomMeetingList" />
        );
    }

    return (
        <aside className="h-full w-full grid grid-rows-[max-content,1fr] gap-2 p-2">  
            <span className="inline-flex items-center gap-2">
                <MessageSquareMore className="min-w-[40px] w-10 h-10" />
                <p className="text-2xl font-medium leading-none">
                    Google Meetings Recordings
                </p>
            </span>
            <ul className="h-full overflow-auto">
                {data.pages.map((googleMeetPagination, index) =>
                    googleMeetPagination.meetings.map((googleMeet) => (
                        <li key={index}>
                            <GoogleMeetingListItem
                                item={googleMeet}
                                currentGoogleMeetingId={googleMeetingId}
                            />
                        </li>
                    )),
                )}
                {hasNextPage && (
                    <div className="grid grid-flow-row w-full items-center rounded-md mb-2 p-2 hover:bg-gray-100/90 overflow-hidden shadow-sm">
                        {isFetchingNextPage ? (
                            <div className="flex flex-col justify-center items-center">
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            </div>
                        ) : (
                            <button
                                className="flex flex-col justify-center items-center gap-x-6 py-1"
                                onClick={() => fetchNextPage()}
                            >
                                fetch more
                            </button>
                        )}
                    </div>
                )}
                <li>
                    <div className="grid grid-flow-row w-full items-center rounded-md mb-2 p-2 hover:bg-gray-100/90 overflow-hidden shadow-sm">
                        <div className="flex flex-col justify-center items-center gap-x-6 py-1">
                            <span> Only showing Google Meet recordings</span>
                            <span> from last 90 days</span>
                        </div>
                    </div>
                </li>
            </ul>
        </aside>
    );
};

export default GoogleMeetingList;
