import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { Media } from "types/backend";

export default function useRecordingCacheCheckQuery() {
    const query = useQuery({
        queryKey: [Q.RECORDING_CACHE_CHECK],
        queryFn: async () => {
            const { data } = await api.get<{
                cached: boolean;
                progressingMedia: Media[];
            }>("/media/healthcheck/cache");
            return data;
        },
        refetchOnMount: "always",
    });

    return query;
}
