import api from "@libs/api";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";

export default function useForgotPassword() {
    const posthog = usePostHog();

    const mutation = useMutation<
        { status: string },
        AxiosError,
        { email: string }
    >({
        mutationFn: async ({ email }) => {
            const { data } = await api.post<{ status: string }>(
                `/tenant-users/reset_password`,
                {
                    email,
                    platform: "customer",
                },
            );
            return data;
        },
        onSuccess: () => {
            posthog?.capture('forgot_password_requested');
        },
    });

    return mutation;
}
