import { Button, IconButtonWithTooltip } from "@component-ui/utility/Button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@component-ui/utility/Dialog";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";
import { Switch } from "@component-ui/utility/Switch";
import { Textarea } from "@component-ui/utility/Textarea";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import { FORM_KEY } from "@constant/form";
import { usePopupNotification } from "@context/popup-notification";
import useCreateSummarizingSystemMessageMutate from "@hooks/summarize/useCreateSummarizingSystemMessageMutate";
import { Plus } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { FC, FormEvent, useEffect, useState } from "react";

const CreateSystemMessageDialogCapture = () => {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("$pageview", {
            pageName: "/system-messages/new",
        });
    }, []);

    return null;
};

const CreateSystemMessageDialog: FC = () => {
    const { mutateAsync: createSummarizingSystemMessage } =
        useCreateSummarizingSystemMessageMutate();
    const { push } = usePopupNotification();
    const [dialogOpen, setDialogOpen] = useState(false);

    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        await createSummarizingSystemMessage(formData);
        push({
            title: "Successfully create summarizing prompt",
            description: "You can check the new prompt now",
            type: "success",
        });
        setDialogOpen(false);
    }

    return (
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogTrigger asChild>
                <IconButtonWithTooltip
                    variant="ghost"
                    size="icon"
                    icon={<Plus />}
                    content="Add new prompt"
                />
            </DialogTrigger>
            <DialogContent
                onInteractOutside={(e) => e.preventDefault()}
                onOpenAutoFocus={(e) => e.preventDefault()}
            >
                <CreateSystemMessageDialogCapture />
                <DialogHeader>
                    <DialogTitle>
                        Create new prompt for summarization
                    </DialogTitle>
                    <DialogDescription>
                        Create your own customized prompt to analyze the
                        transcript for the team.
                    </DialogDescription>
                    <DialogDescription>
                        You can create your Slack web-hook url{" "}
                        <a
                            href="https://api.slack.com/apps?new_app=1"
                            target="_blank"
                            rel="noreferrer"
                            className=" text-blue-600 hover:underline"
                        >
                            here
                        </a>
                        .
                    </DialogDescription>
                    <TooltipProvider delayDuration={300}>
                        <form
                            className="grid gap-4 py-4"
                            onSubmit={handleSubmit}
                        >
                            <div className="grid grid-cols-4 items-center gap-4">
                                <Tooltip>
                                    <TooltipTrigger type="button">
                                        <Label
                                            htmlFor={
                                                FORM_KEY.SUMMARIZATION.LABEL
                                            }
                                            className="text-right"
                                        >
                                            Label
                                        </Label>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        The label displayed at the AI
                                        Summarization section.
                                    </TooltipContent>
                                </Tooltip>
                                <Input
                                    id={FORM_KEY.SUMMARIZATION.LABEL}
                                    name={FORM_KEY.SUMMARIZATION.LABEL}
                                    className="col-span-3"
                                    required
                                    maxLength={21}
                                />
                            </div>
                            <div className="grid grid-cols-4 items-center gap-4">
                                <Tooltip>
                                    <TooltipTrigger type="button">
                                        <Label
                                            htmlFor={
                                                FORM_KEY.SUMMARIZATION
                                                    .SYSTEM_MESSAGE
                                            }
                                            className="text-right"
                                        >
                                            Prompt
                                        </Label>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        The instruction for the AI to summarize
                                        your transcript
                                    </TooltipContent>
                                </Tooltip>
                                <Textarea
                                    id={FORM_KEY.SUMMARIZATION.SYSTEM_MESSAGE}
                                    name={FORM_KEY.SUMMARIZATION.SYSTEM_MESSAGE}
                                    className="col-span-3"
                                    required
                                    maxLength={1000}
                                />
                            </div>
                            <div className="grid grid-cols-4 items-center gap-4">
                                <Tooltip>
                                    <TooltipTrigger type="button">
                                        <Label
                                            htmlFor={
                                                FORM_KEY.SUMMARIZATION
                                                    .SLACK_WEBHOOK_URL
                                            }
                                            className="text-right inline-grid grid-rows-2"
                                        >
                                            <span>Slack url</span>
                                            <span className="text-muted-foreground text-xs">
                                                (Optional)
                                            </span>
                                        </Label>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        The summarization will automatically
                                        send through the web-hook to your Slack
                                        channel
                                    </TooltipContent>
                                </Tooltip>
                                <Input
                                    id={
                                        FORM_KEY.SUMMARIZATION.SLACK_WEBHOOK_URL
                                    }
                                    name={
                                        FORM_KEY.SUMMARIZATION.SLACK_WEBHOOK_URL
                                    }
                                    className="col-span-3"
                                />
                            </div>
                            <div className="grid grid-cols-4 items-center gap-4">
                                <Tooltip>
                                    <TooltipTrigger type="button">
                                        <Label
                                            htmlFor={
                                                FORM_KEY.SUMMARIZATION
                                                    .AUTO_TRIGGER
                                            }
                                            className="text-right"
                                        >
                                            Auto trigger
                                        </Label>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        The summarization would be automatically
                                        triggered for your further recordings.
                                    </TooltipContent>
                                </Tooltip>
                                <Switch
                                    id={FORM_KEY.SUMMARIZATION.AUTO_TRIGGER}
                                    name={FORM_KEY.SUMMARIZATION.AUTO_TRIGGER}
                                    defaultChecked
                                />
                            </div>
                            <DialogFooter>
                                <Button type="submit">Create</Button>
                            </DialogFooter>
                        </form>
                    </TooltipProvider>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
};

export default CreateSystemMessageDialog;
