import EmailAvatar from "@component-func/EmailAvatar";
import { Button } from "@component-ui/utility/Button";
import dayjs from "@libs/dayjs";
import { FC, useMemo } from "react";
import { GoogleCalendarEvent as GoogleCalendarEventType } from "types/backend/google_calendar";
import { rrulestr } from "rrule";

type GoogleCalendarEventProps = {
    calendarEvent: GoogleCalendarEventType;
};

const GoogleCalendarEvent: FC<GoogleCalendarEventProps> = ({
    calendarEvent,
}) => {
    const zoomEndpoint = useMemo(() => {
        if (calendarEvent.conferenceData) {
            const entryPoint = calendarEvent.conferenceData.entryPoints.find(
                (entry) =>
                    entry.entryPointType === "video" &&
                    entry.uri.includes("zoom.us"),
            );
            if (entryPoint) {
                return entryPoint.uri;
            }
        }
        if (
            calendarEvent.location &&
            calendarEvent.location.includes("zoom.us")
        ) {
            return calendarEvent.location;
        }
        return "";
    }, [calendarEvent]);
    const googleMeetEndpoint = useMemo(() => {
        if (!calendarEvent.conferenceData) return "";
        const entryPoint = calendarEvent.conferenceData.entryPoints.find(
            (entry) =>
                entry.entryPointType === "video" &&
                entry.uri.includes("meet.google"),
        );
        if (entryPoint) {
            return entryPoint.uri;
        }
        return "";
    }, [calendarEvent]);

    const joinMeeting = (platform: "zoom" | "google-meet") => {
        if (platform === "zoom" && zoomEndpoint) {
            open(zoomEndpoint, "_blank");
        }
        if (platform === "google-meet" && googleMeetEndpoint) {
            open(googleMeetEndpoint, "_blank");
        }
        return;
    };

    return (
        <div className="rounded-lg w-full shadow-md mb-2 p-2 text-sm">
            {calendarEvent.status === "cancelled" ? (
                <div className="text-gray-500 italic">Cancelled Event</div>
            ) : (
                <>
                    <div className="text-lg mb-2">{calendarEvent.summary}</div>
                    {calendarEvent.attendees && (
                        <div className="flex">
                            <span className="mt-0.5">Attendees: </span>
                            <div className="flex flex-wrap">
                                {calendarEvent.attendees.map((attendee) => (
                                    <EmailAvatar
                                        className="w-6 h-6"
                                        email={attendee.email}
                                        key={attendee.email}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="flex justify-between items-center">
                        {calendarEvent.start?.date ? (
                            <div>
                                Date:{" "}
                                {dayjs(calendarEvent.start.date)
                                    .tz(calendarEvent.start.timeZone)
                                    .format("DD/MM/YY")}
                            </div>
                        ) : (
                            <div>
                                {calendarEvent.start && (
                                    <div>
                                        Start:{" "}
                                        {dayjs(calendarEvent.start.dateTime)
                                            .tz(calendarEvent.start.timeZone)
                                            .format("DD/MM/YY HH:mm")}
                                    </div>
                                )}
                                {calendarEvent.end && (
                                    <div>
                                        End:{" "}
                                        {dayjs(calendarEvent.end.dateTime)
                                            .tz(calendarEvent.end.timeZone)
                                            .format("DD/MM/YY HH:mm")}
                                    </div>
                                )}
                                {calendarEvent.recurrence && (
                                    <div className="flex">
                                        <div className="mr-2">
                                            Repeat rules:{" "}
                                        </div>
                                        <div className="flex flex-col">
                                            {calendarEvent.recurrence.map(
                                                (rec) => (
                                                    <span
                                                        key={rec}
                                                        className="italic"
                                                    >
                                                        {rrulestr(rec).toText()}
                                                    </span>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="flex items-center">
                            Organizer:{" "}
                            <EmailAvatar
                                email={calendarEvent.creator?.email || "-"}
                                className="w-8 h-8"
                            />
                        </div>
                    </div>
                    <div className="flex justify-end">
                        {zoomEndpoint && (
                            <Button
                                className="bg-blue-500 hover:bg-blue-900 text-white text-lg"
                                onClick={() => joinMeeting("zoom")}
                            >
                                Join Zoom Meeting
                            </Button>
                        )}
                        {googleMeetEndpoint && (
                            <Button
                                className="bg-blue-500 hover:bg-blue-900 text-white text-lg"
                                onClick={() => joinMeeting("google-meet")}
                            >
                                Join Google Meeting
                            </Button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default GoogleCalendarEvent;
