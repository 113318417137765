import { Button, ButtonProps } from "@component-ui/utility/Button";
import { useOnboardProgressStatusStore } from "@store/onboard-progress-status-store";
import { FC } from "react";

const OnboardProgressDisplayButton: FC<Omit<ButtonProps, "onClick">> = (
    props,
) => {
    const displayCheckList =
        useOnboardProgressStatusStore.use.displayCheckList();
    const setDisplayCheckList =
        useOnboardProgressStatusStore.use.setDisplayCheckList();

    return (
        <Button {...props} onClick={setDisplayCheckList}>
            {displayCheckList ? "Hide" : "Show"} Onboarding Tour check list
        </Button>
    );
};

export default OnboardProgressDisplayButton;
