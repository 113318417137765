import { LOCALSTORAGE } from "@constant/localstorage-key";
import { Q } from "@constant/query-key";
import { useAuth } from "@context/auth";
import api, { API_HEADERS_KEY } from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { TenantUserSelfInformation } from "types/backend/tenant-user";

export default function useUserSelfInformation() {
    const { isAuth } = useAuth();
    const currentTeam = !!window.localStorage.getItem(
        LOCALSTORAGE.CLOSER_CURRENT_TEAM,
    );
    const query = useQuery({
        queryKey: [Q.GET_USER_SELF_INFORMATION],
        queryFn: async () => {
            const { data } = await api.get<TenantUserSelfInformation>(
                "/tenant-users/user-info",
            );
            return data;
        },
        refetchOnWindowFocus: false,
        retry: currentTeam
            ? (count, error) => {
                  if (error instanceof AxiosError) {
                      if (error.response?.status === 403) {
                          window.localStorage.removeItem(
                              LOCALSTORAGE.CLOSER_CURRENT_TEAM,
                          );
                          delete api.defaults.headers[
                              API_HEADERS_KEY.CURRENT_TEAM
                          ];
                          return true;
                      }
                  }
                  return false;
              }
            : 1,
        staleTime: 24 * 60 * 60 * 1000,
        gcTime: 25 * 60 * 60 * 1000,
        enabled: isAuth,
    });

    return query;
}
