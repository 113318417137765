import { FORM_KEY } from "@constant/form";
import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { usePostHog } from "posthog-js/react";
import { CreateMediaRequestBody, Media } from "types/backend/media";

function validateFormData(formData: FormData) {
    const meetingId = formData.get(FORM_KEY.MEETING.ID) as string;
    const language = formData.get(FORM_KEY.MEDIA.LANGUAGE) as string;
    const mimeType = formData.get(FORM_KEY.MEDIA.MIMETYPE) as string;
    if (!meetingId)
        throw new Error("Invalid meeting, please select a correct meeting");
    if (!language)
        throw new Error("Invalid language, please select a correct language");
    if (!mimeType)
        throw new Error("Invalid file type, please select a correct file type");
    return {
        meetingId,
        language,
        mimeType,
    };
}

export function useCreateMediaMutate() {
    const { push } = usePopupNotification();
    const posthog = usePostHog();
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationKey: [M.CREATE_MEDIA],
        mutationFn: async (formData: FormData) => {
            const { meetingId, language, mimeType } =
                validateFormData(formData);
            posthog?.capture("recording_media_created");
            const { data } = await api.post<
                Media,
                AxiosResponse<Media>,
                CreateMediaRequestBody
            >("/media", {
                meetingId,
                language,
                mimeType,
                key: "",
            });
            return data;
        },
        onError: (err) => {
            const errorMessage =
                err instanceof AxiosError
                    ? err.response?.data.message
                    : err.message;

            push({
                title: "Cannot create media",
                description: errorMessage,
                type: "destructive",
            });
        },
        onSuccess: ({ meetingId }) => {
            queryClient.invalidateQueries({
                queryKey: [Q.GET_MEETING_MEDIA, meetingId],
            });
        },
    });
    return mutate;
}
