export const SEARCH_PARAMS = {
    MEDIA_TARGET_KEY: "key",
    MIMETYPE: "type",
    CURRENT_SELECTED_TRANSCRIPT: "current_transcript",
    CURRENT_DOCUMENT: "document",
    CURRENT_SELECTED_TRANSCRIPT_SEGMENT_ID: "current_transcript_segment_id",
    CURRENT_SPEAKER: "current_speaker",
    CURRENT_TAG: "current_tag",
    OAUTH_CODE: "code",
    OAUTH_REDIRECT: "redirect",
    OAUTH_PLATFORM: "platform",
    MEETING_ID: "Meeting",
    MEDIA_ID: "Media",
    DOCUMENT_ID: "Document",
    VALIDATION_TOKEN: "validationToken",
} as const;
