import { Q } from "@constant/query-key";
import { isMatchedResource } from "@constant/resource";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import {
    ResourceOption,
    ResourcePlatform,
    ResourceType,
} from "types/backend/resources";

async function fetchAssociatedResourceOptions(
    resourcePlatform: ResourcePlatform,
    resourceType: ResourceType,
    resourceId: string,
    associatedResourceType: ResourceType,
) {
    const { data } = await api.get<Array<ResourceOption>>(
        `/resource-config/${resourcePlatform}/${resourceType}/${resourceId}/${associatedResourceType}/options`,
    );
    return data;
}

export default function useCrmAssociatedResourceOptionsQuery(
    resourcePlatform: ResourcePlatform,
    resourceType: ResourceType,
    resourceId: string,
    associatedResourceType: ResourceType,
    enabled = true,
) {
    const query = useQuery({
        queryKey: [
            Q.GET_CRM_ASSOCIATED_RESOURCE_OPTIONS,
            resourcePlatform,
            resourceType,
            resourceId,
            associatedResourceType,
        ],
        queryFn: () =>
            fetchAssociatedResourceOptions(
                resourcePlatform,
                resourceType,
                resourceId,
                associatedResourceType,
            ),
        enabled:
            enabled &&
            !!resourceId &&
            isMatchedResource(resourcePlatform, resourceType) &&
            isMatchedResource(resourcePlatform, associatedResourceType),
    });
    return query;
}
