import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";
import { CustomerTag, CustomerTagItemType } from "types/backend/customer_tags";

type TagItemInput = {
    customerTagId: string;
    itemId: string;
};

export default function useTaggingMutate(itemType: CustomerTagItemType) {
    const queryClient = useQueryClient();
    const { push } = usePopupNotification();
    const posthog = usePostHog()

    const mutation = useMutation({
        mutationKey: [M.TAG_ITEM, { itemType }],
        mutationFn: async ({ customerTagId, itemId }: TagItemInput) => {
            posthog?.capture(`${itemType}_tagging_created`)
            const { data } = await api.patch<CustomerTag>(
                `/customer-tags/${customerTagId}/${itemType}/${itemId}`,
            );
            return data;
        },
        onSuccess: (data, { itemId }) => {
            const listQueryKey =
                itemType === "meeting"
                    ? Q.GET_MEETINGS
                    : itemType === "document"
                      ? Q.GET_ALL_DOCUMENT
                      : Q.GET_ALL_DOCUMENT_TEMPLATE;
            queryClient.invalidateQueries({ queryKey: [listQueryKey] });
            if (itemType === "document") {
                queryClient.invalidateQueries({
                    queryKey: [Q.GET_DOCUMENT, itemId],
                    exact: true,
                });
            }
            if (itemType === "document-template") {
                queryClient.invalidateQueries({
                    queryKey: [Q.GET_DOCUMENT_TEMPLATE, itemId],
                    exact: true,
                });
            }
            push({
                title: `Successfully tag ${itemType} with ${data.name} `,
                description: "",
                type: "success",
            });
        },
        onError: (error) => {
            const errorMessage =
                error instanceof AxiosError
                    ? error.response?.data.message
                    : error.message;
            push({
                title: "Failed to tag",
                description: `Error occurred due to ${errorMessage}`,
                type: "destructive",
            });
        },
    });
    return mutation;
}
