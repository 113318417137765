import { Button } from "@component-ui/utility/Button";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@component-ui/utility/Dialog";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";
import { FORM_KEY } from "@constant/form";
import {
    ComponentProps,
    Dispatch,
    FC,
    FormEvent,
    SetStateAction,
    useState,
} from "react";
import DeleteRecordingAlertDialog from "./DeleteRecordingAlertDialog";
import useTriggerConcatMutate from "@hooks/recording/useTriggerConcatMutate";
import { usePopupNotification } from "@context/popup-notification";
import { useAudioRecorderStore } from "@store/audio-recorder-store";
import useDeleteCachedRecording from "@hooks/recording/useDeleteCachedRecordingMutate";
import useSoftDeleteMediaMutate from "@hooks/media/useSoftDeleteMediaMutate";

function validateFormData(formData: FormData) {
    const mediaName = formData.get(FORM_KEY.MEDIA.NAME) as string;

    if (!mediaName) {
        throw Error("Invalid media name, please input a correct media name");
    }

    return {
        mediaName,
    };
}

const UploadRecordingDialog: FC<
    ComponentProps<typeof Dialog> & {
        setOuterDialogOpen: Dispatch<SetStateAction<boolean>>;
    }
> = ({ setOuterDialogOpen, ...props }) => {
    const [open, setOpen] = useState(true);
    const { push } = usePopupNotification();
    const { mutateAsync: concatMedia } = useTriggerConcatMutate();
    const meetingId = useAudioRecorderStore.use.meetingId();
    const mediaId = useAudioRecorderStore.use.mediaId();
    const reset = useAudioRecorderStore.use.resetInitialState();
    const { mutateAsync: deleteCachedRecording, isPending: isDeleting } =
        useDeleteCachedRecording();
    const { mutateAsync: deleteMedia, isPending: isDeletingMedia } =
        useSoftDeleteMediaMutate();

    const handleDelete = async () => {
        await deleteCachedRecording(undefined, {
            onSuccess: async () => {
                await deleteMedia(mediaId);
                reset();
            },
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        try {
            const { mediaName } = validateFormData(formData);
            await concatMedia({ meetingId, mediaId, mediaName });
            setOpen(false);
            reset();
            setOuterDialogOpen(false);
        } catch (err) {
            push({
                title: "Cannot upload file",
                description: (err as Error).message,
                type: "destructive",
            });
        }
    };

    return (
        <Dialog
            open={open}
            onOpenChange={(open) => {
                setOpen(open);
                setOuterDialogOpen(open);
                open === false && reset();
            }}
            {...props}
        >
            <DialogContent onPointerDownOutside={(e) => e.preventDefault()}>
                <DialogHeader>
                    <DialogTitle>Upload recording</DialogTitle>
                </DialogHeader>
                <form id="media-concat-form" onSubmit={handleSubmit}>
                    <Label htmlFor={FORM_KEY.MEDIA.NAME}>Media name</Label>
                    <Input
                        id={FORM_KEY.MEDIA.NAME}
                        name={FORM_KEY.MEDIA.NAME}
                        placeholder="media name..."
                    />
                </form>
                <DialogFooter>
                    <DeleteRecordingAlertDialog
                        handleDeleteCachedRecording={handleDelete}
                        isDeleting={isDeleting || isDeletingMedia}
                    />
                    <Button
                        type="submit"
                        form="media-concat-form"
                        disabled={isDeleting || isDeletingMedia}
                    >
                        Upload
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default UploadRecordingDialog;
