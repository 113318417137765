import { LOCALSTORAGE } from "@constant/localstorage-key";
import api from "@libs/api";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";
import { TenantUserAuth } from "types/backend/tenant-user";

export default function useVerification() {
    const posthog = usePostHog();

    const mutation = useMutation<
        TenantUserAuth,
        AxiosError,
        { id: string; registerToken: string }
    >({
        mutationFn: async ({ id, registerToken }) => {
            const { data } = await api.post<TenantUserAuth>(
                `/tenant-users/verify`,
                {
                    id,
                    registerToken,
                },
            );
            return data;
        },
        onSuccess: ({ jwt, isActive, id, username }) => {
            posthog?.identify(id);
            posthog?.capture("user_verified", { tenantUserId: id, username });
            api.defaults.headers["Authorization"] = "Bearer " + jwt;
            window.localStorage.setItem(LOCALSTORAGE.CLOSER_JWT, jwt);
            window.localStorage.setItem(
                LOCALSTORAGE.CLOSER_ACCOUNT_ACTIVE,
                isActive.toString(),
            );
        },
    });

    return mutation;
}
