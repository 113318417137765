import FeatureList from "@component-func/Sidebar/FeatureList";
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "@component-ui/utility/Resizable";
import { FC } from "react";
import { Outlet } from "react-router-dom";

const MainLayout: FC = () => {
    return (
        <ResizablePanelGroup
            direction="horizontal"
            className="h-full w-full"
            autoSaveId="persistence"
        >
            <ResizablePanel defaultSize={20} minSize={15} maxSize={25}>
                <FeatureList />
            </ResizablePanel>
            <ResizableHandle withHandle />
            <ResizablePanel defaultSize={80} minSize={75} maxSize={85}>
                <Outlet />
            </ResizablePanel>
        </ResizablePanelGroup>
    );
};

export default MainLayout;
