import { cn } from "@libs/utils";
import { VariantProps, cva } from "class-variance-authority";
import { Loader2 } from "lucide-react";
import { FC } from "react";

const loadingSpinnerCenterVariants = cva("animate-spin", {
    variants: {
        size: {
            default: "w-8 h-8",
            sm: "w-4 h-4",
            md: "w-6 h-6",
            lg: "w-8 h-8",
        },
    },
    defaultVariants: {
        size: "default",
    },
});

export type LoadingSpinnerCenterProps = VariantProps<
    typeof loadingSpinnerCenterVariants
>;

const LoadingSpinnerCenter: FC<LoadingSpinnerCenterProps> = ({ size }) => {
    return (
        <div className="h-full grid place-items-center">
            <Loader2 className={cn(loadingSpinnerCenterVariants({ size }))} />
        </div>
    );
};

export default LoadingSpinnerCenter;
