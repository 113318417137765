import { FC, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import { useAuth } from "@context/auth";
import useUserSelfInformation from "@hooks/user/useUserSelfInformationQuery";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { usePostHog } from "posthog-js/react";
import {
    setTag as setSentryTag,
    setUser as setSentryUser,
} from "@sentry/react";
import { TenantUserSelfInformation } from "types/backend/tenant-user";
import { Provider, Tour, useGroup, useUser } from "@frigade/react";
import { FRIGADE_FLOW_ID } from "@constant/frigade-flows";
import FrigadeOnboardingProgress from "@component-func/onboarding-tour/FrigadeOnboardingProgress";

const AppLayoutContent: FC<{ tenantUser: TenantUserSelfInformation }> = ({
    tenantUser,
}) => {
    const { addProperties } = useUser();
    const { addProperties: addGroupProperties } = useGroup();
    const posthog = usePostHog();

    useEffect(() => {
        // INFO: Posthog
        posthog?.identify(tenantUser.id, {
            id: tenantUser.id,
            name: tenantUser.username,
            email: tenantUser.email,
            firstName: tenantUser.firstName,
            lastName: tenantUser.lastName,
            tenantId: tenantUser.tenantId,
            tenant: tenantUser.tenant.name,
            teamId: tenantUser.teamId,
            team: tenantUser.team.name,
            teamPlan: tenantUser.team.plan,
        });
        posthog?.group("Team", tenantUser.teamId, {
            name: tenantUser.team.name,
            plan: tenantUser.team.plan,
        });

        // INFO: Sentry
        setSentryUser({
            id: tenantUser.id,
            name: tenantUser.username,
            teamId: tenantUser.teamId,
            teamName: tenantUser.team.name,
            tenantId: tenantUser.tenantId,
        });
        setSentryTag("team", tenantUser.team.name);

        // INFO: Frigade
        addGroupProperties({
            name: tenantUser.team.name,
        });
        addProperties({
            name: tenantUser.username,
            email: tenantUser.email,
            team: tenantUser.team.name,
            tenant: tenantUser.tenant.name,
            teamPlan: tenantUser.team.plan,
        });

        // INFO: Chatwoot
        const setChatwootUserInfo = () => {
            window.$chatwoot?.setUser(tenantUser.id, {
                email: tenantUser.email,
                name: tenantUser.username,
            });

            window.$chatwoot?.setCustomAttributes({
                team: tenantUser.team.name,
                teamId: tenantUser.teamId,
                teamPlan: tenantUser.team.plan,
            });
        };
        window.addEventListener("chatwoot:ready", setChatwootUserInfo);
        return window.removeEventListener(
            "chatwoot:ready",
            setChatwootUserInfo,
        );
    }, [tenantUser]);

    return (
        <div className="h-full w-full grid grid-rows-[max-content,1fr]">
            <NavBar />
            <Tour
                flowId={FRIGADE_FLOW_ID.TAGGING}
                onClick={(e) => e.stopPropagation()}
            />
            <Outlet />
        </div>
    );
};

const AppLayout: FC = () => {
    const { isAuth, isActive } = useAuth();
    const {
        data: tenantUser,
        isError,
        isSuccess,
        isLoading,
        isFetching,
    } = useUserSelfInformation();
    const navigate = useNavigate();

    const pathname = window.location.pathname;
    const search = window.location.search;
    if (pathname.split("/")[1] === "oauth") {
        return <Navigate to={`${pathname}${search}`} />;
    }

    if (!isAuth) return <Navigate to="/login" />;
    if (!isActive) return <Navigate to="/not_active" />;
    if (isLoading) return <LoadingSpinnerCenter />;
    if (isError || !isSuccess) return <Navigate to="/login" />;

    return (
        <Provider
            userId={tenantUser.id}
            groupId={tenantUser.teamId}
            apiKey={import.meta.env.VITE_FRIGADE_PUBLIC_API_KEY}
            navigate={(url, target) => {
                if (target === "_blank") {
                    window.open(url, "_blank");
                } else {
                    navigate(url);
                }
            }}
        >
            <FrigadeOnboardingProgress />
            {isFetching ? (
                <LoadingSpinnerCenter />
            ) : (
                <AppLayoutContent tenantUser={tenantUser} />
            )}
        </Provider>
    );
};

export default AppLayout;
