import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function useDeleteResourcePermissionMutate() {
    const { push } = usePopupNotification();
    const queryClient = useQueryClient();
    const mutate = useMutation({
        mutationKey: [M.DELETE_SHARE_PERMISSION],
        mutationFn: async (
            body: {
                userId: string;
                userType: "TenantUser" | "Guest";
            } & Record<string, string>,
        ) => {
            const { data } = await api.delete("/resource-permissions", {
                data: body,
            });
            return data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [Q.GET_RESOURCE_PERMISSION],
            });
        },
        onError: (error) => {
            push({
                title: "Invalid input",
                description: error.message,
                type: "destructive",
            });
        },
    });

    return mutate;
}
