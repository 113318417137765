import { isEmpty } from "lodash";
import { Button } from "@component-ui/utility/Button";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";

import { Loader2 } from "lucide-react";
import React, {
    FC,
    FormEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import useResetPassword from "@hooks/user/useResetPassword";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "@libs/api";
import { TenantUser } from "types/backend/tenant-user";
import { AxiosError, isAxiosError } from "axios";
import { usePopupNotification } from "@context/popup-notification";

interface InputError {
    url: null | string;
    password: string | null;
    repeatPassword: string | null;
}

const ResetPassword: FC = () => {
    const { mutate: resetPassword, isPending } = useResetPassword();
    const { push } = usePopupNotification();
    const [tenantUser, setTenantUser] = useState<TenantUser>();
    const passwordInput = useRef(null);
    const repeatPasswordInput = useRef(null);
    const [searchParams] = useSearchParams();
    const [initLoading, setInitLoading] = useState(true);
    const navigate = useNavigate();
    const [err, setError] = useState<InputError>({
        password: null,
        repeatPassword: null,
        url: null,
    });

    const resetPasswordToken = searchParams.get("resetPasswordToken");
    const id = searchParams.get("id");

    useEffect(() => {
        const fetchTenantUser = async (userId: string, token: string) => {
            try {
                const user = await api.get<TenantUser>(
                    `tenant-users/${userId}`,
                    {
                        params: { resetPasswordToken: token },
                    },
                );
                if (user) {
                    setTenantUser(user.data);
                    setInitLoading(false);
                }
            } catch (error) {
                if (isAxiosError(error)) {
                    if ((error as AxiosError).response?.status === 404) {
                        setError({
                            ...err,
                            url: "Reset Password Token and id doesn't match.",
                        });
                    }
                }
                setInitLoading(false);
            } finally {
                setInitLoading(false);
            }
        };

        if (!id || !resetPasswordToken) {
            setError({
                ...err,
                url: "Please use a correct url to enter this page. ",
            });
            setInitLoading(false);
        }
        if (typeof id === "string" && typeof resetPasswordToken === "string") {
            fetchTenantUser(id, resetPasswordToken);
        }
    }, []);

    const handleRestPassword = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            try {
                const formData = new FormData(e.currentTarget);
                const password = formData.get("password") as string;
                const repeatPassword = formData.get(
                    "repeat_password",
                ) as string;

                const updated: InputError = {
                    url: err.url,
                    password: isEmpty(password)
                        ? "Please enter password"
                        : err.password,
                    repeatPassword: isEmpty(repeatPassword)
                        ? "Please enter repeat password"
                        : err.repeatPassword,
                };
                const passValidation: boolean = Object.values(updated).reduce(
                    (p, c) => {
                        if (!isEmpty(c)) {
                            return false;
                        }
                        return p;
                    },
                    true,
                );

                if (passValidation && id && resetPasswordToken) {
                    resetPassword(
                        { id, password, resetPasswordToken },
                        {
                            onSuccess: () => {
                                navigate("/");
                            },
                            onError: () => {
                                push({
                                    type: "destructive",
                                    title: "Cannot reset password",
                                    description:
                                        "Unexpected error, please contact admin",
                                });
                            },
                        },
                    );
                } else {
                    setError(updated);
                }
            } catch {
                // todo
            }
        },
        [err],
    );

    const repeatPasswordVerify = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.currentTarget.value) {
                checkIsEmpty("repeatPassword", event.currentTarget.value);
            } else {
                if (passwordInput.current) {
                    if (
                        event.currentTarget.value !==
                        (passwordInput.current as HTMLInputElement).value
                    ) {
                        setError({
                            ...err,
                            repeatPassword:
                                "Password and Repeat Password does not match.",
                        });
                    } else {
                        setError({
                            ...err,
                            repeatPassword: null,
                        });
                    }
                }
            }
        },
        [passwordInput.current, err],
    );
    const passwordVerify = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.currentTarget.value) {
                checkIsEmpty("password", event.currentTarget.value);
            } else {
                const update: InputError = {
                    ...err,
                    password: null,
                };
                if (repeatPasswordInput.current) {
                    if (
                        event.currentTarget.value ===
                        (repeatPasswordInput.current as HTMLInputElement).value
                    ) {
                        update.repeatPassword = null;
                    } else {
                        update.repeatPassword =
                            "Password and Repeat Password does not match.";
                    }
                }

                setError(update);
            }
        },
        [repeatPasswordInput.current, err],
    );

    const checkIsEmpty = useCallback(
        (type: keyof InputError, value: string | null | undefined) => {
            const _isEmpty = isEmpty(value);
            switch (type) {
                case "password":
                    if (_isEmpty) {
                        setError({ ...err, password: "Please enter password" });
                    } else {
                        setError({ ...err, password: null });
                    }
                    break;
                case "repeatPassword":
                    if (_isEmpty) {
                        setError({
                            ...err,
                            repeatPassword: "Please enter repeat password",
                        });
                    } else {
                        setError({ ...err, repeatPassword: null });
                    }
                    break;
            }
        },
        [err],
    );

    if (initLoading) {
        return (
            <div role="status" className="flex justify-center">
                <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                    />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                    />
                </svg>
                <span className="sr-only">Loading...</span>
            </div>
        );
    }

    return (
        <div className="w-full h-full grid place-items-center">
            <div className="w-full max-w-lg flex flex-col justify-center items-center p-4 rounded-md border-2 border-gray-400">
                <h1 className="text-2xl">Reset Password</h1>
                {err.url && (
                    <h1 className="text-sm text-rose-600">{err.url}</h1>
                )}
                <form className="w-full" onSubmit={handleRestPassword}>
                    <Label className="mb-10" htmlFor="email">
                        Email
                    </Label>
                    <Input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter email"
                        value={tenantUser?.email}
                        required
                        disabled
                    />
                    <Label htmlFor="password">Password</Label>
                    <Input
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Enter password"
                        onChange={passwordVerify}
                        onBlur={passwordVerify}
                        ref={passwordInput}
                    />
                    {err.password && (
                        <div className="my-1">
                            <span className="text-rose-600 text-xs">
                                {err.password}
                            </span>
                        </div>
                    )}
                    <Label htmlFor="repeat_password">Repeat Password</Label>
                    <Input
                        id="repeat_password"
                        name="repeat_password"
                        type="password"
                        placeholder="Repeat password"
                        onBlur={repeatPasswordVerify}
                        onChange={repeatPasswordVerify}
                        ref={repeatPasswordInput}
                    />
                    {err.repeatPassword && (
                        <div className="my-1">
                            <span className="text-rose-600 text-xs">
                                {err.repeatPassword}
                            </span>
                        </div>
                    )}
                    <Button
                        className="w-full mt-4"
                        type="submit"
                        disabled={err.url ? true : false || isPending}
                    >
                        {isPending && (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Confirm
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
