import useUserSelfInformation from "@hooks/user/useUserSelfInformationQuery";
import PusherSocket from "./PusherSocket";

const PusherWrapper = () => {
    const { data: user, isSuccess, isError } = useUserSelfInformation();

    if (!isSuccess || isError) return null;

    return <PusherSocket user={user} />;
};

export default PusherWrapper;
