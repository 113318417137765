import Empty from "@component-ui/Empty";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import {
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "@component-ui/utility/Dialog";
import useRecordingCacheCheckQuery from "@hooks/recording/useRecordingCacheCheckQuery";
import StartRecordingDialogContent from "./StartRecordingDialogContent";
import { Dispatch, FC, SetStateAction } from "react";
import HandleCachedRecordingDialog from "./HandleCachedRecordingDialog";

type RecordingProps = {
    setInputDeviceId: Dispatch<SetStateAction<string>>;
    // useCloserAudio: boolean;
    // setUseCloserAudio: Dispatch<SetStateAction<boolean>>;
    setupStream: () => Promise<MediaStream | undefined>;
    startRecord: (mediaStream: MediaStream) => void;
};

const PrerecordingDialogContent: FC<RecordingProps> = (props) => {
    return (
        <DialogContent>
            <PrerecordingContent {...props} />
        </DialogContent>
    );
};

const PrerecordingContent: FC<RecordingProps> = (props) => {
    const { data, isLoading, isFetching, isSuccess, isError } =
        useRecordingCacheCheckQuery();

    if (isLoading || isFetching) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return <Empty label="Recording function failed, please retry" />;
    }

    if (data.cached && data.progressingMedia.length > 0) {
        return (
            <>
                <DialogHeader>
                    <DialogTitle>Recording</DialogTitle>
                    <DialogDescription>
                        You have previous recording cache or the recording is
                        processing, please wait for a moment and check the
                        recording, if you feel it takes too long, please contact
                        us for help
                    </DialogDescription>
                </DialogHeader>
            </>
        );
    }

    if (data.cached && data.progressingMedia.length == 0) {
        return <HandleCachedRecordingDialog />;
    }

    if (!data.cached) {
        return <StartRecordingDialogContent {...props} />;
    }
};

export default PrerecordingDialogContent;
