import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";
import { RecordingDownloadTask } from "types/backend/zoom_download_task";

interface PostDownloadZoomBody {
    fileId: string;
    meetingName: string;

    language: "es" | "en-US" | "pt-BR";
}

export default function useMutationDownloadGoogleMeetRecording() {
    const posthog = usePostHog();
    const { push } = usePopupNotification();

    const mutation = useMutation<
        RecordingDownloadTask,
        AxiosError,
        PostDownloadZoomBody
    >({
        mutationFn: async ({ fileId, meetingName, language }) => {
            const { data } = await api.post<RecordingDownloadTask>(
                `/google-meets/${fileId}/download/`,
                { meetingName, language },
            );
            return data;
        },
        onSuccess: () => {
            posthog?.capture("zoom_recordings_downloaded");
        },
        onError: (error) => {
            if (axios.isAxiosError(error) && error.response?.status == 429) {
                push({
                    type: "destructive",
                    title: "Rate Limit",
                    description: "too many request in one minutes",
                });
            }
        },
    });

    return mutation;
}
