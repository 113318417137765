import { CircleOff } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { FC, useEffect } from "react";

export const PageError: FC<{ description: string; page: string }> = ({
    description,
    page,
}) => {
    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture(`${page}_error`);
    }, []);

    return (
        <div className="h-full grid place-items-center">
            <div className="flex flex-col items-center justify-center">
                <CircleOff color="red" size={100} className="mb-5" />
                <h1 className="text-3xl text-gray-400 tracking-wide font-light">
                    {description}
                </h1>
            </div>
        </div>
    );
};
