import { Extension } from "@tiptap/core";

export const BaseAttribute = Extension.create({
    name: "base-attribute",

    priority: 1000,

    addGlobalAttributes() {
        return [
            {
                types: ["listItem", "taskItem"],
                attributes: {
                    "data-id": {
                        keepOnSplit: false,
                        parseHTML: (element) => element.getAttribute("data-id"),
                        renderHTML: (attributes) => ({
                            "data-id": attributes["data-id"],
                        }),
                    },
                    "data-segment": {
                        keepOnSplit: false,
                        parseHTML: (element) =>
                            element.getAttribute("data-segment"),
                        renderHTML: (attributes) => ({
                            segment: attributes["data-segment"],
                        }),
                    },
                    "data-segment-id": {
                        keepOnSplit: false,
                        parseHTML: (element) =>
                            element.getAttribute("data-segment-id"),
                        renderHTML: (attributes) => ({
                            segmentId: attributes["data-segment-id"],
                        }),
                    },
                    "data-segment-type": {
                        keepOnSplit: false,
                        parseHTML: (element) =>
                            element.getAttribute("data-segment-type"),
                        renderHTML: (attributes) => ({
                            segmentType: attributes["data-segment-type"],
                        }),
                    },
                    "data-media-id": {
                        keepOnSplit: false,
                        parseHTML: (element) =>
                            element.getAttribute("data-media-id"),
                        renderHTML: (attributes) => ({
                            segmentType: attributes["data-media-id"],
                        }),
                    },
                    "data-timestamp": {
                        keepOnSplit: false,
                        parseHTML: (element) =>
                            element.getAttribute("data-timestamp"),
                        renderHTML: (attributes) => ({
                            segmentType: attributes["data-timestamp"],
                        }),
                    },
                    "data-posthog-captured": {
                        default: false,
                        keepOnSplit: false,
                        parseHTML: (element) =>
                            element.getAttribute("data-posthog-captured"),
                        renderHTML: (attributes) => ({
                            posthogCaptured:
                                attributes["data-posthog-captured"],
                        }),
                    },
                },
            },
        ];
    },
});
