import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { GoogleCalendar } from "types/backend/google_calendar";

export function useWatchingGoogleCalendarsQuery() {
    const query = useQuery({
        queryKey: [Q.GET_WATCHING_GOOGLE_CALENDARS],
        queryFn: async () => {
            const { data } = await api.get<Array<GoogleCalendar["id"]>>(
                `google-calendar/calendars/watch`,
            );
            return data;
        },
    });
    return query;
}
