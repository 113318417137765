import { LOCALSTORAGE } from "@constant/localstorage-key";
import axios from "axios";

export const BASE_URL = {
    development: "http://localhost:3001",
    staging: "https://chat-js-backend-staging.herokuapp.com",
    production: "https://chat-js-backend-production.herokuapp.com",
    review_app:
        import.meta.env.VITE_BACKEND_URL ||
        "https://chat-js-back-feature-ho-p7bufz.herokuapp.com",
};

export const API_HEADERS_KEY = {
    CURRENT_TEAM: "current-team",
} as const;

const api = axios.create({
    baseURL:
        BASE_URL[process.env.NODE_ENV] + "/api" || "http://localhost:3001/api",
    headers: {
        Authorization:
            "Bearer " + window.localStorage.getItem(LOCALSTORAGE.CLOSER_JWT),
        [API_HEADERS_KEY.CURRENT_TEAM]: window.localStorage.getItem(
            LOCALSTORAGE.CLOSER_CURRENT_TEAM,
        ),
    },
});

export const guestApi = axios.create({
    baseURL: BASE_URL[process.env.NODE_ENV] + "/guest",
    headers: {
        Authorization:
            "Bearer " +
            window.localStorage.getItem(LOCALSTORAGE.CLOSER_GUEST_JWT),
    },
});

export default api;

const _HUBSPOT_OAUTH_URLS = {
    development:
        "https://app.hubspot.com/oauth/authorize?client_id=c3c40895-552d-4a9c-8198-7322a887cac5&redirect_uri=http://localhost:5173/oauth/hubspot&scope=timeline%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write",
    staging:
        "https://app.hubspot.com/oauth/authorize?client_id=03af8e15-a800-4401-94b3-9c02825215bd&redirect_uri=https://customer-staging.closer.contact/oauth/hubspot&scope=timeline%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write",
    production:
        "https://app.hubspot.com/oauth/authorize?client_id=4ba6455e-2f6a-4bd0-8786-95856bdc9e9a&redirect_uri=https://customer.closer.contact/oauth/hubspot&scope=timeline%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write",
    review_app: import.meta.env.VITE_HUBSPOT_REVIEW_OAUTH_URL || "",
};
export const HUBSPOT_OAUTH_URL = _HUBSPOT_OAUTH_URLS[process.env.NODE_ENV];

const _SALESFORCE_OAUTH_CALLBACK_URLS = {
    development: "http://localhost:5173/oauth/salesforce",
    staging: "https://customer-staging.closer.contact/oauth/salesforce",
    production: "https://customer.closer.contact/oauth/salesforce",
    review_app: import.meta.env.VITE_SALESFORCE_REVIEW_OAUTH_CALLBACK_URL || "",
};

export const SALESFORCE_OAUTH_CALLBACK_URL =
    _SALESFORCE_OAUTH_CALLBACK_URLS[process.env.NODE_ENV];
