import { FC } from "react";

export const SalesforceSvg: FC<{ size?: "default" | "sm" }> = ({
    size = "default",
}) => {
    let svgSize = "40px";
    if (size === "sm") {
        svgSize = "18px";
    }

    return (
        <svg
            width={svgSize}
            height={svgSize}
            viewBox="0 -5.938 40 40"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMidYMid"
        >
            <g>
                <path
                    d="M16.649 3.07c1.289 -1.343 3.083 -2.176 5.067 -2.176 2.638 0 4.939 1.471 6.165 3.655a8.519 8.519 0 0 1 3.484 -0.741c4.758 0 8.615 3.891 8.615 8.69 0 4.8 -3.857 8.69 -8.615 8.69 -0.581 0 -1.148 -0.058 -1.697 -0.169 -1.079 1.925 -3.136 3.226 -5.497 3.226a6.268 6.268 0 0 1 -2.755 -0.634c-1.094 2.573 -3.643 4.378 -6.614 4.378 -3.094 0 -5.73 -1.958 -6.742 -4.703 -0.442 0.094 -0.9 0.143 -1.371 0.143C3.006 23.43 0.02 20.412 0.02 16.691a6.75 6.75 0 0 1 3.335 -5.838 7.715 7.715 0 0 1 -0.639 -3.083C2.716 3.49 6.192 0.02 10.478 0.02c2.517 0 4.753 1.197 6.171 3.05"
                    fill="#FFFFFF"
                />
                <path
                    d="M5.808 14.524c-0.025 0.066 0.009 0.079 0.017 0.091 0.075 0.055 0.151 0.094 0.228 0.138 0.408 0.216 0.792 0.28 1.195 0.28 0.82 0 1.329 -0.436 1.329 -1.138v-0.014c0 -0.649 -0.574 -0.885 -1.113 -1.055l-0.07 -0.023c-0.406 -0.132 -0.757 -0.246 -0.757 -0.513v-0.014c0 -0.229 0.205 -0.398 0.522 -0.398 0.353 0 0.772 0.117 1.042 0.266 0 0 0.079 0.051 0.108 -0.025 0.016 -0.041 0.152 -0.409 0.167 -0.449 0.015 -0.043 -0.012 -0.075 -0.04 -0.092 -0.308 -0.187 -0.734 -0.315 -1.174 -0.315l-0.082 0.001c-0.75 0 -1.274 0.453 -1.274 1.103v0.014c0 0.685 0.578 0.907 1.119 1.062l0.087 0.027c0.394 0.121 0.734 0.225 0.734 0.503v0.014c0 0.254 -0.221 0.443 -0.577 0.443 -0.138 0 -0.579 -0.003 -1.056 -0.304 -0.057 -0.034 -0.091 -0.058 -0.135 -0.085 -0.023 -0.015 -0.082 -0.041 -0.107 0.037l-0.162 0.449Z"
                    fill="#00A1E0"
                />
                <path
                    d="M17.808 14.524c-0.025 0.066 0.009 0.079 0.017 0.091 0.075 0.055 0.151 0.094 0.228 0.138 0.408 0.216 0.792 0.28 1.195 0.28 0.82 0 1.329 -0.436 1.329 -1.138v-0.014c0 -0.649 -0.574 -0.885 -1.113 -1.055l-0.07 -0.023c-0.406 -0.132 -0.757 -0.246 -0.757 -0.513v-0.014c0 -0.229 0.205 -0.398 0.523 -0.398 0.353 0 0.772 0.117 1.042 0.266 0 0 0.079 0.051 0.108 -0.025 0.016 -0.041 0.152 -0.409 0.167 -0.449 0.015 -0.043 -0.012 -0.075 -0.04 -0.092 -0.308 -0.187 -0.734 -0.315 -1.174 -0.315l-0.082 0.001c-0.75 0 -1.274 0.453 -1.274 1.103v0.014c0 0.685 0.578 0.907 1.119 1.062l0.087 0.027c0.395 0.121 0.735 0.225 0.735 0.503v0.014c0 0.254 -0.222 0.443 -0.578 0.443 -0.138 0 -0.58 -0.003 -1.056 -0.304 -0.057 -0.034 -0.092 -0.057 -0.135 -0.085 -0.015 -0.01 -0.084 -0.037 -0.108 0.037l-0.162 0.449Z"
                    fill="#00A1E0"
                />
                <path
                    d="M26 13.15c0 0.397 -0.074 0.709 -0.22 0.93 -0.144 0.219 -0.362 0.325 -0.666 0.325 -0.305 0 -0.521 -0.106 -0.663 -0.325 -0.143 -0.22 -0.216 -0.533 -0.216 -0.93 0 -0.396 0.073 -0.708 0.216 -0.927 0.142 -0.216 0.359 -0.322 0.663 -0.322 0.304 0 0.522 0.105 0.667 0.322 0.145 0.219 0.219 0.531 0.219 0.927m0.684 -0.735c-0.067 -0.227 -0.172 -0.428 -0.311 -0.594 -0.14 -0.167 -0.316 -0.302 -0.525 -0.4 -0.209 -0.097 -0.456 -0.147 -0.733 -0.147 -0.278 0 -0.525 0.05 -0.734 0.147 -0.209 0.098 -0.386 0.232 -0.526 0.4 -0.139 0.167 -0.244 0.368 -0.311 0.594 -0.067 0.226 -0.1 0.473 -0.1 0.735 0 0.263 0.033 0.51 0.1 0.735 0.068 0.227 0.172 0.427 0.312 0.594 0.139 0.167 0.316 0.301 0.525 0.396 0.209 0.095 0.456 0.143 0.734 0.143 0.277 0 0.524 -0.048 0.733 -0.143 0.209 -0.095 0.386 -0.229 0.525 -0.396 0.14 -0.167 0.244 -0.367 0.311 -0.594 0.067 -0.226 0.101 -0.473 0.101 -0.735 0 -0.262 -0.034 -0.509 -0.101 -0.735"
                    fill="#00A1E0"
                />
                <path
                    d="M32.303 14.299c-0.023 -0.067 -0.087 -0.042 -0.087 -0.042 -0.1 0.038 -0.205 0.073 -0.318 0.091a2.473 2.473 0 0 1 -0.375 0.027c-0.331 0 -0.594 -0.098 -0.783 -0.293 -0.189 -0.195 -0.295 -0.509 -0.294 -0.935 0.001 -0.388 0.095 -0.679 0.263 -0.901 0.167 -0.221 0.421 -0.334 0.759 -0.334 0.282 0 0.498 0.033 0.723 0.104 0 0 0.054 0.023 0.08 -0.047 0.06 -0.166 0.104 -0.285 0.168 -0.468 0.018 -0.052 -0.026 -0.074 -0.042 -0.08 -0.089 -0.035 -0.298 -0.091 -0.457 -0.115 -0.148 -0.023 -0.321 -0.035 -0.513 -0.035 -0.288 0 -0.544 0.049 -0.763 0.147 -0.219 0.097 -0.404 0.232 -0.551 0.399s-0.258 0.368 -0.333 0.594c-0.074 0.226 -0.112 0.474 -0.112 0.737 0 0.568 0.153 1.026 0.455 1.362 0.303 0.337 0.758 0.508 1.351 0.508 0.351 0 0.71 -0.071 0.969 -0.173 0 0 0.05 -0.024 0.028 -0.081l-0.168 -0.464Z"
                    fill="#00A1E0"
                />
                <path
                    d="M33.5 12.77c0.032 -0.22 0.093 -0.404 0.187 -0.546 0.142 -0.217 0.358 -0.336 0.662 -0.336 0.304 0 0.505 0.12 0.649 0.336 0.096 0.143 0.137 0.334 0.154 0.546H33.5Zm2.304 -0.484c-0.058 -0.219 -0.202 -0.441 -0.297 -0.542 -0.149 -0.16 -0.295 -0.273 -0.44 -0.335a1.694 1.694 0 0 0 -0.664 -0.134c-0.289 0 -0.552 0.048 -0.764 0.148 -0.213 0.1 -0.393 0.237 -0.533 0.407 -0.141 0.17 -0.246 0.372 -0.314 0.601 -0.068 0.228 -0.102 0.477 -0.102 0.739 0 0.267 0.035 0.516 0.105 0.739 0.07 0.225 0.183 0.424 0.336 0.589 0.152 0.166 0.348 0.295 0.582 0.386 0.233 0.09 0.516 0.137 0.841 0.136 0.669 -0.002 1.021 -0.152 1.166 -0.232 0.026 -0.014 0.05 -0.039 0.019 -0.111l-0.151 -0.424c-0.023 -0.063 -0.087 -0.04 -0.087 -0.04 -0.166 0.061 -0.401 0.172 -0.951 0.171 -0.359 -0.001 -0.626 -0.107 -0.792 -0.272 -0.171 -0.17 -0.255 -0.419 -0.27 -0.771l2.316 0.002s0.061 -0.001 0.067 -0.06c0.002 -0.025 0.08 -0.476 -0.069 -0.998Z"
                    fill="#00A1E0"
                />
                <path
                    d="M14.95 12.77c0.033 -0.22 0.093 -0.404 0.187 -0.546 0.142 -0.217 0.358 -0.336 0.662 -0.336 0.304 0 0.505 0.12 0.65 0.336 0.095 0.143 0.137 0.334 0.153 0.546H14.95Zm2.303 -0.484c-0.058 -0.219 -0.202 -0.441 -0.296 -0.542 -0.149 -0.16 -0.295 -0.273 -0.439 -0.335a1.694 1.694 0 0 0 -0.664 -0.134c-0.289 0 -0.552 0.048 -0.764 0.148 -0.214 0.1 -0.393 0.237 -0.533 0.407 -0.141 0.17 -0.246 0.372 -0.314 0.601 -0.067 0.228 -0.102 0.477 -0.102 0.739 0 0.267 0.035 0.516 0.105 0.739 0.07 0.225 0.183 0.424 0.336 0.589 0.152 0.166 0.348 0.295 0.582 0.386 0.233 0.09 0.516 0.137 0.841 0.136 0.669 -0.002 1.021 -0.152 1.166 -0.232 0.026 -0.014 0.05 -0.039 0.019 -0.111l-0.151 -0.424c-0.023 -0.063 -0.088 -0.04 -0.088 -0.04 -0.166 0.061 -0.401 0.172 -0.951 0.171 -0.359 -0.001 -0.625 -0.107 -0.792 -0.272 -0.171 -0.17 -0.255 -0.419 -0.27 -0.771l2.316 0.002s0.061 -0.001 0.067 -0.06c0.002 -0.025 0.08 -0.476 -0.069 -0.998Z"
                    fill="#00A1E0"
                />
                <path
                    d="M9.944 14.286c-0.091 -0.072 -0.103 -0.091 -0.134 -0.137 -0.046 -0.071 -0.069 -0.173 -0.069 -0.301 0 -0.204 0.067 -0.35 0.207 -0.448 -0.002 0 0.199 -0.174 0.672 -0.168 0.332 0.005 0.628 0.054 0.628 0.054v1.053h0.001s-0.294 0.063 -0.626 0.083c-0.471 0.028 -0.681 -0.136 -0.679 -0.135m0.922 -1.627c-0.094 -0.007 -0.216 -0.011 -0.361 -0.011 -0.199 0 -0.391 0.025 -0.57 0.073 -0.181 0.048 -0.344 0.124 -0.484 0.224a1.117 1.117 0 0 0 -0.336 0.382c-0.082 0.153 -0.123 0.333 -0.123 0.534 0 0.205 0.035 0.383 0.106 0.529a0.953 0.953 0 0 0 0.302 0.362c0.129 0.094 0.288 0.163 0.472 0.204 0.182 0.042 0.388 0.063 0.613 0.063 0.237 0 0.474 -0.019 0.704 -0.059a14.705 14.705 0 0 0 0.583 -0.113c0.077 -0.018 0.162 -0.042 0.162 -0.042 0.057 -0.014 0.053 -0.076 0.053 -0.076l-0.001 -2.118c0 -0.465 -0.124 -0.809 -0.368 -1.022 -0.243 -0.213 -0.601 -0.321 -1.064 -0.321 -0.173 0 -0.453 0.024 -0.62 0.057 0 0 -0.506 0.098 -0.715 0.261 0 0 -0.045 0.028 -0.02 0.092l0.164 0.441c0.02 0.057 0.076 0.037 0.076 0.037s0.018 -0.007 0.038 -0.019c0.446 -0.242 1.009 -0.235 1.009 -0.235 0.25 0 0.443 0.05 0.573 0.15 0.126 0.097 0.191 0.243 0.191 0.552v0.098c-0.199 -0.028 -0.382 -0.045 -0.382 -0.045"
                    fill="#00A1E0"
                />
                <path
                    d="M29.547 11.465a0.063 0.063 0 0 0 -0.035 -0.083c-0.039 -0.015 -0.236 -0.057 -0.388 -0.067 -0.291 -0.018 -0.453 0.031 -0.597 0.096 -0.143 0.065 -0.303 0.17 -0.392 0.289V11.419c0 -0.039 -0.028 -0.071 -0.067 -0.071h-0.594c-0.039 0 -0.067 0.031 -0.067 0.071v3.455c0 0.039 0.032 0.07 0.071 0.07h0.608a0.07 0.07 0 0 0 0.07 -0.07V13.147c0 -0.232 0.026 -0.463 0.077 -0.608 0.05 -0.143 0.118 -0.258 0.203 -0.341a0.711 0.711 0 0 1 0.286 -0.172c0.107 -0.033 0.227 -0.044 0.311 -0.044 0.121 0 0.254 0.031 0.254 0.031 0.044 0.005 0.07 -0.022 0.084 -0.063 0.04 -0.106 0.152 -0.423 0.174 -0.486"
                    fill="#00A1E0"
                />
                <path
                    d="M23.835 9.864a1.991 1.991 0 0 0 -0.229 -0.055 1.813 1.813 0 0 0 -0.315 -0.024c-0.419 0 -0.749 0.118 -0.981 0.352 -0.231 0.232 -0.387 0.586 -0.466 1.051l-0.029 0.157h-0.526s-0.064 -0.002 -0.077 0.067l-0.086 0.482c-0.006 0.046 0.014 0.075 0.075 0.075h0.512l-0.519 2.898c-0.04 0.233 -0.087 0.425 -0.139 0.571 -0.051 0.143 -0.1 0.251 -0.162 0.33 -0.059 0.075 -0.115 0.131 -0.212 0.163 -0.08 0.027 -0.172 0.039 -0.273 0.039 -0.056 0 -0.13 -0.009 -0.185 -0.021a0.549 0.549 0 0 1 -0.125 -0.04s-0.06 -0.023 -0.084 0.037c-0.019 0.05 -0.155 0.425 -0.172 0.471 -0.016 0.046 0.007 0.082 0.036 0.093 0.068 0.024 0.119 0.04 0.212 0.062 0.129 0.03 0.237 0.032 0.339 0.032 0.213 0 0.408 -0.03 0.569 -0.088 0.162 -0.059 0.303 -0.161 0.428 -0.298 0.135 -0.149 0.22 -0.305 0.3 -0.518 0.08 -0.211 0.149 -0.473 0.204 -0.778l0.522 -2.953h0.763s0.064 0.002 0.077 -0.068l0.087 -0.482c0.006 -0.046 -0.014 -0.075 -0.076 -0.075h-0.741c0.004 -0.017 0.038 -0.277 0.122 -0.522 0.036 -0.104 0.105 -0.189 0.162 -0.247a0.486 0.486 0 0 1 0.194 -0.121c0.073 -0.024 0.157 -0.035 0.249 -0.035 0.069 0 0.138 0.008 0.19 0.019 0.072 0.015 0.1 0.023 0.118 0.029 0.076 0.023 0.086 0.001 0.101 -0.036l0.177 -0.486c0.018 -0.053 -0.027 -0.075 -0.043 -0.081"
                    fill="#00A1E0"
                />
                <path
                    d="M13.488 14.873c0 0.039 -0.028 0.07 -0.067 0.07h-0.614c-0.039 0 -0.066 -0.031 -0.066 -0.07V9.93c0 -0.039 0.027 -0.07 0.066 -0.07h0.614c0.039 0 0.067 0.031 0.067 0.07V14.873Z"
                    fill="#00A1E0"
                />
            </g>
        </svg>
    );
};
