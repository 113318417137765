import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import {
    InfiniteData,
    QueryKey,
    useInfiniteQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { GoogleMeetRecord } from "types/backend/google_meet";

export interface GoogleMeeting {
    meetings: GoogleMeetRecord[];
    nextPageToken?: string;
    startTime: string;
}

export function useGetGoogleMeetingsQuery() {
    const { push } = usePopupNotification();
    const query = useInfiniteQuery<
        GoogleMeeting,
        Error,
        InfiniteData<GoogleMeeting, unknown>,
        QueryKey,
        | undefined
        | {
              nextPageToken: string | undefined;
              startTime: string;
          }
    >({
        queryKey: [Q.GET_GOOGLE_MEETS],
        queryFn: async ({ pageParam }) => {
            try {
                const { data } = await api.get<GoogleMeeting>("/google-meets", {
                    params: {
                        nextPage: pageParam?.nextPageToken,
                        startTime: pageParam?.startTime,
                    },
                });
                return data;
            } catch (error) {
                if (
                    axios.isAxiosError(error) &&
                    error.response?.status == 429
                ) {
                    push({
                        type: "destructive",
                        title: "Rate Limit",
                        description: "too many request in one minutes",
                    });
                    return {
                        meetings: [] as Array<GoogleMeetRecord>,
                        nextPageToken: pageParam as string | undefined,
                        startTime: "",
                    };
                }
                throw error;
            }
        },

        getNextPageParam: ({ nextPageToken, startTime }) => {
            if (nextPageToken) {
                return {
                    nextPageToken,
                    startTime,
                };
            } else {
                return undefined;
            }
        },
        initialPageParam: undefined,
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
    });
    return query;
}
