import { Button } from "@component-ui/utility/Button";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import useSoftDeleteMediaMutate from "@hooks/media/useSoftDeleteMediaMutate";
import { Loader, Trash } from "lucide-react";
import { FC } from "react";

type SoftDeleteMediaButtonProps = {
    id: string;
};

const SoftDeleteMediaButton: FC<SoftDeleteMediaButtonProps> = ({ id }) => {
    const { mutateAsync: deleteMedia, isPending } = useSoftDeleteMediaMutate();

    return (
        <TooltipProvider delayDuration={200}>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={async (e) => {
                            e.stopPropagation();
                            await deleteMedia(id);
                        }}
                    >
                        {isPending ? (
                            <Loader className="animate-spin" />
                        ) : (
                            <Trash />
                        )}
                    </Button>
                </TooltipTrigger>
                <TooltipContent>Delete media</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export default SoftDeleteMediaButton;
