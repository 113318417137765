import { usePopupNotification } from "@context/popup-notification";
import { useEffect, useState } from "react";

type useGetMediaDevicesArgs =
    | {
          type: "audio";
          dir: "audioinput" | "audiooutput";
      }
    | { type: "video" };

export default function useGetMediaDevices(args: useGetMediaDevicesArgs) {
    const { type } = args;
    const [mediaDevices, setMediaDevices] = useState<MediaDeviceInfo[]>([]);
    const [error, setError] = useState(false);
    const { push } = usePopupNotification();

    async function _getDevices() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        if (type === "audio") {
            setMediaDevices(devices.filter((d) => d.kind === args.dir));
        } else {
            setMediaDevices(devices.filter((d) => d.kind === "videoinput"));
        }
    }

    useEffect(() => {
        async function getDevices() {
            try {
                const permission = await navigator.permissions.query({
                    name: "microphone" as PermissionName,
                });
                if (permission.state !== "granted") {
                    const stream = await navigator.mediaDevices.getUserMedia(
                        type === "audio" ? { audio: true } : { video: true },
                    );
                    stream.getTracks().forEach((t) => t.stop());
                }
            } catch (err) {
                push({
                    title: "Cannot access recording inputs",
                    description:
                        "User permission not given. To fix, please click on the settings icon to the left of the URL and turn on permission for Microphone, and refresh this page. Please see our help center for more information",
                    type: "destructive",
                });
                console.error(err);
                setError(true);
                return;
            }
            await _getDevices();
            navigator.mediaDevices.addEventListener("devicechange", _getDevices);
        }
        getDevices();

        return () => {
            navigator.mediaDevices.removeEventListener(
                "devicechange",
                _getDevices,
            );
        };
    }, []);

    return [mediaDevices, error] as const;
}
