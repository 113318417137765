import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { HubSpotAccountDetail } from "types/backend/resources";

async function fetchHubSpotDetail() {
    const url = `/resource-config/hubspot-detail`;
    const { data } = await api.get<HubSpotAccountDetail>(url);
    return data;
}

export function useHubSpotDetail(enable = true) {
    const result = useQuery({
        queryKey: [Q.GET_HUBSPOT_DETAIL],
        queryFn: () => fetchHubSpotDetail(),
        enabled: enable,
    });
    return result;
}
