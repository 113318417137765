import { TableCell, TableRow } from "@component-ui/utility/Table";
import dayjs from "dayjs";
import { filesize } from "filesize";
import { FC, useEffect, useRef, useState } from "react";
import { RecordingFile } from "@hooks/zoom/useGetZoomMeetingRecordsQuery";
import { ZoomDownloadTask } from "types/backend/zoom_download_task";
import { FORM_KEY } from "@constant/form";
import { Button } from "@component-ui/utility/Button";
import { Label } from "@component-ui/utility/Label";
import { Download, Check, XCircle, Loader2 } from "lucide-react";
import { Input } from "@component-ui/utility/Input";
import BasicSelect from "@component-func/BasicSelect";
import { languageOptions } from "@constant/language";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@component-ui/utility/Dialog";
import { ChildrenWithHover } from "@component-ui/utility/Icon";
import useMutationDownloadZoomRecording from "@hooks/zoom/useMutationDownloadZoomRecording";
import { formatDuration } from "@libs/duration";
import _ from "lodash";

interface ZoomMeetingRecordingRowProps {
    downloadAccessToken: string;
    recordingFile: RecordingFile;
    zoomDownloadTask: ZoomDownloadTask | undefined;
    timezone: string;
}

export const ZoomMeetingRecordingRow: FC<ZoomMeetingRecordingRowProps> = ({
    recordingFile,
    downloadAccessToken,
    zoomDownloadTask,
    timezone,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [status, setStatus] = useState(zoomDownloadTask?.status);

    useEffect(() => {
        zoomDownloadTask && setStatus(zoomDownloadTask.status);
    }, [zoomDownloadTask]);
    const { mutate, isPending } = useMutationDownloadZoomRecording();

    const onSubmit = async (form: React.FormEvent<HTMLFormElement>) => {
        form.preventDefault();
        const formData = new FormData(form.currentTarget);
        const meetingName = formData.get(FORM_KEY.MEETING.NAME) as string;
        const language = formData.get(FORM_KEY.MEDIA.LANGUAGE) as
            | "en-US"
            | "pt-BR"
            | "es";
        mutate(
            {
                zoomAccessToken: downloadAccessToken,
                zoomDownloadUrl: recordingFile.download_url,
                zoomRecordingId: recordingFile.id,
                meetingName,
                language,
                fileType: recordingFile.file_type as "MP4" | "M4A",
            },
            {
                onSuccess({ status }) {
                    setIsOpen(false);
                    setStatus(status);
                },
            },
        );
    };

    const startTime = dayjs(recordingFile.recording_start);
    const endTime = dayjs(recordingFile.recording_end);
    const duration = formatDuration(endTime.diff(startTime) / 1000);
    return (
        <TableRow key={recordingFile.id} className="h-[70px]">
            <TableCell className="text-center">
                {dayjs(recordingFile.recording_start)
                    .tz(_.isEmpty(timezone) ? undefined : timezone)
                    .format("DD/MM/YYYY HH:mm")}
            </TableCell>
            <TableCell className="text-center">{duration}</TableCell>
            <TableCell className="text-center">
                {recordingFile.file_type}
            </TableCell>
            <TableCell className="text-center">
                {filesize(recordingFile.file_size, {
                    standard: "jedec",
                })}
            </TableCell>
            <TableCell className="text-center">
                {status ? (
                    status === "waiting" ? (
                        <span>waiting</span>
                    ) : status === "error" ? (
                        <div className="flex justify-center">
                            <ChildrenWithHover description="error">
                                <XCircle color="#f70000" />
                            </ChildrenWithHover>
                        </div>
                    ) : status === "success" ? (
                        <div className="flex justify-center">
                            <ChildrenWithHover description="finished">
                                <Check color="#38db07" />
                            </ChildrenWithHover>
                        </div>
                    ) : status === "progress" ? (
                        <span>on progress</span>
                    ) : null
                ) : null}
            </TableCell>
            <TableCell className="text-center">
                <Dialog open={isOpen} onOpenChange={setIsOpen}>
                    <ChildrenWithHover description="download and transcript">
                        <Button
                            size="icon"
                            variant="ghost"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setIsOpen(true);
                            }}
                            disabled={!!status}
                        >
                            <Download />
                        </Button>
                    </ChildrenWithHover>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>
                                DownLoad And Transcript Recording
                            </DialogTitle>
                        </DialogHeader>
                        <form
                            id="downLoad-and-transcript-recording"
                            ref={formRef}
                            onSubmit={onSubmit}
                        >
                            <Label htmlFor={FORM_KEY.MEETING.NAME}>
                                Meeting name
                            </Label>
                            <Input
                                id={FORM_KEY.MEETING.NAME}
                                name={FORM_KEY.MEETING.NAME}
                                type="text"
                                required
                            />
                            <Label htmlFor={FORM_KEY.MEDIA.LANGUAGE}>
                                Language
                            </Label>
                            <BasicSelect
                                name={FORM_KEY.MEDIA.LANGUAGE}
                                placeholder="Select a language"
                                options={languageOptions}
                                triggerClassName="mt-2"
                                required
                            />
                        </form>
                        <Button
                            form="downLoad-and-transcript-recording"
                            type="submit"
                            disabled={isPending}
                        >
                            {isPending && (
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            )}
                            Download and Transcript
                        </Button>
                    </DialogContent>
                </Dialog>
            </TableCell>
        </TableRow>
    );
};
