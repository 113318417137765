import AudioContainer from "@component-ui/AudioContainer";
import VideoContainer from "@component-ui/VideoContainer";
import { SEARCH_PARAMS } from "@constant/searchParams";
import useIsDev from "@hooks/useDev";
import useGetMediaSourceQuery from "@hooks/useGetMediaSourceQuery";
import { Ref, forwardRef } from "react";
import { useSearchParams } from "react-router-dom";

const MediaPlayer = forwardRef<HTMLVideoElement | HTMLAudioElement>(
    (_, ref) => {
        const [search] = useSearchParams();
        const targetKey = search.get(SEARCH_PARAMS.MEDIA_TARGET_KEY);
        const mimeType = search.get(SEARCH_PARAMS.MIMETYPE);
        const mediaType = mimeType?.split("/")[0] as "audio" | "video";
        const {
            data: src,
            isLoading,
            isFetching,
            isSuccess,
            isError,
        } = useGetMediaSourceQuery(targetKey);
        const isDev = useIsDev();

        if (isDev) {
            return (
                <div className="w-full h-full aspect-video animate-pulse rounded-md bg-muted mx-auto"></div>
            );
        }

        if (isLoading || isFetching) {
            return (
                <div className="w-full h-full aspect-video animate-pulse rounded-md bg-muted mx-auto"></div>
            );
        }

        if (!isSuccess || isError) {
            return <div>Cannot get src</div>;
        }

        if (mediaType === "audio") {
            return (
                <AudioContainer src={src} ref={ref as Ref<HTMLAudioElement>} />
            );
        }

        if (mediaType === "video") {
            return (
                <VideoContainer src={src} ref={ref as Ref<HTMLVideoElement>} />
            );
        }
    },
);

MediaPlayer.displayName = "MediaPlayer";

export default MediaPlayer;
