import { Q } from "@constant/query-key";
import api, { guestApi } from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { UserType } from "types/backend";
import { GetMediaDownloadPresignedUrlResponse } from "types/backend/media";

const EXPIRE = 60 * 60 * 24 * 7 * 1000;

export default function useGetSharedMediaSourceQuery(
    key: string | null,
    userType: Exclude<UserType, "tenant-user">,
) {
    const query = useQuery({
        queryKey: [Q.GET_SHARED_MEDIA_SOURCE_BY_KEY, key, userType],
        queryFn: async () => {
            let response: AxiosResponse<GetMediaDownloadPresignedUrlResponse>;
            switch (userType) {
                case "shared-user":
                    response =
                        await api.get<GetMediaDownloadPresignedUrlResponse>(
                            `/media/share/r2/download-url/${encodeURIComponent(key || "")}`,
                        );
                    break;
                case "guest":
                    response =
                        await guestApi.get<GetMediaDownloadPresignedUrlResponse>(
                            `/media/r2/download-url/${encodeURIComponent(key || "")}`,
                        );

                    break;
            }
            return response.data.presignedURL;
        },
        enabled: !!key,
        staleTime: EXPIRE,
        gcTime: EXPIRE,
    });

    return query;
}
