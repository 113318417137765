import { Badge } from "@component-ui/utility/Badge";
import { ZoomParticipant } from "@hooks/zoom/useGetMeetingParticipantsQuery";
import { wholeStringToColour } from "@libs/utils";
import { FC } from "react";
import { GoogleMeetParticipant } from "@hooks/google-meet/useGetGoogleMeetParticipantsQuery";

type ParticipantItemProps =
    | ZoomParticipantListProps
    | GoogleMeetParticipantListProps;

interface ZoomParticipantListProps {
    type: "zoom";
    participant: ZoomParticipant;
}
interface GoogleMeetParticipantListProps {
    type: "google-meet";
    participant: GoogleMeetParticipant;
}

export const ParticipantItem: FC<ParticipantItemProps> = ({
    participant,
    type,
}) => {
    const name =
        type == "zoom"
            ? (participant as ZoomParticipant).name
            : (participant as GoogleMeetParticipant).signedinUser
              ? (participant as GoogleMeetParticipant).signedinUser?.displayName
              : (participant as GoogleMeetParticipant).anonymousUser
                ? (participant as GoogleMeetParticipant).anonymousUser
                      ?.displayName
                : (participant as GoogleMeetParticipant).phoneUser
                  ? (participant as GoogleMeetParticipant).phoneUser
                        ?.displayName
                  : "";
    const user_id =
        type === "google-meet"
            ? participant.name
            : type === "zoom"
              ? participant.user_id
              : "";
    return (
        <Badge
            variant={"noHover"}
            className="px-2 font-normal text-gray-800 cursor-default w-fit text-nowrap"
            style={{
                background: wholeStringToColour(user_id) + "4D",
            }}
        >
            {name}
        </Badge>
    );
};
