import { Button } from "@component-ui/utility/Button";
import { SEARCH_PARAMS } from "@constant/searchParams";
import useMediaTranscriptQuery from "@hooks/useTranscriptQuery";
import { NodeViewProps } from "@tiptap/core";
import { Plus } from "lucide-react";
import { FC } from "react";
import { usePostHog } from "posthog-js/react";
import { useParams, useSearchParams } from "react-router-dom";

type TranscriptLinkButtonProps = Pick<NodeViewProps, 'updateAttributes'>

type PathParam = {
    meetingId: string;
    mediaId: string;
};

const TranscriptLinkButton: FC<TranscriptLinkButtonProps> = ({
    updateAttributes,
}) => {
    const [search, setSearch] = useSearchParams();
    const { mediaId } = useParams<PathParam>();
    const { data: transcript } = useMediaTranscriptQuery(mediaId);
    const posthog = usePostHog()

    function handleLinkTranscript() {
        const currentTranscriptSegmentId = search.get(
            SEARCH_PARAMS.CURRENT_SELECTED_TRANSCRIPT_SEGMENT_ID,
        );
        if (!currentTranscriptSegmentId) {
            console.warn("You haven't select transcript");
            return;
        }

        const transcriptSegment = transcript?.transcriptionSegments.find(
            (transcriptSegment) =>
                transcriptSegment.id === currentTranscriptSegmentId,
        );

        if (!transcriptSegment) {
            console.error("Cannot link transcript");
            return;
        }

        updateAttributes({
            "data-segment-id": transcriptSegment.id,
            "data-segment-type": "TranscriptionSegment",
            "data-segment": transcriptSegment,
        });
        posthog?.capture('transcript_reference_attached')
        setSearch((prev) => {
            prev.delete(SEARCH_PARAMS.CURRENT_SELECTED_TRANSCRIPT_SEGMENT_ID);
            return prev;
        });
    }

    return (
        <>
            <Button
                size="icon"
                className="w-6 h-6"
                variant="ghost"
                onClick={handleLinkTranscript}
            >
                <Plus className="w-4 h-4" />
            </Button>
        </>
    );
};

export default TranscriptLinkButton;
