export const LOCALSTORAGE = {
    CLOSER_JWT: `CLOSER_JWT_${process.env.NODE_ENV.toUpperCase()}_${
        import.meta.env.VITE_BACKEND_URL
    }`,
    CLOSER_ACCOUNT_ACTIVE: "CLOSER_ACCOUNT_ACTIVE",
    CLOSER_LAST_DOCUMENT: "CLOSER_LAST_DOCUMENT",
    CLOSER_MEDIA_SPEAKER_LIST_MAP: "CLOSER_MEDIA_SPEAKER_LIST_MAP",
    CLOSER_GUEST_JWT: "CLOSER_GUEST_JWT",
    CLOSER_CURRENT_TEAM: "CLOSER_CURRENT_TEAM",
} as const;
