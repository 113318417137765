import { LOCALSTORAGE } from "@constant/localstorage-key";
import { M } from "@constant/mutation-key";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MediaSpeakerMap } from "./useSpeakerMapQuery";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import { usePostHog } from "posthog-js/react";

type RenamingArgs = {
    mediaId: string;
    speakerKey: string;
    newName: string;
};

export default function useSpeakerMapMutate() {
    const queryClient = useQueryClient();
    const { push } = usePopupNotification();
    const posthog = usePostHog();

    const mutate = useMutation<unknown, Error, RenamingArgs>({
        mutationKey: [M.RENAMING_MEDIA_TRANSCRIPT_SPEAKER_NAME],
        mutationFn: async ({ mediaId, speakerKey, newName }) => {
            const mediaSpeakerMap = window.localStorage.getItem(
                LOCALSTORAGE.CLOSER_MEDIA_SPEAKER_LIST_MAP,
            );
            posthog?.capture("speaker_renamed");
            let speakerListMap = {};
            if (!mediaSpeakerMap) {
                speakerListMap = { [mediaId]: { [speakerKey]: newName } };
            } else {
                const currentMap = JSON.parse(
                    mediaSpeakerMap,
                ) as MediaSpeakerMap;
                const speakerMap = currentMap[mediaId];
                if (speakerMap) {
                    speakerMap[speakerKey] = newName;
                } else {
                    currentMap[mediaId] = {
                        [speakerKey]: newName,
                    };
                }
                speakerListMap = currentMap;
            }
            window.localStorage.setItem(
                LOCALSTORAGE.CLOSER_MEDIA_SPEAKER_LIST_MAP,
                JSON.stringify(speakerListMap),
            );
        },
        onSuccess: async (_, { mediaId }) => {
            await queryClient.invalidateQueries({
                queryKey: [Q.GET_CLOSER_MEDIA_SPEAKER_MAP, mediaId],
                exact: true,
            });
            push({
                type: "success",
                title: "Speaker name is changed",
                description: "",
            });
        },
    });

    return mutate;
}
