import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { MediaTranscript } from "types/backend/media";

export default function useMediaTranscriptQuery(mediaId: string | undefined) {
    const { push } = usePopupNotification();
    const [uploadNotificationPushed, setUploadNotificationPushed] =
        useState(false);
    const [notificationPushed, setNotificationPushed] = useState(false);

    const query = useQuery({
        queryKey: [Q.GET_TRANSCRIPT, mediaId],
        queryFn: async () => {
            const { data } = await api.get<MediaTranscript>(
                `/media/${mediaId}/transcription_segments`,
            );
            if (
                (data.status === "pending" || data.status === "progressing") &&
                !uploadNotificationPushed
            ) {
                push({
                    type: "warning",
                    title: "Media is still processing",
                    description:
                        "Media is still processing. If you feel it takes too long, please contact us",
                });
                setUploadNotificationPushed(true);
                return data;
            }
            if (data.transcriptionSegments.length <= 0 && !notificationPushed) {
                push({
                    type: "warning",
                    title: "Transcript is still generating",
                    description:
                        "Transcript is still generating. If you feel it takes too long, please contact us",
                });
                setNotificationPushed(true);
                return data;
            }
            return data;
        },
        enabled: !!mediaId,
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
        retry: 0,
        refetchInterval: (query) => {
            if (query.state.status === "error") {
                return false;
            }
            if (
                query.state.data &&
                (query.state.data.transcriptStatus === "progressing" ||
                    query.state.data.status === "pending" ||
                    query.state.data.status === "progressing")
            ) {
                return 5000;
            }
            return false;
        },
    });

    return query;
}
