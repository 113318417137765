import { LOCALSTORAGE } from "@constant/localstorage-key";
import { Q } from "@constant/query-key";
import { guestApi } from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { GuestMeeting } from "types/backend/meetings";

export default function useGuestGetMeetingQuery(id: string) {
    const query = useQuery<GuestMeeting, AxiosError>({
        queryKey: [Q.GUEST_GET_MEDIA_BY_ID, id],
        queryFn: async ({ queryKey }) => {
            const [, id] = queryKey;
            const { data } = await guestApi.get<GuestMeeting>(
                `/meetings/${id}`,
            );
            return data;
        },
        enabled: !!id,
        refetchOnWindowFocus: false,
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
        retry: (_, error) => {
            if (error.response?.status === 401) {
                return false;
            }
            return true;
        },
    });

    useEffect(() => {
        if (query.error) {
            if (query.error.response?.status === 401) {
                localStorage.removeItem(LOCALSTORAGE.CLOSER_GUEST_JWT);
            }
        }
    }, [query.error]);

    return query;
}
