import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import api, { guestApi } from "@libs/api";
import { useHocuspocusStore } from "@store/hocuspocus-store";
import {
    MutationKey,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { UserType } from "types/backend";
import { Comment, CreateCommentBody } from "types/backend/comment";
import { Thread } from "types/backend/thread";

export default function useCreateCommentMutate(userType: UserType) {
    const queryClient = useQueryClient();
    const hocuspocus = useHocuspocusStore.use.hocuspocus();
    const mutationKey: MutationKey = [M.CREATE_COMMENT, userType];

    const mutate = useMutation({
        mutationKey,
        mutationFn: async (body: CreateCommentBody) => {
            const { documentId, threadId, ...rest } = body;
            let response: AxiosResponse<Comment>;
            switch (userType) {
                case "tenant-user":
                    response = await api.post<Comment>(
                        `/documents/${documentId}/threads/${threadId}/comments`,
                        rest,
                    );
                    break;
                case "shared-user":
                    response = await api.post<Comment>(
                        `/documents/share/${documentId}/threads/${threadId}/comments`,
                        rest,
                    );
                    break;
                case "guest":
                    response = await guestApi.post<Comment>(
                        `/documents/${documentId}/threads/${threadId}/comments`,
                        rest,
                    );
                    break;
            }
            return response.data;
        },
        onSuccess: (data, { documentId, threadId }) => {
            queryClient.setQueryData<Thread>(
                [Q.GET_THREAD, documentId, threadId, userType],
                (prev) => {
                    if (prev && prev.comments) {
                        prev.comments.push(data);
                    }
                    return prev;
                },
            );
            hocuspocus &&
                hocuspocus.sendStateless(
                    JSON.stringify({
                        documentId,
                        threadId,
                    }),
                );
        },
    });

    return mutate;
}
