export const buttons = {
    development: [
        {
            label: "Summary",
            systemMessageId: "457f88ab-a82c-40fa-a2b9-20c9a3a0ab06",
        },
        {
            label: "Summary List",
            systemMessageId: "6980d29d-0562-4fde-a2c1-e193ef7efa9d",
        },
    ],
    review_app: [
        {
            label: "Summary",
            systemMessageId: "ed5b6cff-1553-4454-93ff-0d833087e60f",
        },
        {
            label: "Summary List",
            systemMessageId: "deb0965f-1090-49e7-a7bc-1a7b5fd8a044",
        },
    ],
    staging: [
        {
            label: "Summary",
            systemMessageId: "ed5b6cff-1553-4454-93ff-0d833087e60f",
        },
        {
            label: "Summary List",
            systemMessageId: "deb0965f-1090-49e7-a7bc-1a7b5fd8a044",
        },
    ],
    production: [
        {
            label: "Summary",
            systemMessageId: "df9328b9-08ad-43fa-a340-e8022071bf3c",
        },
        {
            label: "Summary List",
            systemMessageId: "f13c5113-129a-473e-9fcc-5ae4cb12ad4e",
        },
        {
            label: "Resumir",
            systemMessageId: "3493b3fa-2a95-4e00-aede-1c78516805e3"
        }
    ],
} as const;
