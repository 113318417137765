import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import {
    SummarizingSystemMessage,
    UpdateSummarizingSystemMessageBody,
} from "types/backend/summarizing_system_message";

export default function useUpdateSummarizingSystemMessageMutate() {
    const { push } = usePopupNotification();
    const queryClient = useQueryClient();
    const posthog = usePostHog();

    const mutate = useMutation({
        mutationKey: [M.UPDATE_SUMMARIZING_SYSTEM_MESSAGE],
        mutationFn: async (body: UpdateSummarizingSystemMessageBody) => {
            posthog?.capture("system_message_updated");
            const { data } = await api.put<SummarizingSystemMessage>(
                "summarizing-system-messages",
                body,
            );
            return data;
        },
        onError: (error) => {
            push({
                title: "Cannot create summarizing prompt",
                description: error.message,
                type: "destructive",
            });
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [Q.GET_SUMMARIZE_SYSTEM_MESSAGES],
            });
        },
    });
    return mutate;
}
