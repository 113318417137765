import { Badge } from "@component-ui/utility/Badge";
import { SEARCH_PARAMS } from "@constant/searchParams";
import { cn } from "@libs/utils";
import { usePostHog } from "posthog-js/react";
import { FC, HTMLAttributes } from "react";
import { useNavigate } from "react-router-dom";
import { Media } from "types/backend/media";

type LinkedMediaListProps = {
    linkedMedia: Media[];
    documentId: string;
    className?: HTMLAttributes<"div">["className"];
};

const LinkedMediaList: FC<LinkedMediaListProps> = ({
    linkedMedia,
    documentId,
    className = "",
}) => {
    const navigate = useNavigate();
    const posthog = usePostHog();

    function handleClickLinkedMedia(media: Media | undefined) {
        if (!media || !documentId) return;
        posthog?.capture("linked_media_clicked");
        const { meetingId, id: mediaId, key, mimeType } = media;
        navigate(
            `/meeting-transcripts/${meetingId}/${mediaId}/transcript?${new URLSearchParams(
                {
                    key,
                    type: mimeType,
                    [SEARCH_PARAMS.CURRENT_DOCUMENT]: documentId,
                },
            )}`,
        );
    }

    return (
        <div className={cn("flex items-center gap-1 flex-wrap", className)}>
            {linkedMedia.map((media) => (
                <Badge
                    className="text-nowrap cursor-pointer"
                    key={media.id}
                    onClick={() => handleClickLinkedMedia(media)}
                >
                    {media.meeting?.label}
                </Badge>
            ))}
        </div>
    );
};

export default LinkedMediaList;
