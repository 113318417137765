import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@libs/utils";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "./Tooltip";

const buttonVariants = cva(
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
    {
        variants: {
            variant: {
                default:
                    "bg-primary text-primary-foreground hover:bg-primary/90 active:bg-primary/80",
                destructive:
                    "bg-destructive text-destructive-foreground hover:bg-destructive/90 active:bg-destructive/80",
                outline:
                    "border border-input bg-background hover:bg-accent hover:text-accent-foreground active:bg-accent/80",
                secondary:
                    "bg-secondary text-secondary-foreground hover:bg-secondary/90 active:bg-secondary/80",
                ghost: "hover:bg-gray-200 hover:text-accent-foreground active:bg-gray-200/80",
                link: "text-primary underline-offset-4 hover:underline",
            },
            size: {
                default: "h-10 px-4 py-2",
                sm: "h-9 rounded-md px-3",
                lg: "h-11 rounded-md px-8",
                icon: "h-8 w-8",
                sIcon: "h-6 w-6",
                xsIcon: "h-4 w-4",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    },
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "button";
        return (
            <Comp
                className={cn(buttonVariants({ variant, size, className }))}
                ref={ref}
                {...props}
            />
        );
    },
);
Button.displayName = "Button";

export interface ButtonWithTooltipProps extends ButtonProps {
    icon: React.ReactNode;
    content: string;
    delayms?: number;
}

const IconButtonWithTooltip = React.forwardRef<
    HTMLButtonElement,
    ButtonWithTooltipProps
>(({ icon, content, delayms = 300, ...props }, ref) => {
    return (
        <TooltipProvider delayDuration={delayms} disableHoverableContent>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button ref={ref} {...props}>
                        {icon}
                    </Button>
                </TooltipTrigger>
                <TooltipContent>{content}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
});

IconButtonWithTooltip.displayName = Button.displayName;
export { Button, IconButtonWithTooltip, buttonVariants };
