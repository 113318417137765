import { FRIGADE_FLOW_ID } from "@constant/frigade-flows";
import { cn } from "@libs/utils";
import { FC, useState } from "react";
import { Flow, Tour, useFlow } from "@frigade/react";
import { Label } from "@component-ui/utility/Label";
import { Button } from "@component-ui/utility/Button";
import {
    Maximize2,
    Minimize2,
    CircleEllipsis,
    CircleDashed,
    CheckCircle,
} from "lucide-react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import { useOnboardProgressStatusStore } from "@store/onboard-progress-status-store";
import { useMeetingsQuery } from "@hooks/meeting/useMeetingsQuery";
import { useNavigate } from "react-router-dom";
import { captureException } from "@sentry/react";
import useAllDocumentQuery from "@hooks/document/useAllDocumentQuery";
import { Meeting } from "types/backend/meetings";

const FrigadeFlow: FC<{ flow: Flow; onClick?: () => void }> = ({
    flow,
    onClick,
}) => {
    return (
        <TooltipProvider delayDuration={200}>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div
                        className={cn(
                            "flex space-x-2 items-center text-gray-500 cursor-pointer hover:bg-slate-100 hover:text-black p-2 rounded-md shadow-sm",
                            flow.isStarted && !flow.isCompleted && "text-black",
                        )}
                        onClick={async (e) => {
                            e.stopPropagation();
                            await flow.restart();
                            onClick && onClick();
                            window.location.reload();
                        }}
                    >
                        {flow.isCompleted ? (
                            <CheckCircle className="w-4 h-4" />
                        ) : flow.isStarted ? (
                            <CircleEllipsis className="w-4 h-4" />
                        ) : (
                            <CircleDashed className="w-4 h-4" />
                        )}
                        <Label className="cursor-pointer">{flow.title}</Label>
                    </div>
                </TooltipTrigger>
                <TooltipContent>Click here to restart this step</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

const RecordingTour: FC<{
    flow: Flow;
    onboardingTourMeeting: Meeting | undefined;
}> = ({ flow, onboardingTourMeeting }) => {
    const navigate = useNavigate();
    if (!onboardingTourMeeting) {
        captureException(
            new Error("No meeting with media found for the onboarding tour"),
        );
        return null;
    }

    return (
        <>
            <Tour
                flowId={flow.id}
                variables={{
                    meetingId: onboardingTourMeeting.id,
                    mediaId: onboardingTourMeeting.media[0].id || "",
                    mediaKey: onboardingTourMeeting.media[0].key || "",
                    mediaMimeType:
                        onboardingTourMeeting.media[0].mimeType || "",
                }}
            />
            <FrigadeFlow
                flow={flow}
                onClick={() => {
                    navigate("/meetings");
                }}
            />
        </>
    );
};

const TranscriptTour: FC<{
    flow: Flow;
    onboardingTourMeeting: Meeting | undefined;
}> = ({ flow, onboardingTourMeeting }) => {
    const navigate = useNavigate();
    const { data: documents } = useAllDocumentQuery();
    if (!documents || documents.length < 1 || !onboardingTourMeeting) {
        return null;
    }
    const onboardingDocument = documents[0];
    return (
        <FrigadeFlow
            flow={flow}
            onClick={() => {
                navigate(
                    `/meeting-transcripts/${onboardingTourMeeting.id}/${onboardingTourMeeting.media[0].id}/transcript?key=${encodeURIComponent(onboardingTourMeeting.media[0].key)}&type=${onboardingTourMeeting.media[0].mimeType}&document=${onboardingDocument.id}`,
                );
            }}
        />
    );
};

const FrigadeOnboardingProgress: FC = () => {
    const { data: meetings } = useMeetingsQuery();

    const { flow: uploadRecordingFlow } = useFlow(
        FRIGADE_FLOW_ID.OPEN_UPLOAD_A_RECORDING,
    );
    const { flow: reviewTranscriptFlow } = useFlow(
        FRIGADE_FLOW_ID.REVIEW_A_TRANSCRIPT,
    );
    const { flow: createSummaryFlow } = useFlow(
        FRIGADE_FLOW_ID.CREATE_A_SUMMARY,
    );
    const { flow: integrateCrmFlow } = useFlow(FRIGADE_FLOW_ID.INTEGRATE_CRM);
    const { flow: helpButtonFlow } = useFlow(FRIGADE_FLOW_ID.HELP_BUTTON);
    const display = useOnboardProgressStatusStore.use.displayCheckList();

    const [isMinimized, setIsMinimized] = useState(false);

    if (!meetings) {
        return null;
    }
    const onboardingTourMeeting = meetings.find(
        (meeting) => meeting.media && meeting.media.length > 0,
    );

    if (
        !uploadRecordingFlow ||
        !reviewTranscriptFlow ||
        !createSummaryFlow ||
        !integrateCrmFlow ||
        !helpButtonFlow
    ) {
        return null;
    }

    if (
        uploadRecordingFlow.isCompleted &&
        reviewTranscriptFlow.isCompleted &&
        createSummaryFlow.isCompleted &&
        integrateCrmFlow.isCompleted &&
        helpButtonFlow.isCompleted &&
        !display
    ) {
        return null;
    }

    return (
        <div
            className={cn(
                "absolute bottom-14 left-4 grid grid-flow-row gap-2 p-4 rounded-md shadow-xl bg-white z-[999]",
                isMinimized ? "w-48" : "w-72",
            )}
        >
            <div className="flex justify-between items-center">
                <span>Onboarding Tour</span>
                <Button
                    variant={"ghost"}
                    size={"sIcon"}
                    onClick={() => {
                        setIsMinimized((prev) => !prev);
                    }}
                >
                    {isMinimized ? (
                        <Maximize2 className="w-4 h-4" />
                    ) : (
                        <Minimize2 className="w-4 h-4" />
                    )}
                </Button>
            </div>

            {!isMinimized && (
                <>
                    <RecordingTour
                        flow={uploadRecordingFlow}
                        onboardingTourMeeting={onboardingTourMeeting}
                    />
                    <TranscriptTour
                        flow={reviewTranscriptFlow}
                        onboardingTourMeeting={onboardingTourMeeting}
                    />
                    <TranscriptTour
                        flow={createSummaryFlow}
                        onboardingTourMeeting={onboardingTourMeeting}
                    />
                    <TranscriptTour
                        flow={integrateCrmFlow}
                        onboardingTourMeeting={onboardingTourMeeting}
                    />
                    <FrigadeFlow flow={helpButtonFlow} />
                </>
            )}
        </div>
    );
};

export default FrigadeOnboardingProgress;
