import { HubSpotResourceType, ResourcePlatform, SalesforceResourceType } from "types/backend/resources";

export const RESOURCE_PLATFORM = {
    HUBSPOT: "hubspot",
    SALESFORCE: "salesforce",
    ZOOM: "zoom"
} as const;

export const RESOURCE_TYPE = {
    HUBSPOT: {
        DEAL: "deal",
        CONTACT: "contact",
        COMPANY: "company",
    },
    SALESFORCE: {
        OPPORTUNITY: "Opportunity",
        CONTACT: "Contact",
        ACCOUNT: "Account",
    },
} as const;

export const CRM_QUERY_VARIANT = {
    FILTER_OBJECT_DATA: "1",
    EXACT_OBJECT_ID: "2",
    SEARCH_WITH_CONTACT: "3",
} as const;

export function isResourcePlatform(resourcePlatform: string): resourcePlatform is ResourcePlatform {
    const resourcePlatforms = Object.values(RESOURCE_PLATFORM)
    return resourcePlatforms.indexOf(resourcePlatform as ResourcePlatform) !== -1
}

export function isHubSpotResourceType(resourceType: string): resourceType is HubSpotResourceType {
    const resourceTypes = Object.values(RESOURCE_TYPE.HUBSPOT)
    return resourceTypes.indexOf(resourceType as HubSpotResourceType) !== -1
}
export function isSalesforceResourceType(resourceType: string): resourceType is SalesforceResourceType {
    const resourceTypes = Object.values(RESOURCE_TYPE.SALESFORCE)
    return resourceTypes.indexOf(resourceType as SalesforceResourceType) !== -1
}
export function isResourceType(resourceType: string): resourceType is HubSpotResourceType | SalesforceResourceType {
    return isHubSpotResourceType(resourceType) || isSalesforceResourceType(resourceType)
}

export function isMatchedResource(resourcePlatform: string, resourceType: string): resourceType is HubSpotResourceType | SalesforceResourceType {
    switch (resourcePlatform) {
        case RESOURCE_PLATFORM.HUBSPOT:
            return isHubSpotResourceType(resourceType)
        case RESOURCE_PLATFORM.SALESFORCE:
            return isSalesforceResourceType(resourceType)
        default:
            return false
    }
}
