import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { DocumentTemplate } from "types/backend/document_template";

export function useGetDocumentTemplateQuery(id: string | undefined) {
    const query = useQuery({
        queryKey: [Q.GET_DOCUMENT_TEMPLATE, id],
        queryFn: async () => {
            if (!id) {
                return;
            }
            const { data } = await api.get<DocumentTemplate>(
                `/document-templates/${encodeURIComponent(id)}`,
            );
            return data;
        },
        enabled: !!id,
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
    });

    return query;
}
