import TenantUserAvatar from "@component-func/TenantUserAvatar";
import CustomerTagBadge from "@component-func/customer-tag/CustomerTagBadge";
import { humanizeDate } from "@libs/duration";
import { cn } from "@libs/utils";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Document } from "types/backend/document";

type DocumentRowProps = Document & {
    currentDocumentId: string;
};

const DocumentRow: FC<DocumentRowProps> = ({
    id,
    title,
    createdAt,
    createdByTenantUser,
    customerTags,
    currentDocumentId,
}) => {
    return (
        <Link to={`/documents/${id}`}>
            <div
                className={cn(
                    currentDocumentId === id && "bg-gray-100/90",
                    `grid grid-flow-row w-full items-center rounded-md mb-2 p-2 hover:bg-gray-100/90 overflow-hidden shadow-sm`,
                )}
            >
                <h1 className="text-primary text-base font-medium truncate">
                    {title}
                </h1>
                <div className="flex flex-wrap gap-1 my-1">
                    {customerTags.map((customerTag) => (
                        <CustomerTagBadge
                            customerTag={customerTag}
                            key={customerTag.id}
                        />
                    ))}
                </div>
                <div className="grid grid-cols-[1fr,max-content] text-xs items-center">
                    <div className="flex flex-row justify-start items-center truncate">
                        <div className="truncate">Created by </div>
                        <TenantUserAvatar
                            tenantUser={createdByTenantUser}
                            className="h-6 w-6"
                        />
                    </div>
                    <div className="justify-self-end">
                        <span className="truncate w-full">
                            {humanizeDate(createdAt)}
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default DocumentRow;
