import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Media } from "types/backend";

export default function useSoftDeleteMediaMutate() {
    const { push } = usePopupNotification();
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationKey: [M.DELETE_MEDIA],
        mutationFn: async (id: string) => {
            return api.delete<Media>(`/media/${id}`);
        },
        onSuccess: ({ data }) => {
            const { meetingId } = data;
            queryClient.invalidateQueries({
                queryKey: [Q.GET_MEETING_MEDIA, meetingId],
                exact: true,
            });
        },
        onError: (err) => {
            push({
                title: "Cannot delete media",
                description: err.message,
                type: "destructive",
            });
        },
    });

    return mutate;
}
