import api from "@libs/api";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";
import { ZoomDownloadTask } from "types/backend/zoom_download_task";

interface PostDownloadZoomBody {
    meetingName: string;
    zoomRecordingId: string;
    zoomDownloadUrl: string;
    zoomAccessToken: string;
    language: "es" | "en-US" | "pt-BR";
    fileType: "MP4" | "M4A";
}

export default function useMutationDownloadZoomRecording() {
    const posthog = usePostHog();

    const mutation = useMutation<
        ZoomDownloadTask,
        AxiosError,
        PostDownloadZoomBody
    >({
        mutationFn: async (body) => {
            const { data } = await api.post<ZoomDownloadTask>(
                "/zoom-recordings/download/",
                body,
            );
            return data;
        },
        onSuccess: () => {
            posthog?.capture("zoom-recordings_download");
        },
    });

    return mutation;
}
