import api from "@libs/api";
import { useMutation } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { ResourcePlatform } from "types/backend/resources";

export default function useOAuthMutate(platform: ResourcePlatform) {
    const posthog = usePostHog();

    let path: string = platform;
    if (platform === "hubspot") {
        path = "hub_spot";
    }
    const mutation = useMutation({
        mutationFn: async (body: { code: string }) => {
            const { data } = await api.post<{ status: string }>(
                `/oauth/${path}?app=customer`,
                body,
            );
            return data;
        },
        onSuccess: () => {
            posthog?.capture(`oauth_${platform}_connected`);
        },
    });
    return mutation;
}
