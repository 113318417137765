import { useLocation, useParams } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@component-ui/utility/Table";

import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { GoogleMeetRecord } from "types/backend/google_meet";
import GoogleMeetingRecordingRow from "./GoogleMeetingRecordingRow";
import { useGetGoogleMeetParticipantsQuery } from "@hooks/google-meet/useGetGoogleMeetParticipantsQuery";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { useGetGoogleDownloadTasksQuery } from "@hooks/google-meet/useGetGoogleDownloadTasksQuery";
import { ParticipantList } from "@component-func/participant/ParticipantList";
import { Loader2, RefreshCcw } from "lucide-react";
import { Button } from "@component-ui/utility/Button";

const GoogleMeetingTable: React.FC = () => {
    const posthog = usePostHog();

    const { googleMeetingId } = useParams();

    const { state } = useLocation();
    const { recordings } = state as GoogleMeetRecord;

    const { isLoading: participantIsLoading, data: participant } =
        useGetGoogleMeetParticipantsQuery(googleMeetingId);

    const {
        isLoading: downloadTasksIsLoading,
        data: downloadTasks,
        refetch,
        isRefetching,
    } = useGetGoogleDownloadTasksQuery(recordings);

    useEffect(() => {
        posthog?.capture("google_meet_recording_table_page_navigated");
        posthog?.capture("$pageview", { pageName: "/google/recordings" });
    }, []);

    if (downloadTasksIsLoading || participantIsLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!state) {
        return;
    }

    return (
        <div className="h-full p-12 overflow-y-auto">
            <div className="flex justify-between">
                <div className="flex flex-col">
                    <span className="text-3xl font-bold py-3">
                        {/* {space} */}
                        Google Meeting
                    </span>
                    <div className="grid grid-cols-[max-content,1fr] py-3 gap-2">
                        <div className="pt-0.5">Participants: </div>
                        <ParticipantList
                            type="google-meet"
                            participants={participant ?? []}
                        />
                    </div>
                </div>
                <div className="flex justify-end flex-col">
                    <Button
                        onClick={() => refetch()}
                        variant="ghost"
                        className="gap-2"
                    >
                        {isRefetching ? (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        ) : (
                            <RefreshCcw />
                        )}
                        Refresh
                    </Button>
                </div>
            </div>
            <Table>
                <TableCaption>List of Google Meet Recordings</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead className="text-center">
                            Start Time
                        </TableHead>
                        <TableHead className="text-center">Duration</TableHead>
                        <TableHead className="text-center">
                            Download Status
                        </TableHead>
                        <TableHead className="w-1/5 text-center wit" />
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {recordings.map((recording) => (
                        <GoogleMeetingRecordingRow
                            key={recording.name}
                            googleRecording={recording}
                            recordingDownloadTask={
                                downloadTasks
                                    ? downloadTasks[
                                          recording.driveDestination?.file ?? ""
                                      ]
                                    : undefined
                            }
                        />
                    ))}
                </TableBody>
                {recordings.length <= 0 && (
                    <TableFooter>
                        <TableRow>
                            <TableCell
                                colSpan={6}
                                className="text-center text-gray-400 tracking-wide"
                            >
                                Do not have google meet recordings
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </div>
    );
};

export default GoogleMeetingTable;
