import BasicSelect from "@component-func/BasicSelect";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import { useGoogleCalendarEventsQuery } from "@hooks/google-calendar/useGoogleCalendarEventsQuery";
import { useGoogleCalendarsQuery } from "@hooks/google-calendar/useGoogleCalendarsQuery";
import { usePostHog } from "posthog-js/react";
import { FC, useEffect, useState } from "react";
import GoogleCalendarEvent from "./GoogleCalendarEvent";
import { useWatchGoogleCalendarMutate } from "@hooks/google-calendar/useWatchGoogleCalendarMutate";
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@component-ui/utility/Alert";
import { AlertCircle } from "lucide-react";
import { useWatchingGoogleCalendarsQuery } from "@hooks/google-calendar/useWatchingGoogleCalendarsQuery";
import { GoogleCalendar as GoogleCalendarType } from "types/backend/google_calendar";
import Empty from "@component-ui/Empty";
import { Badge } from "@component-ui/utility/Badge";

type GoogleCalendarProps = {
    calendar: GoogleCalendarType;
    watchingCalendars: Array<string>;
};
const GoogleCalendar: FC<GoogleCalendarProps> = ({
    calendar,
    watchingCalendars,
}) => {
    const { mutate, isPending } = useWatchGoogleCalendarMutate();
    return (
        <>
            <div className="flex items-center">
                <div
                    className="rounded-full w-6 h-6 m-2"
                    style={{
                        background: calendar.backgroundColor,
                    }}
                />
                <div>
                    {calendar.description || (
                        <span className="text-gray-500 italic">
                            No description
                        </span>
                    )}
                </div>
            </div>
            <div className="flex items-center justify-between">
                <div className="text-lg">Upcoming events</div>
                <TooltipProvider>
                    <Tooltip delayDuration={200}>
                        <TooltipTrigger asChild>
                            {isPending ? (
                                <LoadingSpinnerCenter />
                            ) : (
                                <Button
                                    onClick={() => {
                                        if (!calendar) return;
                                        mutate(calendar.id);
                                    }}
                                    disabled={watchingCalendars.includes(
                                        calendar.id,
                                    )}
                                >
                                    {watchingCalendars.includes(calendar.id)
                                        ? "Subscribed"
                                        : "Subscribe"}
                                </Button>
                            )}
                        </TooltipTrigger>
                        <TooltipContent side="bottom" className="max-w-[320px]">
                            Subscribe this Google Calendar to setup recording
                            bots for incoming{" "}
                            <strong>Google Meet meetings</strong>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </div>
            <GoogleCalendarEventList calendarId={calendar.id} />
        </>
    );
};

type GoogleCalendarEventsProps = {
    calendarId: string;
};
const GoogleCalendarEventList: FC<GoogleCalendarEventsProps> = ({
    calendarId,
}) => {
    const { data, isLoading, isError, isSuccess, isFetching } =
        useGoogleCalendarEventsQuery(calendarId);

    if (!calendarId) {
        return null;
    }
    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (isError || !isSuccess) {
        return (
            <Alert variant={"destructive"}>
                <AlertCircle className="w-4 h-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>
                    Fail to fetch Google Calendars Events
                </AlertDescription>
            </Alert>
        );
    }

    return isFetching ? (
        <LoadingSpinnerCenter />
    ) : (
        <div className="px-4">
            {data.items.map((calendarEvent) => (
                <GoogleCalendarEvent
                    calendarEvent={calendarEvent}
                    key={calendarEvent.id}
                />
            ))}
            {data.items.length === 0 && <Empty label="No upcoming events" />}
        </div>
    );
};

const GoogleCalendarRoot: FC = () => {
    const posthog = usePostHog();
    const {
        data: googleCalendars,
        isLoading,
        isError,
        isSuccess,
    } = useGoogleCalendarsQuery();
    const {
        data: watchingCalendars,
        isLoading: watchingIsLoading,
        isError: watchingIsError,
        isSuccess: watchingIsSuccess,
    } = useWatchingGoogleCalendarsQuery();
    const [selectedCalendarId, setSelectedCalendarId] = useState<string | null>(
        null,
    );

    useEffect(() => {
        posthog?.capture("$pageview", {
            pageName: "/settings/google-calendar",
        });
    }, []);

    if (isLoading || watchingIsLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (isError || !isSuccess || watchingIsError || !watchingIsSuccess) {
        return (
            <Alert variant={"destructive"}>
                <AlertCircle className="w-4 h-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>
                    Fail to fetch Google Calendars
                </AlertDescription>
            </Alert>
        );
    }

    const options = googleCalendars.items.map((item) => ({
        label: item.summary,
        value: item.id,
    }));

    const selectedCalendar = googleCalendars.items.find(
        (calendar) => calendar.id === selectedCalendarId,
    );

    return (
        <div className="h-full w-full p-12 grid grid-rows-[max-content,1fr] overflow-y-auto">
            <h1 className="text-2xl font-bold mb-12">Google Calendar</h1>
            <div className="h-full max-w-screen-md w-full px-2 flex flex-col gap-1">
                <div className="flex ">
                    <span className="mr-2 mt-0.5">Subscribed Calendars: </span>
                    <div className="flex gap-2 flex-wrap">
                        {watchingCalendars.length === 0 && (
                            <span className="text-gray-500 italic">No subscribed calendars</span>
                        )}
                        {watchingCalendars.map((calendarId) => (
                            <div key={calendarId}>
                                <Badge variant={"noHover"}>
                                    {
                                        options.find(
                                            (option) =>
                                                option.value === calendarId,
                                        )?.label
                                    }
                                </Badge>
                            </div>
                        ))}
                    </div>
                </div>

                <BasicSelect
                    name="google-calendars"
                    placeholder="Select a Google Calendar"
                    options={options}
                    value={selectedCalendarId || ""}
                    onValueChange={(value) => setSelectedCalendarId(value)}
                />
                {selectedCalendar && (
                    <GoogleCalendar
                        calendar={selectedCalendar}
                        watchingCalendars={watchingCalendars}
                    />
                )}
            </div>
        </div>
    );
};

export default GoogleCalendarRoot;
