import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { CustomerTag } from "types/backend/customer_tags";

export default function useCustomerTags() {
    const query = useQuery({
        queryKey: [Q.GET_CUSTOMER_TAG],
        queryFn: async () => {
            const { data } = await api.get<CustomerTag[]>("/customer-tags");
            return data;
        },
        staleTime: 30 * 1000,
        gcTime: 60 * 1000,
    });

    return query
}
