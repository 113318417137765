import { Q } from "@constant/query-key";
import api, { guestApi } from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { UserType } from "types/backend";
import { GuestDocument } from "types/backend/document";

export default function useGetSharedDocumentQuery(
    id: string | null,
    userType: Exclude<UserType, "tenant-user">,
) {
    const query = useQuery<GuestDocument, AxiosError>({
        queryKey: [Q.GET_SHARED_DOCUMENT_BY_ID, id, userType],
        queryFn: async ({ queryKey }) => {
            const [, id] = queryKey;
            let response: AxiosResponse<GuestDocument>;
            switch (userType) {
                case "shared-user":
                    response = await api.get<GuestDocument>(
                        `/documents/${id}/share`,
                    );
                    break;
                case "guest":
                    response = await guestApi.get<GuestDocument>(
                        `/documents/${id}`,
                    );
                    break;
            }
            return response.data;
        },
        enabled: !!id,
        gcTime: 0,
        staleTime: 0,
        retry: (_, error) => {
            if (error.response?.status === 401) {
                return false;
            }
            return true;
        },
    });

    return query;
}
