import { Avatar, AvatarFallback } from "@component-ui/utility/Avatar";
import { convertTextToColour } from "@libs/utils";
import { FC } from "react";
import { Comment } from "types/backend/comment";
import dayjs from "@libs/dayjs";

type CommentProps = {
    comment: Comment;
};

const Comment: FC<CommentProps> = ({ comment }) => {
    return (
        <div>
            <div className="flex items-center gap-2">
                <Avatar className="w-6 h-6 text-xs">
                    <AvatarFallback
                        className="text-white cursor-default uppercase"
                        style={{
                            backgroundColor: convertTextToColour(
                                comment.creator?.email,
                            ),
                        }}
                    >
                        {comment.creator.email.slice(0, 2)}
                    </AvatarFallback>
                </Avatar>
                <span className="text-sm font-semibold">
                    {comment.creator.username ?? comment.creator.email}
                </span>
                <div className="text-xs text-gray-400">
                    {dayjs(comment.createdAt).fromNow()}
                </div>
            </div>
            <div className="pl-8 text-sm">{comment.content}</div>
        </div>
    );
};

export { Comment };
