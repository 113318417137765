import { create } from "zustand";

type TranscriptionLayoutStore = {
    isPointForm: boolean;
    isOriginalLangTranscriptHidden: boolean;
    togglePointForm: (value: boolean) => void;
    toggleOriginalLangTranscriptHidden: (value: boolean) => void;
};

export const useTranscriptionLayoutStore = create<TranscriptionLayoutStore>(
    (set) => ({
        isPointForm: false,
        isOriginalLangTranscriptHidden: false,
        togglePointForm: (value: boolean) =>
            set(() => ({ isPointForm: value })),
        toggleOriginalLangTranscriptHidden: (value: boolean) =>
            set(() => ({
                isOriginalLangTranscriptHidden: value,
            })),
    }),
);
