import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@component-ui/utility/Tabs";
import { FC, useEffect, useState } from "react";
import ZoomMeetingList from "./zoom/ZoomMeetingList";
import GoogleMeetingList from "./google-meet/GoogleMeetingList";
import { useLocation } from "react-router-dom";
import useUserSelfInformation from "@hooks/user/useUserSelfInformationQuery";

const RecordingDownload: FC = () => {
    const location = useLocation();
    const { data: tenantUser } = useUserSelfInformation();
    const googleEnable =
        tenantUser?.oauth.google;
    const zoomEnable = tenantUser?.oauth.zoom;

    const [tab, setTab] = useState<string>(zoomEnable ? "1" : "2");


    useEffect(() => {
        if (location.pathname.includes("google-meets")) {
            setTab("2");
        }
    }, [location.pathname, setTab]);
    return (
        <Tabs defaultValue="1" value={tab} onValueChange={setTab}>
            <TabsList aria-label="Manage your account">
                <TabsTrigger value="1" disabled={!zoomEnable}>
                    Zoom
                </TabsTrigger>
                <TabsTrigger value="2" disabled={!googleEnable}>
                    Google Meet
                </TabsTrigger>
            </TabsList>
            <TabsContent value="1">
                {zoomEnable && <ZoomMeetingList />}
            </TabsContent>
            <TabsContent value="2">
                {googleEnable && <GoogleMeetingList />}
            </TabsContent>
        </Tabs>
    );
};

export default RecordingDownload;
