import dayjs from "@libs/dayjs";

export function formatDuration(seconds: string | number) {
    const sec = Math.round(Number(seconds));

    const h = Math.floor(sec / 3600);
    const m = Math.floor((sec % 3600) / 60);
    const s = sec % 60;
    return dayjs
        .duration({
            hours: h,
            minutes: m,
            seconds: s,
        })
        .format("HH:mm:ss");
}

export function humanizeDate(referenceTime: string | Date) {
    return dayjs(referenceTime).calendar(null, {
        sameDay: "[Today at] h:mm A", // The same day ( Today at 2:30 AM )
        nextDay: "[Tomorrow] h:mm A", // The next day ( Tomorrow at 2:30 AM )
        nextWeek: "dddd", // The next week ( Sunday at 2:30 AM )
        lastDay: "[Yesterday] h:mm A", // The day before ( Yesterday at 2:30 AM )
        lastWeek: "[Last] dddd h:mm A", // Last week ( Last Monday at 2:30 AM )
        sameElse: "DD/MM/YYYY hh:mm", // Everything else ( 7/10/2011 )
    });
}
