import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { Meeting } from "types/backend/meetings";

export function useMeetingsQuery(tagId = "") {
    const query = useQuery({
        queryKey: [Q.GET_MEETINGS, { tagId }],
        queryFn: async () => {
            const { data } = await api.get<Meeting[]>("/meetings", {
                params: { "tag-id": tagId },
            });
            return data;
        },
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
    });

    return query;
}
