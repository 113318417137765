import Empty from "@component-ui/Empty";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@component-ui/utility/Dialog";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@component-ui/utility/HoverCard";
import { Label } from "@component-ui/utility/Label";
import { RadioGroup, RadioGroupItem } from "@component-ui/utility/RadioGroup";
import { RESOURCE_PLATFORM, RESOURCE_TYPE } from "@constant/resource";
import useTeamPermission from "@hooks/user/useTeamPermissionQuery";
import { PlusCircle, X } from "lucide-react";
import { FC, useEffect, useState } from "react";
import {
    ResourceMappingItemInfo,
    ResourceOption,
    ResourcePlatform,
    ResourceType,
} from "types/backend/resources";
import ResourceOptionsComboBox from "./ResourceOptionComboBox";
import useCreateResourceMappingRelationMutate from "@hooks/resource-mapping/useCreateResourceMappingRelationMutate";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

type ResourceOptionSelectDialogProps = {
    itemInfo: ResourceMappingItemInfo;
};

type ResourceOptionSelectContentProps = ResourceOptionSelectDialogProps & {
    resourcePlatformOptions: Array<ResourcePlatform>;
    handleSubmit: () => void;
};

const ResourceOptionSelectContent: FC<ResourceOptionSelectContentProps> = ({
    resourcePlatformOptions,
    itemInfo,
    handleSubmit,
}) => {
    const navigate = useNavigate();
    const [resourcePlatform, setResourcePlatform] = useState<ResourcePlatform>(
        resourcePlatformOptions[0],
    );
    const [resourceType, setResourceType] = useState<
        ResourceType | undefined
    >();
    const [selectedResourceOption, setSelectedResourceOption] = useState<
        ResourceOption | undefined
    >();
    const { mutate, isPending } = useCreateResourceMappingRelationMutate(
        itemInfo.itemType,
    );

    const onSubmit = () => {
        if (!selectedResourceOption) {
            return;
        }
        mutate(
            { resourceOption: selectedResourceOption, itemInfo },
            {
                onSuccess: () => handleSubmit(),
            },
        );
    };

    if (resourcePlatformOptions.length <= 0) {
        return (
            <div className="flex flex-col gap-6">
                <Empty label="You don't have any CRM connected yet. Please go to settings page to connect one" />
                <Button onClick={() => navigate("/settings/crm-connection")}>
                    Go to CRM setting
                </Button>
            </div>
        );
    }
    return (
        <div className="grid grid-cols-[max-content,1fr] gap-x-2 gap-y-5 items-center p-2">
            <div>CRM:</div>
            <RadioGroup
                defaultValue={resourcePlatform}
                onValueChange={(value: ResourcePlatform) => {
                    setResourcePlatform(value);
                    setResourceType(undefined);
                }}
                className="grid-cols-2"
            >
                {resourcePlatformOptions.map((platform) => (
                    <div key={platform} className="flex items-center space-x-2">
                        <RadioGroupItem value={platform} id={platform} />
                        <Label htmlFor={platform} className="mb-0">
                            {_.capitalize(platform)}
                        </Label>
                    </div>
                ))}
            </RadioGroup>
            <div>Object:</div>
            <RadioGroup
                onValueChange={(value: ResourceType) => {
                    setResourceType(value);
                    setSelectedResourceOption(undefined);
                }}
            >
                {resourcePlatform === RESOURCE_PLATFORM.HUBSPOT &&
                    Object.values(RESOURCE_TYPE.HUBSPOT).map((type) => (
                        <div key={type} className="flex items-center space-x-2">
                            <RadioGroupItem value={type} id={type} />
                            <Label className="mb-0">{_.capitalize(type)}</Label>
                        </div>
                    ))}
                {resourcePlatform === RESOURCE_PLATFORM.SALESFORCE &&
                    Object.values(RESOURCE_TYPE.SALESFORCE).map((type) => (
                        <div key={type} className="flex items-center space-x-2">
                            <RadioGroupItem value={type} id={type} />
                            <Label className="mb-0">{_.capitalize(type)}</Label>
                        </div>
                    ))}
            </RadioGroup>
            <ResourceOptionsComboBox
                resourcePlatform={resourcePlatform}
                resourceType={resourceType}
                resourceOption={selectedResourceOption}
                setResourceOption={setSelectedResourceOption}
                className="col-span-2"
            />
            <Button
                onClick={onSubmit}
                disabled={!selectedResourceOption}
                className="col-span-2"
            >
                {isPending && <LoadingSpinnerCenter />}
                Connect
            </Button>
        </div>
    );
};

const ResourceOptionSelectDialogCapture: FC<ResourceMappingItemInfo> = ({
    itemType,
}) => {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("$pageview", {
            pageName: `/${itemType}/resource_mapping/new`,
        });
    }, []);
    return null;
};

const ResourceOptionSelectDialog: FC<ResourceOptionSelectDialogProps> = ({
    itemInfo,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
        data: permission,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useTeamPermission();

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return (
            <HoverCard openDelay={200} closeDelay={300}>
                <HoverCardTrigger asChild>
                    <X className="w-8 h-8" />
                </HoverCardTrigger>
                <HoverCardContent className="w-fit flex flex-col gap-2">
                    Failed to get permission.
                    <Button onClick={() => refetch()}>Retry</Button>
                </HoverCardContent>
            </HoverCard>
        );
    }

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild onClick={(e) => e.stopPropagation()}>
                <Button size="sIcon" variant="ghost" className="rounded-full">
                    <PlusCircle className="w-4 h-4 text-gray-500" />
                </Button>
            </DialogTrigger>
            <DialogContent onClick={(e) => e.stopPropagation()}>
                <DialogHeader>
                    <DialogTitle>Select Search Type</DialogTitle>
                    <ResourceOptionSelectDialogCapture {...itemInfo} />
                </DialogHeader>
                <ResourceOptionSelectContent
                    resourcePlatformOptions={permission.crmIntegrations}
                    itemInfo={itemInfo}
                    handleSubmit={() => setIsOpen(false)}
                />
            </DialogContent>
        </Dialog>
    );
};

export default ResourceOptionSelectDialog;
