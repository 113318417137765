import api from "@libs/api";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";
import {
    TranslationSegment,
    TranslationSegmentMetadata,
} from "types/backend/media";

export default function useTranslationSegmentUpdate(id: string) {
    const posthog = usePostHog();
    const mutation = useMutation<
        TranslationSegment,
        AxiosError,
        {
            metadata: TranslationSegmentMetadata;
        }
    >({
        mutationFn: async ({ metadata }) => {
            const { data } = await api.put<TranslationSegment>(
                `/translation-segments/${id}`,
                {
                    metadata,
                },
            );
            return data;
        },
        onSuccess: () => {
            posthog?.capture("transcription_segment_updated");
        },
    });

    return mutation;
}
