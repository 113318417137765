import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export interface GoogleMeetParticipant {
    name: string;
    signedinUser?: {
        user: string;
        displayName: string;
    };
    anonymousUser?: {
        displayName: string;
    };
    phoneUser?: {
        displayName: string;
    };
    earliestStartTime: string;
    latestEndTime: string;
}


export const useGetGoogleMeetParticipantsQuery = (
    googleMeetingName: string | undefined,
) => {
    const { push } = usePopupNotification();

    const query = useQuery<Array<GoogleMeetParticipant>>({
        queryKey: [Q.GET_GOOGLE_MEETING_PARTICIPANTS, googleMeetingName],
        queryFn: async () => {
            try {
                const { data } = await api.post(`google-meets/participants`, {
                    meetingName: `conferenceRecords/${googleMeetingName}`,
                });
                return data;
            } catch (error) {
                if (
                    axios.isAxiosError(error) &&
                    error.response?.status == 429
                ) {
                    push({
                        type: "destructive",
                        title: "Rate Limit",
                        description: "too many request in one minutes",
                    });
                }
                throw error;
            }
        },
        enabled: !!googleMeetingName,
    });

    return query;
};
