import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import useTeamPermission from "@hooks/user/useTeamPermissionQuery";
import { FC, MouseEvent, useEffect, useState } from "react";
import { HubspotSvg } from "./HubSpotSvg";
import { SalesforceSvg } from "./SalesforceSvg";
import { useQueryClient } from "@tanstack/react-query";
import { Q } from "@constant/query-key";
import { HUBSPOT_OAUTH_URL, SALESFORCE_OAUTH_CALLBACK_URL } from "@libs/api";
import { usePostHog } from "posthog-js/react";

type CrmOAuthConnectButtonProps = {
    disable: boolean;
};

const HubSpotConnectButton: FC<CrmOAuthConnectButtonProps> = ({ disable }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [popup, setPopup] = useState<Window | null>(null);
    const queryClient = useQueryClient();

    function onClick(event: MouseEvent) {
        event.stopPropagation();
        if (disable || isLoading) return;
        const width = 800;
        const height = 600;
        const left = window.screenX + (window.outerWidth - width) / 2;
        const top = window.screenY + (window.outerHeight - height) / 2.5;
        const title = "HUBSPOT OAUTH";
        const url = HUBSPOT_OAUTH_URL;
        const popup = window.open(
            url,
            title,
            `width=${width},height=${height},left=${left},top=${top}`,
        );
        setIsLoading(true);
        setPopup(popup);
    }

    useEffect(() => {
        if (!popup) {
            return;
        }
        const timer = setInterval(async () => {
            if (!popup) {
                timer && clearInterval(timer);
                return;
            }
            if (popup.closed) {
                setPopup(null);
                setIsLoading(false);
                await queryClient.invalidateQueries({
                    queryKey: [Q.GET_TEAM_PERMISSION],
                });
                timer && clearInterval(timer);
            }
        }, 500);
    }, [popup]);

    return (
        <Button
            className="bg-[#ff7a59] hover:bg-[#ff7a59] bg-opacity-95 hover:bg-opacity-100 text-white px-4 shadow-md w-60 h-14"
            onClick={onClick}
            disabled={disable || isLoading}
        >
            {isLoading ? (
                <LoadingSpinnerCenter />
            ) : (
                <div className="flex w-full justify-between items-center">
                    <HubspotSvg />
                    <span>{disable ? "Connected" : "Connect"} to HubSpot</span>
                </div>
            )}
        </Button>
    );
};

const SalesforceConnectButton: FC<CrmOAuthConnectButtonProps> = ({
    disable,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [popup, setPopup] = useState<Window | null>(null);
    const queryClient = useQueryClient();

    const baseUrl = "https://login.salesforce.com";
    const clientId = import.meta.env.VITE_SALESFORCE_CLIENT_ID || "";
    const redirectUri = SALESFORCE_OAUTH_CALLBACK_URL;

    function onClick(event: MouseEvent) {
        event.stopPropagation();
        if (disable || isLoading) return;
        const width = 800;
        const height = 600;
        const left = window.screenX + (window.outerWidth - width) / 2;
        const top = window.screenY + (window.outerHeight - height) / 2.5;
        const title = "SALESFORCE OAUTH";
        const url = `${baseUrl}/services/oauth2/authorize?response_type=code&client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUri)}`;
        const popup = window.open(
            url,
            title,
            `width=${width},height=${height},left=${left},top=${top}`,
        );
        setIsLoading(true);
        setPopup(popup);
    }

    useEffect(() => {
        if (!popup) {
            return;
        }
        const timer = setInterval(async () => {
            if (!popup) {
                timer && clearInterval(timer);
                return;
            }
            if (popup.closed) {
                setPopup(null);
                setIsLoading(false);
                await queryClient.invalidateQueries({
                    queryKey: [Q.GET_TEAM_PERMISSION],
                });
                timer && clearInterval(timer);
            }
        }, 500);
    }, [popup]);

    return (
        <Button
            className="bg-[#00A1E0] hover:bg-[#00A1E0] bg-opacity-95 hover:bg-opacity-100 text-white px-4 shadow-md w-60 h-14"
            onClick={onClick}
            disabled={disable}
        >
            <div className="flex w-full justify-between items-center">
                <SalesforceSvg />
                <span>{disable ? "Connected" : "Connect"} to Salesforce</span>
            </div>
        </Button>
    );
};

const CrmConnection: FC = () => {
    const {
        data: teamPermission,
        isLoading,
        isFetching,
        isError,
        isSuccess,
        refetch,
    } = useTeamPermission();
    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture("$pageview", { pageName: "/settings/crm" });
    }, []);

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (isError || !isSuccess) {
        return (
            <div className="h-full grid place-items-center ">
                <h1 className="text-4xl text-foreground/50">
                    Cannot get the crm connection information, please try again.
                </h1>
                <Button onClick={() => refetch()}>Retry</Button>
            </div>
        );
    }

    const { crmIntegrations } = teamPermission;

    return (
        <div className="h-full w-full p-12 grid grid-rows-[max-content,1fr] overflow-hidden">
            <h1 className="text-2xl font-bold mb-12">CRM Connection</h1>
            <div className="h-full w-full overflow-y-auto">
                {isFetching ? (
                    <LoadingSpinnerCenter />
                ) : (
                    <div className="grid grid-flow-row gap-4">
                        <div>
                            <HubSpotConnectButton
                                disable={crmIntegrations.includes("hubspot")}
                            />
                        </div>
                        <div>
                            <SalesforceConnectButton
                                disable={crmIntegrations.includes("salesforce")}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CrmConnection;
