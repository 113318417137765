import { FORM_KEY } from "@constant/form";
import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { Meeting } from "types/backend/meetings";

function validateForm(formData: FormData) {
    const label = formData.get(FORM_KEY.MEETING.NAME) as string;
    if (!label) throw new Error("Invalid meeting, please input correct meeting");
    return {
        label,
    };
}

export default function useUpdateMeetingMutate(meetingId: string) {
    const queryClient = useQueryClient();
    const { push } = usePopupNotification();
    const posthog = usePostHog();

    return useMutation({
        mutationKey: [M.UPDATE_MEETING, meetingId],
        mutationFn: async (formData: FormData) => {
            const patchBody = validateForm(formData);
            posthog?.capture("meeting_updated");
            const { data } = await api.patch<Meeting>(
                "/meetings/" + meetingId,
                patchBody,
            );
            return data;
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [Q.GET_MEETINGS] });
            push({
                title: `Successfully updated meeting ${data.label}`,
                description: "You have successfully updated the meeting",
                type: "success",
            });
        },
        onError: (err) => {
            push({
                title: "Failed to update meeting",
                description: `Error occurred due to ${(err as Error).message}`,
                type: "destructive",
            });
        },
    });
}
