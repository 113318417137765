import AudioVisualizer from "@component-func/recorder/AudioVisualizer";
import { Button } from "@component-ui/utility/Button";
import { formatDuration } from "@libs/duration";
import { useAudioRecorderStore } from "@store/audio-recorder-store";
import { Pause, Play, Square } from "lucide-react";
import { FC } from "react";

type AudioRecorderProps = {
    stopRecord: () => void;
    togglePause: () => void;
};

const AudioRecorder: FC<AudioRecorderProps> = ({ stopRecord, togglePause }) => {
    const time = useAudioRecorderStore.use.time();
    const isRecording = useAudioRecorderStore.use.isRecording();
    const mediaRecorder = useAudioRecorderStore.use.mediaRecorder();

    return (
        <div className="flex items-center justify-center">
            <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                    stopRecord();
                }}
            >
                <Square fill="black" className="w-4 h-4" />
            </Button>
            <Button variant="ghost" size="icon" onClick={() => togglePause()}>
                {isRecording ? (
                    <Pause fill="black" className="w-4 h-4" />
                ) : (
                    <Play fill="black" className="w-4 h-4" />
                )}
            </Button>
            {mediaRecorder && (
                <span className="inline-flex items-center gap-2">
                    <AudioVisualizer
                        mediaRecorder={mediaRecorder}
                        color="black"
                        bgColor="transparent"
                        barWidth={4}
                    />
                    {formatDuration(time)}
                </span>
            )}
        </div>
    );
};

export default AudioRecorder;
