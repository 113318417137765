import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { usePostHog } from "posthog-js/react";
import {
    CreateDocumentResourceMappingRelationRequestBody,
    DocumentResourceMappingRelation,
    ResourceMappingItemInfo,
    ResourceMappingItemType,
    ResourceOption,
} from "types/backend/resources";

export default function useCreateResourceMappingRelationMutate(
    itemType: ResourceMappingItemType,
) {
    const queryClient = useQueryClient();
    const { push } = usePopupNotification();
    const posthog = usePostHog();

    return useMutation({
        mutationKey: [M.CREATE_RESOURCE_MAPPING_RELATION],
        mutationFn: async ({
            resourceOption,
            itemInfo,
        }: {
            resourceOption: ResourceOption;
            itemInfo: ResourceMappingItemInfo;
        }) => {
            posthog?.capture(`${itemType}_resource_mapping_relation_created`);
            const { data } = await api.post<
                DocumentResourceMappingRelation,
                AxiosResponse<DocumentResourceMappingRelation>,
                CreateDocumentResourceMappingRelationRequestBody
            >(`/customer-crm-relation/${itemType}`, {
                resourcePlatform: resourceOption.resourcePlatform,
                resourceType: resourceOption.resourceType,
                resourceId: resourceOption.value,
                resourceName: resourceOption.label,
                itemId: itemInfo.itemId,
            });
            return data;
        },
        onSuccess: async (_, variables) => {
            if (itemType === "document") {
                await queryClient.invalidateQueries({
                    queryKey: [Q.GET_DOCUMENT, variables.itemInfo.itemId],
                });
                await queryClient.invalidateQueries({
                    queryKey: [Q.GET_ALL_DOCUMENT],
                });
            }
            push({
                title: `Successfully connected CRM resource to ${itemType}`,
                description: "You have successfully connected CRM resource",
                type: "success",
            });
        },
        onError: (error) => {
            const errorMessage =
                error instanceof AxiosError
                    ? error.response?.data.message
                    : error.message;
            push({
                title: "Failed to connect CRM resource",
                description: `Error occurred due to ${errorMessage}`,
                type: "destructive",
            });
        },
    });
}
