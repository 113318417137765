import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { Document } from "types/backend/document";

export default function useDocumentQuery(id: string | null) {
    const query = useQuery({
        queryKey: [Q.GET_DOCUMENT, id],
        queryFn: async ({ queryKey }) => {
            const [, id] = queryKey;
            const { data } = await api.get<Document>(
                `/documents/${id}`,
            );
            return data;
        },
        enabled: !!id,
        refetchOnWindowFocus: false,
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
    });

    return query;
}
