import {
    FC,
    PropsWithChildren,
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { AlertCircle, CheckCircle2, XCircle } from "lucide-react";
import { createPortal, flushSync } from "react-dom";
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@component-ui/utility/Alert";

type PopupNotificationCtx = {
    push: (notification: PopupNotification) => void;
};

const PopupNotificationContext = createContext<PopupNotificationCtx>({});

export type PopupNotification = {
    type: "success" | "warning" | "destructive";
    title: string;
    description: string;
};

const DURATION = 5000;

export const PopupNotificationProvider: FC<PropsWithChildren> = ({
    children,
}) => {
    const icons: Record<PopupNotification["type"], ReactNode> = {
        success: <CheckCircle2 className="w-4 h-4" />,
        warning: <AlertCircle className="w-4 h-4" />,
        destructive: <XCircle className="w-4 h-4" />,
    };

    const [notifications, setNotifications] = useState<PopupNotification[]>([]);

    const value: PopupNotificationCtx = useMemo(
        () => ({
            push: (notification) => {
                setNotifications((prev) => [...prev, notification]);
                setTimeout(() => {
                    setNotifications((prev) => [...prev.slice(1)]);
                }, DURATION);
            },
        }),
        [],
    );

    return (
        <PopupNotificationContext.Provider value={value}>
            {children}
            {createPortal(
                <div className="absolute top-4 left-1/2 -translate-x-1/2 flex flex-col-reverse gap-2 max-w-md w-full z-[999]">
                    {notifications.map((notification, i) => (
                        <Alert
                            key={i}
                            variant={notification.type}
                            className="animate-pop-up-notification"
                        >
                            {icons[notification.type]}
                            <AlertTitle>{notification.title}</AlertTitle>
                            <AlertDescription>
                                {notification.description}
                            </AlertDescription>
                        </Alert>
                    ))}
                </div>,
                document.body,
            )}
        </PopupNotificationContext.Provider>
    );
};

export function usePopupNotification() {
    return useContext(PopupNotificationContext);
}
