import { EditableContainer } from "@component-func/EditableContainer";
import { Button } from "@component-ui/utility/Button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@component-ui/utility/Popover";
import { SEARCH_PARAMS } from "@constant/searchParams";
import useCreateThreadMutate from "@hooks/thread/useCreateThreadMutate";
import { Editor } from "@tiptap/core";
import { ArrowUp, MessageSquare } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { FC, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { UserType } from "types/backend";

export type CreateThreadPopoverProps = {
    editor: Editor;
    userType: UserType;
};

const CreateThreadPopoverCapture = () => {
    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture("$pageview", {
            pageName: "/threads/new",
        });
    }, []);

    return null;
};

const CreateThreadPopover: FC<CreateThreadPopoverProps> = ({
    editor,
    userType,
}) => {
    const { mutateAsync: createThread } = useCreateThreadMutate(userType);
    const [contentEditable, setContentEditable] = useState(true);
    const [search] = useSearchParams();

    const inputRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    async function handleCreateThread() {
        if (!inputRef.current) return;
        setContentEditable(false);
        try {
            const documentId = search.get(SEARCH_PARAMS.CURRENT_DOCUMENT);
            const { state } = editor;
            const { from, to } = state.selection;
            const referenceText = state.doc.textBetween(from, to);
            if (!documentId || !referenceText) return;
            const { id } = await createThread({
                documentId,
                referenceText,
                comment: inputRef.current.innerText,
            });
            editor.commands.setThread(id);
        } finally {
            setContentEditable(true);
            setOpen(false);
        }
    }

    async function handleKeyDown(e: KeyboardEvent<HTMLDivElement>) {
        if (e.code === "Enter") {
            e.preventDefault();
            await handleCreateThread();
        }
    }

    return (
        <Popover modal={false} open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button variant="outline" size="icon">
                    <MessageSquare className="w-4 h-4" />
                </Button>
            </PopoverTrigger>
            <PopoverContent
                sideOffset={30}
                className="p-1 w-96 focus-visible:outline-none"
                onOpenAutoFocus={() => {
                    setTimeout(() => {
                        inputRef.current?.focus();
                    });
                }}
                hideWhenDetached
            >
                <div className="p-2 flex justify-end items-end">
                    <div className="flex-1">
                        <EditableContainer
                            ref={inputRef}
                            contentEditable={contentEditable}
                            onKeyDown={handleKeyDown}
                            data-placeholder="Add a comment..."
                            className="flex-1 whitespace-pre-wrap break-all focus-visible:outline-none [&[contentEditable]]:empty:after:content-[attr(data-placeholder)] [&[contentEditable]]:empty:after:text-gray-200
                    text-sm"
                        />
                    </div>
                    <div>
                        <Button
                            variant="ghost"
                            size="sIcon"
                            onClick={handleCreateThread}
                        >
                            <ArrowUp className="w-4 h-4" />
                        </Button>
                    </div>
                </div>
                <CreateThreadPopoverCapture />
            </PopoverContent>
        </Popover>
    );
};

export default CreateThreadPopover;
