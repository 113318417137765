import { M } from "@constant/mutation-key";
import { useCreateMediaMutate } from "@hooks/media/useCreateMediaMutate";
import { useMutation } from "@tanstack/react-query";
import useTriggerConcatMutate from "./useTriggerConcatMutate";
import { FORM_KEY } from "@constant/form";

export default function useHandleCachedRecording() {
    const { mutateAsync: createMedia } = useCreateMediaMutate();
    const { mutateAsync: concatMedia } = useTriggerConcatMutate();

    const mutate = useMutation({
        mutationKey: [M.HANDLE_CACHED_RECORDING],
        mutationFn: async (formData: FormData) => {
            const mediaName = formData.get(FORM_KEY.MEDIA.NAME) as string;
            if (!mediaName) throw new Error("Please enter media name");
            const media = await createMedia(formData);
            await concatMedia({
                meetingId: media.meetingId,
                mediaId: media.id,
                mediaName,
            });
        },
    });

    return mutate;
}
