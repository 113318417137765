import LogoutButton from "@component-func/auth/LogoutButtom";
import useUserSelfInformation from "@hooks/user/useUserSelfInformationQuery";
import { cn } from "@libs/utils";
import { Settings } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { FC, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

type SettingCategoryItem = {
    path: string;
    name: string;
    displayText: string;
    hidden?: boolean;
};

const SettingList: FC = () => {
    const location = useLocation();
    const posthog = usePostHog();
    const { data: tenantUser } = useUserSelfInformation();

    const [, , categoryName = "user-information"] =
        location.pathname.split("/");

    const categories: SettingCategoryItem[] = [
        {
            path: "/settings/user-information",
            name: "user-information",
            displayText: "User Information",
        },
        {
            path: "/settings/tags",
            name: "tags",
            displayText: "Tags",
        },
        {
            path: "/settings/summarization",
            name: "summarization",
            displayText: "Summarization",
        },
        {
            path: "/settings/crm-connection",
            name: "crm-connection",
            displayText: "CRM Connection",
        },
        {
            path: "/settings/google-calendar",
            name: "google-calendar",
            displayText: "Google Calendar",
            hidden: tenantUser?.oauth.google ? false : true,
        },
    ];

    useEffect(() => {
        posthog?.capture("setting_page_navigated");
        posthog?.capture("$pageview", { pageName: "/settings" });
    }, []);

    return (
        <aside className="h-full w-full grid grid-rows-[max-content,1fr,max-content] p-2">
            <span className="inline-flex items-center gap-2">
                <Settings className="min-w-[40px] w-10 h-10" />
                <h1 className="text-2xl font-medium leading-none">Settings</h1>
            </span>
            <ul className="h-full overflow-auto">
                {categories.map(
                    (category) =>
                        !category.hidden && (
                            <li key={category.name}>
                                <Link to={category.path}>
                                    <div
                                        className={cn(
                                            categoryName === category.name &&
                                                "bg-gray-100/90",
                                            `flex justify-between items-center max-h-32 rounded-md my-4 mx-2 p-4 shadow-sm hover:bg-gray-100/90`,
                                        )}
                                    >
                                        {category.displayText}
                                    </div>
                                </Link>
                            </li>
                        ),
                )}
            </ul>
            <LogoutButton />
        </aside>
    );
};

export default SettingList;
