import { CopyButtonWithTooltip } from "@component-func/CopyButton";
import Empty from "@component-ui/Empty";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import useSummarizeMutate from "@hooks/summarize/useSummarizeMutate";
import useSummarizingResult from "@hooks/summarize/useSummarizingResult";
import { cn } from "@libs/utils";
import { Accordion } from "@radix-ui/react-accordion";
import {
    ComponentPropsWithoutRef,
    ElementRef,
    forwardRef,
    useEffect,
    useState,
} from "react";

type SummarizeProps = {
    mediaId: string;
    summarizingSystemMessageId: string;
};

const Summarize = forwardRef<
    ElementRef<"div">,
    ComponentPropsWithoutRef<"div"> & SummarizeProps
>(({ mediaId, summarizingSystemMessageId, className, ...props }, ref) => {
    const { data, isLoading, isSuccess, isError, isFetching } =
        useSummarizingResult(mediaId, summarizingSystemMessageId);
    const {
        mutate,
        isPending,
        isError: isSummarizeError,
        error,
    } = useSummarizeMutate(mediaId, summarizingSystemMessageId);
    const [triggered, setTriggered] = useState(false);
    const [showCopy, setShowCopy] = useState(false);

    useEffect(() => {
        if (!mediaId || !summarizingSystemMessageId || isFetching) return;
        if (!data && !triggered) {
            mutate({ mediaId, systemMessageId: summarizingSystemMessageId });
            setTriggered(true);
        }
    }, [mediaId, summarizingSystemMessageId, data, isFetching]);

    if (!summarizingSystemMessageId) {
        return <Empty label="Pick an summarizing option to view the result" />;
    }

    if (isLoading || isPending || (!data && triggered)) {
        return (
            <div>
                <LoadingSpinnerCenter />
            </div>
        );
    }

    if (!isSuccess || isError) {
        return <Empty label="Cannot get the corresponding summarize result" />;
    }

    if (isSummarizeError) {
        if (error.response?.data.message !== "Insert Error") {
            return (
                <Empty label="Cannot get the corresponding summarize result" />
            );
        }
    }

    if (data.status === "progressing") {
        return (
            <div>
                <LoadingSpinnerCenter />
            </div>
        );
    }

    if (data.status === "error") {
        return (
            <div>
                <Empty label="Cannot get the corresponding summarize result" />
            </div>
        );
    }

    return (
        <div className="h-full bg-gray-50/90 p-2 rounded-md overflow-y-auto relative">
            <div className="sticky top-0 right-0">
                {showCopy && (
                    <CopyButtonWithTooltip
                        data={data.result}
                        className="absolute right-0 z-50 bg-gray-100 shadow-md"
                        onMouseEnter={() => {
                            setShowCopy(true);
                        }}
                    />
                )}
            </div>
            <div
                ref={ref}
                {...props}
                className={cn("h-full whitespace-pre-wrap", className)}
                onMouseEnter={() => {
                    setShowCopy(true);
                }}
                onMouseLeave={() => {
                    setShowCopy(false);
                }}
            >
                {data.result}
            </div>
        </div>
    );
});
Summarize.displayName = Accordion.displayName;

export default Summarize;
