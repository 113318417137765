import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { GetMediaDownloadPresignedUrlResponse } from "types/backend/media";

const EXPIRE = 60 * 60 * 24 * 7 * 1000;

export default function useGetMediaSourceQuery(key: string | null) {
    const query = useQuery({
        queryKey: [Q.GET_MEDIA_SOURCE_BY_KEY, key],
        queryFn: async () => {
            const {
                data: { presignedURL: downloadUrl },
            } = await api.get<GetMediaDownloadPresignedUrlResponse>(
                `/media/r2/download-url/${encodeURIComponent(key || "")}`,
            );
            return downloadUrl;
        },
        enabled: !!key,
        staleTime: EXPIRE,
        gcTime: EXPIRE,
    });

    return query;
}
