import { FC } from "react";
import type { Meeting } from "types/backend/meetings";
import { Link } from "react-router-dom";
import { humanizeDate } from "@libs/duration";
import TenantUserAvatar from "@component-func/TenantUserAvatar";
import CustomerTagBadge from "@component-func/customer-tag/CustomerTagBadge";
import { cn } from "@libs/utils";

export type MeetingProps = Meeting & {
    currentMeetingId: string;
};

const MeetingRow: FC<MeetingProps> = ({
    id,
    label,
    createdAt,
    tenantUser,
    media,
    customerTags,
    currentMeetingId,
}) => {
    return (
        <Link
            to={
                media?.length === 1
                    ? `/meeting-transcripts/${id}/${media[0].id}/transcript?key=${encodeURIComponent(media[0].key)}&type=${media[0].mimeType}`
                    : `/meeting-transcripts/${id}`
            }
        >
            <div
                className={cn(
                    currentMeetingId === id && "bg-gray-100/90",
                    "grid grid-flow-row w-full items-center rounded-md mb-2 p-2 hover:bg-gray-100/90 overflow-hidden shadow-sm",
                )}
            >
                <h1 className="text-primary text-base font-medium truncate">
                    {label}
                </h1>
                <div className="flex flex-wrap gap-1 my-1">
                    {customerTags &&
                        customerTags.map((customerTag) => (
                            <CustomerTagBadge
                                customerTag={customerTag}
                                key={customerTag.id}
                            />
                        ))}
                </div>
                <div className="grid grid-cols-[1fr,max-content] text-xs items-center">
                    <div className="flex flex-row justify-start items-center truncate">
                        <div className="truncate">Created by </div>
                        <TenantUserAvatar
                            tenantUser={tenantUser}
                            className="h-6 w-6"
                        />
                    </div>
                    <div className="justify-self-end">
                        <span className="truncate w-full">
                            {humanizeDate(createdAt)}
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default MeetingRow;
