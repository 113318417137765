import { Button } from "@component-ui/utility/Button";
import Empty from "@component-ui/Empty";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { useMeetingsQuery } from "@hooks/meeting/useMeetingsQuery";
import MeetingRow from "./MeetingRow";
import { MessageSquareMore } from "lucide-react";
import { useState } from "react";
import CustomerTagComboBox from "@component-func/customer-tag/CustomerTagComboBox";
import { useParams, useSearchParams } from "react-router-dom";
import { SEARCH_PARAMS } from "@constant/searchParams";
import CreateMeetingDialog from "./CreateMeetingDialog";

export default function MeetingList() {
    const [search] = useSearchParams();
    const { meetingId } = useParams();
    const [selectedTagId, setSelectedTagId] = useState(
        search.get(SEARCH_PARAMS.CURRENT_TAG) || "",
    );
    const {
        data: meetings,
        isFetching,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useMeetingsQuery(selectedTagId);

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return (
            <div className="h-full grid place-items-center">
                <Button onClick={() => refetch()}>Retry</Button>
            </div>
        );
    }

    return (
        <aside className="h-full w-full grid grid-rows-[max-content,max-content,1fr,max-content] gap-2 p-2">
            <span className="inline-flex items-center gap-2">
                <MessageSquareMore className="min-w-[40px] w-10 h-10" />
                <h1 className="text-2xl font-medium leading-none">Meetings</h1>
            </span>
            <CustomerTagComboBox
                selectedTagId={selectedTagId}
                setSelectedTagId={setSelectedTagId}
            />
            {isFetching ? (
                <LoadingSpinnerCenter />
            ) : (
                <ul className="h-full overflow-auto">
                    {meetings.length <= 0 ? (
                        <Empty label="Do not have meeting yet, create a new meeting to start" />
                    ) : (
                        meetings.map((meeting) => (
                            <li key={meeting.id}>
                                <MeetingRow
                                    {...meeting}
                                    currentMeetingId={meetingId || ""}
                                />
                            </li>
                        ))
                    )}
                </ul>
            )}
            <CreateMeetingDialog />
        </aside>
    );
}
