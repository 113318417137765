import NavigatableTableRow from "@component-generic/NavigatableSidebarRow.tsx";
import Empty from "@component-ui/Empty";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import useShareMeetingQuery from "@hooks/share-to-me/useShareMeetingQuery";
import { FC, useState } from "react";

const ShareToMeList: FC = () => {
    const {
        data: sharedMeetings,
        isLoading,
        isSuccess,
        isError,
        isFetching,
        refetch,
    } = useShareMeetingQuery();

    const [selected, setSelected] = useState("");

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return (
            <div className="h-full grid place-items-center">
                <Button onClick={() => refetch()}>Retry</Button>
            </div>
        );
    }

    return (
        <aside className="h-full w-full grid grid-rows-[max-content,1fr] gap-2 p-2">
            <span className="inline-flex items-center gap-2">
                <h1 className="text-2xl font-medium leading-none">
                    Shared Meeting
                </h1>
            </span>
            {isFetching ? (
                <LoadingSpinnerCenter />
            ) : (
                <ul className="h-full overflow-auto">
                    {sharedMeetings.length <= 0 ? (
                        <Empty label="Do not have meeting share to you yet" />
                    ) : (
                        sharedMeetings.map((meeting) => (
                            <li
                                key={meeting.id}
                                onClick={() => setSelected(meeting.id)}
                            >
                                {meeting.media ? (
                                    <NavigatableTableRow
                                        to={`/meetings/share/${meeting.id}/${meeting.media[0].id}/transcript?key=${encodeURIComponent(meeting.media[0].key)}&type=${meeting.media[0].mimeType}`}
                                        label={meeting.label}
                                        tenantUser={meeting.tenantUser}
                                        createdAt={meeting.createdAt}
                                        selected={selected === meeting.id}
                                    />
                                ) : (
                                    <NavigatableTableRow
                                        to={`/meetings/share/${meeting.id}`}
                                        label={meeting.label}
                                        tenantUser={meeting.tenantUser}
                                        createdAt={meeting.createdAt}
                                        selected={selected === meeting.id}
                                    />
                                )}
                            </li>
                        ))
                    )}
                </ul>
            )}
        </aside>
    );
};

export default ShareToMeList;
