import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";
import {
    ResourceMappingRelation,
    ResourceMappingItemInfo,
    ResourceMappingItemType,
} from "types/backend/resources";

export default function useRemoveResourceMappingRelationMutate(
    itemType: ResourceMappingItemType,
) {
    const queryClient = useQueryClient();
    const { push } = usePopupNotification();
    const posthog = usePostHog();

    const mutation = useMutation({
        mutationKey: [M.REMOVE_RESOURCE_MAPPING_RELATION, itemType],
        mutationFn: async ({
            resourceMappingId,
            itemInfo,
        }: {
            resourceMappingId: string;
            itemInfo: ResourceMappingItemInfo;
        }) => {
            posthog?.capture(`${itemType}_resource_mapping_relation_removed`);
            const { data } = await api.delete<ResourceMappingRelation>(
                `/customer-crm-relation/${itemType}/${itemInfo.itemId}/${resourceMappingId}`,
            );
            return data;
        },
        onSuccess: async (_, variables) => {
            if (itemType === "document") {
                await queryClient.invalidateQueries({
                    queryKey: [Q.GET_DOCUMENT, variables.itemInfo.itemId],
                });
                await queryClient.invalidateQueries({
                    queryKey: [Q.GET_ALL_DOCUMENT],
                });
            }
            push({
                title: `Successfully disconnected CRM resource and ${itemType}`,
                description: "You have successfully disconnected CRM resource",
                type: "success",
            });
        },
        onError: (error) => {
            const errorMessage =
                error instanceof AxiosError
                    ? error.response?.data.message
                    : error.message;
            push({
                title: "Failed to disconnect CRM resource",
                description: `Error occurred due to ${errorMessage}`,
                type: "destructive",
            });
        },
    });
    return mutation;
}
