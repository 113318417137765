import { FC, useState } from "react";
import Summarize from "./Summarize";
import { useParams } from "react-router-dom";
import SummarizeButtonGroup from "./SummarizeButtonGroup";
import Empty from "@component-ui/Empty";

type PathParam = {
    meetingId: string;
    mediaId: string;
};

const SummarizeRoot: FC = () => {
    const { mediaId } = useParams<PathParam>();
    const [summarizingSystemMessageId, setSummarizingSystemMessage] =
        useState("");

    function handleButtonClick(summarizingSystemMessageId: string) {
        setSummarizingSystemMessage(summarizingSystemMessageId);
    }

    if (!mediaId) {
        return (
            <div>
                <Empty label="Cannot get media" />;
            </div>
        );
    }

    return (
        <>
            <SummarizeButtonGroup
                summarizingSystemMessageId={summarizingSystemMessageId}
                handleButtonClick={handleButtonClick}
            />
            <Summarize
                mediaId={mediaId}
                summarizingSystemMessageId={summarizingSystemMessageId}
            />
        </>
    );
};

export default SummarizeRoot;
