import FileUploader from "@component-func/FileUploader";
import TenantUserAvatar from "@component-func/TenantUserAvatar";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@component-ui/utility/Table";
import { SEARCH_PARAMS } from "@constant/searchParams";
import { useMeetingsQuery } from "@hooks/meeting/useMeetingsQuery";
import { humanizeDate } from "@libs/duration";
import { usePostHog } from "posthog-js/react";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Meeting } from "types/backend/meetings";
import UpdateMeetingDialog from "./UpdateMeetingDialog";
import ItemCustomerTagList from "@component-func/customer-tag/ItemCustomerTagList";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import { ChevronRight } from "lucide-react";
import SoftDeleteMeetingButton from "./SoftDeleteMeetingButton";

const MeetingTitle: FC<Meeting> = (meeting) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <TableCell
            className="min-w-72"
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <div className="grid grid-cols-[1fr,max-content] items-center min-w-72 w-full">
                {meeting.label}
                {isHover && (
                    <UpdateMeetingDialog
                        meeting={meeting}
                        onClose={() => setIsHover(false)}
                    />
                )}
            </div>
        </TableCell>
    );
};

const MeetingTable: FC = () => {
    const [search] = useSearchParams();
    const tagId = search.get(SEARCH_PARAMS.CURRENT_TAG);
    const {
        data: meetings,
        isFetching,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useMeetingsQuery(tagId || undefined);
    const navigate = useNavigate();
    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture("meeting_table_page_navigated");
        posthog?.capture("$pageview", {
            pageName: tagId ? "/meetings/with-tags" : "/meetings",
        });
    }, []);

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return (
            <div className="h-full grid place-items-center">
                <Button onClick={() => refetch()}>Retry</Button>
            </div>
        );
    }

    return (
        <div className="h-full p-12 overflow-y-auto">
            {isFetching ? (
                <LoadingSpinnerCenter />
            ) : (
                <>
                    <Table>
                        <TableCaption>List of Meetings</TableCaption>
                        <TableHeader>
                            <TableRow>
                                <TableHead className="flex justify-start items-center min-w-72 w-full">
                                    Name
                                </TableHead>
                                <TableHead className="w-60">Tags</TableHead>
                                <TableHead className="text-center w-24">
                                    Media
                                </TableHead>
                                <TableHead className="text-center w-[120px]">
                                    Created by
                                </TableHead>
                                <TableHead className="text-right w-[240px]">
                                    Created at
                                </TableHead>
                                <TableHead className="w-12 text-center">
                                    Upload Media
                                </TableHead>
                                <TableHead className="w-12" />
                                <TableHead className="w-12" />
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {meetings.map((meeting) => (
                                <TableRow
                                    key={meeting.id}
                                    className="cursor-pointer"
                                    onClick={() =>
                                        navigate(
                                            meeting.media?.length === 1
                                                ? `/meeting-transcripts/${meeting.id}/${meeting.media[0].id}/transcript?key=${encodeURIComponent(meeting.media[0].key)}&type=${meeting.media[0].mimeType}`
                                                : `/meeting-transcripts/${meeting.id}/`,
                                        )
                                    }
                                >
                                    <MeetingTitle {...meeting} />
                                    <TableCell className="w-60">
                                        <ItemCustomerTagList
                                            itemInfo={{
                                                itemType: "meeting",
                                                itemId: meeting.id,
                                            }}
                                            customerTags={meeting.customerTags}
                                        />
                                    </TableCell>
                                    <TableCell className="text-center">
                                        <Link
                                            to={`/meeting-transcripts/${meeting.id}`}
                                            className="flex justify-center items-center"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <TooltipProvider
                                                delayDuration={200}
                                            >
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <Button
                                                            size="icon"
                                                            variant="ghost"
                                                        >
                                                            {meeting.media
                                                                ?.length || 0}
                                                        </Button>
                                                    </TooltipTrigger>
                                                    <TooltipContent side="bottom">
                                                        List Media
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <div className="flex justify-center items-center">
                                            <TenantUserAvatar
                                                tenantUser={meeting.tenantUser}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell className="text-right">
                                        {humanizeDate(meeting.createdAt)}
                                    </TableCell>
                                    <TableCell className="text-center">
                                        <FileUploader meetingId={meeting.id} />
                                    </TableCell>
                                    <TableCell className="text-right">
                                        <SoftDeleteMeetingButton
                                            id={meeting.id}
                                        />
                                    </TableCell>
                                    <TableCell className="text-right">
                                        <ChevronRight />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        {meetings.length <= 0 && (
                            <TableFooter>
                                <TableRow>
                                    <TableCell
                                        colSpan={6}
                                        className="text-center text-gray-400 tracking-wide"
                                    >
                                        Do not have meeting yet, you can create
                                        a meeting and view it here
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        )}
                    </Table>
                </>
            )}
        </div>
    );
};

export default MeetingTable;
