import {
    Dialog,
    DialogTrigger,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@component-ui/utility/Dialog";
import { Button } from "@component-ui/utility/Button";
import { FC, FormEvent, useEffect, useState } from "react";
import { Loader2 } from "lucide-react";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";
import useCreateMeetingMutate from "@hooks/meeting/useCreateMeetingMutate";
import { FORM_KEY } from "@constant/form";
import { usePostHog } from "posthog-js/react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import { Switch } from "@component-ui/utility/Switch";
import { useDocumentTemplatesQuery } from "@hooks/template/useDocumentTemplatesQuery";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import Empty from "@component-ui/Empty";
import BasicSelect from "@component-func/BasicSelect";

type CreateMeetingButtonProps = {
    ButtonElement?: React.ReactElement;
};

const CreateMeetingDialogCapture: FC = () => {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("$pageview", {
            pageName: "/meetings/new",
        });
    }, []);

    return null;
};

const DocumentTemplateListSelect: FC = () => {
    const {
        data: documentTemplates,
        isLoading,
        isError,
        isSuccess,
    } = useDocumentTemplatesQuery();

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (isError || !isSuccess) {
        return <Empty label="Cannot get document templates" />;
    }

    const templateOptions = documentTemplates.map((v) => ({
        label: v.title,
        value: v.id,
    }));

    return (
        <BasicSelect
            name={FORM_KEY.MEETING.DOCUMENT_TEMPLATE}
            placeholder={
                templateOptions.length <= 0
                    ? "No document templates found"
                    : "Select a Document Template to create the document"
            }
            options={templateOptions}
            disabled={templateOptions.length <= 0}
        />
    );
};

const CreateMeetingDialog: FC<CreateMeetingButtonProps> = ({
    ButtonElement = <Button className="w-full">Create new meeting</Button>,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [withDocument, setWithDocument] = useState(true);
    const { mutate: createMeeting, isPending: isCreating } =
        useCreateMeetingMutate();

    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();
        const formData = new FormData(e.currentTarget);
        createMeeting(formData, {
            onSuccess: () => {
                setIsOpen(false);
            },
        });
    }

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild id="create-meeting-button">
                {ButtonElement}
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Create new meeting</DialogTitle>
                    <CreateMeetingDialogCapture />
                </DialogHeader>
                <TooltipProvider delayDuration={300}>
                    <form className="grid gap-4" onSubmit={handleSubmit}>
                        <Label htmlFor={FORM_KEY.MEETING.NAME}>
                            Meeting name
                        </Label>
                        <Input
                            id={FORM_KEY.MEETING.NAME}
                            name={FORM_KEY.MEETING.NAME}
                            type="text"
                        />
                        <div className="grid grid-cols-2 items-center">
                            <Tooltip>
                                <TooltipTrigger type="button" asChild>
                                    <Label
                                        htmlFor={FORM_KEY.MEETING.WITH_DOCUMENT}
                                    >
                                        Create connected document
                                    </Label>
                                </TooltipTrigger>
                                <TooltipContent side="left">
                                    Create a connected document for this meeting
                                </TooltipContent>
                            </Tooltip>
                            <Switch
                                id={FORM_KEY.MEETING.WITH_DOCUMENT}
                                name={FORM_KEY.MEETING.WITH_DOCUMENT}
                                onCheckedChange={setWithDocument}
                                checked={withDocument}
                            />
                        </div>
                        {withDocument && <DocumentTemplateListSelect />}

                        <Button
                            type="submit"
                            disabled={isCreating}
                            className="w-full"
                        >
                            {isCreating && (
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            )}
                            Create
                        </Button>
                    </form>
                </TooltipProvider>
            </DialogContent>
        </Dialog>
    );
};

export default CreateMeetingDialog;
