import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { GetAllResourcePermissionByResourceIdResponse } from "types/backend/resource_permission";

export function useResourcePermissionsQuery(
    meetingId: string,
    mediaId: string,
    documentId: string,
) {
    const query = useQuery({
        queryKey: [Q.GET_RESOURCE_PERMISSION, meetingId, mediaId, documentId],
        queryFn: async ({ queryKey }) => {
            const [, meetingId, mediaId, documentId] = queryKey;
            if (!documentId) {
                const { data } = await api.get<GetAllResourcePermissionByResourceIdResponse>("/resource-permissions", {
                    params: {
                        meetingId,
                        mediaId,
                    },
                });
                return data;
            } else {
                const { data } = await api.get<GetAllResourcePermissionByResourceIdResponse>("/resource-permissions", {
                    params: {
                        meetingId,
                        mediaId,
                        documentId,
                    },
                });
                return data;
            }
        },
        enabled: !!meetingId && !!mediaId,
    });
    return query;
}
