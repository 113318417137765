import {
    NavigationMenu,
    NavigationMenuList,
    NavigationMenuItem,
    NavigationMenuLink,
    navigationMenuTriggerStyle,
} from "@component-ui/utility/NavigationMenu";
import { FC } from "react";
import LogoutButton from "@component-func/auth/LogoutButtom";
import { Link } from "react-router-dom";
import useUserSelfInformation from "@hooks/user/useUserSelfInformationQuery";
import { cn } from "@libs/utils";
import { Tour } from "@frigade/react";
import { FRIGADE_FLOW_ID } from "@constant/frigade-flows";
import CurrentTeamSelect from "@component-func/auth/CurrentTeamSelect";
import RecordingRoot from "@component-func/recording/RecordingRoot";

const NavBar: FC = () => {
    const { data: tenantUser } = useUserSelfInformation();
    return (
        <NavigationMenu className="max-w-full shadow-sm justify-start">
            <Link to="/meetings" className="mx-4">
                <div className="w-24">
                    <img
                        className="w-24"
                        src="https://d1pnnwteuly8z3.cloudfront.net/images/30ba08ff-7870-4ce4-855e-cc07414e7915/b345644c-a350-44b9-bd80-4332b12161f5.png"
                        alt="Closer"
                    />
                </div>
            </Link>
            <NavigationMenuList>
                <NavigationMenuItem>
                    <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                        id="nav-meeting"
                        asChild
                    >
                        <Link to="/meetings">Meetings</Link>
                    </NavigationMenuLink>
                </NavigationMenuItem>
                <NavigationMenuItem>
                    <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                        asChild
                    >
                        <Link to="/documents">Documents</Link>
                    </NavigationMenuLink>
                </NavigationMenuItem>
                <NavigationMenuItem>
                    <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                        asChild
                    >
                        <Link to="/document-templates">Templates</Link>
                    </NavigationMenuLink>
                </NavigationMenuItem>
                <NavigationMenuItem>
                    <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                        asChild
                    >
                        <Link to="/settings">Settings</Link>
                    </NavigationMenuLink>
                </NavigationMenuItem>
                {(tenantUser?.oauth.google || tenantUser?.oauth.zoom) && (
                    <NavigationMenuItem>
                        <NavigationMenuLink
                            className={navigationMenuTriggerStyle()}
                            asChild
                        >
                            <Link to="/recording-download">
                                Recording Integration
                            </Link>
                        </NavigationMenuLink>
                    </NavigationMenuItem>
                )}
                <NavigationMenuItem>
                    <Tour flowId={FRIGADE_FLOW_ID.HELP_BUTTON} />
                    <NavigationMenuLink
                        className={cn(
                            navigationMenuTriggerStyle(),
                            "cursor-pointer",
                        )}
                        id="help-button"
                        onClick={() => {
                            window.$chatwoot.toggle();
                        }}
                    >
                        Help
                    </NavigationMenuLink>
                </NavigationMenuItem>
            </NavigationMenuList>
            <div className="mx-auto max-w-sm w-full">
                <RecordingRoot />
            </div>
            <CurrentTeamSelect />
            <LogoutButton className="m-1" />
        </NavigationMenu>
    );
};

export default NavBar;
