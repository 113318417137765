import { FORM_KEY } from "@constant/form";
import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { usePostHog } from "posthog-js/react";
import { CreateMeetingBody, Meeting } from "types/backend/meetings";

function validateForm(formData: FormData) {
    const label = formData.get(FORM_KEY.MEETING.NAME) as string;
    const withDocument = formData.get(FORM_KEY.MEETING.WITH_DOCUMENT) as
        | string
        | null;
    const documentTemplateId = formData.get(
        FORM_KEY.MEETING.DOCUMENT_TEMPLATE,
    ) as string;
    if (!label) throw new Error("Please input meeting name");
    return {
        label,
        withDocument: withDocument ? true : false,
        documentTemplateId: documentTemplateId || undefined,
    };
}

export default function useCreateMeetingMutate() {
    const { push } = usePopupNotification();
    const queryClient = useQueryClient();
    const posthog = usePostHog();

    const mutate = useMutation({
        mutationKey: [M.CREATE_MEETING],
        mutationFn: async (formData: FormData) => {
            const body = validateForm(formData);
            posthog?.capture("meeting_created");
            const { data } = await api.post<
                Meeting,
                AxiosResponse<Meeting>,
                CreateMeetingBody
            >("/meetings", body);
            return data;
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [Q.GET_MEETINGS] });
            push({
                title: "Create meeting successfully",
                description:
                    "Meeting is created, you can find it in the meetings table.",
                type: "success",
            });
        },
        onError: (error) => {
            const errorMessage =
                error instanceof AxiosError
                    ? error.response?.data.message
                    : error.message;
            push({
                title: "Cannot create meeting",
                description: errorMessage,
                type: "destructive",
            });
        },
    });

    return mutate;
}
