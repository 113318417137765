import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function useDeleteCachedRecording() {
    const queryClient = useQueryClient();
    const { push } = usePopupNotification();

    const mutate = useMutation({
        mutationKey: [M.DELETE_CACHED_RECORDING],
        mutationFn: async () => {
            await api.delete("/media/record/cache");
        },
        onSuccess: () => {
            push({
                title: "Successfully delete cached recording",
                description: "",
                type: "success",
            });
        },
        onError: () => {
            push({
                title: "Failed to delete cached recording, please try again",
                description: "Unexpected error during delete recording",
                type: "destructive",
            });
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: [Q.RECORDING_CACHE_CHECK],
            });
        },
    });

    return mutate;
}
