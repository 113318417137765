import useUserSelfInformation from "@hooks/user/useUserSelfInformationQuery";
import React from "react";
import { Navigate } from "react-router-dom";
import { TenantUserSelfInformation } from "types/backend/tenant-user";

interface ProtectedRouteProps {
    redirectPath: string;
    children: JSX.Element;
    validation: (tenantUser: TenantUserSelfInformation) => boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    redirectPath = "/meetings",
    children,
    validation,
}) => {
    const { data: tenantUser } = useUserSelfInformation();

    if (tenantUser) {
        const pass = validation(tenantUser);

        if (!pass) {
            return <Navigate to={redirectPath} replace />;
        }
    }

    return children;
};
