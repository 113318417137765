import { Button } from "@component-ui/utility/Button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@component-ui/utility/Dialog";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";
import { FORM_KEY } from "@constant/form";
import { SEARCH_PARAMS } from "@constant/searchParams";
import useCreateDocumentMutate from "@hooks/document/useCreateDocumentMutate";
import { Loader2 } from "lucide-react";
import {
    Dispatch,
    FC,
    FormEvent,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import BasicSelect from "../BasicSelect";
import { useDocumentTemplatesQuery } from "@hooks/template/useDocumentTemplatesQuery";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { usePostHog } from "posthog-js/react";
import Empty from "@component-ui/Empty";
import MeetingComboBox from "@component-func/meetings/MeetingComboBox";

type DocumentDialogProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const CreateDocumentDialogCapture: FC = () => {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("$pageview", { pageName: "/documents/new" });
    }, []);

    return null;
};

const DocumentTemplateListSelect: FC = () => {
    const {
        data: documentTemplates,
        isLoading,
        isError,
        isSuccess,
    } = useDocumentTemplatesQuery();

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (isError || !isSuccess) {
        return <Empty label="Cannot get document templates" />;
    }

    const templateOptions = documentTemplates.map((v) => ({
        label: v.title,
        value: v.id,
    }));

    return (
        <BasicSelect
            name={FORM_KEY.DOCUMENT.TEMPLATE}
            triggerClassName="mt-3"
            placeholder={
                templateOptions.length <= 0
                    ? "No templates found"
                    : "Select a Template"
            }
            options={templateOptions}
            disabled={templateOptions.length <= 0}
        />
    );
};

const CreateDocumentDialog: FC<DocumentDialogProps> = ({ open, setOpen }) => {
    const { mutate: createDocument, isPending: isCreating } =
        useCreateDocumentMutate();
    const [, setSearch] = useSearchParams();
    const [selectedMeetingId, setSelectedMeetingId] = useState("");

    const handleSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            if (selectedMeetingId) {
                formData.set(FORM_KEY.DOCUMENT.MEETING, selectedMeetingId);
            }
            createDocument(formData, {
                onSuccess: (data) => {
                    setOpen(false);
                    setSelectedMeetingId("");
                    setSearch(
                        (prev) => {
                            prev.set(SEARCH_PARAMS.CURRENT_DOCUMENT, data.id);
                            return prev;
                        },
                        { replace: true },
                    );
                },
            });
        },
        [selectedMeetingId],
    );

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Create new document</DialogTitle>
                    <CreateDocumentDialogCapture />
                </DialogHeader>
                <form id={FORM_KEY.DOCUMENT.FORM_ID} onSubmit={handleSubmit}>
                    <Label htmlFor={FORM_KEY.DOCUMENT.TITLE}>
                        Document Title
                    </Label>
                    <Input
                        id={FORM_KEY.DOCUMENT.TITLE}
                        name={FORM_KEY.DOCUMENT.TITLE}
                        type="text"
                    />
                    <DocumentTemplateListSelect />
                    <MeetingComboBox
                        selectedMeetingId={selectedMeetingId}
                        setSelectedMetingId={setSelectedMeetingId}
                    />
                </form>
                <Button
                    form={FORM_KEY.DOCUMENT.FORM_ID}
                    type="submit"
                    disabled={isCreating}
                >
                    {isCreating && (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Create Document
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default CreateDocumentDialog;
