export const Q = {
    GET_MEETINGS: "get-meetings",
    GET_MEETING_MEDIA: "get-meeting-media",
    GET_MEDIA: "get-media",
    GET_MEDIA_SOURCE_BY_KEY: "get-media-source-by-key",
    GET_TRANSCRIPT: "get-transcript",
    GET_ALL_DOCUMENT: "get-all-document",
    GET_DOCUMENT: "get-document",
    GET_ALL_DOCUMENT_TEMPLATE: "get-all-document-template",
    GET_DOCUMENT_TEMPLATE: "get-document-template",
    GET_USER_SELF_INFORMATION: "get-user-self-information",
    GET_CUSTOMER_TAG: "get-customer-tag",
    GET_CLOSER_MEDIA_SPEAKER_MAP: "get-closer-media-speaker-map",
    GET_TEAM_PERMISSION: "get-team-permission",
    GET_SUMMARIZE: "get-summarize",
    GET_SUMMARIZES: "get-summarizes",
    GET_SUMMARIZE_SYSTEM_MESSAGES: "get-summarize-system-messages",
    GET_CRM_RESOURCE_OPTIONS: "get-crm-resource-options",
    GET_CRM_ASSOCIATED_RESOURCE_OPTIONS: "get-crm-associated-resource-options",
    GET_HUBSPOT_DETAIL: "get-hubspot-detail",
    GET_SALESFORCE_INSTANCE_URL: "get-salesforce-instance-url",
    GET_DOCUMENT_RECORD: "get-document-record",
    GET_DOCUMENT_TEMPLATE_RECORD: "get-document-template-record",
    GET_ZOOM_MEETING_RECORDINGS: "get-zoom-meeting-recordings",
    GET_ZOOM_MEETINGS: "get-zoom-meetings",
    GET_ZOOM_DOWNLOAD_TASKS: "get-zoom-download-tasks",
    GET_ZOOM_MEETING_PARTICIPANTS: "get-zoom-meeting-participants",
    GET_RESOURCE_PERMISSION: "get-resource-permission",
    GUEST_GET_MEETING_BY_ID: "guest-get-meetings-by-id",
    GUEST_GET_MEDIA_BY_ID: "guest-get-media-by-id",
    GUEST_GET_DOCUMENT_BY_ID: "guest-get-document-by-id",
    GUEST_GET_DOCUMENT_RAW_DATA_BY_ID: "guest-get-document-raw-data-by-id",
    GET_ALL_SHARE_MEETING: "get-all-share-meeting",
    GET_SHARED_MEDIA_BY_MEETING: "get-shared-media-by-meeting",
    GET_SHARED_MEDIA_TRANSCRIPT_BY_ID: "get-share-media-transcript-by-id",
    GET_SHARED_MEDIA_SOURCE_BY_KEY: "get-share-media-source_by_key",
    GET_SHARED_DOCUMENT_BY_ID: "get-share-document-by-id",
    GET_SHARED_DOCUMENT_RAW_DATA_BY_ID: "get-share-document-raw-data-by-id",
    GET_SHARED_ALL_DOCUMENT: "get-all-shared-document",
    GET_GOOGLE_MEETS: "get-google-meets",
    GET_GOOGLE_MEETING_PARTICIPANTS: "get-google-meeting-participants",
    GET_THREAD: "get-thread",
    GET_GOOGLE_CALENDARS: "get-google-calendars",
    GET_GOOGLE_CALENDAR_EVENTS: "get-google-calendar-events",
    GET_WATCHING_GOOGLE_CALENDARS: "get-watching-google-calendars",
    RECORDING_CACHE_CHECK: "recording-cache-check",
    GET_ACCESSIBLE_TEAM: "get-accessible-team",
} as const;

export type CloserQueryKey = (typeof Q)[keyof typeof Q]
