import { SEARCH_PARAMS } from "@constant/searchParams";
import { create } from "zustand";

type CurrentDocumentIdState = {
    currentDocumentId: string;
    update: (documentId: string) => void;
};

export const useCurrentDocumentStore = create<CurrentDocumentIdState>(
    (set) => ({
        currentDocumentId:
            new URLSearchParams(window.location.href).get(
                SEARCH_PARAMS.CURRENT_DOCUMENT,
            ) || "",
        update: (documentId: string) => set({ currentDocumentId: documentId }),
    }),
);
