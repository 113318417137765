import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";
import { TenantUserSelfInformation } from "types/backend/tenant-user";

export default function useSignUp() {
    const { push } = usePopupNotification();
    const posthog = usePostHog();

    const mutation = useMutation<
        TenantUserSelfInformation,
        Error,
        {
            email: string;
            password: string;
            firstName: string;
            lastName: string;
            teamName: string;
        }
    >({
        mutationFn: async ({
            email,
            password,
            firstName,
            lastName,
            teamName,
        }) => {
            const { data } = await api.post<TenantUserSelfInformation>(
                "/register/customer",
                {
                    email,
                    password,
                    firstName,
                    lastName,
                    teamName,
                },
            );
            return data;
        },
        onSuccess: (tenantUser) => {
            posthog?.identify(tenantUser.id);
            posthog?.capture("user_registered");
        },
        onError: (error) => {
            const errorMessage =
                error instanceof AxiosError
                    ? error.response?.data.message
                    : error.message;
            push({
                title: `Signup unsuccessful`,
                description: `${errorMessage}`,
                type: "destructive",
            });
        },
    });

    return mutation;
}
