export const M = {
    LOGIN: "login",
    UPLOAD_FILE: "upload-file",
    CREATE_DOCUMENT: "create-document",
    DELETE_DOCUMENT: "delete-document",
    UPDATE_DOCUMENT: "update-document",
    CREATE_DOCUMENT_SECTION: "create-document-section",
    CREATE_DOCUMENT_SECTION_ITEM: "create-document-section-item",
    CREATE_DOCUMENT_TEMPLATE: "create-document-template",
    UPDATE_DOCUMENT_TEMPLATE: "update-document-template",
    CREATE_MEETING: "create-meeting",
    DELETE_MEETING: "delete-meeting",
    CREATE_MEDIA: "create-media",
    DELETE_MEDIA: "delete-media",
    UPDATE_MEDIA: "update-media",
    UPDATE_MEETING: "update-meeting",
    UPSERT_CUSTOMER_TAG: "create-or-update-customer-tag",
    REMOVE_CUSTOMER_TAG: "remove-customer-tag",
    TAG_ITEM: "tag-item",
    UNTAG_ITEM: "untag-item",
    RENAMING_MEDIA_TRANSCRIPT_SPEAKER_NAME:
        "renaming-media-transcript-speaker-name",
    GENERATE_TRANSCRIPT_SUMMARIZE: "generate-transcript-summarize",
    CREATE_RESOURCE_MAPPING_RELATION: "create-resource-mapping-relation",
    REMOVE_RESOURCE_MAPPING_RELATION: "remove-resource-mapping-relation",
    CREATE_SHARE_PERMISSION: "create-share-permission",
    DELETE_SHARE_PERMISSION: "delete-share-permission",
    SHARE_PERMISSION_SEND_EMAIL: "share-permission-send-email",
    GUEST_VALIDATION: "guest-validation",
    CREATE_THREAD: "create-thread",
    CREATE_COMMENT: "create-comment",
    CREATE_SUMMARIZING_SYSTEM_MESSAGE: "create-summarizing-system-messages",
    UPDATE_SUMMARIZING_SYSTEM_MESSAGE: "update-summarizing-system-messages",
    WATCH_GOOGLE_CALENDAR: "watch-google-calendar",
    DELETE_CACHED_RECORDING: "delete-cached-recording",
    TRIGGER_CONCAT: "trigger-concat",
    HANDLE_CACHED_RECORDING: "handle-cached-recording",
} as const;
