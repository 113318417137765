import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { useGetZoomMeetingRecordsQuery } from "@hooks/zoom/useGetZoomMeetingRecordsQuery";
import { useLocation, useParams } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@component-ui/utility/Table";
import { useGetZoomDownloadTasksQuery } from "@hooks/zoom/useGetZoomDownloadTasksQuery";
import { ZoomMeetingRecordingRow } from "./ZoomMeetingRecordingRow";
import { Button } from "@component-ui/utility/Button";
import { Loader2, RefreshCcw } from "lucide-react";
import { useGetMeetingParticipantsQuery } from "@hooks/zoom/useGetMeetingParticipantsQuery";
import { ParticipantList } from "@component-func/participant/ParticipantList";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

const ZoomMeetingTable: React.FC = () => {
    const posthog = usePostHog();

    const { zoomMeetingId } = useParams();

    const { state } = useLocation();

    const encodedZoomMeetingId = encodeURIComponent(
        encodeURIComponent(zoomMeetingId || ""),
    );

    const { isLoading, data: zoomMeetingRecord } =
        useGetZoomMeetingRecordsQuery(encodedZoomMeetingId);

    const { isLoading: participantIsLoading, data: participant } =
        useGetMeetingParticipantsQuery(encodedZoomMeetingId);

    const {
        isLoading: downloadTasksIsLoading,
        data: downloadTasks,
        refetch,
        isRefetching,
    } = useGetZoomDownloadTasksQuery(zoomMeetingRecord);

    useEffect(() => {
        posthog?.capture("zoom_recording_table_page_navigated");
        posthog?.capture("$pageview", { pageName: "/zoom/recordings" });
    }, [zoomMeetingId]);

    if (!zoomMeetingId) {
        return (
            <div className="h-full grid place-items-center">
                <h1 className="text-3xl text-gray-400 tracking-wide font-light">
                    Select a zoom meeting to view the recordings
                </h1>
            </div>
        );
    }

    if (isLoading || downloadTasksIsLoading || participantIsLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!zoomMeetingRecord) {
        return;
    }

    const { topic, recording_files, timezone, download_access_token } =
        zoomMeetingRecord;
    return (
        <div className="h-full p-12 overflow-y-auto">
            <div className="flex justify-between">
                <div className="flex flex-col">
                    <span className="text-3xl font-bold py-3">
                        {topic ?? state?.topic}
                    </span>
                    <div className="grid grid-cols-[max-content,1fr] py-3 gap-2">
                        <div className="pt-0.5">Participants: </div>
                        <ParticipantList
                            type="zoom"
                            participants={participant?.participants ?? []}
                        />
                    </div>
                </div>
                <div className="flex justify-end flex-col">
                    <Button
                        onClick={() => refetch()}
                        variant="ghost"
                        className="gap-2"
                    >
                        {isRefetching ? (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        ) : (
                            <RefreshCcw />
                        )}
                        Refresh
                    </Button>
                </div>
            </div>
            <Table>
                <TableCaption>List of Zoom Recordings</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead className="text-center">
                            Start Time
                        </TableHead>
                        <TableHead className="text-center">Duration</TableHead>
                        <TableHead className="text-center">File Type</TableHead>
                        <TableHead className="text-center">File Size</TableHead>
                        <TableHead className="text-center">
                            Download Status
                        </TableHead>
                        <TableHead className="w-1/5 text-center wit" />
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {recording_files.map((recording_file) => (
                        <ZoomMeetingRecordingRow
                            key={recording_file.id}
                            recordingFile={recording_file}
                            downloadAccessToken={download_access_token}
                            timezone={timezone}
                            zoomDownloadTask={
                                downloadTasks
                                    ? downloadTasks[recording_file.id]
                                    : undefined
                            }
                        />
                    ))}
                </TableBody>
                {recording_files.length <= 0 && (
                    <TableFooter>
                        <TableRow>
                            <TableCell
                                colSpan={6}
                                className="text-center text-gray-400 tracking-wide"
                            >
                                Do not have zoom recordings
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </div>
    );
};

export default ZoomMeetingTable;
