import { cn } from "@libs/utils";
import { FC, HTMLAttributes } from "react";
import {
    ResourceMappingItemInfo,
    ResourceMappingRelation,
} from "types/backend/resources";
import ResourceMappingBadge from "./ResourceMappingBadge";
import ResourceOptionSelectDialog from "./ResourceOptionSelectDialog";

type ItemResourceMappingListProps = {
    resourceMappingRelations: Array<ResourceMappingRelation>;
    itemInfo?: ResourceMappingItemInfo;
    className?: HTMLAttributes<"div">["className"];
};

const ItemResourceMappingList: FC<ItemResourceMappingListProps> = ({
    resourceMappingRelations,
    itemInfo,
    className = "",
}) => {
    return (
        <div className={cn("flex items-center gap-1 flex-wrap", className)}>
            {resourceMappingRelations.map((resourceMapping) => (
                <ResourceMappingBadge
                    {...resourceMapping}
                    itemInfo={itemInfo}
                    key={
                        resourceMapping.resourceMapping.resourceType +
                        "-" +
                        resourceMapping.resourceMapping.resourceId
                    }
                />
            ))}
            {itemInfo && <ResourceOptionSelectDialog itemInfo={itemInfo} />}
        </div>
    );
};

export default ItemResourceMappingList;
