import * as React from "react";

import { cn } from "@libs/utils";
import { VariantProps, cva } from "class-variance-authority";

const inputVariant = cva(
    "flex h-10 w-full bg-background px-3 py-2 my-1 text-sm disabled:cursor-not-allowed disabled:opacity-50 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none",
    {
        variants: {
            variant: {
                default:
                    "rounded-md border border-input ring-offset-background file:border-0 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                ghost: "",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    },
);

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement>,
        VariantProps<typeof inputVariant> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, variant, type, ...props }, ref) => {
        return (
            <input
                type={type}
                className={cn(inputVariant({ variant, className }))}
                ref={ref}
                {...props}
            />
        );
    },
);
Input.displayName = "Input";

export { Input };
