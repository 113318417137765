import { Q } from "@constant/query-key";
import { useAuth } from "@context/auth";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { Team } from "types/backend";

export default function useAccessibleTeamsQuery() {
    const { isAuth } = useAuth();
    const query = useQuery({
        queryKey: [Q.GET_ACCESSIBLE_TEAM],
        queryFn: async () => {
            const { data } = await api.get<Array<Team>>("teams/accessible");
            return data;
        },
        staleTime: 5 * 60 * 1000,
        gcTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled: isAuth,
    });
    return query;
}
