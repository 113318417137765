import TenantUserAvatar from "@component-func/TenantUserAvatar";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import {
    TableCaption,
    TableHeader,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    TableFooter,
    Table,
} from "@component-ui/utility/Table";
import { SEARCH_PARAMS } from "@constant/searchParams";
import useAllDocumentQuery from "@hooks/document/useAllDocumentQuery";
import { humanizeDate } from "@libs/duration";
import { usePostHog } from "posthog-js/react";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Document } from "types/backend/document";
import UpdateDocumentDialog from "./UpdateDocumentDialog";
import ItemCustomerTagList from "@component-func/customer-tag/ItemCustomerTagList";
import { HubspotSvg } from "@component-func/settings/HubSpotSvg";
import { SalesforceSvg } from "@component-func/settings/SalesforceSvg";
import { ChevronRight, MessageSquareMore } from "lucide-react";
import SoftDeleteDocumentButton from "./SoftDeleteDocumentButton";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";

const DocumentTitle: FC<Document> = (document) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <TableCell
            className="min-w-72"
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <div className="grid grid-cols-[1fr,max-content] items-center min-w-72 w-full">
                {document.title}
                {isHover && (
                    <UpdateDocumentDialog
                        document={document}
                        onClose={() => setIsHover(false)}
                    />
                )}
            </div>
        </TableCell>
    );
};

const DocumentTable: FC = () => {
    const [search] = useSearchParams();
    const tagId = search.get(SEARCH_PARAMS.CURRENT_TAG);
    const {
        data: documents,
        isFetching,
        isLoading,
        isSuccess,
        isError,
        refetch,
    } = useAllDocumentQuery("", tagId || "");
    const navigate = useNavigate();
    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture("document_table_page_navigated");
        posthog?.capture("$pageview", {
            pageName: tagId ? "/documents/with-tags" : "/documents",
        });
    }, [tagId]);

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return (
            <div className="h-full grid place-items-center">
                <Button onClick={() => refetch()}>Retry</Button>
            </div>
        );
    }

    return (
        <div className="h-full p-12 overflow-y-auto">
            {isFetching ? (
                <LoadingSpinnerCenter />
            ) : (
                <Table>
                    <TableCaption>List of Documents</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="flex justify-start items-center min-w-72 w-full">
                                Name
                            </TableHead>
                            <TableHead />
                            <TableHead className="w-60">Tags</TableHead>
                            <TableHead className="text-center w-24">
                                Meeting
                            </TableHead>
                            <TableHead className="text-center w-32">
                                Created by
                            </TableHead>
                            <TableHead className="text-right w-60">
                                Created at
                            </TableHead>
                            <TableHead className="w-12" />
                            <TableHead className="w-12" />
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {documents.map((document) => (
                            <TableRow
                                key={document.id}
                                className="cursor-pointer"
                                onClick={() =>
                                    navigate(`/documents/${document.id}`)
                                }
                            >
                                <DocumentTitle {...document} />
                                <TableCell>
                                    <div className="flex gap-2 items-center justify-center opacity-70">
                                        {!!document.documentResourceMappingRelations.find(
                                            (relation) =>
                                                relation.resourceMapping
                                                    .resourcePlatform ===
                                                "hubspot",
                                        ) && (
                                            <div className="bg-[#ff7a59] p-1 rounded-md w-fit h-fit">
                                                <HubspotSvg size="sm" />
                                            </div>
                                        )}
                                        {!!document.documentResourceMappingRelations.find(
                                            (relation) =>
                                                relation.resourceMapping
                                                    .resourcePlatform ===
                                                "salesforce",
                                        ) && (
                                            <div className="bg-[#00A1E0] p-1 rounded-md">
                                                <SalesforceSvg size="sm" />
                                            </div>
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell className="w-60">
                                    <ItemCustomerTagList
                                        itemInfo={{
                                            itemType: "document",
                                            itemId: document.id,
                                        }}
                                        customerTags={document.customerTags}
                                    />
                                </TableCell>
                                <TableCell>
                                    {document.meetingDocumentRelations &&
                                        document.meetingDocumentRelations
                                            .length > 0 && (
                                            <Link
                                                to={`/meeting-transcripts/${document.meetingDocumentRelations[0].meetingId}`}
                                                className="flex justify-center items-center"
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                            >
                                                <TooltipProvider
                                                    delayDuration={200}
                                                >
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            <Button
                                                                size={"icon"}
                                                                variant={
                                                                    "ghost"
                                                                }
                                                            >
                                                                <MessageSquareMore />
                                                            </Button>
                                                        </TooltipTrigger>
                                                        <TooltipContent side="bottom">
                                                            View Connected
                                                            Meeting
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </Link>
                                        )}
                                </TableCell>
                                <TableCell>
                                    <div className="flex justify-center items-center">
                                        <TenantUserAvatar
                                            tenantUser={
                                                document.createdByTenantUser
                                            }
                                        />
                                    </div>
                                </TableCell>
                                <TableCell className="text-right">
                                    {humanizeDate(document.createdAt)}
                                </TableCell>
                                <TableCell className="text-right">
                                    <ChevronRight />
                                </TableCell>
                                <TableCell className="text-right">
                                    <SoftDeleteDocumentButton
                                        id={document.id}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    {documents.length <= 0 && (
                        <TableFooter>
                            <TableRow>
                                <TableCell
                                    colSpan={6}
                                    className="text-center text-gray-400 tracking-wide"
                                >
                                    Do not have document yet, you can create a
                                    document and view it here
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
            )}
        </div>
    );
};

export default DocumentTable;
