import { Button, IconButtonWithTooltip } from "@component-ui/utility/Button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@component-ui/utility/Popover";
import { Mark } from "@tiptap/pm/model";
import { MessageSquareText, Trash } from "lucide-react";
import { FC, useEffect } from "react";
import { SharedThread, Thread } from "./Thread";
import { UserType } from "types/backend";
import { Editor } from "@tiptap/core";
import { usePostHog } from "posthog-js/react";

export type ThreadProps = {
    marks: Mark[];
    userType: UserType;
    container: HTMLDivElement;
    editor: Editor;
};

const ThreadsCapture = () => {
    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture("$pageview", {
            pageName: "/threads",
        });
    }, []);

    return null;
};

const Threads: FC<ThreadProps> = ({ marks, userType, container, editor }) => {
    if (marks.length <= 0) return null;

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant="ghost"
                    size="xsIcon"
                    className="min-w-fit"
                    onClick={() => {
                        const threadId = marks[0].attrs["threadId"];
                        if (!threadId) return;
                        const node = container.querySelector(
                            `span[threadid='${threadId}'`,
                        );
                        if (!node) return;
                        node.scrollIntoView({ behavior: "smooth", block: "center" });
                    }}
                >
                    <MessageSquareText className="w-4 h-4" />
                    <span>{marks.length}</span>
                </Button>
            </PopoverTrigger>
            <PopoverContent
                className="w-96 max-h-[50vh] overflow-y-auto"
                align="end"
                onOpenAutoFocus={(e) => e.preventDefault()}
            >
                <ThreadsCapture />
                <ul className="space-y-2">
                    {marks.map((mark) => (
                        <li
                            key={mark.attrs["threadId"]}
                            className="grid grid-cols-[1fr,max-content]"
                        >
                            <div className="min-w-0">
                                {userType === "tenant-user" ? (
                                    <Thread mark={mark} />
                                ) : (
                                    <SharedThread
                                        mark={mark}
                                        userType={userType}
                                    />
                                )}
                            </div>
                            <IconButtonWithTooltip
                                icon={<Trash className="w-4 h-4" />}
                                content="delete thread"
                                variant="ghost"
                                size="icon"
                                onClick={() => {
                                    editor.commands.unsetThread(
                                        mark.attrs["threadId"],
                                    );
                                }}
                            />
                        </li>
                    ))}
                </ul>
            </PopoverContent>
        </Popover>
    );
};

export default Threads;
