import Empty from "@component-ui/Empty";
import { Button } from "@component-ui/utility/Button";
import { Input } from "@component-ui/utility/Input";
import { FORM_KEY } from "@constant/form";
import { LOCALSTORAGE } from "@constant/localstorage-key";
import { SEARCH_PARAMS } from "@constant/searchParams";
import { usePopupNotification } from "@context/popup-notification";
import useGuestValidation from "@hooks/shareable/useGuestValidation";
import { Label } from "@radix-ui/react-label";
import { Loader2 } from "lucide-react";
import { FC, FormEvent, useMemo } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

const PreviewValidate: FC = () => {
    const [search] = useSearchParams();
    const { push } = usePopupNotification();

    const { mutateAsync: validate, isPending } = useGuestValidation();

    const redirectUrl = useMemo(() => {
        const meetingId = search.get(SEARCH_PARAMS.MEETING_ID);
        const mediaId = search.get(SEARCH_PARAMS.MEDIA_ID);
        const documentId = search.get(SEARCH_PARAMS.DOCUMENT_ID);
        if (documentId) {
            return `/guest/preview/${meetingId}/${mediaId}/transcript?document=${documentId}`;
        } else {
            return `/guest/preview/${meetingId}/${mediaId}/transcript`;
        }
    }, [search]);

    if (localStorage.getItem(LOCALSTORAGE.CLOSER_GUEST_JWT)) {
        return <Navigate to={redirectUrl} />;
    }

    if (!search.get(SEARCH_PARAMS.VALIDATION_TOKEN)) {
        return (
            <Empty label="You have no right to view this content, please contact the owner" />
        );
    }
    async function handleValidation(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const email = formData.get(FORM_KEY.GUEST.EMAIL) as string;
        if (!email) {
            push({
                title: "Invalid input",
                description: "email is not correct",
                type: "destructive",
            });
            return;
        }
        const validationToken = search.get(SEARCH_PARAMS.VALIDATION_TOKEN);
        if (!validationToken) return;
        await validate({
            email: email,
            validationToken,
        });
    }

    return (
        <div className="w-full h-full grid place-items-center">
            <div className="w-full max-w-lg flex flex-col justify-center items-center p-4 rounded-md border-2 border-gray-400">
                <h1 className="text-2xl">Validate</h1>
                <h1 className="text-sm text-gray-400">Enter your email</h1>
                <form className="w-full" onSubmit={handleValidation}>
                    <Label className="mb-10" htmlFor={FORM_KEY.GUEST.EMAIL}>
                        Email
                    </Label>
                    <Input
                        id={FORM_KEY.GUEST.EMAIL}
                        name={FORM_KEY.GUEST.EMAIL}
                        type="text"
                        placeholder="your email"
                        required
                    />
                    <Button
                        className="w-full mt-4"
                        type="submit"
                        disabled={isPending}
                    >
                        {isPending && (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Submit
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default PreviewValidate;
