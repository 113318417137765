import { Badge } from "@component-ui/utility/Badge";
import { ZoomMeeting } from "@hooks/zoom/useGetZoomMeetingsQuery";
import { cn } from "@libs/utils";
import dayjs from "dayjs";
import { FC } from "react";
import { Link } from "react-router-dom";

interface ZoomMeetingListItemProps {
    item: ZoomMeeting;
    currentZoomMeetingId: number | undefined;
}

const ZoomMeetingListItem: FC<ZoomMeetingListItemProps> = ({
    item,
    currentZoomMeetingId,
}) => {
    const { start_time, topic, id, timezone, recording_count, uuid } = item;

    return (
        <Link
            to={`recording-download/zoom/${encodeURIComponent(uuid)}`}
            state={{ start_time, topic, timezone }}
        >
            <div
                className={cn(
                    currentZoomMeetingId === id && "bg-gray-100/90",
                    "grid grid-flow-row w-full items-center rounded-md mb-2 px-3 py-1 hover:bg-gray-100/90 overflow-hidden shadow-sm",
                )}
            >
                <div className="flex justify-between gap-x-6 py-1">
                    <div className="flex min-w-0 gap-x-4">
                        <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                                {topic}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col items-end">
                        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                            <p className="mt-1 text-xs leading-5 text-gray-500">
                                <time dateTime="2023-01-23T13:23Z">
                                    {dayjs(start_time).fromNow()}
                                </time>
                            </p>
                        </div>
                        <Badge
                            variant={"noHover"}
                            className="text-sm cursor-default w-fit text-nowrap"
                        >
                            {recording_count ?? 0}
                        </Badge>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default ZoomMeetingListItem;
