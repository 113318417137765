import { Button } from "@component-ui/utility/Button";
import { Checkbox } from "@component-ui/utility/Checkbox";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";
import { FORM_KEY } from "@constant/form";
import { SEARCH_PARAMS } from "@constant/searchParams";
import { useCreateSharePermissionMutate } from "@hooks/shareable/useCreateSharePermissionMutate";
import { Loader2 } from "lucide-react";
import { FC, FormEvent, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ShareableHistory from "./ShareableHistory";

type PathParam = {
    meetingId: string;
    mediaId: string;
};

const ShareableLinkForm: FC = () => {
    const [isShareDocument, setIsShareDocument] = useState(false);
    const { mediaId, meetingId } = useParams<PathParam>();
    const [search] = useSearchParams();
    const documentId = search.get(SEARCH_PARAMS.CURRENT_DOCUMENT);
    const { mutateAsync: createSharePermission, isPending: isCreating } =
        useCreateSharePermissionMutate();
    const emailInputRef = useRef<HTMLInputElement>(null);

    async function handleShareSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        formData.set(FORM_KEY.SHAREABLE.MEETING_ID, meetingId || "");
        formData.set(FORM_KEY.SHAREABLE.MEDIA_ID, mediaId || "");
        formData.set(FORM_KEY.SHAREABLE.DOCUMENT_ID, documentId || "");
        await createSharePermission(formData);
        if (!emailInputRef.current) return;
        emailInputRef.current.value = emailInputRef.current.defaultValue;
        emailInputRef.current.focus();
    }

    if (!meetingId || !mediaId) {
        return null;
    }

    return (
        <div className="space-y-8">
            <form className="space-y-2" onSubmit={handleShareSubmit}>
                <div>
                    <Label htmlFor={FORM_KEY.SHAREABLE.EMAIL}>Email</Label>
                    <Input
                        id={FORM_KEY.SHAREABLE.EMAIL}
                        name={FORM_KEY.SHAREABLE.EMAIL}
                        type="email"
                        placeholder="email"
                        required
                        disabled={isCreating}
                        ref={emailInputRef}
                        defaultValue=""
                    />
                </div>
                <div className="flex justify-between items-center">
                    <div className="space-y-2">
                        <div className="flex items-center space-x-2 space-y-0">
                            <Checkbox
                                id={FORM_KEY.SHAREABLE.SEND_WITH_EMAIL}
                                name={FORM_KEY.SHAREABLE.SEND_WITH_EMAIL}
                                value="true"
                                disabled={isCreating}
                            />
                            <Label
                                htmlFor={FORM_KEY.SHAREABLE.SEND_WITH_EMAIL}
                                className="text-sm"
                            >
                                Send invite email
                            </Label>
                        </div>
                        <div className="flex items-center space-x-2 space-y-0">
                            <Checkbox
                                id={FORM_KEY.SHAREABLE.SHARE_DOCUMENT}
                                name={FORM_KEY.SHAREABLE.SHARE_DOCUMENT}
                                value="true"
                                disabled={isCreating}
                                checked={isShareDocument}
                                defaultChecked={isShareDocument}
                                onCheckedChange={(checked) => {
                                    checked === "indeterminate"
                                        ? null
                                        : setIsShareDocument(checked);
                                }}
                            />{" "}
                            <Label htmlFor={FORM_KEY.SHAREABLE.SHARE_DOCUMENT}>
                                {" "}
                                Share Document
                            </Label>
                        </div>
                    </div>
                    <Button disabled={isCreating}>
                        {isCreating && (
                            <Loader2 className="mr-2 w-4 h-4 animate-spin" />
                        )}
                        Confirm
                    </Button>
                </div>
            </form>
            <ShareableHistory
                isShareDocument={isShareDocument}
                meetingId={meetingId}
                mediaId={mediaId}
                documentId={documentId}
            />
        </div>
    );
};

export default ShareableLinkForm;
