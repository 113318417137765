import {
    Document,
    SharedDocument,
} from "@component-func/document/DocumentRoot";
import MediaPlayer from "@component-func/media/MediaPlayer";
import SharedMediaPlayer from "@component-func/media/SharedMediaPlayer";
import ShareableButton from "@component-func/shareable/ShareableButton";
import SummarizeRoot from "@component-func/summarize/SummarizeRoot";
import TranscriptMiscBar from "@component-func/transcript/TranscriptMiscBar";
import {
    TranscriptView,
    TranscriptWithEdit,
    TranscriptWithoutEdit,
    TranslateWithEdit,
    TranslateWithoutEdit,
} from "@component-func/transcript/TranscriptView";
import Empty from "@component-ui/Empty";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "@component-ui/utility/Resizable";
import { SEARCH_PARAMS } from "@constant/searchParams";
import useSharedMediaTranscriptQuery from "@hooks/share-to-me/useShareMediaTranscriptQuery";
import useSpeakerMapQuery from "@hooks/speaker/useSpeakerMapQuery";
import useMediaTranscriptQuery from "@hooks/useTranscriptQuery";
import { ChevronLeft } from "lucide-react";
import { useEffect, useMemo, useRef } from "react";
import { Link, Navigate, useParams, useSearchParams } from "react-router-dom";
import { MediaTranscript } from "types/backend/media";
import { Tour } from "@frigade/react";
import { FRIGADE_FLOW_ID } from "@constant/frigade-flows";
import { usePostHog } from "posthog-js/react";
import { UserType } from "types/backend";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@component-ui/utility/Tabs";

type TranscriptRootProps = {
    mediaId: string | undefined;
    meetingId: string | undefined;
    mediaTranscript: MediaTranscript | undefined;
    shareButton?: React.ReactNode;
    mediaPlayer: React.ReactNode;
    transcriptView: React.ReactNode;
    document: React.ReactNode;
    back?: string;
    showSummarize: boolean;
};

const TranscriptRoot = ({
    mediaId,
    meetingId,
    mediaTranscript,
    shareButton,
    mediaPlayer,
    transcriptView,
    document,
    back,
    showSummarize,
}: TranscriptRootProps) => {
    const speakers = useMemo(() => {
        if (!mediaTranscript || !mediaTranscript.transcriptionSegments) {
            return [];
        }
        return Array.from(
            new Set(
                mediaTranscript.transcriptionSegments.map(
                    (transcriptSegment) => transcriptSegment.speaker,
                ),
            ),
        );
    }, [mediaTranscript]);

    if (!meetingId || !mediaId || !mediaTranscript) return null;

    return (
        <ResizablePanelGroup
            className="h-full"
            direction="horizontal"
            autoSaveId="persistence"
        >
            <ResizablePanel>
                {mediaTranscript.status &&
                mediaTranscript.status === "progressing" ? (
                    <LoadingSpinnerCenter />
                ) : (
                    <ResizablePanelGroup
                        direction="vertical"
                        className="h-full"
                    >
                        <div className="grid grid-cols-[max-content,1fr,max-content] items-center gap-1 p-2">
                            {back && (
                                <Button size="icon" variant="ghost">
                                    <Link to={back} relative="path">
                                        <ChevronLeft />
                                    </Link>
                                </Button>
                            )}
                            <h1 className="font-bold text-lg">
                                {mediaTranscript.meeting.label}
                            </h1>
                            {shareButton}
                        </div>
                        <ResizablePanel defaultSize={30}>
                            {mediaPlayer}
                        </ResizablePanel>
                        <ResizableHandle withHandle />
                        <ResizablePanel
                            defaultSize={70}
                            className="flex flex-col py-1"
                        >
                            <TranscriptMiscBar
                                mediaId={mediaId}
                                speakers={speakers}
                                lang={mediaTranscript.language}
                            />
                            {transcriptView}
                        </ResizablePanel>
                    </ResizablePanelGroup>
                )}
            </ResizablePanel>
            <ResizableHandle withHandle />
            <ResizablePanel>
                <div className="px-2 h-full">
                    {showSummarize ? (
                        <Tabs
                            defaultValue="documents"
                            className="w-full max-w-full h-full grid grid-rows-[max-content,1fr]"
                        >
                            <Tour
                                flowId={FRIGADE_FLOW_ID.CREATE_A_SUMMARY}
                                onClick={(e) => e.stopPropagation()}
                            />
                            <TabsList className="max-w-[400px] w-full mx-auto mt-2">
                                <TabsTrigger
                                    value="summarization"
                                    id="summarize-button"
                                >
                                    AI Summarization
                                </TabsTrigger>
                                <TabsTrigger value="documents">
                                    Document
                                </TabsTrigger>
                            </TabsList>
                            <TabsContent
                                value="summarization"
                                className="h-full"
                            >
                                <SummarizeRoot />
                            </TabsContent>
                            <TabsContent
                                value="documents"
                                className="overflow-y-auto pb-2"
                            >
                                {document}
                            </TabsContent>
                        </Tabs>
                    ) : (
                        <div className="flex-1 overflow-y-auto">{document}</div>
                    )}
                </div>
            </ResizablePanel>
        </ResizablePanelGroup>
    );
};

type PathParam = {
    meetingId: string;
    mediaId: string;
};

const SharedTranscript = ({
    userType,
}: {
    userType: Exclude<UserType, "tenant-user">;
}) => {
    const { meetingId, mediaId } = useParams<PathParam>();

    const {
        data: mediaTranscript,
        isLoading,
        isSuccess,
        isError,
    } = useSharedMediaTranscriptQuery(mediaId, userType);

    const [search, setSearch] = useSearchParams();
    const selectedSpeaker = search.get(SEARCH_PARAMS.CURRENT_SPEAKER);
    const currentSelectedTranscriptId = search.get(
        SEARCH_PARAMS.CURRENT_SELECTED_TRANSCRIPT_SEGMENT_ID,
    );
    const posthog = usePostHog();

    useEffect(() => {
        if (mediaTranscript) {
            setSearch((prev) => {
                prev.set(SEARCH_PARAMS.MEDIA_TARGET_KEY, mediaTranscript.key);
                prev.set(SEARCH_PARAMS.MIMETYPE, mediaTranscript.mimeType);
                return prev;
            });
            posthog?.capture("$pageview", {
                pageName:
                    userType === "guest"
                        ? "/guest/transcript"
                        : "/shared/transcript",
            });
        }
    }, [mediaTranscript]);

    const transcriptionSegments = useMemo(() => {
        if (!mediaTranscript) return [];
        if (selectedSpeaker) {
            return mediaTranscript.transcriptionSegments.filter(
                (transcriptSegment) =>
                    transcriptSegment.speaker === selectedSpeaker,
            );
        }
        return mediaTranscript.transcriptionSegments;
    }, [mediaTranscript, selectedSpeaker]);

    const { data: speakerMap } = useSpeakerMapQuery(mediaId);

    const mediaRef = useRef<HTMLVideoElement | HTMLAudioElement>(null);

    function setCurrentSelectedTranscriptId(id: string) {
        setSearch(
            (prev) => {
                const prevId = prev.get(
                    SEARCH_PARAMS.CURRENT_SELECTED_TRANSCRIPT_SEGMENT_ID,
                );
                if (id === prevId) {
                    prev.delete(
                        SEARCH_PARAMS.CURRENT_SELECTED_TRANSCRIPT_SEGMENT_ID,
                    );
                } else {
                    prev.set(
                        SEARCH_PARAMS.CURRENT_SELECTED_TRANSCRIPT_SEGMENT_ID,
                        id,
                    );
                }
                return prev;
            },
            { replace: true },
        );
    }

    async function handleSeekPlaybackTimestamp(time: string) {
        const parsedTime = parseFloat(time);
        if (isNaN(parsedTime)) {
            console.error("Timestamp is not correct, please try again");
            return;
        }
        if (!mediaRef || !mediaRef.current) {
            console.warn("Media player is not presented");
            return;
        }
        mediaRef.current.currentTime = parsedTime;
        await mediaRef.current.play();
    }

    const transcriptRootProps = useMemo<TranscriptRootProps>(
        () => ({
            showSummarize: false,
            meetingId,
            mediaId,
            mediaTranscript,
            mediaPlayer: (
                <SharedMediaPlayer ref={mediaRef} userType={userType} />
            ),
            transcriptView: (
                <TranscriptView
                    mediaId={mediaId}
                    speakerMap={speakerMap}
                    transcriptionSegments={transcriptionSegments}
                    handleSeekPlaybackTimestamp={handleSeekPlaybackTimestamp}
                    TranscriptDisplay={({
                        hidden,
                        pointform,
                        transcriptSegment,
                    }) => {
                        return (
                            <div className="flex">
                                <TranscriptWithoutEdit
                                    originalHidden={hidden}
                                    pointform={pointform}
                                    transcriptSegment={transcriptSegment}
                                />
                                <TranslateWithoutEdit
                                    pointform={pointform}
                                    transcriptSegment={transcriptSegment}
                                />
                            </div>
                        );
                    }}
                />
            ),
            document: <SharedDocument userType={userType} />,
        }),
        [
            meetingId,
            mediaId,
            transcriptionSegments,
            speakerMap,
            currentSelectedTranscriptId,
            setCurrentSelectedTranscriptId,
        ],
    );

    if (!mediaId || !meetingId) return <Navigate to="/" />;

    if (isLoading) return <LoadingSpinnerCenter />;

    if (!isSuccess || isError)
        return <Empty label="Cannot get shared media transcript" />;

    return <TranscriptRoot {...transcriptRootProps} />;
};

const Transcript = () => {
    const { meetingId, mediaId } = useParams<PathParam>();

    const {
        data: mediaTranscript,
        isLoading,
        isSuccess,
        isError,
    } = useMediaTranscriptQuery(mediaId);

    const [search, setSearch] = useSearchParams();
    const posthog = usePostHog();
    const selectedSpeaker = search.get(SEARCH_PARAMS.CURRENT_SPEAKER);
    const currentSelectedTranscriptId = search.get(
        SEARCH_PARAMS.CURRENT_SELECTED_TRANSCRIPT_SEGMENT_ID,
    );

    const mediaRef = useRef<HTMLVideoElement | HTMLAudioElement>(null);

    const transcriptionSegments = useMemo(() => {
        if (!mediaTranscript) return [];
        if (selectedSpeaker) {
            return mediaTranscript.transcriptionSegments.filter(
                (transcriptSegment) =>
                    transcriptSegment.speaker === selectedSpeaker,
            );
        }
        return mediaTranscript.transcriptionSegments;
    }, [mediaTranscript, selectedSpeaker]);

    const { data: speakerMap } = useSpeakerMapQuery(mediaId);

    function setCurrentSelectedTranscriptId(id: string) {
        setSearch(
            (prev) => {
                const prevId = prev.get(
                    SEARCH_PARAMS.CURRENT_SELECTED_TRANSCRIPT_SEGMENT_ID,
                );
                if (id === prevId) {
                    prev.delete(
                        SEARCH_PARAMS.CURRENT_SELECTED_TRANSCRIPT_SEGMENT_ID,
                    );
                } else {
                    prev.set(
                        SEARCH_PARAMS.CURRENT_SELECTED_TRANSCRIPT_SEGMENT_ID,
                        id,
                    );
                }
                return prev;
            },
            { replace: true },
        );
    }

    async function handleSeekPlaybackTimestamp(time: string) {
        const parsedTime = parseFloat(time);
        if (isNaN(parsedTime)) {
            console.error("Timestamp is not correct, please try again");
            return;
        }
        if (!mediaRef || !mediaRef.current) {
            console.warn("Media player is not presented");
            return;
        }
        mediaRef.current.currentTime = parsedTime;
        await mediaRef.current.play();
    }

    const transcriptRootProps = useMemo<TranscriptRootProps>(
        () => ({
            showSummarize: true,
            back: `/meeting-transcripts/${meetingId}`,
            meetingId,
            mediaId,
            mediaTranscript,
            shareButton: <ShareableButton />,
            mediaPlayer: <MediaPlayer ref={mediaRef} />,
            transcriptView: (
                <>
                    <Tour flowId={FRIGADE_FLOW_ID.TRANSCRIPT_PAGE_HINTS} />
                    <TranscriptView
                        mediaId={mediaId}
                        speakerMap={speakerMap}
                        transcriptionSegments={transcriptionSegments}
                        handleSeekPlaybackTimestamp={
                            handleSeekPlaybackTimestamp
                        }
                        TranscriptDisplay={({
                            hidden,
                            pointform,
                            transcriptSegment,
                        }) => {
                            return (
                                <div className="flex">
                                    <TranscriptWithEdit
                                        originalHidden={hidden}
                                        pointform={pointform}
                                        transcriptSegment={transcriptSegment}
                                        setCurrentSelectedTranscriptId={
                                            setCurrentSelectedTranscriptId
                                        }
                                        currentSelectedTranscriptId={
                                            currentSelectedTranscriptId
                                        }
                                    />
                                    <TranslateWithEdit
                                        pointform={pointform}
                                        transcriptSegment={transcriptSegment}
                                    />
                                </div>
                            );
                        }}
                    />
                </>
            ),
            document: <Document />,
        }),
        [
            meetingId,
            mediaId,
            transcriptionSegments,
            speakerMap,
            currentSelectedTranscriptId,
            setCurrentSelectedTranscriptId,
        ],
    );

    useEffect(() => {
        if (mediaTranscript) {
            posthog?.capture("$pageview", {
                pageName: "/transcript",
            });

            const meetingDocumentRelations =
                mediaTranscript.meeting.meetingDocumentRelations;
            if (
                meetingDocumentRelations &&
                meetingDocumentRelations.length > 0
            ) {
                setSearch((prev) => {
                    const previousDocumentId = prev.get(
                        SEARCH_PARAMS.CURRENT_DOCUMENT,
                    );
                    if (!previousDocumentId) {
                        prev.set(
                            SEARCH_PARAMS.CURRENT_DOCUMENT,
                            meetingDocumentRelations[0].documentId || "",
                        );
                    }
                    return prev;
                });
            }
        }
    }, [mediaTranscript]);

    if (!mediaId || !meetingId) return <Navigate to="/" />;

    if (isLoading) return <LoadingSpinnerCenter />;

    if (!isSuccess || isError)
        return <Empty label="Cannot get media transcript" />;

    return <TranscriptRoot {...transcriptRootProps} />;
};

export { Transcript, SharedTranscript };
