export const FORM_KEY = {
    MEETING: {
        ID: "meeting-id",
        NAME: "meeting-name",
        WITH_DOCUMENT: "meeting-with-document",
        DOCUMENT_TEMPLATE: "document-template-id",
    },
    MEDIA: {
        NAME: "media-name",
        AUDIO_FILE: "audio-file",
        LANGUAGE: "language",
        MIMETYPE: "mime-type",
    },
    DOCUMENT: {
        FORM_ID: "create-document",
        TITLE: "document-title",
        TEMPLATE: "document-template-id",
        MEETING: "document-meeting-id",
    },
    DOCUMENT_SECTION: {
        FORM_ID: "create-document-section",
        TITLE: "document-section-title",
    },
    DOCUMENT_TEMPLATE: {
        TITLE: "template-title",
    },
    UPLOAD_AUDIO: {
        AUDIO_FILE: "audio-file",
        LANGUAGE: "language",
    },
    CREATE_CUSTOMER_TAG: {
        FORM_ID: "create-customer-tag",
        NAME: "customer-tag-name",
        DESCRIPTION: "customer-tag-description",
    },
    SPEAKER_RENAMING: {
        NEW_NAME: "new-name",
    },
    SHAREABLE: {
        EMAIL: "email",
        SEND_WITH_EMAIL: "send-with-email",
        SHARE_DOCUMENT: "share-document",
        MEETING_ID: "meeting-id",
        MEDIA_ID: "media-id",
        DOCUMENT_ID: "document-id",
    },
    GUEST: {
        EMAIL: "email",
    },
    SUMMARIZATION: {
        LABEL: "label",
        SYSTEM_MESSAGE: "system_message",
        AUTO_TRIGGER: "auto_trigger",
        SLACK_WEBHOOK_URL: "slack_webhook_url",
    },
} as const;
