import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";

interface ZoomMeetingParticipantsResponse {
    page_count: number;
    page_size: number;
    total_records: number;
    next_page_token: string;
    participants: Array<ZoomParticipant>;
}

export interface ZoomParticipant {
    id: string;
    user_id: string;
    name: string;
    user_email: string;
    join_time: string;
    leave_time: string;
    duration: number;
    registrant_id: string;
    failover: boolean;
    status: string;
}

export const useGetMeetingParticipantsQuery = (
    zoomMeetingId: string | undefined,
) => {
    const query = useQuery<ZoomMeetingParticipantsResponse>({
        queryKey: [Q.GET_ZOOM_MEETING_PARTICIPANTS, zoomMeetingId],
        queryFn: async () => {
            const { data } = await api.get(
                `zoom/${zoomMeetingId}/participants`,
            );
            return data;
        },
        enabled: !!zoomMeetingId,
    });

    return query;
};
