import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@component-ui/utility/Command";
import { PopoverContent, PopoverTrigger } from "@component-ui/utility/Popover";
import { SEARCH_PARAMS } from "@constant/searchParams";
import useCustomerTags from "@hooks/customer-tag/useCustomerTagsQuery";
import { cn } from "@libs/utils";
import { Popover } from "@radix-ui/react-popover";
import { CommandLoading } from "cmdk";
import { Check, ChevronsUpDown, RotateCcw, XCircle } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useSearchParams } from "react-router-dom";

type CustomerTagComboBoxProps = {
    selectedTagId: string;
    setSelectedTagId: Dispatch<SetStateAction<string>>;
};

const CustomerTagComboBox: FC<CustomerTagComboBoxProps> = ({
    selectedTagId,
    setSelectedTagId,
}) => {
    const [open, setOpen] = useState(false);
    const [, setSearchParams] = useSearchParams();
    const {
        data: customerTags,
        isSuccess,
        isError,
        isFetching,
        refetch,
    } = useCustomerTags();
    const posthog = usePostHog();

    if (!isSuccess || isError) {
        return (
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        className="w-full max-w-[200px] justify-between"
                        onClick={() => refetch()}
                    >
                        Click to retry
                        <RotateCcw className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
            </Popover>
        );
    }

    const currentCustomerTag = customerTags.find(
        (_tag) => _tag.id === selectedTagId,
    );

    const handleSelectCustomerTag = (customerTagId: string) => {
        setOpen(false);
        setSelectedTagId((prev) => {
            if (prev === customerTagId) return "";
            return customerTagId;
        });
        setSearchParams((prev) => {
            if (
                !customerTagId ||
                prev.get(SEARCH_PARAMS.CURRENT_TAG) === customerTagId
            ) {
                prev.delete(SEARCH_PARAMS.CURRENT_TAG);
                posthog?.capture("customer_tag_unselected");
                return prev;
            }
            prev.set(SEARCH_PARAMS.CURRENT_TAG, customerTagId);
            posthog?.capture("customer_tag_selected");
            return prev;
        });
    };

    return (
        <>
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className="w-full justify-between overflow-hidden"
                        id="customer-tag-combobox"
                    >
                        <span className="truncate">
                            {currentCustomerTag
                                ? currentCustomerTag.name
                                : "Select tag"}
                        </span>
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0" align="end">
                    <Command
                        label="Tag list"
                        className="w-[240px]"
                        filter={(value, search) => {
                            if (value.includes(search)) return 1;
                            return 0;
                        }}
                    >
                        <CommandInput
                            className="focus:outline-none"
                            placeholder="Search tag..."
                        />
                        <CommandEmpty>No result found...</CommandEmpty>
                        <CommandList className="overflow-auto max-h-[320px]">
                            <CommandGroup heading="misc">
                                <CommandItem
                                    onSelect={() => {
                                        handleSelectCustomerTag("");
                                    }}
                                    value="reset-button"
                                >
                                    <XCircle className="mr-2 h-4 w-4 shrink-0 opacity-50" />
                                    Clear
                                </CommandItem>
                            </CommandGroup>
                            <CommandGroup heading="Tags">
                                {isFetching && (
                                    <CommandLoading>
                                        <div className="h-8">
                                            <LoadingSpinnerCenter size="sm" />
                                        </div>
                                    </CommandLoading>
                                )}
                                {customerTags.map((customerTag) => (
                                    <CommandItem
                                        key={customerTag.id}
                                        value={customerTag.name}
                                        onSelect={() => {
                                            handleSelectCustomerTag(
                                                customerTag.id,
                                            );
                                        }}
                                    >
                                        <Check
                                            className={cn(
                                                "mr-2 h-4 w-4",
                                                currentCustomerTag &&
                                                    currentCustomerTag.id ===
                                                        customerTag.id
                                                    ? "opacity-100"
                                                    : "opacity-0",
                                            )}
                                        />
                                        {customerTag.name}
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
        </>
    );
};

export default CustomerTagComboBox;
