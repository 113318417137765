import { Avatar, AvatarFallback } from "@component-ui/utility/Avatar";
import { IconButtonWithTooltip } from "@component-ui/utility/Button";
import { FC, useMemo } from "react";
import { X, Mail, Link } from "lucide-react";
import { convertTextToColour } from "@libs/utils";
import {
    GetAllResourcePermissionByResourceIdResponse,
    ResourcePermission,
} from "types/backend/resource_permission";
import useDeleteResourcePermissionMutate from "@hooks/shareable/useDeleteSharePermissionMutate";
import useResourcePermissionSendEmail from "@hooks/shareable/useResourcePermissionSendEmail";

type ResourcePermissionItem = GetAllResourcePermissionByResourceIdResponse[
    | "guests"
    | "tenantUser"][number];

export type PermissionRowProps = {
    permission: ResourcePermissionItem;
    userType: "TenantUser" | "Guest";
};

function constructDeleteRecord(
    permission: ResourcePermission[],
): Record<string, string> {
    const record: Record<string, string> = {};
    for (const p of permission) {
        record[p.resourceType.toLowerCase() + "Id"] = p.resourceId;
    }
    return record;
}

const PermissionRow: FC<PermissionRowProps> = ({ permission, userType }) => {
    const { mutateAsync: deletePermission } =
        useDeleteResourcePermissionMutate();
    const resendEmail = useResourcePermissionSendEmail();
    const shareReqBody = useMemo(
        () => ({
            userId: permission.user.id,
            userType,
            ...constructDeleteRecord(permission.resourcePermissions),
        }),
        [permission],
    );

    if (
        permission.resourcePermissions.findIndex(
            (resourcePermission) => resourcePermission.resourceType === "Media",
        ) === -1
    ) {
        return null;
    }
    const url = permission.url;

    return (
        <div className="p-1 flex justify-between items-center">
            <div className="flex items-center gap-2">
                <Avatar>
                    <AvatarFallback
                        className="text-white"
                        style={{
                            backgroundColor: convertTextToColour(
                                permission.user.email,
                            ),
                        }}
                    >
                        {permission.user.email.slice(0, 2)}
                    </AvatarFallback>
                </Avatar>
                <span className="text-sm">{permission.user.email}</span>
            </div>
            <div>
                <IconButtonWithTooltip
                    icon={<X className="w-4 h-4" />}
                    content="Remove permission"
                    variant="ghost"
                    size="icon"
                    onClick={() => deletePermission(shareReqBody)}
                />
                <IconButtonWithTooltip
                    icon={<Mail className="w-4 h-4" />}
                    content="Send email"
                    variant="ghost"
                    size="icon"
                    onClick={() => {
                        resendEmail(shareReqBody);
                    }}
                />
                {url && (
                    <IconButtonWithTooltip
                        icon={<Link className="w-4 h-4" />}
                        content="Copy link"
                        variant="ghost"
                        size="icon"
                        onClick={async () => {
                            await navigator.clipboard.writeText(url);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default PermissionRow;
