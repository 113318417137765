import { TableCell, TableRow } from "@component-ui/utility/Table";
import dayjs from "dayjs";
import { FC, useEffect, useRef, useState } from "react";
import { RecordingDownloadTask } from "types/backend/zoom_download_task";
import { FORM_KEY } from "@constant/form";
import { Button } from "@component-ui/utility/Button";
import { Label } from "@component-ui/utility/Label";
import { Download, Check, XCircle, Loader2, Video } from "lucide-react";
import { Input } from "@component-ui/utility/Input";
import BasicSelect from "@component-func/BasicSelect";
import { languageOptions } from "@constant/language";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@component-ui/utility/Dialog";
import { ChildrenWithHover } from "@component-ui/utility/Icon";

import { formatDuration } from "@libs/duration";
import { GoogleMeetRecordingRecord } from "types/backend/google_meet";
import useMutationDownloadGoogleMeetRecording from "@hooks/google-meet/useMutationDownloadGoogleMeetRecording";

interface GoogleMeetingRecordingRow {
    googleRecording: GoogleMeetRecordingRecord;
    recordingDownloadTask: RecordingDownloadTask | undefined;
}

const openInNewTab = (url: string | null | undefined): void => {
    if (!url) {
        return;
    }

    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
};

const GoogleMeetingRecordingRow: FC<GoogleMeetingRecordingRow> = ({
    googleRecording,
    recordingDownloadTask,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [status, setStatus] = useState(recordingDownloadTask?.status);

    useEffect(() => {
        setStatus(recordingDownloadTask?.status);
    }, [recordingDownloadTask]);

    const { mutate, isPending } = useMutationDownloadGoogleMeetRecording();

    const onSubmit = async (form: React.FormEvent<HTMLFormElement>) => {
        form.preventDefault();
        const formData = new FormData(form.currentTarget);
        const meetingName = formData.get(FORM_KEY.MEETING.NAME) as string;
        const language = formData.get(FORM_KEY.MEDIA.LANGUAGE) as
            | "en-US"
            | "pt-BR"
            | "es";

        if (googleRecording.driveDestination?.file) {
            mutate(
                {
                    fileId: googleRecording.driveDestination.file,
                    meetingName,
                    language,
                },
                {
                    onSuccess({ status }) {
                        setIsOpen(false);
                        setStatus(status);
                    },
                },
            );
        }
    };

    const startTime = dayjs(Number(googleRecording.startTime?.seconds) * 1000);
    const endTime = dayjs(Number(googleRecording.endTime?.seconds) * 1000);
    const duration = formatDuration(endTime.diff(startTime) / 1000);
    return (
        <TableRow className="h-[70px]">
            <TableCell className="text-center">
                {startTime.format("DD/MM/YYYY HH:mm")}
            </TableCell>
            <TableCell className="text-center">{duration}</TableCell>
            <TableCell className="text-center">
                {status ? (
                    status === "waiting" ? (
                        <span>waiting</span>
                    ) : status === "error" ? (
                        <div className="flex justify-center">
                            <ChildrenWithHover description="error">
                                <XCircle color="#f70000" />
                            </ChildrenWithHover>
                        </div>
                    ) : status === "success" ? (
                        <div className="flex justify-center">
                            <ChildrenWithHover description="finished">
                                <Check color="#38db07" />
                            </ChildrenWithHover>
                        </div>
                    ) : status === "progress" ? (
                        <span>In progress</span>
                    ) : null
                ) : null}
            </TableCell>
            <TableCell className="text-center">
                <Dialog open={isOpen} onOpenChange={setIsOpen}>
                    <ChildrenWithHover description="download and transcript">
                        <Button
                            size="icon"
                            variant="ghost"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setIsOpen(true);
                            }}
                            disabled={!!status}
                        >
                            <Download />
                        </Button>
                    </ChildrenWithHover>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>
                                DownLoad And Transcript Recording
                            </DialogTitle>
                        </DialogHeader>
                        <form
                            id="downLoad-and-transcript-recording"
                            ref={formRef}
                            onSubmit={onSubmit}
                        >
                            <Label htmlFor={FORM_KEY.MEETING.NAME}>
                                Meeting name
                            </Label>
                            <Input
                                id={FORM_KEY.MEETING.NAME}
                                name={FORM_KEY.MEETING.NAME}
                                type="text"
                                required
                            />
                            <Label htmlFor={FORM_KEY.MEDIA.LANGUAGE}>
                                Language
                            </Label>
                            <BasicSelect
                                name={FORM_KEY.MEDIA.LANGUAGE}
                                placeholder="Select a language"
                                options={languageOptions}
                                triggerClassName="mt-2"
                                required
                            />
                        </form>
                        <Button
                            form="downLoad-and-transcript-recording"
                            type="submit"
                            disabled={isPending}
                        >
                            {isPending && (
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            )}
                            Download and Transcript
                        </Button>
                    </DialogContent>
                </Dialog>
                <ChildrenWithHover description="preview video">
                    <Button
                        size="icon"
                        variant="ghost"
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            openInNewTab(
                                googleRecording.driveDestination?.exportUri,
                            );
                        }}
                    >
                        <Video />
                    </Button>
                </ChildrenWithHover>
            </TableCell>
        </TableRow>
    );
};

export default GoogleMeetingRecordingRow;
