import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { Document } from "types/backend/document";

export default function useAllDocumentQuery(search = "", tagId = "") {
    const query = useQuery({
        queryKey: [Q.GET_ALL_DOCUMENT, search, tagId],
        queryFn: async ({ queryKey }) => {
            const [, search, tagId] = queryKey;
            const { data } = await api.get<Document[]>("/documents", {
                params: {
                    search,
                    'tag-id': tagId
                },
            });
            return data;
        },
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
        placeholderData: [],
    });

    return query;
}
