import Empty from "@component-ui/Empty";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { useMeetingsQuery } from "@hooks/meeting/useMeetingsQuery";
import { FC, useState } from "react";
import { Check, ChevronsUpDown, XCircle } from "lucide-react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@component-ui/utility/Popover";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@component-ui/utility/Command";
import { CommandLoading } from "cmdk";
import { cn } from "@libs/utils";
import { Button } from "@component-ui/utility/Button";

type MeetingComboBoxProps = {
    selectedMeetingId: string;
    setSelectedMetingId: (meetingId: string) => void;
    placeholder?: string;
    tooltipContent?: string;
};

const MeetingComboBox: FC<MeetingComboBoxProps> = ({
    selectedMeetingId,
    setSelectedMetingId,
    placeholder = "Select a Meeting to connect",
    tooltipContent = "Connect the new Document to an existing meeting",
}) => {
    const {
        data: meetings,
        isLoading,
        isError,
        isSuccess,
        isFetching,
    } = useMeetingsQuery();
    const [open, setOpen] = useState(false);

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (isError || !isSuccess) {
        return <Empty label="Cannot get meetings" />;
    }

    const currentMeeting = meetings.find(
        (meeting) => meeting.id === selectedMeetingId,
    );

    const handleSelectMeeting = (meetingId: string) => {
        setOpen(false);
        setSelectedMetingId(meetingId);
    };

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <TooltipProvider delayDuration={300}>
                <Tooltip>
                    <PopoverTrigger asChild>
                        <TooltipTrigger asChild>
                            <Button
                                variant="outline"
                                role="combobox"
                                aria-expanded={open}
                                className="w-full justify-between overflow-hidden my-1"
                                id="customer-tag-combobox"
                            >
                                <span className="truncate font-normal">
                                    {currentMeeting
                                        ? currentMeeting.label
                                        : placeholder}
                                </span>
                                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                        </TooltipTrigger>
                    </PopoverTrigger>
                    <TooltipContent side="left">
                        {tooltipContent}
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            <PopoverContent className="w-full p-0" align="end">
                <Command
                    label="Meeting list"
                    className="w-[400px]"
                    filter={(value, search) => {
                        if (value.includes(search)) return 1;
                        return 0;
                    }}
                >
                    <CommandInput
                        className="focus:outline-none"
                        placeholder="Search meeting..."
                    />
                    <CommandEmpty>No result found...</CommandEmpty>
                    <CommandList className="overflow-auto max-h-[320px]">
                        <CommandGroup heading="misc">
                            <CommandItem
                                onSelect={() => {
                                    handleSelectMeeting("");
                                }}
                                value="reset-button"
                            >
                                <XCircle className="mr-2 h-4 w-4 shrink-0 opacity-50" />
                                Clear
                            </CommandItem>
                        </CommandGroup>
                        <CommandGroup heading="Meetings">
                            {isFetching && (
                                <CommandLoading>
                                    <div className="h-8">
                                        <LoadingSpinnerCenter size="sm" />
                                    </div>
                                </CommandLoading>
                            )}
                            {meetings.map((meeting) => (
                                <CommandItem
                                    key={meeting.id}
                                    value={meeting.id}
                                    onSelect={() => {
                                        handleSelectMeeting(meeting.id);
                                    }}
                                >
                                    <Check
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            currentMeeting &&
                                                currentMeeting.id === meeting.id
                                                ? "opacity-100"
                                                : "opacity-0",
                                        )}
                                    />
                                    {meeting.label}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
};

export default MeetingComboBox;
