import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import _, { Dictionary } from "lodash";
import { ZoomMeetingRecord } from "./useGetZoomMeetingRecordsQuery";
import { useMemo } from "react";
import { ZoomDownloadTask } from "types/backend/zoom_download_task";



export const useGetZoomDownloadTasksQuery = (
    zoomMeetingRecord: ZoomMeetingRecord | undefined,
) => {
    const ids = useMemo(() => {
        if (zoomMeetingRecord) {
            return zoomMeetingRecord.recording_files.map((v) => v.id);
        }
        return undefined;
    }, [zoomMeetingRecord]);

    const query = useQuery<Dictionary<ZoomDownloadTask>>({
        queryKey: [Q.GET_ZOOM_DOWNLOAD_TASKS],
        queryFn: async () => {
            const { data } = await api.post<Array<ZoomDownloadTask>>(
                `/zoom-recordings/tasks`,
                {
                    ids,
                },
            );
            return _.keyBy(
                data,
                (task) => task.zoomRecordingFileId,
            ) as Dictionary<ZoomDownloadTask>;
        },
        enabled: !!ids,
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
    });
    return query;
};
