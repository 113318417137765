import { DocumentComboBox, SharedDocumentComboBox } from "./DocumentComboBox";
import { FC, useEffect, useMemo } from "react";
import { Label } from "@component-ui/utility/Label";
import LinkedMediaList from "./LinkedMediaList";
import {
    SharedTiptapEditor,
    TiptapEditor,
} from "@component-func/tiptap/TiptapEditor";
import ItemResourceMappingList from "@component-func/resource-mapping/ItemResourceMappingList";
import useDocumentQuery from "@hooks/document/useDocumentQuery";
import useDocumentRawDataQuery from "@hooks/document/useDocumentRawDataQuery";
import { SEARCH_PARAMS } from "@constant/searchParams";
import { useSearchParams } from "react-router-dom";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import useGetSharedDocumentQuery from "@hooks/share-to-me/useGetSharedDocumentQuery";
import useGetSharedDocumentRawQuery from "@hooks/share-to-me/useGetSharedDocumentRawDataQuery";
import { Tour } from "@frigade/react";
import { FRIGADE_FLOW_ID } from "@constant/frigade-flows";
import { usePostHog } from "posthog-js/react";
import { PlaySquare, Link } from "lucide-react";
import { UserType } from "types/backend";

type DocumentRootProps = {
    documentComboBox?: React.ReactNode;
    document: React.ReactNode;
};

const DocumentRoot: FC<DocumentRootProps> = ({
    documentComboBox,
    document,
}) => {
    return (
        <div className="h-full flex flex-col gap-2 py-2 px-4">
            <div className="grid w-full items-center">
                <Label className="text-xs">Document</Label>
                {documentComboBox}
            </div>
            <div className="h-full overflow-hidden flex flex-col">
                {document}
            </div>
        </div>
    );
};

type ShareDocumentProps = {
    userType: Exclude<UserType, "tenant-user">;
};

const SharedDocument: FC<ShareDocumentProps> = ({ userType }) => {
    const [search] = useSearchParams();
    const posthog = usePostHog();
    const id = search.get(SEARCH_PARAMS.CURRENT_DOCUMENT);

    const { data: document, isLoading } = useGetSharedDocumentQuery(
        id,
        userType,
    );

    const {
        yjsDoc,
        hocuspocus,
        isLoading: rawDataLoading,
    } = useGetSharedDocumentRawQuery(id, userType);

    useEffect(() => {
        if (!hocuspocus) return;
    }, [hocuspocus]);

    const documentProps = useMemo<DocumentRootProps>(() => {
        return {
            documentComboBox: userType === "shared-user" && (
                <SharedDocumentComboBox />
            ),
            document: document && id && (
                <>
                    <div className="h-full grid grid-rows-[max-content,1fr] pb-10">
                        <span className="inline-flex justify-between items-center">
                            <h1 className="text-xl tracking-wide font-medium">
                                <span>{document.title}</span>
                            </h1>
                        </span>
                        <div className="overflow-y-auto">
                            {yjsDoc && (
                                <SharedTiptapEditor
                                    document={yjsDoc}
                                    userType={userType}
                                />
                            )}
                        </div>
                    </div>
                </>
            ),
        };
    }, [document, yjsDoc, id]);

    useEffect(() => {
        if (id) {
            posthog?.capture("$pageview", {
                pageName: `/${userType}/transcript/document`,
            });
        }
    }, [id]);

    if (isLoading || rawDataLoading) {
        return <LoadingSpinnerCenter />;
    }

    return <DocumentRoot {...documentProps} />;
};

const Document: FC = () => {
    const [search] = useSearchParams();
    const id = search.get(SEARCH_PARAMS.CURRENT_DOCUMENT);
    const posthog = usePostHog();

    const { data: document, isLoading } = useDocumentQuery(id);

    const { yjsDoc, isLoading: rawDataLoading } = useDocumentRawDataQuery(
        id,
        "document",
    );

    const documentProps = useMemo<DocumentRootProps>(() => {
        return {
            guest: false,
            documentComboBox: <DocumentComboBox />,
            document: document && id && (
                <>
                    <Tour
                        flowId={FRIGADE_FLOW_ID.INTEGRATE_CRM}
                        onClick={(e) => e.stopPropagation()}
                    />
                    <div className="h-full grid grid-rows-[max-content,max-content,max-content,1fr] gap-1">
                        <div className="flex items-center gap-2 shrink-0">
                            <PlaySquare className="w-4 h-4" />
                            <span>:</span>
                            <LinkedMediaList
                                linkedMedia={document.linkedMedia}
                                documentId={id}
                            />
                        </div>
                        <div className="flex items-center gap-2">
                            <span>CRM</span>
                            <Link className="w-4 h-4" />
                            <span>:</span>
                            <span id="link-crm-resource-button">
                                <ItemResourceMappingList
                                    itemInfo={{
                                        itemType: "document",
                                        itemId: id,
                                    }}
                                    resourceMappingRelations={
                                        document.documentResourceMappingRelations
                                    }
                                />
                            </span>
                        </div>
                        <span className="inline-flex justify-between items-center">
                            <h1 className="text-xl tracking-wide font-medium">
                                <span>{document.title}</span>
                            </h1>
                        </span>
                        <div className="overflow-y-auto" id="document-editor">
                            {yjsDoc && <TiptapEditor document={yjsDoc} />}
                        </div>
                    </div>
                </>
            ),
        };
    }, [document, yjsDoc, id]);

    useEffect(() => {
        if (id) {
            posthog?.capture("$pageview", {
                pageName: "/transcript/document",
            });
        }
    }, [id]);

    if (isLoading || rawDataLoading) {
        return <LoadingSpinnerCenter />;
    }

    return <DocumentRoot {...documentProps} />;
};

export { Document, SharedDocument };
