import { Button } from "@component-ui/utility/Button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@component-ui/utility/Dialog";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import { Share2 } from "lucide-react";
import { FC, useEffect } from "react";
import ShareableLinkForm from "./ShareableLinkForm";
import { useParams, useSearchParams } from "react-router-dom";
import useMediaTranscriptQuery from "@hooks/useTranscriptQuery";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import useDocumentQuery from "@hooks/document/useDocumentQuery";
import { SEARCH_PARAMS } from "@constant/searchParams";
import { usePostHog } from "posthog-js/react";

type PathParam = {
    meetingId: string;
    mediaId: string;
};

const ShareDialogCapture: FC = () => {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("$pageview", { pageName: "/transcript/share/new" });
    }, []);

    return null;
};

const ShareableButton: FC = () => {
    const { mediaId } = useParams<PathParam>();
    const [search] = useSearchParams();
    const {
        data: transcript,
        isLoading: isMediaLoading,
        isSuccess: isMediaSuccess,
        isError: isMediaError,
    } = useMediaTranscriptQuery(mediaId);
    const { data: document } = useDocumentQuery(
        search.get(SEARCH_PARAMS.CURRENT_DOCUMENT),
    );

    if (isMediaLoading) {
        return (
            <div>
                <LoadingSpinnerCenter />
            </div>
        );
    }

    if (!isMediaSuccess || isMediaError) {
        return null;
    }

    return (
        <Dialog>
            <TooltipProvider delayDuration={300}>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <DialogTrigger asChild>
                            <Button variant="ghost" size="icon">
                                <Share2 className="w-4 h-4" />
                            </Button>
                        </DialogTrigger>
                    </TooltipTrigger>
                    <TooltipContent>Share the transcript</TooltipContent>
                </Tooltip>
            </TooltipProvider>
            <DialogContent
                className="max-w-xl"
                onCloseAutoFocus={(e) => e.preventDefault()}
                onPointerDownOutside={(e) => e.preventDefault()}
            >
                <DialogHeader>
                    <DialogTitle>
                        Share Transcript for{" "}
                        <span>{transcript.meeting.label}</span>
                        <ShareDialogCapture />
                    </DialogTitle>
                    <DialogDescription>
                        Please add an email to invite them to view the
                        transcript and document
                    </DialogDescription>
                    <DialogDescription>
                        Current Document:{" "}
                        {document ? document.title : "Not selected"}
                    </DialogDescription>
                    <ShareableLinkForm />
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
};

export default ShareableButton;
