import TenantUserAvatar from "@component-func/TenantUserAvatar";
import { TiptapEditor } from "@component-func/tiptap/TiptapEditor";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import { useGetDocumentTemplateQuery } from "@hooks/template/useGetDocumentTemplateQuery";
import { humanizeDate } from "@libs/duration";
import { ChevronLeft } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { FC, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import UpdateDocumentTemplateDialog from "./UpdateDocumentTemplateDialog";
import ItemCustomerTagList from "@component-func/customer-tag/ItemCustomerTagList";
import useDocumentRawDataQuery from "@hooks/document/useDocumentRawDataQuery";

type PathParams = {
    documentTemplateId: string;
};

export const DocumentTemplateDetail: FC = () => {
    const { documentTemplateId } = useParams<PathParams>();
    const {
        data: documentTemplate,
        isLoading,
        isError,
        isSuccess,
    } = useGetDocumentTemplateQuery(documentTemplateId);

    const { isLoading: rawDataIsLoading, yjsDoc } = useDocumentRawDataQuery(
        documentTemplateId,
        "documentTemplate",
    );
    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture("document_template_detail_page_navigated");
        posthog?.capture("$pageview", { pageName: "/template" });
    }, [documentTemplateId]);

    if (!documentTemplateId) {
        return <Navigate to="/document-templates" />;
    }

    if (isLoading || rawDataIsLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError || !documentTemplate) {
        return (
            <div className="h-full grid place-items-center ">
                <h1 className="text-4xl text-foreground/50">
                    Cannot get the document template, please try again
                </h1>
            </div>
        );
    }

    return (
        <div className="h-full grid grid-rows-[max-content,max-content,1fr] gap-2 py-8 px-4">
            <Button size={"icon"} variant={"ghost"}>
                <Link to={`..`} relative="path">
                    <ChevronLeft />
                </Link>
            </Button>
            <div className="max-w-screen-md w-full mx-auto p-2">
                <div className="flex gap-2 items-center">
                    <h1 className="text-2xl font-bold">
                        {documentTemplate.title}
                    </h1>
                    <UpdateDocumentTemplateDialog
                        documentTemplate={documentTemplate}
                    />
                </div>
                <div className="flex gap-2 items-center my-2">
                    Tags:
                    <ItemCustomerTagList
                        itemInfo={{
                            itemType: "document-template",
                            itemId: documentTemplate.id,
                        }}
                        customerTags={documentTemplate.customerTags}
                    />
                </div>
                <div className="mt-4 w-full text-xs truncate">
                    <span className="flex items-center">
                        Created by{" "}
                        <TenantUserAvatar
                            tenantUser={documentTemplate.createdByTenantUser}
                            className="h-6 w-6"
                        />{" "}
                        at {humanizeDate(documentTemplate.createdAt)}
                    </span>
                </div>
            </div>
            <div className="max-w-screen-md w-full bg-slate-50 mx-auto overflow-y-auto px-4 py-2">
                {yjsDoc && <TiptapEditor document={yjsDoc} />}
            </div>
        </div>
    );
};
