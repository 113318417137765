import { CloserQueryKey } from "./query-key";

export const PUSHER_EVENT = {
    INVALIDATE_QUERY: "invalidate-query",
} as const;

export type PUSHER_EVENT_DATA = {
    INVALIDATE_QUERY: {
        queryKey: [CloserQueryKey, ...string[]];
        exact: boolean
    };
};
