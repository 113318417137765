export async function getAudioStream(id: string) {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: { deviceId: id },
        });
        return stream;
    } catch (err) {
        throw new Error(`Cannot get stream with device id: ${id}`);
    }
}

export async function getCloserAudioStream() {
    const closerAudioDeviceId = (
        await navigator.mediaDevices.enumerateDevices()
    ).find((d) => d.label.includes("CloserAudio"));
    if (!closerAudioDeviceId) {
        throw new Error("Cannot get closer audio input device");
    }
    return getAudioStream(closerAudioDeviceId.deviceId);
}

export function mergeStream(ctx: AudioContext, streams: MediaStream[]) {
    const mergeNode = ctx.createChannelMerger(streams.length);
    const outputNode = ctx.createMediaStreamDestination();
    for (const stream of streams) {
        const node = ctx.createMediaStreamSource(stream);
        for (let i = 0; i < node.numberOfOutputs; i++) {
            node.connect(mergeNode, 0, i);
        }
    }
    mergeNode.connect(outputNode);
    return outputNode;
}
