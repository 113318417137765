import { FORM_KEY } from "@constant/form";
import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useResourcePermissionSendEmail from "./useResourcePermissionSendEmail";

function validateForm(formData: FormData) {
    const email = formData.get(FORM_KEY.SHAREABLE.EMAIL) as string;
    if (!email) throw new Error("Invalid email, please input a correct email");
    const meetingId = formData.get(FORM_KEY.SHAREABLE.MEETING_ID) as string;
    if (!meetingId)
        throw new Error("Invalid meeting, please select a correct meeting");
    const mediaId = formData.get(FORM_KEY.SHAREABLE.MEDIA_ID) as string;
    if (!mediaId)
        throw new Error("Invalid media, please select a correct media");
    const shareDocument =
        formData.get(FORM_KEY.SHAREABLE.SHARE_DOCUMENT) === "true";
    const documentId = formData.get(FORM_KEY.SHAREABLE.DOCUMENT_ID) as string;
    const sendWithEmail =
        formData.get(FORM_KEY.SHAREABLE.SEND_WITH_EMAIL) === "true";

    const body = {
        shareResources: [
            {
                resourceType: "Meeting",
                resourceId: meetingId,
            },
            {
                resourceType: "Media",
                resourceId: mediaId,
            },
        ],
        email,
    };
    if (shareDocument) {
        if (!documentId) {
            throw new Error("You have not select the document");
        }
        body.shareResources.push({
            resourceType: "Document",
            resourceId: documentId,
        });
    }
    return [body, sendWithEmail, meetingId, mediaId, documentId] as const;
}

export function useCreateSharePermissionMutate() {
    const { push } = usePopupNotification();
    const queryClient = useQueryClient();
    const sendEmail = useResourcePermissionSendEmail();
    const mutate = useMutation({
        mutationKey: [M.CREATE_SHARE_PERMISSION],
        mutationFn: async (formData: FormData) => {
            const [body, sendWithEmail, meetingId, mediaId, documentId] =
                validateForm(formData);
            const { data } = await api.post<{
                url: string;
                userType: "TenantUser" | "Guest";
                userId: string;
            }>("/share/link", body);
            if (sendWithEmail) {
                const emailReqBody: Record<string, string> = {
                    meetingId,
                    mediaId,
                };
                if (documentId) {
                    emailReqBody["documentId"] = documentId;
                }
                await sendEmail({
                    userId: data.userId,
                    userType: data.userType,
                    ...emailReqBody,
                });
            }
            return data;
        },
        onSuccess: (_, inputForm) => {
            const meetingId = inputForm.get(
                FORM_KEY.SHAREABLE.MEETING_ID,
            ) as string;
            const mediaId = inputForm.get(
                FORM_KEY.SHAREABLE.MEDIA_ID,
            ) as string;
            queryClient.invalidateQueries({
                queryKey: [Q.GET_RESOURCE_PERMISSION, meetingId, mediaId],
            });
        },
        onError: (error) => {
            push({
                title: "Invalid input",
                description: error.message,
                type: "destructive",
            });
        },
    });
    return mutate;
}
