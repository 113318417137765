import { ZoomParticipant } from "@hooks/zoom/useGetMeetingParticipantsQuery";
import { FC, useCallback } from "react";
import { ParticipantItem } from "./ParticipantItem";
import { GoogleMeetParticipant } from "@hooks/google-meet/useGetGoogleMeetParticipantsQuery";

type ParticipantListProps =
    | ZoomParticipantListProps
    | GoogleMeetParticipantListProps;

interface ZoomParticipantListProps {
    type: "zoom";
    participants: Array<ZoomParticipant>;
}
interface GoogleMeetParticipantListProps {
    type: "google-meet";
    participants: Array<GoogleMeetParticipant>;
}

export const ParticipantList: FC<ParticipantListProps> = ({
    type,
    participants,
}) => {
    const participantGetKey = useCallback(
        (participant: ZoomParticipant | GoogleMeetParticipant) => {
            if (type === "google-meet") {
                return (participant as GoogleMeetParticipant).name;
            }

            if (type === "zoom") {
                return (participant as ZoomParticipant).user_id;
            }

            return "";
        },
        [type],
    );

    return (
        <div className="flex flex-wrap gap-2">
            {participants.map((participant) =>
                type === "google-meet" ? (
                    <ParticipantItem
                        type={type}
                        key={participantGetKey(participant)}
                        participant={participant as GoogleMeetParticipant}
                    />
                ) : (
                    <ParticipantItem
                        type={type}
                        key={participantGetKey(participant)}
                        participant={participant as ZoomParticipant}
                    />
                ),
            )}
        </div>
    );
};
