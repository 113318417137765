import { Checkbox } from "@component-ui/utility/Checkbox";
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { FC, useEffect, useMemo, useState } from "react";
import TranscriptLinkButton from "../TranscriptLinkButton";
import { TranscriptionSegment } from "types/backend/media";
import { usePostHog } from "posthog-js/react";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@component-ui/utility/HoverCard";
import SharedBlockTranscriptReference from "./SharedBlockTranscriptReference";
import SharedTimestamp from "./SharedTimestamp";
import { Mark } from "@tiptap/pm/model";
import { Thread } from "@libs/tiptap/Thread";
import Threads from "../thread/Threads";
import { useLocation } from "react-router-dom";
import { getUserType } from "@libs/utils";
import { createPortal } from "react-dom";

const SharedTaskItem: FC<NodeViewProps> = (props) => {
    const { node, updateAttributes } = props;
    const transcriptSegment = node.attrs[
        "data-segment"
    ] as TranscriptionSegment;
    const timestamp = node.attrs["data-timestamp"];

    const location = useLocation();

    const posthogCaptured = node.attrs["data-posthog-captured"] as boolean;
    const posthog = usePostHog();
    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    const marks = useMemo(() => {
        const marks: Mark[] = [];
        const fstChild = node.firstChild;
        if (!fstChild) return [];
        fstChild.descendants((node) => {
            const m = node.marks.find((mark) => mark.type.name === Thread.name);
            if (!m) return;
            marks.push(m);
        });
        return marks;
    }, [node]);

    useEffect(() => {
        if (!posthogCaptured) {
            setTimeout(() => {
                posthog?.capture("task_item_created");
                updateAttributes({ "data-posthog-captured": true });
            }, 0);
        }
    }, []);

    const toggleCheck = (checked: boolean) => {
        if (checked) {
            updateAttributes({
                checked: true,
            });
        } else {
            updateAttributes({
                checked: false,
            });
        }
    };

    return (
        <NodeViewWrapper
            as="li"
            data-type="taskItem"
            key={node.attrs["data-id"]}
        >
            <div className="py-1 grid grid-cols-[max-content,1fr]">
                <div
                    className={`flex ${timestamp ? "items-center" : "items-start"} justify-start`}
                >
                    <HoverCard openDelay={300} closeDelay={100}>
                        <HoverCardTrigger>
                            <Checkbox
                                checked={node.attrs.checked}
                                onCheckedChange={toggleCheck}
                                className="text-3xl grow-0"
                                tabIndex={-1}
                            />
                        </HoverCardTrigger>
                        <HoverCardContent align="center" className="w-fit p-0">
                            <TranscriptLinkButton
                                updateAttributes={updateAttributes}
                            />
                        </HoverCardContent>
                    </HoverCard>
                </div>
                <div className="grid">
                    <SharedTimestamp {...props} />
                    {transcriptSegment && (
                        <div className="max-w-full min-w-0 ml-1">
                            <SharedBlockTranscriptReference
                                transcriptSegment={transcriptSegment}
                            />
                        </div>
                    )}
                    <div ref={(ref) => setContainer(ref)}>
                        <NodeViewContent className="ml-1 break-words min-w-0 w-full relative" />
                    </div>
                    {container &&
                        createPortal(
                            <Threads
                                marks={marks}
                                userType={getUserType(location)}
                                container={container}
                                editor={props.editor}
                            />,
                            document.getElementById("comments"),
                        )}
                </div>
            </div>
        </NodeViewWrapper>
    );
};

export default SharedTaskItem;
