import { M } from "@constant/mutation-key";
import api, { guestApi } from "@libs/api";
import { MutationKey, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { UserType } from "types/backend";
import { CreateThreadBody, Thread } from "types/backend/thread";

export default function useCreateThreadMutate(userType: UserType) {
    const mutationKey: MutationKey = [M.CREATE_THREAD, userType];
    const mutate = useMutation({
        mutationKey,
        mutationFn: async (body: CreateThreadBody) => {
            const { documentId, ...rest } = body;
            let response: AxiosResponse<Thread>;
            switch (userType) {
                case "tenant-user":
                    response = await api.post<Thread>(
                        `/documents/${documentId}/threads`,
                        rest,
                    );
                    break;
                case "shared-user":
                    response = await api.post<Thread>(
                        `/documents/share/${documentId}/threads`,
                        rest,
                    );
                    break;
                case "guest":
                    response = await guestApi.post<Thread>(
                        `/documents/${documentId}/threads`,
                        rest,
                    );
                    break;
            }
            return response.data;
        },
    });

    return mutate;
}
