import { Button } from "@component-ui/utility/Button";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import { cn } from "@libs/utils";
import { Check, Copy } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import React, {
    ComponentPropsWithoutRef,
    ElementRef,
    FC,
    useState,
} from "react";

type CopyButtonProps = {
    data: string;
};

const CopyButton = React.forwardRef<
    ElementRef<"button">,
    ComponentPropsWithoutRef<"button"> & CopyButtonProps
>(({ data, className, onMouseEnter }, ref) => {
    const [isCopied, setIsCopied] = useState(false);
    const posthog = usePostHog();

    return (
        <Button
            ref={ref}
            size="icon"
            variant="ghost"
            className={className}
            onClick={async () => {
                await navigator.clipboard.writeText(data);
                setIsCopied(true);
                posthog?.capture("summary_copied");
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            }}
            onMouseEnter={onMouseEnter}
            disabled={isCopied}
        >
            {isCopied ? (
                <Check className={cn("w-4 h-4")} />
            ) : (
                <Copy className={cn("w-4 h-4")} />
            )}
        </Button>
    );
});

CopyButton.displayName = Button.displayName;

const CopyButtonWithTooltip: FC<
    CopyButtonProps & ComponentPropsWithoutRef<"button">
> = ({ data, className, onMouseEnter }) => {
    return (
        <TooltipProvider delayDuration={300} disableHoverableContent>
            <Tooltip>
                <TooltipTrigger asChild>
                    <CopyButton
                        data={data}
                        className={className}
                        onMouseEnter={onMouseEnter}
                    />
                </TooltipTrigger>
                <TooltipContent>
                    <p>Copy to clipboard</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export { CopyButton, CopyButtonWithTooltip };
