import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import {
    Command,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandEmpty,
} from "@component-ui/utility/Command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@component-ui/utility/Popover";
import { SEARCH_PARAMS } from "@constant/searchParams";
import useAllDocumentQuery from "@hooks/document/useAllDocumentQuery";
import { cn } from "@libs/utils";
import { CommandLoading } from "cmdk";
import { Check, ChevronsUpDown, Plus } from "lucide-react";
import { FC, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Document } from "types/backend/document";
import CreateDocumentDialog from "./CreateDocumentDialog";
import useDocumentQuery from "@hooks/document/useDocumentQuery";
import { useCurrentDocumentStore } from "@store/currentDocumentStore";
import { usePostHog } from "posthog-js/react";
import useAllSharedDocumentQuery from "@hooks/share-to-me/useAllSharedDocumentQuery";
import useGetSharedDocumentQuery from "@hooks/share-to-me/useGetSharedDocumentQuery";
import { Tour } from "@frigade/react";
import { FRIGADE_FLOW_ID } from "@constant/frigade-flows";

const DocumentComboBox: FC = () => {
    const [open, setOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [search, setSearchParams] = useSearchParams();
    const { data: currentDocument } = useDocumentQuery(
        search.get(SEARCH_PARAMS.CURRENT_DOCUMENT),
    );

    // INFO: Later usage for search document
    // const [searchValue, setSearchValue] = useState("");
    // const debouncedValue = useDebounce(searchValue, 1000);

    const {
        data: documentList,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        refetch,
    } = useAllDocumentQuery();
    const updateCurrentDocumentId = useCurrentDocumentStore(
        (state) => state.update,
    );
    const posthog = usePostHog();
    const { meetingId, mediaId } = useParams();

    const documentMap = useMemo<Map<string, Document>>(() => {
        if (documentList) {
            return new Map(
                documentList.map((document) => [document.id, document]),
            );
        } else {
            return new Map();
        }
    }, [documentList]);

    function handleDocumentChange(document: Document) {
        updateCurrentDocumentId(document.id);
        setSearchParams((prev) => {
            prev.set(SEARCH_PARAMS.CURRENT_DOCUMENT, document.id);
            return prev;
        });
    }

    function handleSelectDocument(document: Document) {
        setOpen(false);
        handleDocumentChange(document);
        posthog?.capture("document_selected");
        // TODO: Invalidate query client for document sections
    }

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return (
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        className="w-full max-w-[200px] justify-between"
                        onClick={() => refetch()}
                    >
                        Click to retry
                    </Button>
                </PopoverTrigger>
            </Popover>
        );
    }

    return (
        <>
            {documentList[0] && (
                <Tour
                    flowId={FRIGADE_FLOW_ID.REVIEW_A_TRANSCRIPT}
                    variables={{
                        documentId: documentList[0].id,
                        meetingId,
                        mediaId,
                        mediaKey: search.get(SEARCH_PARAMS.MEDIA_TARGET_KEY),
                        mediaType: search.get(SEARCH_PARAMS.MIMETYPE),
                    }}
                />
            )}
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className="w-full justify-between overflow-hidden"
                        id="document-combobox"
                    >
                        <span className="truncate">
                            {currentDocument
                                ? currentDocument.title
                                : "Select document"}
                        </span>
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0" align="end">
                    <Command
                        label="Document menu"
                        className="w-[240px]"
                        filter={(value, search) => {
                            const document = documentMap.get(value);
                            if (document) {
                                if (document.title.toLowerCase().includes(search.toLowerCase())) return 1;
                            } else {
                                if (value.includes(search.toLowerCase()))
                                    return 1;
                            }
                            return 0;
                        }}
                    >
                        <CommandInput
                            className="focus:outline-none"
                            placeholder="Search document..."
                        />
                        <CommandEmpty>No result found...</CommandEmpty>
                        <CommandList className="overflow-auto max-h-[320px]">
                            <CommandGroup heading="misc">
                                <CommandItem
                                    onSelect={() => setDialogOpen(true)}
                                    value="Create New Document"
                                >
                                    <Plus className="mr-2 h-4 w-4" />
                                    Create new document
                                </CommandItem>
                            </CommandGroup>
                            <CommandGroup heading="document">
                                {isFetching && (
                                    <CommandLoading>
                                        <div className="h-8">
                                            <LoadingSpinnerCenter size="sm" />
                                        </div>
                                    </CommandLoading>
                                )}
                                {documentList.map((document) => (
                                    <CommandItem
                                        key={document.id}
                                        value={document.id}
                                        onSelect={() =>
                                            handleSelectDocument(document)
                                        }
                                    >
                                        <Check
                                            className={cn(
                                                "mr-2 h-4 w-4",
                                                currentDocument &&
                                                    currentDocument.id ===
                                                        document.id
                                                    ? "opacity-100"
                                                    : "opacity-0",
                                            )}
                                        />
                                        {document.title}
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
            <CreateDocumentDialog open={dialogOpen} setOpen={setDialogOpen} />
        </>
    );
};

const SharedDocumentComboBox = () => {
    const [open, setOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [search, setSearchParams] = useSearchParams();
    const { data: currentDocument } = useGetSharedDocumentQuery(
        search.get(SEARCH_PARAMS.CURRENT_DOCUMENT),
        "shared-user",
    );

    // INFO: Later usage for search document
    // const [searchValue, setSearchValue] = useState("");
    // const debouncedValue = useDebounce(searchValue, 1000);

    const {
        data: documentList,
        isFetching,
        isSuccess,
        isError,
        refetch,
    } = useAllSharedDocumentQuery();
    const updateCurrentDocumentId = useCurrentDocumentStore(
        (state) => state.update,
    );

    const documentMap = useMemo<Map<string, Document>>(() => {
        if (documentList) {
            return new Map(
                documentList.map((document) => [document.id, document]),
            );
        } else {
            return new Map();
        }
    }, [documentList]);

    function handleDocumentChange(document: Document) {
        updateCurrentDocumentId(document.id);
        setSearchParams((prev) => {
            prev.set(SEARCH_PARAMS.CURRENT_DOCUMENT, document.id);
            return prev;
        });
    }

    function handleSelectDocument(document: Document) {
        setOpen(false);
        handleDocumentChange(document);
        // TODO: Invalidate query client for document sections
    }

    if (!isSuccess || isError) {
        return (
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        className="w-full max-w-[200px] justify-between"
                        onClick={() => refetch()}
                    >
                        Click to retry
                    </Button>
                </PopoverTrigger>
            </Popover>
        );
    }

    return (
        <>
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className="w-full justify-between overflow-hidden"
                    >
                        <span className="truncate">
                            {currentDocument
                                ? currentDocument.title
                                : "Select shared document"}
                        </span>
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0" align="end">
                    <Command
                        label="Document menu"
                        className="w-[240px]"
                        filter={(value, search) => {
                            const document = documentMap.get(value);
                            if (document) {
                                if (document.title.toLowerCase().includes(search.toLowerCase())) return 1;
                            } else {
                                if (value.includes(search.toLowerCase()))
                                    return 1;
                            }
                            return 0;
                        }}
                    >
                        <CommandInput
                            className="focus:outline-none"
                            placeholder="Search document..."
                        />
                        <CommandEmpty>No result found...</CommandEmpty>
                        <CommandList className="overflow-auto max-h-[320px]">
                            <CommandGroup heading="document">
                                {isFetching && (
                                    <CommandLoading>
                                        <div className="h-8">
                                            <LoadingSpinnerCenter size="sm" />
                                        </div>
                                    </CommandLoading>
                                )}
                                {documentList.map((document) => (
                                    <CommandItem
                                        key={document.id}
                                        value={document.id}
                                        onSelect={() =>
                                            handleSelectDocument(document)
                                        }
                                    >
                                        <Check
                                            className={cn(
                                                "mr-2 h-4 w-4",
                                                currentDocument &&
                                                    currentDocument.id ===
                                                        document.id
                                                    ? "opacity-100"
                                                    : "opacity-0",
                                            )}
                                        />
                                        {document.title}
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
            <CreateDocumentDialog open={dialogOpen} setOpen={setDialogOpen} />
        </>
    );
};

export { DocumentComboBox, SharedDocumentComboBox };
