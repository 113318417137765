import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import calendar from "dayjs/plugin/calendar";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(duration);
dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
    relativeTime: {
        past: "%s",
        s: "a few seconds",
        m: "a minute",
        mm: "%dm",
        h: "an hour",
        hh: "%dh",
        d: "a day",
        dd: "%dd",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%dy",
    },
});

export default dayjs;
