import { create } from "zustand";

type TranscripInteractStore = {
    transcriptionSegmentContainer: HTMLElement | null;
    setTranscriptionSegmentContainer: (el: HTMLElement) => void;
};

export const useTranscriptionInteractStore = create<TranscripInteractStore>(
    (set) => ({
        transcriptionSegmentContainer: null,
        setTranscriptionSegmentContainer: (el: HTMLElement) =>
            set(() => ({
                transcriptionSegmentContainer: el,
            })),
    }),
);
