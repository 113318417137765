import { isEmpty } from "lodash";
import { Button } from "@component-ui/utility/Button";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";
import { Loader2 } from "lucide-react";
import { FC, FormEvent, useCallback, useState } from "react";
import useForgotPassword from "@hooks/user/useForgotPassword";
import { usePopupNotification } from "@context/popup-notification";

interface InputError {
    email: null | string;
}

const ForgotPassword: FC = () => {
    const { mutate: forgotPassword, isPending } = useForgotPassword();
    const { push } = usePopupNotification();
    const [err, setError] = useState<InputError>({
        email: null,
    });

    const handleResetPassword = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            try {
                const formData = new FormData(e.currentTarget);
                const email = formData.get("email") as string;

                const updated: InputError = {
                    email: isEmpty(email) ? "Please enter email" : err.email,
                };
                const passValidation: boolean = Object.values(updated).reduce(
                    (p, c) => {
                        if (!isEmpty(c)) {
                            return false;
                        }
                        return p;
                    },
                    true,
                );

                if (passValidation) {
                    forgotPassword(
                        { email },
                        {
                            onSuccess: () => {
                                push({
                                    type: "success",
                                    title: "Email sent",
                                    description:
                                        "Reset password email has been sent. Please check your email.",
                                });
                            },
                            onError: (e) => {
                                const status = e.response?.status;

                                if (status === 404) {
                                    push({
                                        type: "destructive",
                                        title: "Email not found",
                                        description:
                                            "Please check email is correct",
                                    });
                                }
                            },
                        },
                    );
                } else {
                    setError(updated);
                }
            } finally {
                e.currentTarget.reset();
            }
        },
        [err],
    );

    const checkIsEmpty = useCallback(
        (type: keyof InputError, value: string | null | undefined) => {
            const _isEmpty = isEmpty(value);
            switch (type) {
                case "email":
                    if (_isEmpty) {
                        setError({ email: "Please enter password" });
                    } else {
                        setError({ email: null });
                    }
                    break;
            }
        },
        [err],
    );

    return (
        <div className="w-full h-full grid place-items-center">
            <div className="w-full max-w-lg flex flex-col justify-center items-center p-4 rounded-md border-2 border-gray-400">
                <h1 className="text-2xl">Forgot Your Password?</h1>
                <h1 className="text-sm text-gray-400">
                    We get it, stuff happens. Just enter your email address
                    below and we will send you a link to reset your password!
                </h1>
                <form className="w-full" onSubmit={handleResetPassword}>
                    <Label className="mb-10" htmlFor="email">
                        Email
                    </Label>
                    <Input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter email"
                        onChange={(e) => {
                            checkIsEmpty("email", e.currentTarget.value);
                        }}
                        onBlur={(e) =>
                            checkIsEmpty("email", e.currentTarget.value)
                        }
                    />
                    {err.email && (
                        <div className="my-1">
                            <span className="text-rose-600 text-xs">
                                {err.email}
                            </span>
                        </div>
                    )}
                    <Button className="w-full mt-4" type="submit">
                        {isPending && (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Confirm
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
