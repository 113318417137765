import Empty from "@component-ui/Empty";
import { Badge } from "@component-ui/utility/Badge";
import { Button } from "@component-ui/utility/Button";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import useUntaggingMutate from "@hooks/customer-tag/useUntaggingMutate";
import { cn, wholeStringToColour } from "@libs/utils";
import { Loader2, X } from "lucide-react";
import { FC, HTMLAttributes } from "react";
import { CustomerTag, CustomerTagItemInfo } from "types/backend/customer_tags";

type CustomerTagBadgeProps = {
    customerTag: CustomerTag;
    itemInfo?: CustomerTagItemInfo;
    className?: HTMLAttributes<"div">["className"];
};
type UntagButtonProps = {
    customerTagId: string;
    itemInfo: CustomerTagItemInfo;
};

const UntagButton: FC<UntagButtonProps> = ({ customerTagId, itemInfo }) => {
    const { mutate: untag, isPending } = useUntaggingMutate(itemInfo.itemType);

    return (
        <Button
            variant={"ghost"}
            size={"xsIcon"}
            className="flex items-center justify-center ml-1"
            disabled={isPending}
            onClick={(event) => {
                event.stopPropagation();
                untag({ customerTagId, itemId: itemInfo.itemId });
            }}
        >
            {isPending ? (
                <Loader2 className="w-3 h-3 animate-spin" />
            ) : (
                <X className="w-3 h-3" />
            )}
        </Button>
    );
};

const CustomerTagBadge: FC<CustomerTagBadgeProps> = ({
    customerTag,
    itemInfo,
    className = "",
}) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        variant={"noHover"}
                        className={cn(
                            "px-2 font-normal text-gray-800 cursor-default w-fit text-nowrap",
                            className,
                            itemInfo && "h-6 pr-1",
                        )}
                        style={{
                            background:
                                wholeStringToColour(customerTag.id) + "4D",
                        }}
                    >
                        {customerTag.name}
                        {itemInfo && (
                            <UntagButton
                                customerTagId={customerTag.id}
                                itemInfo={itemInfo}
                            />
                        )}
                    </Badge>
                </TooltipTrigger>
                <TooltipContent side="bottom" className="max-w-[300px]">
                    {customerTag.description ? (
                        <div>{customerTag.description}</div>
                    ) : (
                        <Empty label="No description" />
                    )}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export default CustomerTagBadge;
