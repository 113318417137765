export const FRIGADE_FLOW_ID = {
    // INFO: ONBOARD TOUR
    OPEN_UPLOAD_A_RECORDING: "flow_O1JM5oeC",
    REVIEW_A_TRANSCRIPT: "flow_3TNiIWpI",
    CREATE_A_SUMMARY: "flow_NGYdv0If",
    INTEGRATE_CRM: "flow_99lI2KP6",
    HELP_BUTTON: "flow_cC58P2ZK",

    // HINTS
    TRANSCRIPT_PAGE_HINTS: "flow_6ZPNghl0",
    TAGGING: "flow_L01RDpCE",
    CREATE_DOCUMENT_TEMPLATE: "flow_aeEvetol",
    RECORDING_BUTTON: "flow_f6wvxxSL",
} as const;
