import { Badge } from "@component-ui/utility/Badge";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@component-ui/utility/HoverCard";
import { formatDuration } from "@libs/duration";
import { NodeViewProps } from "@tiptap/core";
import { FC, useEffect, useRef } from "react";
import { useShallow } from "zustand/react/shallow";
import MediaInfo from "../MediaInfo";
import { useGetMediaQuery } from "@hooks/media/useGetMediaQuery";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import Empty from "@component-ui/Empty";
import { useAudioRecorderStore } from "@store/audio-recorder-store";

const SharedTimestamp: FC<NodeViewProps> = ({ node, updateAttributes }) => {
    const timestamp = node.attrs["data-timestamp"];
    const recordTimestamp = useAudioRecorderStore(
        useShallow((state) => useRef(state.time)),
    );

    const mediaId = useAudioRecorderStore(
        useShallow((state) => useRef(state.mediaId)),
    );

    const {
        data: media,
        isLoading,
        isSuccess,
        isError,
    } = useGetMediaQuery(node.attrs["data-media-id"]);

    function initTimestamp() {
        if (!timestamp && recordTimestamp.current > 0) {
            updateAttributes({
                "data-media-id": mediaId.current,
                "data-timestamp": recordTimestamp.current,
            });
        }
    }

    // INFO: prevent flushSync call in useEffect, enqueue the task into the micortask queue
    useEffect(() => {
        setTimeout(() => initTimestamp());
    }, []);

    return (
        timestamp && (
            <div className="ml-1 max-w-full">
                <HoverCard openDelay={300} closeDelay={100}>
                    <HoverCardTrigger asChild>
                        <Badge
                            className="text-xs cursor-pointer"
                            variant="secondary"
                        >
                            {formatDuration(timestamp)}
                        </Badge>
                    </HoverCardTrigger>
                    <HoverCardContent align="end" className="max-w-72">
                        {isLoading && <LoadingSpinnerCenter />}
                        {isError && <Empty label="No information" />}
                        {isSuccess && <MediaInfo media={media} />}
                    </HoverCardContent>
                </HoverCard>
            </div>
        )
    );
};

export default SharedTimestamp;
