import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { GoogleCalendarsResponse } from "types/backend/google_calendar";

export function useGoogleCalendarsQuery() {
    const query = useQuery({
        queryKey: [Q.GET_GOOGLE_CALENDARS],
        queryFn: async () => {
            const { data } = await api.get<GoogleCalendarsResponse>(
                "google-calendar/calendars",
            );
            return data;
        },
        staleTime: 30 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
        retry: 0,
    });
    return query;
}
