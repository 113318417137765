import { FORM_KEY } from "@constant/form";
import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";

function validateFormData(formData: FormData) {
    const label = formData.get(FORM_KEY.SUMMARIZATION.LABEL) as string;
    const systemMessage = formData.get(
        FORM_KEY.SUMMARIZATION.SYSTEM_MESSAGE,
    ) as string;
    const slackWebhookUrl = formData.get(
        FORM_KEY.SUMMARIZATION.SLACK_WEBHOOK_URL,
    ) as string;
    const autoTrigger = formData.get(
        FORM_KEY.SUMMARIZATION.AUTO_TRIGGER,
    ) as string;

    if (slackWebhookUrl) {
        if (
            !/^https:\/\/hooks.slack.com\/services\/.*$/.test(slackWebhookUrl)
        ) {
            throw new Error("Slack url format is not correct");
        }
    }

    if (!label || !systemMessage) {
        throw new Error("Invalid input for label or prompt");
    }

    return {
        label,
        systemMessage,
        slackWebhookUrl,
        autoTrigger: autoTrigger ? true : false,
    };
}

export default function useCreateSummarizingSystemMessageMutate() {
    const { push } = usePopupNotification();
    const queryClient = useQueryClient();
    const posthog = usePostHog();

    const mutate = useMutation({
        mutationKey: [M.CREATE_SUMMARIZING_SYSTEM_MESSAGE],
        mutationFn: async (formData: FormData) => {
            const body = validateFormData(formData);
            posthog?.capture("system_message_created");
            const { data } = await api.post(
                "/summarizing-system-messages",
                body,
            );
            return data;
        },
        onError: (error) => {
            push({
                title: "Cannot create summarizing prompt",
                description: error.message,
                type: "destructive",
            });
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [Q.GET_SUMMARIZE_SYSTEM_MESSAGES],
            });
        },
    });

    return mutate;
}
