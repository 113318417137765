import { LOCALSTORAGE } from "@constant/localstorage-key";
import React, { FC, createContext, useContext, useMemo, useState } from "react";

type AuthCtx = {
    isAuth: boolean;
    setIsAuth: React.Dispatch<React.SetStateAction<boolean>>;
    isActive: boolean;
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const AuthContext = createContext<AuthCtx | null>(null);

type AuthProviderProps = {
    children: React.ReactNode;
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [isAuth, setIsAuth] = useState(
        !!window.localStorage.getItem(LOCALSTORAGE.CLOSER_JWT),
    );

    const [isActive, setIsActive] = useState(
        window.localStorage.getItem(LOCALSTORAGE.CLOSER_ACCOUNT_ACTIVE)
            ? window.localStorage.getItem(
                  LOCALSTORAGE.CLOSER_ACCOUNT_ACTIVE,
              ) === "true"
                ? true
                : false
            : false,
    );

    const contextValue = useMemo<AuthCtx>(
        () => ({
            isAuth,
            setIsAuth,
            isActive,
            setIsActive,
        }),
        [isAuth, setIsAuth, isActive, setIsActive],
    );

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    const ctx = useContext(AuthContext);
    if (!ctx) {
        throw new Error("AuthProvider is not found");
    }
    return ctx;
}
