import { Button } from "@component-ui/utility/Button";
import { LOCALSTORAGE } from "@constant/localstorage-key";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const PreviewLayout = () => {
    const navigate = useNavigate();
    if (!localStorage.getItem(LOCALSTORAGE.CLOSER_GUEST_JWT))
        return <Navigate to="/guest/preview/error" />;

    return (
        <div className="h-full flex flex-col">
            <nav className="max-w-full shadow-sm flex justify-between items-center h-12">
                <div className="mx-4">
                    <img
                        className="w-24"
                        src="https://d1pnnwteuly8z3.cloudfront.net/images/30ba08ff-7870-4ce4-855e-cc07414e7915/b345644c-a350-44b9-bd80-4332b12161f5.png"
                        alt="Closer"
                    />
                </div>
                <Button
                    variant="link"
                    size="sm"
                    onClick={() => navigate("/sign_up")}
                >
                    signup
                </Button>
            </nav>
            <Outlet />
        </div>
    );
};

export default PreviewLayout;
