import { IconButtonWithTooltip } from "@component-ui/utility/Button";
import { Dialog } from "@component-ui/utility/Dialog";
import { FRIGADE_FLOW_ID } from "@constant/frigade-flows";
import { Tour } from "@frigade/react";
import { Mic } from "lucide-react";
import { useCallback, useRef, useState } from "react";
import PrerecordingDialogContent from "./PrerecordingDialogContent";
import { getAudioStream, getCloserAudioStream, mergeStream } from "@libs/audio";
import { useAudioRecorderStore } from "@store/audio-recorder-store";
import { usePopupNotification } from "@context/popup-notification";
import AudioRecorder from "./AudioRecorder";
import UploadRecordingDialog from "./UploadRecordingDialog";
import useAudioRecorder from "@hooks/recorder/useAudioRecorder";

const RecordingRoot = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const audioCtx = useRef<AudioContext>();
    const [inputDeviceId, setInputDeviceId] = useState("");
    const [useCloserAudio, setUseCloserAudio] = useState(false);
    const setMediaStream = useAudioRecorderStore.use.setMediaStream();
    const { startRecord, stopRecord, togglePause } = useAudioRecorder();
    const progress = useAudioRecorderStore.use.progress();
    const addSourceStream = useAudioRecorderStore.use.addSourceStream();
    const { push } = usePopupNotification();

    const setupStream = useCallback(async () => {
        if (!inputDeviceId) return;
        try {
            const userStream = await getAudioStream(inputDeviceId);
            addSourceStream(userStream);
            audioCtx.current = new AudioContext();
            let stream: MediaStream;
            if (useCloserAudio) {
                const closerAudioStream = await getCloserAudioStream();
                addSourceStream(closerAudioStream);
                const output = mergeStream(audioCtx.current, [
                    userStream,
                    closerAudioStream,
                ]);
                stream = output.stream;
            } else {
                const output = mergeStream(audioCtx.current, [userStream]);
                stream = output.stream;
            }
            return stream;
        } catch (err) {
            push({
                title: "Failed to start record",
                description: (err as Error).message,
                type: "destructive",
            });
        }
    }, [inputDeviceId, useCloserAudio, setMediaStream, push]);

    return (
        <div>
            <Tour
                flowId={FRIGADE_FLOW_ID.RECORDING_BUTTON}
                onClick={(e) => e.stopPropagation()}
            />
            {progress !== "recording" && (
                <IconButtonWithTooltip
                    variant="ghost"
                    size="icon"
                    content="Recording"
                    icon={<Mic className="w-4 h-4" />}
                    onClick={() => setDialogOpen(true)}
                />
            )}
            {progress === "recording" && (
                <AudioRecorder
                    stopRecord={stopRecord}
                    togglePause={togglePause}
                />
            )}
            {progress === "pending" && (
                <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                    <PrerecordingDialogContent
                        setInputDeviceId={setInputDeviceId}
                        setupStream={setupStream}
                        startRecord={startRecord}
                    />
                </Dialog>
            )}
            {progress === "upload" && (
                <UploadRecordingDialog setOuterDialogOpen={setDialogOpen} />
            )}
        </div>
    );
};

export default RecordingRoot;
