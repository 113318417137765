import { Button } from "@component-ui/utility/Button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@component-ui/utility/Dialog";
import { Input } from "@component-ui/utility/Input";
import { FORM_KEY } from "@constant/form";
import useSpeakerMapMutate from "@hooks/speaker/useSpeakerMapMutate";
import { Speaker } from "@hooks/speaker/useSpeakerMapQuery";
import { Label } from "@radix-ui/react-label";
import { usePostHog } from "posthog-js/react";
import { Dispatch, FC, FormEvent, SetStateAction } from "react";

type SpeakerRenamingDialogProps = {
    mediaId: string;
    speakerKey: string;
    speaker: Speaker | undefined;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const SpeakerRenamingDialog: FC<SpeakerRenamingDialogProps> = ({
    mediaId,
    speakerKey,
    speaker,
    open,
    setOpen,
}) => {
    const { mutate: renameSpeaker } = useSpeakerMapMutate();
    const posthog = usePostHog();
    if (!speaker) return null;

    function handleRenamingSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (!mediaId) return;
        posthog?.capture("renaming_clicked");
        const formData = new FormData(e.currentTarget);
        const newName = formData.get(
            FORM_KEY.SPEAKER_RENAMING.NEW_NAME,
        ) as string;
        if (!newName) return;
        renameSpeaker(
            { mediaId, speakerKey, newName },
            { onSuccess: () => setOpen(false) },
        );
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Change Speaker Name</DialogTitle>
                    <DialogDescription>
                        Assign the desire name for the speaker here. Click save
                        when you done
                    </DialogDescription>
                </DialogHeader>
                <form onSubmit={handleRenamingSubmit}>
                    <div className="grid gap-4 py-4 grid-flow-col">
                        <div>
                            <Label className="text-right">Current Name</Label>
                            <Input disabled defaultValue={speaker} />
                        </div>
                        <div>
                            <Label
                                htmlFor={FORM_KEY.SPEAKER_RENAMING.NEW_NAME}
                                className="text-right"
                            >
                                New Name
                            </Label>
                            <Input
                                id={FORM_KEY.SPEAKER_RENAMING.NEW_NAME}
                                name={FORM_KEY.SPEAKER_RENAMING.NEW_NAME}
                                placeholder="New name here..."
                            />
                        </div>
                    </div>
                    <DialogFooter>
                        <Button type="submit">Save changes</Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default SpeakerRenamingDialog;
