import { isEmpty } from "lodash";
import { Button } from "@component-ui/utility/Button";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";

import { Loader2 } from "lucide-react";
import React, { FC, FormEvent, useCallback, useRef, useState } from "react";
import useSignUp from "@hooks/user/useSignUp";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox } from "@component-ui/utility/Checkbox";

interface InputError {
    teamName: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    password: string | null;
    repeatPassword: string | null;
    acceptPolicy: string | null;
}

const SignUp: FC = () => {
    const { mutate: signUp, isPending } = useSignUp();
    const [signUpState, setSignUpState] = useState<"success" | "pending">(
        "pending",
    );

    const navigate = useNavigate();
    const passwordInput = useRef(null);
    const repeatPasswordInput = useRef(null);
    const [err, setError] = useState<InputError>({
        teamName: null,
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        repeatPassword: null,
        acceptPolicy: null,
    });

    const handleSignUp = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            try {
                const formData = new FormData(e.currentTarget);
                const email = formData.get("email") as string;
                const password = formData.get("password") as string;
                const firstName = formData.get("first_name") as string;
                const lastName = formData.get("last_name") as string;
                const teamName = formData.get("team_name") as string;
                const repeatPassword = formData.get(
                    "repeat_password",
                ) as string;
                const acceptPolicy = formData.get("accept_policy") as
                    | "on"
                    | null;

                const updated: InputError = {
                    teamName: isEmpty(teamName)
                        ? "Please enter team name"
                        : err.teamName,
                    firstName: isEmpty(firstName)
                        ? "Please enter first name"
                        : err.firstName,
                    lastName: isEmpty(lastName)
                        ? "Please enter last name"
                        : err.lastName,
                    email: isEmpty(email)
                        ? "Please enter first name"
                        : err.email,
                    password: isEmpty(password)
                        ? "Please enter password"
                        : err.password,
                    repeatPassword: isEmpty(repeatPassword)
                        ? "Please enter repeat password"
                        : err.repeatPassword,
                    acceptPolicy: isEmpty(acceptPolicy)
                        ? "required"
                        : err.acceptPolicy,
                };

                const passValidation: boolean = Object.values(updated).reduce(
                    (p, c) => {
                        if (!isEmpty(c)) {
                            return false;
                        }
                        return p;
                    },
                    true,
                );

                if (passValidation) {
                    signUp(
                        { email, password, firstName, lastName, teamName },
                        {
                            onSuccess: () => {
                                setSignUpState("success");
                            },
                        },
                    );
                } else {
                    setError(updated);
                }
            } catch (err) {
                //todo
            }
        },
        [err],
    );

    const repeatPasswordVerify = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.currentTarget.value) {
                checkIsEmpty("repeatPassword", event.currentTarget.value);
            } else {
                if (passwordInput.current) {
                    if (
                        event.currentTarget.value !==
                        (passwordInput.current as HTMLInputElement).value
                    ) {
                        setError({
                            ...err,
                            repeatPassword:
                                "Password and Repeat Password does not match.",
                        });
                    } else {
                        setError({
                            ...err,
                            repeatPassword: null,
                        });
                    }
                }
            }
        },
        [passwordInput.current, err],
    );
    const passwordVerify = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.currentTarget.value) {
                checkIsEmpty("password", event.currentTarget.value);
            } else {
                const update: InputError = {
                    ...err,
                    password: null,
                };
                if (repeatPasswordInput.current) {
                    if (
                        event.currentTarget.value ===
                        (repeatPasswordInput.current as HTMLInputElement).value
                    ) {
                        update.repeatPassword = null;
                    } else {
                        update.repeatPassword =
                            "Password and Repeat Password does not match.";
                    }
                }

                setError(update);
            }
        },
        [repeatPasswordInput.current, err],
    );

    const checkIsEmpty = useCallback(
        (type: keyof InputError, value: string | null | undefined) => {
            const _isEmpty = isEmpty(value);
            switch (type) {
                case "email":
                    if (_isEmpty) {
                        setError({ ...err, email: "Please enter email" });
                    } else {
                        setError({ ...err, email: null });
                    }
                    break;
                case "firstName":
                    if (_isEmpty) {
                        setError({
                            ...err,
                            firstName: "Please enter first name",
                        });
                    } else {
                        setError({ ...err, firstName: null });
                    }
                    break;
                case "lastName":
                    if (_isEmpty) {
                        setError({
                            ...err,
                            lastName: "Please enter last name",
                        });
                    } else {
                        setError({ ...err, lastName: null });
                    }
                    break;
                case "teamName":
                    if (_isEmpty) {
                        setError({
                            ...err,
                            teamName: "Please enter team name",
                        });
                    } else {
                        setError({ ...err, teamName: null });
                    }
                    break;
                case "password":
                    if (_isEmpty) {
                        setError({ ...err, password: "Please enter password" });
                    } else {
                        setError({ ...err, password: null });
                    }
                    break;
                case "repeatPassword":
                    if (_isEmpty) {
                        setError({
                            ...err,
                            repeatPassword: "Please enter repeat password",
                        });
                    } else {
                        setError({ ...err, repeatPassword: null });
                    }
                    break;
            }
        },
        [err],
    );

    if (signUpState === "success") {
        return (
            <div className="w-full h-full grid place-items-center">
                <div className="w-full max-w-lg flex flex-col justify-center items-center p-4 rounded-md border-2 border-gray-400">
                    <h1 className="text-2xl">Thanks for Signing</h1>
                    <h2 className="mt-3">
                        Please do the email verification with your signing
                        email.
                    </h2>
                    <Button
                        className="w-full mt-4"
                        onClick={() => navigate("/")}
                    >
                        {isPending && (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Back
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full h-full grid place-items-center">
            <div className="w-full max-w-lg flex flex-col justify-center items-center p-4 rounded-md border-2 border-gray-400">
                <h1 className="text-2xl">Sign Up</h1>
                <form className="w-full" onSubmit={handleSignUp}>
                    <Label className="mb-10" htmlFor="team_name">
                        Team Name
                    </Label>
                    <Input
                        id="team_name"
                        name="team_name"
                        type="text"
                        placeholder="Enter team name"
                        onChange={(e) => {
                            checkIsEmpty("teamName", e.currentTarget.value);
                        }}
                        onBlur={(e) =>
                            checkIsEmpty("teamName", e.currentTarget.value)
                        }
                    />
                    {err.teamName && (
                        <div className="my-1">
                            <span className="text-rose-600 text-xs">
                                {err.teamName}
                            </span>
                        </div>
                    )}
                    <Label className="mb-10" htmlFor="first_name">
                        First Name
                    </Label>
                    <Input
                        id="first_name"
                        name="first_name"
                        type="text"
                        placeholder="Enter first name"
                        onChange={(e) => {
                            checkIsEmpty("firstName", e.currentTarget.value);
                        }}
                        onBlur={(e) =>
                            checkIsEmpty("firstName", e.currentTarget.value)
                        }
                    />
                    {err.firstName && (
                        <div className="my-1">
                            <span className="text-rose-600 text-xs">
                                {err.firstName}
                            </span>
                        </div>
                    )}
                    <Label className="mb-10" htmlFor="last_name">
                        Last Name
                    </Label>
                    <Input
                        id="last_name"
                        name="last_name"
                        type="text"
                        placeholder="Enter last name"
                        onChange={(e) => {
                            checkIsEmpty("lastName", e.currentTarget.value);
                        }}
                        onBlur={(e) =>
                            checkIsEmpty("lastName", e.currentTarget.value)
                        }
                    />
                    {err.lastName && (
                        <div className="my-1">
                            <span className="text-rose-600 text-xs">
                                {err.lastName}
                            </span>
                        </div>
                    )}
                    <Label className="mb-10" htmlFor="email">
                        Email
                    </Label>
                    <Input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter email"
                        onChange={(e) => {
                            checkIsEmpty("email", e.currentTarget.value);
                        }}
                        onBlur={(e) =>
                            checkIsEmpty("email", e.currentTarget.value)
                        }
                    />
                    {err.email && (
                        <div className="my-1">
                            <span className="text-rose-600 text-xs">
                                {err.email}
                            </span>
                        </div>
                    )}
                    <Label htmlFor="password">Password</Label>
                    <Input
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Enter password"
                        onChange={passwordVerify}
                        onBlur={passwordVerify}
                        ref={passwordInput}
                    />
                    {err.password && (
                        <div className="my-1">
                            <span className="text-rose-600 text-xs">
                                {err.password}
                            </span>
                        </div>
                    )}
                    <Label htmlFor="repeat_password">Repeat Password</Label>
                    <Input
                        id="repeat_password"
                        name="repeat_password"
                        type="password"
                        placeholder="Repeat password"
                        onBlur={repeatPasswordVerify}
                        onChange={repeatPasswordVerify}
                        ref={repeatPasswordInput}
                    />
                    {err.repeatPassword && (
                        <div className="my-1">
                            <span className="text-rose-600 text-xs">
                                {err.repeatPassword}
                            </span>
                        </div>
                    )}
                    <div className="flex items-center space-x-2">
                        <Checkbox id="accept_policy" name="accept_policy" />
                        <Label htmlFor="accept_policy">
                            I agree to receive emails with the understanding
                            that I may easily opt-out of these communications at
                            any time after signing up.
                        </Label>
                    </div>
                    {err.acceptPolicy && (
                        <div className="my-1">
                            <span className="text-rose-600 text-xs">
                                {err.acceptPolicy}
                            </span>
                        </div>
                    )}
                    <Button
                        className="w-full mt-4"
                        type="submit"
                        disabled={isPending}
                    >
                        {isPending && (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Sign Up
                    </Button>
                    <div className="flex justify-end">
                        <Link
                            to="/login"
                            className="mt-3 text-end text-blue-500 hover:text-blue-800 text-sm"
                        >
                            Already have an account? Login
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignUp;
