import { FC, useEffect } from "react";

declare global {
    interface Window {
        chatwootSDK: any;
        chatwootSettings: any;
        $chatwoot: any;
    }
}

const ChatwootLiveChat: FC = () => {
    const chatwootToken = import.meta.env.VITE_CHATWOOT_WEBSITE_TOKEN
    useEffect(() => {
        window.chatwootSettings = {
            hideMessageBubble: true,
            position: "right",
            locale: "en",
            type: "standard",
        };

        (function (d, t) {
            const BASE_URL = "https://app.chatwoot.com";
            const g = d.createElement(t) as HTMLScriptElement;
            const s = d.getElementsByTagName(t)[0];
            g.src = BASE_URL + "/packs/js/sdk.js";
            g.defer = true;
            g.async = true;
            s.parentNode!.insertBefore(g, s);

            g.onload = function () {
                window.chatwootSDK.run({
                    websiteToken: chatwootToken,
                    baseUrl: BASE_URL,
                });
            };
        })(document, "script");
    }, []);

    return null;
};

export default ChatwootLiveChat;
