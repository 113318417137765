import { FC, useEffect, useRef } from "react";

function computeDrawableData(
    frequenceData: Uint8Array,
    canvas: HTMLCanvasElement,
    barWidth: number,
    gapBetween: number,
) {
    const width = canvas.width;
    // INFO: number of chunk depends on canvas width, barwidth and gapbetween
    let numberOfChunk = Math.floor(width / (barWidth + gapBetween));
    // INFO: each size for a data chunk
    let size = Math.floor(frequenceData.length / numberOfChunk);

    if (numberOfChunk > frequenceData.length) {
        numberOfChunk = frequenceData.length;
        size = 1;
    }

    const data: number[] = [];

    for (let i = 0; i < numberOfChunk; ++i) {
        let sum = 0;
        for (let j = 0; j < size && i * size + j < frequenceData.length; ++j) {
            sum += frequenceData[i * size + j];
        }
        data.push(sum / size / 4);
    }
    return data;
}

function draw(
    data: number[],
    canvas: HTMLCanvasElement,
    barWidth: number,
    gapBetween: number,
    color: string,
    bgColor: string,
) {
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    const width = canvas.width;
    const height = canvas.height;

    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = bgColor;
    ctx.fillRect(0, 0, width, height);

    for (let i = 0; i < data.length; ++i) {
        ctx.fillStyle = color;
        const x = i * (barWidth + gapBetween);
        const y = height / 2 - data[i] / 2;
        const w = barWidth;
        const h = data[i] || 1;

        ctx.beginPath();
        if (ctx.roundRect) {
            ctx.roundRect(x, y, w, h, 50);
            ctx.fill();
        } else {
            ctx.rect(x, y, w, h);
        }
    }
}

function displayWaveform(
    analyser: AnalyserNode,
    canvas: HTMLCanvasElement | null,
    barWidth: number,
    gapBetween: number,
    color: string,
    bgColor: string,
) {
    if (!canvas) return;
    const frequenceData = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(frequenceData);
    const data = computeDrawableData(
        frequenceData,
        canvas,
        barWidth,
        gapBetween,
    );
    draw(data, canvas, barWidth, gapBetween, color, bgColor);
}

type AudioVisualizerProps = {
    mediaRecorder: MediaRecorder;
    width?: number;
    height?: number;
    barWidth?: number;
    gapBetween?: number;
    color?: string;
    bgColor?: string;
};

const AudioVisualizer: FC<AudioVisualizerProps> = ({
    mediaRecorder,
    width = 150,
    height = 50,
    barWidth = 2,
    gapBetween = 1,
    color = "black",
    bgColor = "transparent",
}) => {
    const audioContext = useRef(new AudioContext());
    const analyser = useRef(
        audioContext.current.state === "closed"
            ? null
            : audioContext.current.createAnalyser(),
    );
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const animateFrameRef = useRef<number>();

    useEffect(() => {
        if (!analyser.current) return;
        const source = audioContext.current.createMediaStreamSource(
            mediaRecorder.stream,
        );
        source.connect(analyser.current);
        return () => {
            if (!analyser.current) return;
            source.disconnect(analyser.current);
        };
    }, [mediaRecorder.stream]);

    function animate() {
        if (!analyser.current) return;
        displayWaveform(
            analyser.current,
            canvasRef.current,
            barWidth,
            gapBetween,
            color,
            bgColor,
        );
        animateFrameRef.current = requestAnimationFrame(animate);
    }

    useEffect(() => {
        if (mediaRecorder.state === "recording") {
            animate();
        } else if (mediaRecorder.state === "paused") {
            if (animateFrameRef.current) {
                cancelAnimationFrame(animateFrameRef.current);
            }
            animateFrameRef.current = undefined;
        } else if (
            mediaRecorder.state === "inactive" &&
            audioContext.current.state !== "closed"
        ) {
            audioContext.current.close();
        }
    }, [mediaRecorder.state]);

    return <canvas ref={canvasRef} width={width} height={height} />;
};

export default AudioVisualizer;
