import { cn } from "@libs/utils";
import { ComponentPropsWithoutRef, forwardRef } from "react";

type EditableContainerProps = ComponentPropsWithoutRef<"div">;

const EditableContainer = forwardRef<
    React.ElementRef<"div">,
    EditableContainerProps
>(({ className, ...props }, ref) => {
    return (
        <div
            ref={ref}
            data-placeholder="Add a comment..."
            className={cn(
                "flex-1 cursor-text whitespace-pre-wrap break-all focus-visible:outline-none [&[contentEditable]]:empty:after:content-[attr(data-placeholder)] [&[contentEditable]]:empty:after:text-gray-200 text-sm",
                className,
            )}
            {...props}
        />
    );
});
EditableContainer.displayName = "EditableContainer";

export { EditableContainer };
