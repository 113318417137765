import OnboardProgressDisplayButton from "@component-func/onboarding-tour/OnboardProgressDisplayButton";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import useUserSelfInformation from "@hooks/user/useUserSelfInformationQuery";
import { usePostHog } from "posthog-js/react";
import { FC, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import api from "@libs/api";
import { usePopupNotification } from "@context/popup-notification";
import { GoogleSvg } from "./GoogleSvg";
import { AxiosResponse } from "axios";
import { ZoomSvg } from "./ZoomSvg";

const UserInformation: FC = () => {
    const {
        data: tenantUser,
        isError,
        isSuccess,
        isFetching,
        isLoading,
        refetch,
    } = useUserSelfInformation();
    const posthog = usePostHog();

    const { push } = usePopupNotification();
    const googleLogin = useGoogleLogin({
        flow: "auth-code",
        onSuccess: async (codeResponse) => {
            try {
                const { data } = await api.post<
                    { status: string },
                    AxiosResponse<{ status: string }>,
                    { code: string }
                >("/oauth/google", {
                    code: codeResponse.code,
                });
                if (data.status === "success") {
                    push({
                        type: "success",
                        description: "OAuth Success",
                        title: "Google OAuth",
                    });
                    refetch();
                } else {
                    push({
                        type: "destructive",
                        description: "OAuth Error",
                        title: "Google OAuth",
                    });
                }
            } catch (error) {
                push({
                    type: "destructive",
                    description: "OAuth Error",
                    title: "Google OAuth",
                });
            }
        },
        onError: (errorResponse) => console.log(errorResponse),
        scope: "https://www.googleapis.com/auth/meetings.space.readonly https://www.googleapis.com/auth/meetings.space.created https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.events",
    });

    const zoomLogin = () => {
        const client_id = import.meta.env.VITE_ZOOM_CLIENT_KEY ?? "";
        const redirect_uri = `${window.location.origin}/oauth/zoom`;
        const width = 1200;
        const height = 650;
        const left = (screen.width - width) / 2;
        const top = (screen.height - height) / 2;
        window.open(
            `https://zoom.us/oauth/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}`,
            "Window2",
            "status=no,height=" +
                height +
                ",width=" +
                width +
                ",resizable=yes,left=" +
                left +
                ",top=" +
                top +
                ",screenX=" +
                left +
                ",screenY=" +
                top +
                ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no",
        );
    };

    useEffect(() => {
        posthog?.capture("$pageview", { pageName: "/settings/info" });
    }, []);

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return (
            <div className="h-full grid place-items-center ">
                <h1 className="text-4xl text-foreground/50">
                    Cannot get the user information, please try again.
                </h1>
                <Button onClick={() => refetch()}>Retry</Button>
            </div>
        );
    }

    const displayZoom =
        (process.env.NODE_ENV === "production" &&
            tenantUser.teamId == "a4de9204-09c7-4aa8-b3a7-adeecc4bbc3d") ||
        process.env.NODE_ENV === "development";
    return (
        <div className="h-full w-full p-12 grid grid-rows-[max-content,1fr] overflow-hidden">
            <h1 className="text-2xl font-bold mb-12">User Information</h1>
            <div className="h-full w-full overflow-y-auto">
                {isFetching ? (
                    <LoadingSpinnerCenter />
                ) : (
                    <>
                        <div className="flex flex-col ml-4 gap-4">
                            <span>User: {tenantUser.username}</span>
                            <span>User Email: {tenantUser.email}</span>
                            <span>Team: {tenantUser.team.name}</span>
                        </div>
                        <OnboardProgressDisplayButton className="my-2" />
                        <div className="flex flex-col gap-4">
                            <Button
                                className="bg-[#4285f4] hover:bg-[#4285f4] active:bg-[#4285f4] bg-opacity-95 hover:bg-opacity-100 text-white px-0 py-0 shadow-md w-60 h-12 disabled:opacity-50"
                                onClick={googleLogin}
                                disabled={tenantUser.oauth.google}
                            >
                                <div className="flex justify-center items-center w-1/5">
                                    <GoogleSvg />
                                </div>
                                <div className="w-4/5">
                                    {tenantUser.oauth.google ? (
                                        <span>Already logged in to Google</span>
                                    ) : (
                                        <span>Login to Google</span>
                                    )}
                                </div>
                            </Button>
                            {displayZoom && (
                                <Button
                                    className="bg-[#4285f4] hover:bg-[#4285f4] active:bg-[#4285f4] bg-opacity-95 hover:bg-opacity-100 text-white px-0 py-0 shadow-md w-60 h-12 disabled:opacity-50"
                                    onClick={zoomLogin}
                                    disabled={tenantUser.oauth.zoom}
                                >
                                    <div className="flex justify-center items-center w-1/5">
                                        <ZoomSvg />
                                    </div>
                                    <div className="w-4/5">
                                        {tenantUser.oauth.zoom ? (
                                            <span>
                                                Already logged in to Zoom
                                            </span>
                                        ) : (
                                            <span>Login to Zoom</span>
                                        )}
                                    </div>
                                </Button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default UserInformation;
