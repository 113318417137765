import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { Document } from "types/backend/document";

export default function useAllSharedDocumentQuery(search = "") {
    const query = useQuery({
        queryKey: [Q.GET_SHARED_ALL_DOCUMENT, search],
        queryFn: async ({ queryKey }) => {
            const [, search] = queryKey;
            const { data } = await api.get<Document[]>("/documents/share", {
                params: {
                    search,
                },
            });
            return data;
        },
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
        placeholderData: [],
    });

    return query;
}
