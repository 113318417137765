import { Button } from "@component-ui/utility/Button";
import { FC, useState } from "react";
import CreateDocumentDialog from "./CreateDocumentDialog";
import useAllDocumentQuery from "@hooks/document/useAllDocumentQuery";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import Empty from "@component-ui/Empty";
import { FileText } from "lucide-react";
import DocumentRow from "./DocumentRow";
import { useParams, useSearchParams } from "react-router-dom";
import { SEARCH_PARAMS } from "@constant/searchParams";
import CustomerTagComboBox from "@component-func/customer-tag/CustomerTagComboBox";

const CreateDocumentButton: FC = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    return (
        <>
            <Button onClick={() => setDialogOpen(true)}>
                Create new document
            </Button>
            <CreateDocumentDialog open={dialogOpen} setOpen={setDialogOpen} />
        </>
    );
};

const DocumentList: FC = () => {
    const [search] = useSearchParams();
    const { documentId } = useParams();
    const [selectedTagId, setSelectedTagId] = useState(
        search.get(SEARCH_PARAMS.CURRENT_TAG) || "",
    );
    const {
        data: documents,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        refetch,
    } = useAllDocumentQuery("", selectedTagId);

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return (
            <div className="h-full grid place-items-center">
                <Button onClick={() => refetch()}>Retry</Button>
            </div>
        );
    }

    return (
        <aside className="h-full w-full grid grid-rows-[max-content,max-content,1fr,max-content] gap-2 p-2">
            <span className="inline-flex items-center gap-2">
                <FileText className="min-w-[40px] w-10 h-10" />
                <h1 className="text-2xl font-medium leading-none">Documents</h1>
            </span>
            <CustomerTagComboBox
                selectedTagId={selectedTagId}
                setSelectedTagId={setSelectedTagId}
            />
            {isFetching ? (
                <LoadingSpinnerCenter />
            ) : (
                <ul className="h-full overflow-auto">
                    {documents.length <= 0 ? (
                        <Empty label="Do not have document yet, create a new document to start" />
                    ) : (
                        documents.map((document) => (
                            <li key={document.id}>
                                <DocumentRow
                                    {...document}
                                    currentDocumentId={documentId || ""}
                                />
                            </li>
                        ))
                    )}
                </ul>
            )}
            <CreateDocumentButton />
        </aside>
    );
};

export default DocumentList;
