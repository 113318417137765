import api from "@libs/api";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

export default function useResendVerificationEmail() {
    const mutation = useMutation<string, AxiosError, { id: string }>({
        mutationFn: async ({ id }) => {
            const { data } = await api.get<string>(
                `tenant-users/${id}/resend_verification_email`,
            );
            return data;
        },
    });

    return mutation;
}
