import { NodeViewWrapper, NodeViewContent, NodeViewProps } from "@tiptap/react";
import { Dot } from "lucide-react";
import { FC, useEffect, useMemo, useState } from "react";
import TranscriptLinkButton from "./TranscriptLinkButton";
import { TranscriptionSegment } from "types/backend/media";
import BlockTranscriptReference from "./BlockTranscriptReference";
import Timestamp from "./Timestamp";
import { usePostHog } from "posthog-js/react";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@component-ui/utility/HoverCard";
import { Mark } from "@tiptap/pm/model";
import Threads from "./thread/Threads";
import { Thread } from "@libs/tiptap/Thread";
import { cn } from "@libs/utils";
import { createPortal } from "react-dom";

const ListItem: FC<NodeViewProps> = (props) => {
    const { node, updateAttributes } = props;
    const transcriptSegment = node.attrs[
        "data-segment"
    ] as TranscriptionSegment;
    const timestamp = node.attrs["data-timestamp"];

    const posthogCaptured = node.attrs["data-posthog-captured"] as boolean;
    const posthog = usePostHog();
    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!posthogCaptured) {
            setTimeout(() => {
                posthog?.capture("list_item_created");
                updateAttributes({ "data-posthog-captured": true });
            }, 0);
        }
    }, []);

    const marks = useMemo(() => {
        const marks: Mark[] = [];
        const fstChild = node.firstChild;
        if (!fstChild) return [];
        fstChild.descendants((node) => {
            const m = node.marks.find((mark) => mark.type.name === Thread.name);
            if (!m) return;
            marks.push(m);
        });
        return marks;
    }, [node]);

    return (
        <NodeViewWrapper
            as="li"
            data-type="listItem"
            key={node.attrs["data-id"]}
        >
            <div
                className={cn(
                    "py-1 grid grid-cols-[max-content,1fr] auto-cols-min",
                )}
            >
                <div
                    className={`flex ${timestamp ? "items-center" : "items-start"} justify-start`}
                >
                    <HoverCard openDelay={300} closeDelay={100}>
                        <HoverCardTrigger id="list-item-dot">
                            <Dot className="w-4 h-4 stroke-[6px] cursor-default" />
                        </HoverCardTrigger>
                        <HoverCardContent align="center" className="w-fit p-0">
                            <TranscriptLinkButton
                                updateAttributes={updateAttributes}
                            />
                        </HoverCardContent>
                    </HoverCard>
                </div>
                <div className="flex flex-col min-w-0">
                    <Timestamp {...props} />
                    {transcriptSegment && (
                        <div className="max-w-full min-w-0 ml-1">
                            <BlockTranscriptReference
                                transcriptSegment={transcriptSegment}
                            />
                        </div>
                    )}
                    <div ref={(ref) => setContainer(ref)}>
                        <NodeViewContent className="ml-1 break-words min-w-0 w-full relative" />
                    </div>
                    {container &&
                        createPortal(
                            <Threads
                                marks={marks}
                                userType="tenant-user"
                                container={container}
                                editor={props.editor}
                            />,
                            document.getElementById("comments"),
                        )}
                </div>
            </div>
        </NodeViewWrapper>
    );
};

export default ListItem;
