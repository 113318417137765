import { forwardRef } from "react";

type VideoContainerProps = {
    src: string;
};

const VideoContainer = forwardRef<HTMLVideoElement, VideoContainerProps>(
    ({ src }, ref) => {
        return (
            <video
                controls
                className="w-full h-full aspect-video m-auto"
                key={src}
                ref={ref}
            >
                <source src={`${src}#t=0.1`} />
            </video>
        );
    },
);

VideoContainer.displayName = "VideoContainer";

export default VideoContainer;
