import { FC } from "react";

type EmptyProps = {
    label: string;
};

const Empty: FC<EmptyProps> = ({ label }) => {
    return <div className="grid h-full w-full place-items-center text-gray-500">{label}</div>;
};

export default Empty;
