import { useResourcePermissionsQuery } from "@hooks/shareable/useResourcePermissionsQuery";

import { FC, useMemo } from "react";
import { ResourcePermission } from "types/backend/resource_permission";
import PermissionRow from "./PermissionRow";

type ShareableHistoryProps = {
    isShareDocument: boolean;
    meetingId: string;
    mediaId: string;
    documentId: string;
};

function bothFilter(resourcePermissions: ResourcePermission[]) {
    let count = 0;
    for (const p of resourcePermissions) {
        if (p.resourceType === "Media" || p.resourceType === "Document") {
            count++;
        }
    }
    return count === 2;
}
function onlyMeetingFilter(resourcePermissions: ResourcePermission[]) {
    let count = 0;
    for (const p of resourcePermissions) {
        if (p.resourceType === "Document") {
            count++;
        }
    }
    return count === 0;
}

const ShareableHistory: FC<ShareableHistoryProps> = ({
    isShareDocument,
    meetingId,
    mediaId,
    documentId,
}) => {
    const { data } = useResourcePermissionsQuery(
        meetingId,
        mediaId,
        documentId,
    );

    const filteredGuest = useMemo(() => {
        if (!data) return [];
        return isShareDocument
            ? data.guests.filter((g) => bothFilter(g.resourcePermissions))
            : data.guests.filter((g) =>
                  onlyMeetingFilter(g.resourcePermissions),
              );
    }, [isShareDocument, data]);

    const filteredTenantUser = useMemo(() => {
        if (!data) return [];
        return isShareDocument
            ? data.tenantUser.filter((g) => bothFilter(g.resourcePermissions))
            : data.tenantUser.filter((g) =>
                  onlyMeetingFilter(g.resourcePermissions),
              );
    }, [isShareDocument, data]);

    return (
        <div className="space-y-2">
            <h1 className="text-lg font-semibold leading-none tracking-tight">
                People with access{" "}
                <span>{isShareDocument ? "(with document)" : ""}</span>
            </h1>
            <div className="sm:h-48 md:h-64 overflow-y-auto">
                {filteredGuest.map((permission) => (
                    <PermissionRow
                        key={permission.user.email}
                        permission={permission}
                        userType="Guest"
                    />
                ))}
                {filteredTenantUser.map((permission) => (
                    <PermissionRow
                        key={permission.user.email}
                        permission={permission}
                        userType="TenantUser"
                    />
                ))}
            </div>
        </div>
    );
};

export default ShareableHistory;
