import { Avatar, AvatarFallback } from "@component-ui/utility/Avatar";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@component-ui/utility/HoverCard";
import { cn, convertTextToColour } from "@libs/utils";
import { ComponentPropsWithoutRef, FC } from "react";
import { TenantUser } from "types/backend/tenant-user";

type TenantUserAvatarProps = ComponentPropsWithoutRef<typeof Avatar> & {
    tenantUser?: TenantUser;
};

const TenantUserAvatar: FC<TenantUserAvatarProps> = ({
    tenantUser,
    className,
    ...rest
}) => {
    const displayText = tenantUser
        ? tenantUser.firstName && tenantUser.lastName
            ? [...tenantUser.firstName][0] + [...tenantUser.lastName][0]
            : "?"
        : "?";
    const bgColor = convertTextToColour(displayText);
    return (
        <HoverCard openDelay={200} closeDelay={200}>
            <HoverCardTrigger asChild>
                <Avatar className={cn("mx-1", className)} {...rest}>
                    <AvatarFallback
                        className="text-white cursor-default"
                        style={{ background: bgColor }}
                    >
                        {displayText}
                    </AvatarFallback>
                </Avatar>
            </HoverCardTrigger>
            <HoverCardContent
                className="w-fit cursor-default"
                onClick={(e) => e.stopPropagation()}
            >
                <div>
                    <h1>{tenantUser?.username}</h1>
                </div>
            </HoverCardContent>
        </HoverCard>
    );
};

export default TenantUserAvatar;
