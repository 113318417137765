import { Button } from "@component-ui/utility/Button";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@component-ui/utility/Dialog";
import { FilePlus2, Loader2, X } from "lucide-react";
import { FC, FormEvent, useEffect, useRef, useState } from "react";
import { useUploadFileMutate } from "@hooks/useUploadFileMutate";
import BasicSelect from "./BasicSelect";
import { languageOptions } from "@constant/language";
import { FORM_KEY } from "@constant/form";
import useTeamPermission from "@hooks/user/useTeamPermissionQuery";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@component-ui/utility/HoverCard";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import { usePostHog } from "posthog-js/react";

type FileUploaderProps = {
    meetingId: string | undefined;
};

const FileUploaderCapture: FC = () => {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("$pageview", { pageName: "/media/new" });
    });
    return null;
};

const FileUploader: FC<FileUploaderProps> = ({ meetingId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const {
        data: teamPermission,
        isLoading,
        isFetching,
        isError,
        isSuccess,
        refetch,
    } = useTeamPermission();

    const { mutate: upload, isPending: isUploading } =
        useUploadFileMutate(meetingId);

    if (isLoading || isFetching) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return (
            <HoverCard openDelay={200} closeDelay={300}>
                <HoverCardTrigger asChild>
                    <X className="w-8 h-8" />
                </HoverCardTrigger>
                <HoverCardContent className="w-fit flex flex-col gap-2">
                    Failed to get permission.
                    <Button onClick={() => refetch()}>Retry</Button>
                </HoverCardContent>
            </HoverCard>
        );
    }

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        upload(new FormData(e.currentTarget), {
            onSuccess: () => {
                setIsOpen(false);
            },
        });
    }

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <TooltipProvider delayDuration={200}>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            size="icon"
                            variant="ghost"
                            id="upload-media-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setIsOpen(true);
                            }}
                            disabled={!teamPermission.canCreateMedia}
                        >
                            <FilePlus2 />
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent side="bottom">
                        {teamPermission.canCreateMedia
                            ? "Upload Media"
                            : "Your team have reach the limit of media amount"}
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Upload file</DialogTitle>
                    <FileUploaderCapture />
                </DialogHeader>
                <form
                    id="upload-audio-file"
                    ref={formRef}
                    onSubmit={handleSubmit}
                >
                    <Label htmlFor={FORM_KEY.MEDIA.AUDIO_FILE}>
                        Audio file
                    </Label>
                    <Input
                        id={FORM_KEY.MEDIA.AUDIO_FILE}
                        name={FORM_KEY.MEDIA.AUDIO_FILE}
                        type="file"
                        accept="audio/*, video/*"
                    />
                    <Label htmlFor={FORM_KEY.MEDIA.LANGUAGE}>Language</Label>
                    <BasicSelect
                        name={FORM_KEY.MEDIA.LANGUAGE}
                        placeholder="Select a language"
                        options={languageOptions}
                        triggerClassName="mt-2"
                    />
                </form>
                <Button
                    form="upload-audio-file"
                    type="submit"
                    disabled={isUploading}
                >
                    {isUploading && (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Upload
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default FileUploader;
