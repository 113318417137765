import { Q } from "@constant/query-key";
import api, { guestApi } from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { UserType } from "types/backend";
import { MediaTranscript } from "types/backend/media";

export default function useSharedMediaTranscriptQuery(
    mediaId: string | undefined,
    userType: Exclude<UserType, "tenant-user">,
) {
    const query = useQuery({
        queryKey: [Q.GET_SHARED_MEDIA_TRANSCRIPT_BY_ID, mediaId, userType],
        queryFn: async () => {
            let response: AxiosResponse<MediaTranscript>;
            switch (userType) {
                case "shared-user":
                    response = await api.get<MediaTranscript>(
                        `/media/share/${mediaId}/transcription_segments`,
                    );
                    break;
                case "guest":
                    response = await guestApi.get<MediaTranscript>(
                        `/media/${mediaId}`,
                    );
                    break;
            }
            return response.data;
        },
        enabled: !!mediaId,
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
    });

    return query;
}
