import { FORM_KEY } from "@constant/form";
import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { usePostHog } from "posthog-js/react";
import {
    CreateDocumentTemplateBody,
    DocumentTemplate,
} from "types/backend/document_template";

function validateForm(formData: FormData) {
    const title = formData.get(FORM_KEY.DOCUMENT_TEMPLATE.TITLE) as string;
    if (!title) throw new Error("Invalid title, please input a correct title");
    return { title };
}

export default function useCreateDocumentTemplateMutate() {
    const { push } = usePopupNotification();
    const queryClient = useQueryClient();
    const posthog = usePostHog();

    const mutate = useMutation({
        mutationKey: [M.CREATE_DOCUMENT_TEMPLATE],
        mutationFn: async (formData: FormData) => {
            const postBody = validateForm(formData);
            posthog?.capture("document_template_created");
            const { data } = await api.post<
                DocumentTemplate,
                AxiosResponse<DocumentTemplate>,
                CreateDocumentTemplateBody
            >("/document-templates", postBody);
            return data;
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({
                queryKey: [Q.GET_ALL_DOCUMENT_TEMPLATE],
            });
            push({
                title: `Successfully created template ${data.title}`,
                description: "You can select the template to edit the content",
                type: "success",
            });
        },
        onError: (err) => {
            push({
                title: "Failed to create template",
                description: `Error occurred due to ${(err as Error).message}`,
                type: "destructive",
            });
        },
    });

    return mutate;
}
