import { Button } from "@component-ui/utility/Button";
import { buttons } from "@constant/summarize-button";
import { ComponentPropsWithRef, ElementRef, forwardRef } from "react";
import { Trigger } from "@radix-ui/react-accordion";
import { cn } from "@libs/utils";
import { usePostHog } from "posthog-js/react";
import useGetSummarizeSystemMessagesQuery from "@hooks/summarize/useGetSummarizingSystemMessagesQuery";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import Empty from "@component-ui/Empty";
import SlackIcon from "@component-icons/SlackIcon";

type SummarizeButtonGroupProps = {
    summarizingSystemMessageId: string;
    handleButtonClick: (summarizingSystemMessage: string) => void;
};

const SummarizeButtonGroup = forwardRef<
    ElementRef<"div">,
    ComponentPropsWithRef<"div"> & SummarizeButtonGroupProps
>(
    (
        { className, summarizingSystemMessageId, handleButtonClick, ...props },
        ref,
    ) => {
        const posthog = usePostHog();
        const {
            data: summarizeSystemMessages,
            isLoading,
            isError,
            isSuccess,
        } = useGetSummarizeSystemMessagesQuery();

        if (isLoading) {
            return (
                <div className="w-full">
                    <LoadingSpinnerCenter />
                </div>
            );
        }

        if (isError || !isSuccess) {
            return <Empty label="Cannot get the available summarize option" />;
        }

        return (
            <div
                ref={ref}
                className={cn(
                    "flex justify-between items-center py-2",
                    className,
                )}
                {...props}
            >
                <span>
                    <span className="inline-flex gap-2 items-center flex-wrap">
                        {buttons[process.env.NODE_ENV].map((btn) => (
                            <Button
                                key={btn.systemMessageId}
                                variant={
                                    summarizingSystemMessageId ===
                                    btn.systemMessageId
                                        ? "default"
                                        : "outline"
                                }
                                onClick={(e) => {
                                    e.stopPropagation();
                                    posthog?.capture("$pageview", {
                                        pageName: "/transcript/summary",
                                    });
                                    handleButtonClick(btn.systemMessageId);
                                }}
                            >
                                {btn.label}
                            </Button>
                        ))}
                        {summarizeSystemMessages.map(
                            (summarizeSystemMessage) => (
                                <Button
                                    key={summarizeSystemMessage.id}
                                    variant={
                                        summarizingSystemMessageId ===
                                        summarizeSystemMessage.id
                                            ? "default"
                                            : "outline"
                                    }
                                    id="summarize-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        posthog?.capture("$pageview", {
                                            pageName: "/transcript/summary",
                                        });
                                        handleButtonClick(
                                            summarizeSystemMessage.id,
                                        );
                                    }}
                                >
                                    {summarizeSystemMessage.label}
                                    {summarizeSystemMessage.haveSlackWebhook ? (
                                        <SlackIcon
                                            className="ml-2"
                                            width={16}
                                        />
                                    ) : null}
                                </Button>
                            ),
                        )}
                    </span>
                </span>
            </div>
        );
    },
);
SummarizeButtonGroup.displayName = Trigger.displayName;

export default SummarizeButtonGroup;
