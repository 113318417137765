import RecordingDownload from "@component-func/RecordingDownload";
import DocumentList from "@component-func/document/DocumentList";
import DocumentTemplateList from "@component-func/document_template/DocumentTemplateList";
import MeetingList from "@component-func/meetings/MeetingList";
import SettingList from "@component-func/settings/SettingList";
import ShareToMeList from "@component-func/share-to-me/ShareToMeList";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@component-ui/utility/Tabs";
import React from "react";
import { useLocation } from "react-router-dom";

const FeatureList: React.FC = () => {
    const location = useLocation();
    const [, feature, sub] = location.pathname.split("/");

    return (
        <>
            {(!feature ||
                feature === "meeting-transcripts" ||
                feature === "meetings") && (
                <Tabs
                    className="max-w-full h-full grid grid-rows-[max-content,1fr]"
                    defaultValue={
                        sub === "share" ? "share-to-me" : "meeting-transcript"
                    }
                >
                    <TabsList>
                        <TabsTrigger value="meeting-transcript">
                            Meetings
                        </TabsTrigger>
                        <TabsTrigger value="share-to-me">
                            Shared to me
                        </TabsTrigger>
                    </TabsList>
                    <TabsContent
                        className="mt-0 h-full overflow-hidden"
                        value="meeting-transcript"
                    >
                        <MeetingList />
                    </TabsContent>
                    <TabsContent
                        value="share-to-me"
                        className="h-full overflow-hidden"
                    >
                        <ShareToMeList />
                    </TabsContent>
                </Tabs>
            )}
            {feature === "documents" && <DocumentList />}
            {feature === "document-templates" && <DocumentTemplateList />}
            {feature === "settings" && <SettingList />}
            {feature === "recording-download" && <RecordingDownload />}
        </>
    );
};

export default FeatureList;
