import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Meeting } from "types/backend";

export default function useSoftDeleteMeetingMutate() {
    const { push } = usePopupNotification();
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationKey: [M.DELETE_MEETING],
        mutationFn: async (id: string) => {
            return api.delete<Meeting>(`/meetings/${id}`);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [Q.GET_MEETINGS],
                exact: false,
            });
            queryClient.invalidateQueries({
                queryKey: [Q.GET_TRANSCRIPT],
                exact: false,
            });
        },
        onError: (err) => {
            push({
                title: "Cannot delete meeting",
                description: err.message,
                type: "destructive",
            });
        },
    });

    return mutate;
}
