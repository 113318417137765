import { M } from "@constant/mutation-key";
import { usePopupNotification } from "@context/popup-notification";
import useUserSelfInformation from "@hooks/user/useUserSelfInformationQuery";
import api from "@libs/api";
import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";

type TriggerConcatData = {
    meetingId: string;
    mediaId: string;
    mediaName: string;
};

export default function useTriggerConcatMutate() {
    const { push } = usePopupNotification();
    const posthog = usePostHog();
    const { data: user } = useUserSelfInformation();

    const mutate = useMutation({
        mutationKey: [M.TRIGGER_CONCAT],
        mutationFn: async ({
            meetingId,
            mediaId,
            mediaName,
        }: TriggerConcatData) => {
            posthog?.capture("media_create");
            const key = `${meetingId}/${Date.now()}-${mediaName}.weba`;
            api.post("/media/concat-task", {
                meetingId,
                mediaId,
                key,
                tenantUserId: user && user.id,
            });
            return { key };
        },
        onError: (error) => {
            const errorMessage =
                error instanceof AxiosError
                    ? error.response?.data.message
                    : error.message;
            push({
                title: "Cannot upload file",
                description: errorMessage,
                type: "destructive",
            });
            captureException(error, {
                data: {
                    user,
                    extra: {
                        error,
                        errorMessage,
                    },
                },
            });
        },
        onSuccess: async (_, { mediaName }) => {
            posthog?.capture("media_concat");
            push({
                title: "Upload success",
                description: `Successfully upload ${mediaName}, please wait for the transcript`,
                type: "success",
            });
        },
    });

    return mutate;
}
