import useUserSelfInformation from "@hooks/user/useUserSelfInformationQuery";
import api from "@libs/api";
import { reTryCatch } from "@libs/utils";
import { captureException } from "@sentry/react";
import { useAudioRecorderStore } from "@store/audio-recorder-store";
import axios, { isAxiosError } from "axios";
import { useCallback } from "react";
import { GetMediaUploadPresignedUrlResponse } from "types/backend";

export default function useAudioRecorder() {
    const mediaRecorder = useAudioRecorderStore.use.mediaRecorder();
    const mediaStream = useAudioRecorderStore.use.mediaStream();
    const setMediaRecorder = useAudioRecorderStore.use.setMediaRecorder();
    const isRecording = useAudioRecorderStore.use.isRecording();
    const setIsRecording = useAudioRecorderStore.use.setIsRecording();
    const startTimer = useAudioRecorderStore.use.startTimer();
    const stopTimer = useAudioRecorderStore.use.stopTimer();
    const setIsFinish = useAudioRecorderStore.use.setIsFinish();
    const incChunk = useAudioRecorderStore.use.incChunk();
    const setProgress = useAudioRecorderStore.use.setProgress();

    const { data: user } = useUserSelfInformation();

    function handleWindowUnload(e: BeforeUnloadEvent) {
        e.preventDefault();
        e.returnValue = "";
    }

    async function handleDataAvailable(e: BlobEvent) {
        const chunk = useAudioRecorderStore.getState().chunk;

        const getPresignedAndUpload = async () => {
            const {
                data: { presignedURL },
            } = await api.get<GetMediaUploadPresignedUrlResponse>(
                "/media/record/chunk",
                {
                    params: {
                        n: chunk,
                    },
                },
            );

            await axios.put(presignedURL, e.data, {
                headers: {
                    "Content-Type": "application/octet-stream",
                },
            });
            incChunk();
        };

        try {
            await reTryCatch(getPresignedAndUpload, 2);
        } catch (err) {
            const errDetail = isAxiosError(err)
                ? JSON.stringify(
                      err.response?.data,
                      Object.getOwnPropertyNames(err.response?.data),
                  )
                : JSON.stringify(err, Object.getOwnPropertyNames(err));
            console.log(errDetail);
            captureException(err, {
                data: {
                    message: `Cannot upload chunk: ${chunk}`,
                    user,
                    extra: {
                        err,
                        detail: errDetail,
                    },
                },
            });
        }
    }

    const startRecord = useCallback(
        (mediaStream: MediaStream) => {
            if (!mediaStream) return;
            const _recorder = new MediaRecorder(mediaStream);
            _recorder.ondataavailable = handleDataAvailable;
            setMediaRecorder(_recorder);
            _recorder.start(1000 * 10);
            setProgress("recording");
            startTimer();
            window.onbeforeunload = handleWindowUnload;
        },
        [mediaStream, setMediaRecorder, handleDataAvailable, startTimer],
    );

    const stopRecord = useCallback(() => {
        if (mediaRecorder) {
            stopTimer();
            setIsFinish(true);
            setProgress("upload");
            mediaRecorder.stop();
            window.onbeforeunload = null;
        }
    }, [mediaRecorder, stopTimer]);

    const togglePause = useCallback(() => {
        if (!mediaRecorder) return;
        if (isRecording) {
            mediaRecorder.pause();
            setIsRecording(false);
            stopTimer();
        } else {
            mediaRecorder.resume();
            setIsRecording(true);
            startTimer();
        }
    }, [
        mediaRecorder,
        isRecording,
        setIsRecording,
        stopTimer,
        setIsRecording,
        startTimer,
    ]);

    return { startRecord, stopRecord, togglePause };
}
