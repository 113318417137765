import { create } from "zustand";
import { createSelectors } from "./helper";

type AudioRecorderState = {
    meetingId: string;
    mediaId: string;
    mediaStream: MediaStream | undefined;
    sourceStream: MediaStream[];
    mediaRecorder: MediaRecorder | undefined;
    isRecording: boolean;
    isFinish: boolean;
    time: number;
    timer: NodeJS.Timeout | undefined;
    chunk: number;
    progress: "recording" | "upload" | "pending";
};

type AudioRecorderAction = {
    setMeetingId: (meetingId: string) => void;
    setMediaId: (mediaId: string) => void;
    setMediaStream: (stream: MediaStream) => void;
    addSourceStream: (stream: MediaStream) => void;
    setMediaRecorder: (recorder: MediaRecorder) => void;
    setIsRecording: (recording: boolean) => void;
    setIsFinish: (finish: boolean) => void;
    incChunk: () => void;
    setProgress: (progress: AudioRecorderState["progress"]) => void;
    startTimer: () => void;
    stopTimer: () => void;
    resetInitialState: () => void;
};

const initialState: AudioRecorderState = {
    meetingId: "",
    mediaId: "",
    mediaStream: undefined,
    sourceStream: [],
    mediaRecorder: undefined,
    isRecording: true,
    isFinish: false,
    time: 0,
    timer: undefined,
    chunk: 0,
    progress: "pending",
};

export const useAudioRecorderStore = createSelectors(
    create<AudioRecorderState & AudioRecorderAction>((set, get) => ({
        ...initialState,
        setMeetingId: (id) => {
            set({ meetingId: id });
        },
        setMediaId: (id) => {
            set({ mediaId: id });
        },
        setMediaStream: (stream) => {
            set({ mediaStream: stream });
        },
        addSourceStream: (stream) => {
            set((state) => ({
                sourceStream: [...state.sourceStream, stream],
            }));
        },
        setMediaRecorder: (recorder) => {
            set({ mediaRecorder: recorder });
        },
        setIsRecording: (recording) => {
            set({ isRecording: recording });
        },
        setIsFinish: (finish) => {
            set({ isFinish: finish });
        },
        incChunk: () => {
            set((state) => ({
                chunk: state.chunk + 1,
            }));
        },
        setProgress: (progress) => {
            set({
                progress,
            });
        },
        startTimer: () => {
            const t = setInterval(() => {
                set((state) => ({
                    time: state.time + 1,
                }));
            }, 1000);
            set({ timer: t });
        },
        stopTimer: () => {
            const { timer } = get();
            timer && clearInterval(timer);
        },
        resetInitialState: () => {
            const { timer, sourceStream, mediaStream } = get();
            timer && clearInterval(timer);
            mediaStream && mediaStream.getTracks().forEach((t) => t.stop());
            sourceStream.forEach((stream) => {
                stream.getTracks().forEach((t) => t.stop());
            });
            set(initialState);
        },
    })),
);
