import { Button } from "@component-ui/utility/Button";
import {
    Select,
    SelectContent,
    SelectTrigger,
    SelectValue,
    SelectGroup,
    SelectLabel,
    SelectItem,
} from "@component-ui/utility/Select";
import { Toggle } from "@component-ui/utility/Toggle";
import { Language } from "@constant/language";
import { SEARCH_PARAMS } from "@constant/searchParams";
import useSpeakerMapQuery from "@hooks/speaker/useSpeakerMapQuery";
import { useTranscriptionLayoutStore } from "@store/transcript-layout-store";
import { EyeIcon, EyeOff, List, X } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";

type TranscriptFilterSelectProps = {
    mediaId: string;
    speakers: string[];
};

const TranscriptFilterSelect: FC<TranscriptFilterSelectProps> = ({
    mediaId,
    speakers,
}) => {
    const [key, setKey] = useState<number>(+new Date());
    const [value, setValue] = useState<string | undefined>(undefined);
    const [, setSearch] = useSearchParams();
    const { data: speakerMap } = useSpeakerMapQuery(mediaId);
    const posthog = usePostHog();

    return (
        <span className="inline-flex items-center gap-2">
            <Select
                value={value}
                key={key}
                onValueChange={(value) => {
                    setValue(value);
                    posthog?.capture("speaker_filter_selected");
                    setSearch((prev) => {
                        prev.set(SEARCH_PARAMS.CURRENT_SPEAKER, value);
                        return prev;
                    });
                }}
            >
                <SelectTrigger className="w-[180px] font-medium">
                    <SelectValue placeholder="Filter by speaker" />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        <SelectLabel>Speaker</SelectLabel>
                        {speakers.map((speaker) => (
                            <SelectItem
                                key={"speaker" + speaker}
                                value={speaker}
                            >
                                {(speakerMap && speakerMap[speaker]) ||
                                    `Speaker ${speaker}`}
                            </SelectItem>
                        ))}
                    </SelectGroup>
                </SelectContent>
            </Select>
            {value !== undefined && (
                <Button
                    variant="ghost"
                    onClick={(e) => {
                        e.preventDefault();
                        setValue(undefined);
                        setKey(+new Date());
                        setSearch((prev) => {
                            prev.delete(SEARCH_PARAMS.CURRENT_SPEAKER);
                            return prev;
                        });
                    }}
                >
                    Reset
                    <X className="ml-2 w-4 h-4" />
                </Button>
            )}
        </span>
    );
};

type TranscriptDisplayToggleProps = {
    lang: Language;
};

const TranscriptDisplayToggle: FC<TranscriptDisplayToggleProps> = ({
    lang,
}) => {
    const {
        toggleOriginalLangTranscriptHidden,
        isOriginalLangTranscriptHidden,
    } = useTranscriptionLayoutStore();
    const posthog = usePostHog();

    return (
        lang !== "en-US" && (
            <Toggle
                onPressedChange={(pressed) => {
                    posthog?.capture("original_language_hidden_toggled");
                    toggleOriginalLangTranscriptHidden(pressed);
                }}
            >
                {isOriginalLangTranscriptHidden ? (
                    <EyeIcon className="w-4 h-4 min-w-4" />
                    ) : (
                    <EyeOff className="w-4 h-4 min-w-4" />
                )}
            </Toggle>
        )
    );
};

const TranscriptListToggle: FC = () => {
    const { togglePointForm, isPointForm } = useTranscriptionLayoutStore();
    const posthog = usePostHog();

    return (
        <Toggle
            onPressedChange={(pressed) => {
                posthog?.capture("point_form_toggled");
                togglePointForm(pressed);
            }}
            id="transcript-list-toggle"
            pressed={isPointForm}
        >
            <List className="w-4 h-4 min-w-4" />
        </Toggle>
    );
};

type TranscriptMiscBarProps = TranscriptFilterSelectProps &
    TranscriptDisplayToggleProps & { mediaId: string };

const TranscriptMiscBar: FC<TranscriptMiscBarProps> = ({
    speakers,
    lang,
    mediaId,
}) => {
    return (
        <div className="px-1 flex justify-between">
            <TranscriptFilterSelect mediaId={mediaId} speakers={speakers} />
            <div className="space-x-1">
                <TranscriptDisplayToggle lang={lang} />
                <TranscriptListToggle />
            </div>
        </div>
    );
};

export default TranscriptMiscBar;
