import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import axios, { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GetMediaUploadPresignedUrlResponse, Media } from "types/backend/media";
import { FORM_KEY } from "@constant/form";
import { usePostHog } from "posthog-js/react";

function validateForm(formData: FormData) {
    const file = formData.get(FORM_KEY.MEDIA.AUDIO_FILE) as File;
    const language = formData.get(FORM_KEY.MEDIA.LANGUAGE) as string;
    if (!file.name || file.size === 0)
        throw new Error("Invalid file, please upload a valid file");
    if (!language)
        throw new Error("Invalid language, please select a correct language");
    return { file, language };
}

async function createMedia(
    meetingId: string,
    key: string,
    file: File,
    language: string,
) {
    const { data } = await api.post<Media>("/media", {
        meetingId,
        key,
        mimeType: file.type,
        language,
        status: "success",
    });
    return data;
}

export async function upload(meetingId: string, file: File) {
    const r2Key = `${meetingId}/${Date.now()}-${file.name}`;
    const {
        data: { presignedURL: uploadUrl },
    } = await api.get<GetMediaUploadPresignedUrlResponse>(
        `/media/r2/upload-url/${encodeURIComponent(r2Key)}`,
    );

    await axios.put(uploadUrl, file, {
        headers: {
            "Content-Type": file.type,
        },
    });
    return { key: r2Key };
}

export async function triggerTranscript(id: string) {
    return api.get<string>(`/media/${id}/trigger_transcript`);
}

export function useUploadFileMutate(meetingId: string | undefined) {
    const queryClient = useQueryClient();
    const { push } = usePopupNotification();
    const posthog = usePostHog();

    const mutation = useMutation({
        mutationKey: [M.UPLOAD_FILE, meetingId],
        mutationFn: async (formData: FormData) => {
            if (!meetingId) return;
            const { file, language } = validateForm(formData);
            posthog?.capture("media_uploaded");
            const { key } = await upload(meetingId, file);
            const { id: mediaId } = await createMedia(
                meetingId,
                key,
                file,
                language,
            );
            return mediaId;
        },
        onError: (error) => {
            const errorMessage =
                error instanceof AxiosError
                    ? error.response?.data.message
                    : error.message;
            push({
                title: "Cannot upload file",
                description: errorMessage,
                type: "destructive",
            });
        },
        onSuccess: async (mediaId) => {
            push({
                title: "Upload video successfully",
                description:
                    "File have been upload, please wait until the transcript ready",
                type: "success",
            });
            queryClient.invalidateQueries({
                queryKey: [Q.GET_MEETING_MEDIA, meetingId],
                exact: true,
            });
            queryClient.invalidateQueries({
                queryKey: [Q.GET_MEETINGS],
            });
            queryClient.invalidateQueries({
                queryKey: [Q.GET_MEDIA],
            });
            if (mediaId) {
                triggerTranscript(mediaId);
            }
        },
    });

    return mutation;
}
