import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { RecordingFile } from "./useGetZoomMeetingRecordsQuery";

export interface ZoomMeeting {
    uuid: string;
    id: number;
    host_id: string;
    topic: string;
    type: number;
    start_time: string;
    duration: number;
    timezone: string;
    created_at: string;
    join_url: string;
    account_id: string;
    total_size: number;
    recording_count: number;
    share_url: string;
    recording_files: Array<RecordingFile>;
    recording_play_passcode: string;
}

export function useGetZoomMeetingsQuery() {
    const query = useQuery<Array<ZoomMeeting>>({
        queryKey: [Q.GET_ZOOM_MEETINGS],
        queryFn: async () => {
            const { data } =
                await api.get<Array<ZoomMeeting>>("/zoom/meetings");
            return data;
        },
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
    });
    return query;
}
