import { FC, HTMLAttributes } from "react";
import { CustomerTag, CustomerTagItemInfo } from "types/backend/customer_tags";
import CustomerTagBadge from "./CustomerTagBadge";
import { cn } from "@libs/utils";
import CustomerTagSelectDialog from "./CustomerTagSelectDialog";

type ItemCustomerTagListProps = {
    itemInfo: CustomerTagItemInfo;
    customerTags: Array<CustomerTag>;
    className?: HTMLAttributes<"div">["className"];
};

const ItemCustomerTagList: FC<ItemCustomerTagListProps> = ({
    itemInfo,
    customerTags,
    className = "",
}) => {
    return (
        <div className={cn("flex items-center gap-1 flex-wrap", className)}>
            {customerTags.map((customerTag) => (
                <CustomerTagBadge
                    customerTag={customerTag}
                    itemInfo={itemInfo}
                    key={itemInfo.itemId + "-" + customerTag.id}
                />
            ))}
            <CustomerTagSelectDialog
                tagIds={customerTags.map((tag) => tag.id)}
                {...itemInfo}
            />
        </div>
    );
};

export default ItemCustomerTagList;
