import { Badge } from "@component-ui/utility/Badge";
import { Speaker } from "@hooks/speaker/useSpeakerMapQuery";
import { formatDuration } from "@libs/duration";
import { useTranscriptionInteractStore } from "@store/transcript-interact-store";
import { useTranscriptionLayoutStore } from "@store/transcript-layout-store";
import { FC, useCallback, useEffect, useRef, useState } from "react";

import TextareaAutosize from "react-textarea-autosize";
import { TranscriptionSegment, TranslationSegment } from "types/backend/media";
import SpeakerRenamingDialog from "./SpeakerRenamingDialog";
import { cn } from "@libs/utils";
import useTranscriptionSegmentUpdate from "@hooks/useTranscriptionSegmentUpdate";
import { PenSquare } from "lucide-react";
import { IconButtonWithTooltip } from "@component-ui/utility/Button";
import useTranslationSegmentUpdate from "@hooks/useTranslationSegmentUpdate";

type TranscriptDisplayProps = {
    hidden: boolean;
    pointform: boolean;
    transcriptSegment: TranscriptionSegment;
};

type TranscriptViewProps = {
    mediaId: string | undefined;
    speakerMap: Record<string, Speaker>;
    transcriptionSegments: TranscriptionSegment[];
    handleSeekPlaybackTimestamp: (time: string) => Promise<void>;
    TranscriptDisplay: React.FC<TranscriptDisplayProps>;
};

const TranscriptView: FC<TranscriptViewProps> = ({
    mediaId,
    transcriptionSegments,
    speakerMap,
    handleSeekPlaybackTimestamp,
    TranscriptDisplay,
}) => {
    const transcriptContainerRef = useRef<HTMLDivElement>(null);
    const initContainer = useTranscriptionInteractStore(
        (state) => state.setTranscriptionSegmentContainer,
    );
    const { isOriginalLangTranscriptHidden, isPointForm } =
        useTranscriptionLayoutStore();
    const [speakerRenamingDialogOpen, setSpeakerRenamingDialogOpen] =
        useState(false);
    const [renamingSpeaker, setRenamingSpeaker] = useState<{
        key: string;
        speaker: Speaker;
    }>({ key: "", speaker: "" });

    useEffect(() => {
        if (transcriptContainerRef.current) {
            initContainer(transcriptContainerRef.current);
        }
    }, []);

    if (!mediaId) return null;

    function handleSpeakerRenamingDialogOpen(key: string, speaker: Speaker) {
        setRenamingSpeaker({ key, speaker });
        setSpeakerRenamingDialogOpen(true);
    }

    return (
        <div
            className="overflow-y-auto h-full px-1"
            ref={transcriptContainerRef}
        >
            {transcriptionSegments.map((segment) => (
                <div
                    id={segment.id}
                    key={segment.id}
                    className="grid grid-cols-[max-content,1fr] items-center gap-4 p-2"
                >
                    <div className="flex flex-col gap-2 w-24">
                        <Badge
                            className="cursor-pointer"
                            onClick={() =>
                                handleSeekPlaybackTimestamp(segment.start)
                            }
                        >
                            {formatDuration(segment.start)}
                        </Badge>
                        <Badge
                            variant="noHover"
                            className="cursor-pointer w-full"
                            onClick={() =>
                                handleSpeakerRenamingDialogOpen(
                                    segment.speaker,
                                    speakerMap[segment.speaker] ||
                                        segment.speaker,
                                )
                            }
                            id="speaker-badge"
                        >
                            <span className="w-full truncate text-center">
                                {speakerMap[segment.speaker]
                                    ? speakerMap[segment.speaker]
                                    : `Speaker: ${segment.speaker}`}
                            </span>
                        </Badge>
                    </div>
                    <TranscriptDisplay
                        hidden={isOriginalLangTranscriptHidden}
                        pointform={isPointForm}
                        transcriptSegment={segment}
                    />
                </div>
            ))}
            <SpeakerRenamingDialog
                mediaId={mediaId}
                speakerKey={renamingSpeaker.key}
                speaker={renamingSpeaker.speaker}
                open={speakerRenamingDialogOpen}
                setOpen={setSpeakerRenamingDialogOpen}
            />
        </div>
    );
};

type TranscriptWithoutEditProps = {
    originalHidden: boolean;
    pointform: boolean;
    transcriptSegment: TranscriptionSegment;
};

const TranscriptWithoutEdit: FC<TranscriptWithoutEditProps> = ({
    originalHidden,
    pointform,
    transcriptSegment,
}) => {
    if (originalHidden) return null;
    return (
        <div
            className={cn(
                "text-xs text-left p-2 rounded-md flex-1",
                "hover:bg-gray-200",
            )}
        >
            {pointform ? (
                <ul>
                    {transcriptSegment.metadata.sentences.map((t, idx) => (
                        <li key={transcriptSegment.transcriptionId + idx}>
                            {t.text}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>
                    {transcriptSegment.metadata.sentences
                        .map((t) => t.text)
                        .join(" ")}
                </p>
            )}
        </div>
    );
};

type TranslateWithoutEditProps = {
    pointform: boolean;
    transcriptSegment: TranscriptionSegment;
};

const TranslateWithoutEdit: FC<TranslateWithoutEditProps> = ({
    pointform,
    transcriptSegment,
}) => {
    return (
        <div className="text-xs text-left p-2 rounded-md flex-1">
            {pointform ? (
                <ul className="list-disc ml-4">
                    {transcriptSegment.translationSegments.map((t, idx) => (
                        <li key={t.id + idx}>
                            {t.metadata.translations.map(
                                (translation) => translation,
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>
                    {transcriptSegment.translationSegments.map((t) =>
                        t.metadata.translations
                            .map((segment) => segment)
                            .join(" "),
                    )}
                </p>
            )}
        </div>
    );
};

type TranscriptWithEditProps = {
    transcriptSegment: TranscriptionSegment;
    originalHidden: boolean;
    pointform: boolean;
    setCurrentSelectedTranscriptId: (id: string) => void;
    currentSelectedTranscriptId: string | null;
};

const TranscriptWithEdit: FC<TranscriptWithEditProps> = ({
    originalHidden,
    transcriptSegment,
    pointform,
    currentSelectedTranscriptId,
    setCurrentSelectedTranscriptId,
}) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isShow, setIsShow] = useState(false);
    const togglePointForm = useTranscriptionLayoutStore(
        (state) => state.togglePointForm,
    );
    const [transcriptionListFormatted, setTranscriptionListFormatted] =
        useState<Array<string>>(
            transcriptSegment.metadata.sentences.map((t) => t.text) ?? [""],
        );

    const { mutate: transcriptionSegmentUpdate } =
        useTranscriptionSegmentUpdate(transcriptSegment.id);

    const handleOnKeyDown = (key: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (key.code === "Enter") {
            setIsEdit(false);
            const metadata = transcriptSegment.metadata;
            const sentences = metadata.sentences.map((sentence, index) => {
                sentence.text = transcriptionListFormatted[index];
                return sentence;
            });
            metadata.sentences = sentences;
            transcriptionSegmentUpdate({ metadata });
        }
    };

    const handleOnchange = useCallback(
        (index: number, event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const copy = [...transcriptionListFormatted];
            copy[index] = event.currentTarget.value;
            setTranscriptionListFormatted(copy);
        },
        [transcriptionListFormatted],
    );

    const onClickEdit = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            if (isEdit) {
                const metadata = transcriptSegment.metadata;
                const sentences = metadata.sentences.map((sentence, index) => {
                    sentence.text = transcriptionListFormatted[index];
                    return sentence;
                });
                metadata.sentences = sentences;
                transcriptionSegmentUpdate({ metadata });
                setIsEdit(false);
            } else {
                setIsEdit(true);
                if (!pointform) {
                    togglePointForm(true);
                }
            }
        },
        [
            pointform,
            togglePointForm,
            setIsEdit,
            isEdit,
            transcriptSegment,
            transcriptionListFormatted,
            transcriptionSegmentUpdate,
        ],
    );

    if (originalHidden) {
        return null;
    }

    return (
        <div
            className={cn(
                "flex-1 relative text-xs text-left p-2 rounded-md cursor-pointer",
                transcriptSegment.id === currentSelectedTranscriptId
                    ? "bg-gray-200 hover:bg-gray-300"
                    : "hover:bg-gray-200",
            )}
            onMouseEnter={() => setIsShow(true)}
            onMouseLeave={() => setIsShow(false)}
            onClick={() => setCurrentSelectedTranscriptId(transcriptSegment.id)}
        >
            {pointform ? (
                <ul className="list-disc ml-4">
                    {transcriptSegment.metadata.sentences.map((item, idx) => (
                        <li
                            key={transcriptSegment.id + idx}
                            className="py-2 pr-2"
                        >
                            {isEdit ? (
                                <TextareaAutosize
                                    value={transcriptionListFormatted[idx]}
                                    className="w-full p-2"
                                    onChange={(e) => handleOnchange(idx, e)}
                                    onKeyDown={handleOnKeyDown}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            ) : (
                                item.text
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>
                    {transcriptSegment.metadata.sentences
                        .map((item) => item.text)
                        .join(" ")}
                </p>
            )}
            {isShow && (
                <IconButtonWithTooltip
                    size="sIcon"
                    variant="ghost"
                    icon={<PenSquare />}
                    content="edit"
                    className={cn(
                        "absolute top-0 left-[-20px] p-1 rounded-none rounded-s-md",
                        transcriptSegment.id === currentSelectedTranscriptId
                            ? "bg-gray-300 hover:bg-gray-300"
                            : "bg-gray-200 hover:bg-gray-200",
                    )}
                    onClick={onClickEdit}
                />
            )}
        </div>
    );
};

const TranslateWithEdit: FC<TranslateWithoutEditProps> = ({
    transcriptSegment,
    pointform,
}) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isShow, setIsShow] = useState<boolean>(false);
    const { togglePointForm } = useTranscriptionLayoutStore();
    const [translationListformatted, setTranslationListformatted] = useState<
        Array<string>
    >(
        transcriptSegment.translationSegments.flatMap((s) =>
            s.metadata.translations.map((t) => t),
        ) ?? [""],
    );

    const { mutate: translationSegmentUpdate } = useTranslationSegmentUpdate(
        transcriptSegment.translationSegments[0]?.id ?? "",
    );

    const handleOnKeyDown = (key: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (key.code === "Enter") {
            setIsEdit(false);
            const translationSegment = transcriptSegment.translationSegments[0];
            if (translationSegment) {
                const metadata = translationSegment.metadata;
                metadata.translations = translationListformatted;
                translationSegmentUpdate({ metadata });
            }
        }
    };

    const handleOnchange = useCallback(
        (index: number, event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const copy = [...translationListformatted];
            copy[index] = event.currentTarget.value;
            setTranslationListformatted(copy);
        },
        [translationListformatted],
    );

    const onClickEdit = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            if (isEdit) {
                setIsEdit(false);
                const translationSegment =
                    transcriptSegment.translationSegments[0];
                if (translationSegment) {
                    const metadata = translationSegment.metadata;
                    metadata.translations = translationListformatted;
                    translationSegmentUpdate({ metadata });
                }
            } else {
                setIsEdit(true);
                if (!pointform) {
                    togglePointForm(true);
                }
            }
        },
        [
            pointform,
            togglePointForm,
            setIsEdit,
            isEdit,
            transcriptSegment,
            translationListformatted,
            translationSegmentUpdate,
        ],
    );

    if (transcriptSegment.translationSegments.length <= 0) {
        return null;
    }

    return (
        <div
            className={cn("flex-1 relative text-xs text-left p-2 rounded-md")}
            onMouseEnter={() => setIsShow(true)}
            onMouseLeave={() => setIsShow(false)}
        >
            {pointform ? (
                <ul className="list-disc ml-4">
                    {transcriptSegment.translationSegments.map(
                        (translationSegment) =>
                            translationSegment.metadata.translations.map(
                                (item, idx) => (
                                    <li
                                        key={translationSegment.id + idx}
                                        className="py-2 pr-2"
                                    >
                                        {isEdit ? (
                                            <TextareaAutosize
                                                value={
                                                    translationListformatted[
                                                        idx
                                                    ]
                                                }
                                                className="w-full p-2"
                                                onChange={(e) =>
                                                    handleOnchange(idx, e)
                                                }
                                                onKeyDown={handleOnKeyDown}
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                            />
                                        ) : (
                                            item
                                        )}
                                    </li>
                                ),
                            ),
                    )}
                </ul>
            ) : (
                <p>
                    {transcriptSegment.translationSegments.map((t) =>
                        t.metadata.translations
                            .map((segment) => segment)
                            .join(" "),
                    )}
                </p>
            )}
            {isShow && (
                <IconButtonWithTooltip
                    size="sIcon"
                    variant="ghost"
                    icon={<PenSquare />}
                    content="edit"
                    className={cn("absolute top-0 right-[-10px] p-1")}
                    onClick={onClickEdit}
                />
            )}
        </div>
    );
};

export {
    TranscriptView,
    TranscriptWithEdit,
    TranscriptWithoutEdit,
    TranslateWithoutEdit,
    TranslateWithEdit,
};
