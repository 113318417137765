import { ComponentProps, FC } from "react";

const SlackIcon: FC<ComponentProps<"svg">> = ({ ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 64 64"
            {...props}
        >
            <g>
                <title>Layer 1</title>
                <g stroke="null" id="svg_1">
                    <g stroke="null" id="svg_2">
                        <path
                            stroke="null"
                            id="svg_3"
                            fill="#E01E5A"
                            d="m13.40423,40.31661c0,3.68876 -3.01336,6.70212 -6.70212,6.70212c-3.68876,0 -6.70212,-3.01336 -6.70212,-6.70212c0,-3.68876 3.01336,-6.70212 6.70212,-6.70212l6.70212,0l0,6.70212z"
                            className="st0"
                        />
                        <path
                            stroke="null"
                            id="svg_4"
                            fill="#E01E5A"
                            d="m16.78127,40.31661c0,-3.68876 3.01336,-6.70212 6.70212,-6.70212s6.70212,3.01336 6.70212,6.70212l0,16.78127c0,3.68876 -3.01336,6.70212 -6.70212,6.70212s-6.70212,-3.01336 -6.70212,-6.70212l0,-16.78127z"
                            className="st0"
                        />
                    </g>
                    <g stroke="null" id="svg_5">
                        <path
                            stroke="null"
                            id="svg_6"
                            fill="#36C5F0"
                            d="m23.48339,13.40423c-3.68876,0 -6.70212,-3.01336 -6.70212,-6.70212c0,-3.68876 3.01336,-6.70212 6.70212,-6.70212s6.70212,3.01336 6.70212,6.70212l0,6.70212l-6.70212,0z"
                            className="st1"
                        />
                        <path
                            stroke="null"
                            id="svg_7"
                            fill="#36C5F0"
                            d="m23.48339,16.78127c3.68876,0 6.70212,3.01336 6.70212,6.70212s-3.01336,6.70212 -6.70212,6.70212l-16.78127,0c-3.68876,0 -6.70212,-3.01336 -6.70212,-6.70212s3.01336,-6.70212 6.70212,-6.70212l16.78127,0z"
                            className="st1"
                        />
                    </g>
                    <g stroke="null" id="svg_8">
                        <path
                            stroke="null"
                            id="svg_9"
                            fill="#2EB67D"
                            d="m50.39577,23.48339c0,-3.68876 3.01336,-6.70212 6.70212,-6.70212c3.68876,0 6.70212,3.01336 6.70212,6.70212s-3.01336,6.70212 -6.70212,6.70212l-6.70212,0l0,-6.70212z"
                            className="st2"
                        />
                        <path
                            stroke="null"
                            id="svg_10"
                            fill="#2EB67D"
                            d="m47.01873,23.48339c0,3.68876 -3.01336,6.70212 -6.70212,6.70212c-3.68876,0 -6.70212,-3.01336 -6.70212,-6.70212l0,-16.78127c0,-3.68876 3.01336,-6.70212 6.70212,-6.70212c3.68876,0 6.70212,3.01336 6.70212,6.70212l0,16.78127z"
                            className="st2"
                        />
                    </g>
                    <g stroke="null" id="svg_11">
                        <path
                            stroke="null"
                            id="svg_12"
                            fill="#ECB22E"
                            d="m40.31661,50.39577c3.68876,0 6.70212,3.01336 6.70212,6.70212c0,3.68876 -3.01336,6.70212 -6.70212,6.70212c-3.68876,0 -6.70212,-3.01336 -6.70212,-6.70212l0,-6.70212l6.70212,0z"
                            className="st3"
                        />
                        <path
                            stroke="null"
                            id="svg_13"
                            fill="#ECB22E"
                            d="m40.31661,47.01873c-3.68876,0 -6.70212,-3.01336 -6.70212,-6.70212c0,-3.68876 3.01336,-6.70212 6.70212,-6.70212l16.78127,0c3.68876,0 6.70212,3.01336 6.70212,6.70212c0,3.68876 -3.01336,6.70212 -6.70212,6.70212l-16.78127,0z"
                            className="st3"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default SlackIcon;
