import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import purify from "dompurify";

const taskitemRegex = /^(\[([( |x])?\])\s.*/;
const taskitemCheckedRegex = /^(\[([x])?\])\s.*/;

function isTaskItem(str: string): boolean {
    return taskitemRegex.test(str);
}

function isTaskItemChecked(str: string): boolean {
    return taskitemCheckedRegex.test(str);
}

function setAttribute(node: Element | null, key: string, value: string) {
    if (!node) return;
    node.setAttribute(key, value);
}

function parseHTMLString(html: string): HTMLElement {
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(html, "text/html");
    return parsedHTML.body;
}

export function transformToCloserFormat(html: string): string {
    const body = parseHTMLString(html);
    for (const child of body.children) {
        transformNodeToCloserFormat(child);
    }
    return body.innerHTML;
}

function transformNodeToCloserFormat(node: Element) {
    switch (node.nodeName) {
        case "UL":
            transformUL(node as HTMLUListElement);
            break;
        case "LI":
            transformLI(node as HTMLLIElement);
            break;
    }
}

function transformUL(node: HTMLUListElement) {
    const firstElementChild = node.firstElementChild;
    if (firstElementChild) {
        if (isTaskItem(firstElementChild.innerHTML)) {
            node.setAttribute("data-type", "taskList");
        }
    }
    for (const child of node.children) {
        switch (child.nodeName) {
            case "LI":
                transformLI(child as HTMLLIElement);
                break;
        }
    }
}

function transformLI(node: HTMLLIElement) {
    const haveChildren = node.children.length > 0;
    if (haveChildren) {
        const firstNode = node.firstChild;
        if (firstNode) {
            transformToTaskItem(firstNode);
        }
        for (const child of node.children) {
            switch (child.nodeName) {
                case "UL":
                    transformUL(child as HTMLUListElement);
                    break;
            }
        }
    } else {
        transformToTaskItem(node);
    }
}

function transformToTaskItem(node: HTMLLIElement | ChildNode) {
    if (node instanceof HTMLLIElement) {
        if (isTaskItem(node.innerHTML)) {
            const firstBracket = node.innerHTML.indexOf("]");
            const htmlString = node.innerHTML.slice(firstBracket + 1);
            node.innerHTML = purify.sanitize(htmlString);
            setAttribute(node, "data-type", TaskItem.name);
            if (isTaskItemChecked(node.innerHTML)) {
                setAttribute(node, "data-checked", "true");
            }
        }
    } else {
        const parent = node.parentElement;
        if (parent) {
            const textContent = node.textContent;
            if (textContent && isTaskItem(textContent)) {
                setAttribute(parent, "data-type", TaskItem.name);
                setAttribute(parent.parentElement, "data-type", TaskList.name);
                const firstBracket = textContent.indexOf("]");
                node.textContent = textContent.slice(firstBracket + 1);
                if (isTaskItemChecked(textContent)) {
                    const isChecked = taskitemCheckedRegex.test(
                        parent.innerHTML,
                    );
                    if (isChecked) {
                        setAttribute(parent, "data-checked", "true");
                    }
                }
            }
        }
    }
}
