import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@component-ui/utility/Select";
import { LOCALSTORAGE } from "@constant/localstorage-key";
import useAccessibleTeamsQuery from "@hooks/user/useAccessibleTeamsQuery";
import useUserSelfInformation from "@hooks/user/useUserSelfInformationQuery";
import api, { API_HEADERS_KEY } from "@libs/api";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";

const CurrentTeamSelect: FC = () => {
    const queryClient = useQueryClient();
    const {
        data: accessibleTeams,
        isLoading,
        isSuccess,
        isError,
    } = useAccessibleTeamsQuery();
    const { data: userInfo } = useUserSelfInformation();
    const [teamId, setTeamId] = useState<string>(userInfo?.teamId || "");

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (isError || !isSuccess) {
        return null;
    }

    if (accessibleTeams.length < 2) {
        return null;
    }

    const onValueChange = (value: string) => {
        setTeamId(value);
        window.localStorage.setItem(LOCALSTORAGE.CLOSER_CURRENT_TEAM, value);
        api.defaults.headers[API_HEADERS_KEY.CURRENT_TEAM] = value;
        queryClient.resetQueries();
    };

    return (
        <Select value={teamId} onValueChange={onValueChange}>
            <SelectTrigger className="w-[180px] font-normal">
                <SelectValue />
            </SelectTrigger>
            <SelectContent>
                {accessibleTeams.map((team) => (
                    <SelectItem key={team.id} value={team.id}>
                        {team.name}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
};

export default CurrentTeamSelect;
