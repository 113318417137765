import Empty from "@component-ui/Empty";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@component-ui/utility/Table";
import useGetSummarizeSystemMessagesQuery from "@hooks/summarize/useGetSummarizingSystemMessagesQuery";
import { FC, useEffect } from "react";
import CreateSystemMessageDialog from "./CreateSystemMessageDialog";
import { Switch } from "@component-ui/utility/Switch";
import dayjs from "@libs/dayjs";
import useUpdateSummarizingSystemMessageMutate from "@hooks/summarize/useUpdateSummarizingSystemMessageMutate";
import SlackIcon from "@component-icons/SlackIcon";
import { usePostHog } from "posthog-js/react";
import UpdateSystemMessageDialog from "./UpdateSystemMessageDialog";

const SystemMessageSettingCapture = () => {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("$pageview", {
            pageName: "/settings/summarizations",
        });
    }, []);

    return null;
};

const SummarizeSetting: FC = () => {
    const {
        data: summarizeSystemMessages,
        isLoading,
        isError,
        isSuccess,
    } = useGetSummarizeSystemMessagesQuery();
    const { mutate: updateSummarizingSystemMessageMutate } =
        useUpdateSummarizingSystemMessageMutate();

    if (isLoading) {
        return (
            <div className="w-full">
                <LoadingSpinnerCenter />
            </div>
        );
    }

    if (isError || !isSuccess) {
        return <Empty label="Cannot get the available summarize option" />;
    }

    return (
        <>
            <SystemMessageSettingCapture />
            <div className="p-12">
                <Table>
                    <TableCaption>A list of summarization prompt</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="text-center">Label</TableHead>
                            <TableHead>Prompt</TableHead>
                            <TableHead className="text-center">
                                Created at
                            </TableHead>
                            <TableHead className="text-center">Slack</TableHead>
                            <TableHead className="text-center">
                                Auto trigger
                            </TableHead>
                            <TableHead />
                            <TableHead>
                                <CreateSystemMessageDialog />
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {summarizeSystemMessages.map(
                            (summarizeSystemMessage) => (
                                <TableRow key={summarizeSystemMessage.id}>
                                    <TableCell className="text-center">
                                        {summarizeSystemMessage.label}
                                    </TableCell>
                                    <TableCell>
                                        {summarizeSystemMessage.systemMessage}
                                    </TableCell>
                                    <TableCell className="text-center">
                                        {dayjs(
                                            summarizeSystemMessage.createdAt,
                                        ).format("YYYY-MM-DD HH:mm")}
                                    </TableCell>
                                    <TableCell className="text-center w-16">
                                        {summarizeSystemMessage.haveSlackWebhook ? (
                                            <SlackIcon width={32} />
                                        ) : (
                                            false
                                        )}
                                    </TableCell>
                                    <TableCell className="text-center">
                                        <Switch
                                            checked={summarizeSystemMessage.autoTrigger}
                                            onCheckedChange={(checked) =>
                                                updateSummarizingSystemMessageMutate(
                                                    {
                                                        id: summarizeSystemMessage.id,
                                                        autoTrigger: checked,
                                                    },
                                                )
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <UpdateSystemMessageDialog
                                            summarizeSystemMessage={
                                                summarizeSystemMessage
                                            }
                                        />
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            ),
                        )}
                    </TableBody>
                </Table>
            </div>
        </>
    );
};

export default SummarizeSetting;
