import { Badge } from "@component-ui/utility/Badge";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@component-ui/utility/HoverCard";
import { formatDuration } from "@libs/duration";
import { Link } from "lucide-react";
import { FC } from "react";
import { TranscriptionSegment } from "types/backend/media";
import "../BlockTranscriptReference.css";
import useGuestGetMeetingQuery from "@hooks/share-to-me/useGuestGetMeetingQuery";

type SharedBlockTranscriptReferenceContentProps = {
    transcriptSegment: TranscriptionSegment;
};

type SharedBlockTranscriptReferenceProps =
    Partial<SharedBlockTranscriptReferenceContentProps>;

const SharedBlockTranscriptReferenceContent: FC<
    SharedBlockTranscriptReferenceContentProps
> = ({ transcriptSegment }) => {
    const { start, metadata, meetingId } = transcriptSegment;

    const {
        data: meeting,
        isSuccess,
        isError,
    } = useGuestGetMeetingQuery(meetingId);

    if (!transcriptSegment) {
        return null;
    }

    if (!isSuccess || isError) {
        return null;
    }

    const transcriptContent = (
        <>
            <span>
                <Badge variant="noHover">{formatDuration(start)}</Badge>
            </span>
            <span className="ml-2">
                {metadata.sentences.map((sentence) => sentence.text).join(" ")}
            </span>
        </>
    );

    return (
        <HoverCard openDelay={300} closeDelay={100}>
            <HoverCardTrigger asChild>
                <div className="transcription-segment-bubble p-2 mb-2 flex flex-col gap-1 text-sm items-start rounded bg-gray-300 cursor-pointer">
                    <span className="w-full truncate min-w-0">
                        {transcriptContent}
                    </span>
                    <span className="inline-flex items-center font-medium text-sm text-primary">
                        <Link className="w-4 h-4 mr-2" />
                        {meeting.label}
                    </span>
                </div>
            </HoverCardTrigger>
            <HoverCardContent align="end">
                <div className="space-y-1 flex flex-col justify-center text-sm">
                    <h4 className="font-semibold">{meeting.label}</h4>
                    <span>{transcriptContent}</span>
                </div>
            </HoverCardContent>
        </HoverCard>
    );
};

const SharedBlockTranscriptReference: FC<
    SharedBlockTranscriptReferenceProps
> = ({ transcriptSegment }) => {
    return (
        transcriptSegment && (
            <SharedBlockTranscriptReferenceContent
                transcriptSegment={transcriptSegment}
            />
        )
    );
};

export default SharedBlockTranscriptReference;
