export const languageOptions = [
    {
        label: "English",
        value: "en-US",
    },
    {
        label: "Portuguese (Brazil)",
        value: "pt-BR",
    },
    {
        label: "Spanish",
        value: "es",
    },
] as const;

export type Language = (typeof languageOptions)[number]["value"];
