import TenantUserAvatar from "@component-func/TenantUserAvatar";
import { humanizeDate } from "@libs/duration";
import { cn } from "@libs/utils";
import { Link } from "react-router-dom";
import { TenantUser } from "types/backend/tenant-user";

type NavigatableTableRowProps<T> = T & {
    to: string;
    label: string;
    selected: boolean;
};

const NavigatableSidebarRow = <
    T extends { tenantUser: TenantUser | undefined; createdAt: string | Date },
>({
    to,
    label,
    tenantUser,
    createdAt,
    selected,
}: NavigatableTableRowProps<T>) => {
    return (
        <Link to={to}>
            <div
                className={cn(
                    selected && "bg-gray-100/90",
                    "grid grid-flow-row w-full items-center rounded-md mb-2 p-2 hover:bg-gray-100/90 overflow-hidden shadow-sm",
                )}
            >
                <h1 className="text-primary text-base font-medium truncate">
                    {label}
                </h1>
                <div className="grid grid-cols-[1fr,max-content] text-xs items-center">
                    <div className="flex flex-row justify-start items-center truncate">
                        <div className="truncate">Created by </div>
                        <TenantUserAvatar
                            tenantUser={tenantUser}
                            className="h-6 w-6"
                        />
                    </div>
                    <div className="justify-self-end">
                        <span className="truncate w-full">
                            {humanizeDate(createdAt)}
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default NavigatableSidebarRow;
