import { Q } from "@constant/query-key";
import { CRM_QUERY_VARIANT } from "@constant/resource";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { CrmQueryVariant, ResourceOption, ResourcePlatform, ResourceType } from "types/backend/resources";


async function fetchResourceOptions(
    resourcePlatform: ResourcePlatform,
    resourceType: ResourceType,
    query: string,
    variant?: CrmQueryVariant,
) {
    const { data } = await api.get<Array<ResourceOption>>(
        `/resource-config/${resourcePlatform}/${resourceType}/options?variant=${variant}&query=${query || ""}`,
    );
    return data;
}

export default function useCrmResourceOptionsQuery(
    resourcePlatform: ResourcePlatform,
    resourceType: ResourceType,
    query: string,
    variant?: CrmQueryVariant,
) {
    const _variant = variant ? variant : CRM_QUERY_VARIANT.FILTER_OBJECT_DATA;
    const result = useQuery({
        queryKey: [
            Q.GET_CRM_RESOURCE_OPTIONS,
            resourcePlatform,
            resourceType,
            _variant,
            query,
        ],
        queryFn: () =>
            fetchResourceOptions(
                resourcePlatform,
                resourceType,
                query,
                _variant,
            ),
        enabled: isQueryEnabled(resourceType, query, variant),
    });

    return result;
}

function isQueryEnabled(
    resourceType: ResourceType,
    query: string,
    variant?: CrmQueryVariant,
): boolean {
    const basic = !!resourceType;
    if (!variant || variant === CRM_QUERY_VARIANT.FILTER_OBJECT_DATA) {
        return basic;
    }
    if (variant === CRM_QUERY_VARIANT.EXACT_OBJECT_ID) {
        return basic && !!query;
    }
    if (variant === CRM_QUERY_VARIANT.SEARCH_WITH_CONTACT) {
        return basic && query.length > 4;
    }
    return false;
}
