import { LOCALSTORAGE } from "@constant/localstorage-key";
import { M } from "@constant/mutation-key";
import { guestApi } from "@libs/api";
import { useMutation } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { GuestValidateResponse } from "types/backend/guest";

export default function useGuestValidation() {
    const posthog = usePostHog();
    const mutate = useMutation({
        mutationKey: [M.GUEST_VALIDATION],
        mutationFn: async (body: {
            email: string;
            validationToken: string;
        }) => {
            const { data } = await guestApi.post<GuestValidateResponse>(
                "/auth/validation",
                {
                    email: body.email,
                    validation_token: body.validationToken,
                },
            );
            return data;
        },
        onSuccess: (data) => {
            posthog?.capture("guest_validated", {
                $set: {
                    email: data.email,
                    guestId: data.id,
                },
            });
            localStorage.setItem(LOCALSTORAGE.CLOSER_GUEST_JWT, data.jwt);
            guestApi.defaults.headers["Authorization"] = "Bearer " + data.jwt;
        },
    });

    return mutate;
}
