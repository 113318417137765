import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { Meeting } from "types/backend/meetings";

export default function useShareMeetingQuery() {
    const query = useQuery({
        queryKey: [Q.GET_ALL_SHARE_MEETING],
        queryFn: async () => {
            const { data } = await api.get<Meeting[]>("/meetings/share");
            return data;
        },
    });
    return query;
}
