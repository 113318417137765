import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";

export default function useSummarizeMutate(
    mediaId: string,
    summarizingSystemMessageId: string,
) {
    const { push } = usePopupNotification();
    const queryClient = useQueryClient();
    const posthog = usePostHog();

    const mutate = useMutation<
        any,
        AxiosError<{ message: string }>,
        { mediaId: string; systemMessageId: string }
    >({
        mutationKey: [
            M.GENERATE_TRANSCRIPT_SUMMARIZE,
            mediaId,
            summarizingSystemMessageId,
        ],
        mutationFn: async (body: {
            mediaId: string;
            systemMessageId: string;
        }) => {
            const { mediaId, systemMessageId } = body;
            posthog?.capture("summary_generated", {
                mediaId,
                systemMessageId,
            });
            const { data } = await api.post("/summarize/trigger_summarize", {
                mediaId,
                systemMessageId,
            });
            return data;
        },
        onSuccess: (_, { mediaId, systemMessageId }) => {
            queryClient.invalidateQueries({
                queryKey: [Q.GET_SUMMARIZE, mediaId, systemMessageId],
                exact: true,
            });
        },
        onError: (error) => {
            push({
                type: "warning",
                title: "Cannot generate summarize",
                description: error.response?.data.message || error.message,
            });
        },
    });
    return mutate;
}
