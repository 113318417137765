import { createRoot } from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./main.css";

import "tippy.js/animations/scale.css";
import {
    HashRouter,
    Route,
    Routes,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import MainLayout from "@component-layout/MainLayout";
import MediaTable from "@component-func/media/MediaTable";
import { PopupNotificationProvider } from "@context/popup-notification";
import { AuthProvider } from "@context/auth";
import Authentication from "@component-func/auth/Authentication";
import { DocumentTemplateDetail } from "@component-func/document_template/DocumentTemplateDetail";
import AppLayout from "@component-layout/AppLayout";
import MeetingTable from "@component-func/meetings/MeetingTable";
import DocumentTable from "@component-func/document/DocumentTable";
import DocumentDetail from "@component-func/document/DocumentDetail";
import DocumentTemplateTable from "@component-func/document_template/DocumentTemplateTable";
import UserInformation from "@component-func/settings/UserInformation";
import CustomerTagTable from "@component-func/customer-tag/CustomerTagTable";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { posthog } from "posthog-js";
import SignUp from "@component-func/auth/SignUp";
import ResetPassword from "@component-func/auth/ResetPassword";
import ForgotPassword from "@component-func/auth/ForgotPassword";
import { NotActive } from "@component-func/NotActive";
import Verification from "@component-func/auth/Verification";
import CrmConnection from "@component-func/settings/CrmConnection";
import OAuth from "@component-func/oAuth/OAuth";
import ZoomMeetingTable from "@component-func/zoom/ZoomMeetingTable";
import { ProtectedRoute } from "@component-layout/ProtectedRoute";
import { TenantUserSelfInformation } from "types/backend/tenant-user";
import ChatwootLiveChat from "@component-func/live-chat/ChatwootLiveChat";
import PreviewValidate from "@component-func/shareable/PreviewValidate";
import PreviewLayout from "@component-func/shareable/PreviewLayout";
import PreviewError from "@component-func/shareable/PreviewError";
import ShareToMeTable from "@component-func/share-to-me/ShareToMeTable";
import {
    SharedTranscript,
    Transcript,
} from "@component-func/transcript/TranscriptRoot";
import GoogleMeetingTable from "@component-func/google-meet/GoogleMeetingTable";
import SummarizeSetting from "@component-func/summarize/SummarizeSetting";
import GoogleCalendarRoot from "@component-func/settings/google-calendar/GoogleCalendar";
import PusherWrapper from "@component-func/pusher/PusherWrapper";

const posthogKey =
    process.env.NODE_ENV === "production"
        ? import.meta.env.VITE_POSTHOG_PRODUCTION_KEY
        : import.meta.env.VITE_POSTHOG_STAGING_KEY;
posthog.init(posthogKey, {
    api_host: import.meta.env.VITE_POSTHOG_HOST,
    autocapture: {
        dom_event_allowlist: ["click", "submit"],
        element_allowlist: ["a", "button"],
    },
    persistence: "cookie",
    loaded: (posthog) => {
        if (process.env.NODE_ENV === "development") {
            // INFO: debug mode of posthog, the events captured will be logged instead of sending to posthog
            posthog.debug();
            // INFO: opt_out_capturing to prevent capturing
            posthog.opt_out_capturing();
            // INFO: opt_in_capturing for debugging posthog events
            // posthog.opt_in_capturing();
        }
    },
});

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration({
            blockAllMedia: true,
            maskAllText: true,
        }),
    ],
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function Dev() {
    if (process.env.NODE_ENV === "development")
        return (
            <>
                <h1 className="absolute top-2 right-24">
                    ENV: {process.env.NODE_ENV}
                </h1>
                <ReactQueryDevtools position="bottom" />
            </>
        );
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
    const recordingIntegrationValidation = (
        tenantUserSelfInformation: TenantUserSelfInformation,
    ) => {
        const { oauth } = tenantUserSelfInformation;
        if (oauth) {
            return oauth.zoom || oauth.google;
        }
        return true;
    };

    return (
        <GoogleOAuthProvider
            clientId={import.meta.env.VITE_GOOGLE_CLIENT_KEY ?? ""}
        >
            <PostHogProvider client={posthog}>
                <QueryClientProvider client={queryClient}>
                    <PopupNotificationProvider>
                        <HashRouter>
                            <main className="h-full">
                                <AuthProvider>
                                    <PusherWrapper />
                                    <ChatwootLiveChat />
                                    <SentryRoutes>
                                        <Route
                                            path="/guest/preview"
                                            Component={PreviewValidate}
                                        />
                                        <Route Component={PreviewLayout}>
                                            <Route
                                                path="/guest/preview/:meetingId/:mediaId/transcript"
                                                element={
                                                    <SharedTranscript userType="guest" />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path="/guest/preview/error"
                                            Component={PreviewError}
                                        />
                                        <Route
                                            index
                                            path="/oauth/:platform"
                                            Component={OAuth}
                                        />
                                        <Route Component={AppLayout}>
                                            <Route element={<MainLayout />}>
                                                <Route
                                                    index
                                                    Component={MeetingTable}
                                                />
                                                <Route
                                                    path="/meetings"
                                                    Component={MeetingTable}
                                                />
                                                <Route
                                                    path="/meetings/share/:meetingId"
                                                    Component={ShareToMeTable}
                                                />
                                                <Route
                                                    path="/meetings/share/:meetingId/:mediaId/transcript"
                                                    element={
                                                        <SharedTranscript userType="shared-user" />
                                                    }
                                                />
                                                <Route path="/documents">
                                                    <Route
                                                        index
                                                        Component={
                                                            DocumentTable
                                                        }
                                                    />
                                                    <Route
                                                        path=":documentId"
                                                        Component={
                                                            DocumentDetail
                                                        }
                                                    />
                                                </Route>
                                                <Route path="/document-templates">
                                                    <Route
                                                        index
                                                        Component={
                                                            DocumentTemplateTable
                                                        }
                                                    />
                                                    <Route
                                                        path="/document-templates/:documentTemplateId"
                                                        Component={
                                                            DocumentTemplateDetail
                                                        }
                                                    />
                                                </Route>
                                                <Route path="/meeting-transcripts">
                                                    <Route
                                                        index
                                                        Component={Index}
                                                    />
                                                    <Route
                                                        path=":meetingId"
                                                        Component={MediaTable}
                                                    />
                                                    <Route
                                                        path=":meetingId/:mediaId/transcript"
                                                        element={<Transcript />}
                                                    />
                                                </Route>
                                                <Route path="/settings">
                                                    <Route
                                                        index
                                                        Component={
                                                            UserInformation
                                                        }
                                                    />
                                                    <Route
                                                        path="user-information"
                                                        Component={
                                                            UserInformation
                                                        }
                                                    />
                                                    <Route
                                                        path="summarization"
                                                        Component={
                                                            SummarizeSetting
                                                        }
                                                    />
                                                    <Route
                                                        path="tags"
                                                        Component={
                                                            CustomerTagTable
                                                        }
                                                    />
                                                    <Route
                                                        path="crm-connection"
                                                        Component={
                                                            CrmConnection
                                                        }
                                                    />
                                                    <Route
                                                        path="google-calendar"
                                                        Component={
                                                            GoogleCalendarRoot
                                                        }
                                                    />
                                                </Route>
                                                <Route path="/recording-download">
                                                    <Route
                                                        index
                                                        element={
                                                            <ProtectedRoute
                                                                redirectPath="/meetings"
                                                                validation={
                                                                    recordingIntegrationValidation
                                                                }
                                                            >
                                                                <div className="h-full grid place-items-center">
                                                                    <h1 className="text-3xl text-gray-400 tracking-wide font-light">
                                                                        Select a
                                                                        meeting
                                                                        to view
                                                                        recordings
                                                                    </h1>
                                                                </div>
                                                            </ProtectedRoute>
                                                        }
                                                    />
                                                    <Route
                                                        path="zoom/:zoomMeetingId"
                                                        element={
                                                            <ProtectedRoute
                                                                redirectPath="/meetings"
                                                                validation={
                                                                    recordingIntegrationValidation
                                                                }
                                                            >
                                                                <ZoomMeetingTable />
                                                            </ProtectedRoute>
                                                        }
                                                    />
                                                    <Route
                                                        path="google-meets/:googleMeetingId"
                                                        element={
                                                            <ProtectedRoute
                                                                redirectPath="/meetings"
                                                                validation={
                                                                    recordingIntegrationValidation
                                                                }
                                                            >
                                                                <GoogleMeetingTable />
                                                            </ProtectedRoute>
                                                        }
                                                    />
                                                </Route>
                                            </Route>
                                        </Route>
                                        <Route
                                            index
                                            path="/not_active"
                                            Component={NotActive}
                                        />
                                        <Route
                                            index
                                            path="/verify"
                                            Component={Verification}
                                        />
                                        <Route
                                            index
                                            path="/login"
                                            Component={Authentication}
                                        />
                                        <Route
                                            index
                                            path="/sign_up"
                                            Component={SignUp}
                                        />
                                        <Route
                                            index
                                            path="/reset_password"
                                            Component={ResetPassword}
                                        />
                                        <Route
                                            index
                                            path="/forgot_password"
                                            Component={ForgotPassword}
                                        />
                                    </SentryRoutes>
                                </AuthProvider>
                            </main>
                        </HashRouter>
                    </PopupNotificationProvider>
                    <Dev />
                </QueryClientProvider>
            </PostHogProvider>
        </GoogleOAuthProvider>
    );
}

function Index() {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("transcript_index_page_navigated");
    }, []);

    return (
        <div className="h-full grid place-items-center">
            <h1 className="text-3xl text-gray-400 tracking-wide font-light">
                Select a meeting to view the uploaded media
            </h1>
        </div>
    );
}

const rootElement = document.getElementById("app");
if (!rootElement) throw new Error("#app not found");
const root = createRoot(rootElement);
root.render(<App />);
