import Empty from "@component-ui/Empty";
import { FC } from "react";
import { Media } from "types/backend/media";

type MediaInfoProps = {
    media: Media;
};

const MediaInfo: FC<MediaInfoProps> = ({ media }) => {
    return media.meeting ? (
        <div>{media.meeting.label}</div>
    ) : (
        <Empty label="media not ready" />
    );
};

export default MediaInfo;
