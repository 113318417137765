import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { Media } from "types/backend/media";

export function useMeetingMediaQuery(meetingId: string | undefined) {
    const query = useQuery({
        queryKey: [Q.GET_MEETING_MEDIA, meetingId],
        queryFn: async () => {
            const { data } = await api.get<Media[]>(
                `/meetings/${meetingId}/media`,
            );
            return data;
        },
        enabled: !!meetingId,
        staleTime: 60 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
    });
    return query;
}
