import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { TeamPermission } from "types/backend/team-permission";

export default function useTeamPermission() {
    const query = useQuery({
        queryKey: [Q.GET_TEAM_PERMISSION],
        queryFn: async () => {
            const { data } = await api.get<TeamPermission>("/teams/permissions");
            return data;
        },
        refetchInterval: 15 * 60 * 1000,
        refetchOnWindowFocus: true,
        staleTime: 15 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
    });
    return query
}
