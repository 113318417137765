import { Badge } from "@component-ui/utility/Badge";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@component-ui/utility/HoverCard";
import { SEARCH_PARAMS } from "@constant/searchParams";
import useMediaTranscriptQuery from "@hooks/useTranscriptQuery";
import { formatDuration } from "@libs/duration";
import { isVisible } from "@libs/utils";
import { useTranscriptionInteractStore } from "@store/transcript-interact-store";
import { useCurrentDocumentStore } from "@store/currentDocumentStore";
import { Link } from "lucide-react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { TranscriptionSegment } from "types/backend/media";
import "./BlockTranscriptReference.css";
import { usePostHog } from "posthog-js/react";

type BlockTranscriptReferenceContentProps = {
    transcriptSegment: TranscriptionSegment;
};

type BlockTranscriptReferenceProps =
    Partial<BlockTranscriptReferenceContentProps>;

const BlockTranscriptReferenceContent: FC<
    BlockTranscriptReferenceContentProps
> = ({ transcriptSegment }) => {
    const { id, meetingId, mediaId, start, metadata } = transcriptSegment;

    const transcriptionSegmentContainer = useTranscriptionInteractStore(
        (state) => state.transcriptionSegmentContainer,
    );
    const navigate = useNavigate();
    const posthog = usePostHog();
    const {
        data: mediaTranscript,
        isSuccess,
        isError,
    } = useMediaTranscriptQuery(mediaId);
    const currentDocument = useCurrentDocumentStore(
        (state) => state.currentDocumentId,
    );

    if (!transcriptSegment) {
        return null;
    }

    if (!isSuccess || isError) {
        return null;
    }

    const { key, mimeType, meeting } = mediaTranscript;

    function seekTranscript(id: string) {
        if (!transcriptionSegmentContainer || !id) {
            return;
        }
        const targetTranscript =
            transcriptionSegmentContainer.children.namedItem(id);
        if (!targetTranscript) {
            return;
        }
        if (!isVisible(targetTranscript, transcriptionSegmentContainer)) {
            targetTranscript.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
        const originStyle = targetTranscript.className;
        if (originStyle.includes("animate-transcript-bg-link")) return;
        targetTranscript.className =
            originStyle + " animate-transcript-bg-link";
        setTimeout(() => {
            targetTranscript.className = originStyle;
        }, 2500);
    }

    async function handleTranscriptReferenceClick() {
        if (!currentDocument) return;
        posthog?.capture("transcript_reference_clicked");
        await new Promise((res) => {
            navigate(
                `/meeting-transcripts/${meetingId}/${mediaId}/transcript?${new URLSearchParams(
                    {
                        key,
                        type: mimeType,
                        [SEARCH_PARAMS.CURRENT_DOCUMENT]: currentDocument,
                    },
                ).toString()}`,
            );
            res(null);
        });
        seekTranscript(id);
    }

    const transcriptContent = (
        <>
            <span>
                <Badge variant="noHover">{formatDuration(start)}</Badge>
            </span>
            <span className="ml-2">
                {metadata.sentences.map((sentence) => sentence.text).join(" ")}
            </span>
        </>
    );

    return (
        <HoverCard openDelay={300} closeDelay={100}>
            <HoverCardTrigger asChild>
                <div
                    className="transcription-segment-bubble p-2 mb-2 flex flex-col gap-1 text-sm items-start rounded bg-gray-300 cursor-pointer"
                    onClick={() => handleTranscriptReferenceClick()}
                >
                    <span className="w-full truncate min-w-0">
                        {transcriptContent}
                    </span>
                    <span className="inline-flex items-center font-medium text-sm text-primary">
                        <Link className="w-4 h-4 mr-2" />
                        {meeting.label}
                    </span>
                </div>
            </HoverCardTrigger>
            <HoverCardContent align="end">
                <div className="space-y-1 flex flex-col justify-center text-sm">
                    <h4 className="font-semibold">{meeting.label}</h4>
                    <span>{transcriptContent}</span>
                </div>
            </HoverCardContent>
        </HoverCard>
    );
};

const BlockTranscriptReference: FC<BlockTranscriptReferenceProps> = ({
    transcriptSegment,
}) => {
    return (
        transcriptSegment && (
            <BlockTranscriptReferenceContent
                transcriptSegment={transcriptSegment}
            />
        )
    );
};

export default BlockTranscriptReference;
