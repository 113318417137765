import TenantUserAvatar from "@component-func/TenantUserAvatar";
import NavigatableTable, {
    NavigatableTableProps,
} from "@component-generic/NavigatableTable";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { v4 as uuidV4 } from "uuid";
import { Button } from "@component-ui/utility/Button";
import { TableCell } from "@component-ui/utility/Table";
import useShareMediaByMeetingQuery from "@hooks/share-to-me/useShareMediaByMeetingQuery";
import { humanizeDate } from "@libs/duration";
import { ChevronRight } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { FC, useEffect, useMemo } from "react";
import { Link, Navigate, useParams } from "react-router-dom";

type PathParam = {
    meetingId: string;
};

const ShareToMeTable: FC = () => {
    const { meetingId } = useParams<PathParam>();
    const {
        data: sharedMediaList,
        isLoading,
        isSuccess,
        isError,
    } = useShareMediaByMeetingQuery(meetingId);
    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture("share_media_table_page_navigated");
    }, []);


    const tableProps = useMemo<NavigatableTableProps>(() => {
        return {
            back: "/meetings",
            caption: "List of shared media",
            heads: sharedMediaList
                ? [
                      {
                          id: uuidV4(),
                          content: <div>Language</div>,
                      },
                      {
                          id: uuidV4(),
                          content: (
                              <div className="text-center">Uploaded by</div>
                          ),
                      },
                      {
                          id: uuidV4(),
                          content: <div className="w-48">Created at</div>,
                      },
                      {
                          id: uuidV4(),
                          content: <div className="w-12">Transcript</div>,
                      },
                  ]
                : [],
            rows: sharedMediaList
                ? sharedMediaList.map((m) => ({
                      id: m.id,
                      content: (
                          <>
                              <TableCell>{m.language}</TableCell>
                              <TableCell className="flex justify-center items-center">
                                  <TenantUserAvatar tenantUser={m.tenantUser} />
                              </TableCell>
                              <TableCell>{humanizeDate(m.createdAt)}</TableCell>
                              <TableCell>
                                  <Link
                                      to={`${m.id}/transcript?key=${encodeURIComponent(m.key)}&type=${m.mimeType}`}
                                  >
                                      <Button size="icon" variant="ghost">
                                          <ChevronRight className="w-8 h-8" />
                                      </Button>
                                  </Link>
                              </TableCell>
                          </>
                      ),
                  }))
                : [],
            fallbackFooter:
                "Do not have shared media yet, you can upload media files and view it here",
        };
    }, [sharedMediaList]);
    
    if (!meetingId) return <Navigate to="/" />;

    if (isLoading) return <LoadingSpinnerCenter />;

    if (!isSuccess || isError)
    return (
        <div>
            <h1 className="text-4xl text-foreground/50">
                Cannot get the shared media for this meeting, please try
                again
            </h1>
        </div>
    );

    return <NavigatableTable {...tableProps} />;
};

export default ShareToMeTable;
