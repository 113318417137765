import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import useCustomerTags from "@hooks/customer-tag/useCustomerTagsQuery";
import { Pencil, Plus, Trash2 } from "lucide-react";
import { FC, useEffect, useRef, useState } from "react";
import CreateOrUpdateCustomerTagDialog from "./CreateOrUpdateCustomerTagDialog";
import { CustomerTag } from "types/backend/customer_tags";
import ConfirmRemoveCustomerTagDialog from "@component-func/customer-tag/ConfirmRemoveCustomerTagDialog";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@component-ui/utility/Table";
import CustomerTagBadge from "./CustomerTagBadge";
import TenantUserAvatar from "@component-func/TenantUserAvatar";
import { usePostHog } from "posthog-js/react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";

const CustomerTagTable: FC = () => {
    const {
        data: customerTags,
        isSuccess,
        isError,
        isLoading,
        isFetching,
        refetch,
    } = useCustomerTags();
    const [createOrUpdateDialogOpen, setCreateOrUpdateDialogOpen] =
        useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const selectedTagRef = useRef<CustomerTag | null>(null);
    const posthog = usePostHog();

    useEffect(() => {
        posthog?.capture("customer_tag_table_page_navigated");
        posthog?.capture("$pageview", { pageName: "/settings/tags" });
    }, []);

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return (
            <div className="h-full grid place-items-center ">
                <h1 className="text-4xl text-foreground/50">
                    Cannot get the list of tags, please try again
                </h1>
                <Button onClick={() => refetch()}>Retry</Button>
            </div>
        );
    }

    return (
        <div className="h-full w-full p-12 grid grid-rows-[max-content,1fr] overflow-hidden">
            <h1 className="text-2xl font-bold mb-12">Tags</h1>
            <div className="h-full w-full overflow-y-auto">
                {isFetching ? (
                    <LoadingSpinnerCenter />
                ) : (
                    <TooltipProvider delayDuration={200}>
                        <Table className="px-4">
                            <TableCaption>List of Tags</TableCaption>
                            <TableHeader>
                                <TableRow>
                                    <TableHead className="w-60">Tag</TableHead>
                                    <TableHead>Description</TableHead>
                                    <TableHead className="text-center w-[120px]">
                                        Created by
                                    </TableHead>
                                    <TableHead colSpan={2} className="text-end">
                                        <Button
                                            size={"icon"}
                                            variant={"ghost"}
                                            onClick={() =>
                                                setCreateOrUpdateDialogOpen(
                                                    true,
                                                )
                                            }
                                        >
                                            <Plus />
                                        </Button>
                                    </TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {customerTags.map((customerTag) => (
                                    <TableRow key={customerTag.id}>
                                        <TableCell>
                                            <CustomerTagBadge
                                                customerTag={customerTag}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {customerTag.description}
                                        </TableCell>
                                        <TableCell className="flex justify-center items-center">
                                            <TenantUserAvatar
                                                tenantUser={
                                                    customerTag.tenantUser
                                                }
                                            />
                                        </TableCell>
                                        <TableCell className="text-end w-8">
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        size={"icon"}
                                                        variant={"ghost"}
                                                        onClick={() => {
                                                            selectedTagRef.current =
                                                                customerTag;
                                                            setCreateOrUpdateDialogOpen(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        <Pencil />
                                                    </Button>
                                                </TooltipTrigger>
                                                <TooltipContent side="bottom">
                                                    Edit Tag
                                                </TooltipContent>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className="text-end w-8">
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        size={"icon"}
                                                        variant={"ghost"}
                                                        onClick={() => {
                                                            selectedTagRef.current =
                                                                customerTag;
                                                            setConfirmDialogOpen(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        <Trash2 />
                                                    </Button>
                                                </TooltipTrigger>
                                                <TooltipContent side="bottom">
                                                    Remove Tag
                                                </TooltipContent>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TooltipProvider>
                )}
            </div>
            <CreateOrUpdateCustomerTagDialog
                open={createOrUpdateDialogOpen}
                setOpen={setCreateOrUpdateDialogOpen}
                editingTagRef={selectedTagRef}
            />
            <ConfirmRemoveCustomerTagDialog
                open={confirmDialogOpen}
                setOpen={setConfirmDialogOpen}
                removingTagRef={selectedTagRef}
            />
        </div>
    );
};

export default CustomerTagTable;
