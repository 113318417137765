import { FC } from "react";

export const HubspotSvg: FC<{ size?: "default" | "sm" }> = ({
    size = "default",
}) => {
    let svgSize = "40px";
    if (size === "sm") {
        svgSize = "18px";
    }

    return (
        <svg
            width={svgSize}
            height={svgSize}
            viewBox="0 0 40 40"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill="#FFFFFF"
                d="M30.273 13.217V8.473a3.663 3.663 0 0 0 2.112 -3.297v-0.112a3.667 3.667 0 0 0 -3.655 -3.656h-0.112a3.667 3.667 0 0 0 -3.655 3.655v0.112a3.66 3.66 0 0 0 2.087 3.288l0.022 0.01v4.753a10.367 10.367 0 0 0 -4.948 2.183l0.02 -0.017L9.097 5.234a4.162 4.162 0 1 0 -1.93 2.526l-0.02 0.01 12.828 9.985a10.293 10.293 0 0 0 -1.73 5.743 10.367 10.367 0 0 0 1.912 6.012l-0.022 -0.033 -3.903 3.905a3.28 3.28 0 0 0 -0.967 -0.158h-0.003a3.388 3.388 0 1 0 3.388 3.388 3.297 3.297 0 0 0 -0.167 -0.992l0.008 0.023 3.862 -3.862a10.412 10.412 0 1 0 7.97 -18.557l-0.06 -0.008zm-1.607 15.63a5.343 5.343 0 1 1 5.358 -5.345v0.003a5.343 5.343 0 0 1 -5.345 5.345z"
            />
        </svg>
    );
};
