import { Button } from "@component-ui/utility/Button";
import { LOCALSTORAGE } from "@constant/localstorage-key";
import { useAuth } from "@context/auth";
import useResendVerificationEmail from "@hooks/user/useResendVerificationEmail";
import useUserSelfInformation from "@hooks/user/useUserSelfInformationQuery";
import { jwtDecode } from "jwt-decode";
import { FC, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const NotActive: FC = () => {
    const counter = useRef<NodeJS.Timeout | undefined>();
    const { isAuth, setIsActive } = useAuth();
    const [disabled, setDisabled] = useState(false);
    const [count, setCount] = useState(60);
    const { mutate: resendVerificationEmail } = useResendVerificationEmail();
    const navigate = useNavigate();

    const { data: tenantUser } = useUserSelfInformation();

    const jwt = window.localStorage.getItem(LOCALSTORAGE.CLOSER_JWT);

    const onClickHandle = () => {
        if (jwt) {
            const { sub } = jwtDecode(jwt);
            sub &&
                resendVerificationEmail(
                    { id: sub },
                    {
                        onSuccess() {
                            setIsActive(true);
                        },
                    },
                );
        }
        setDisabled(true);
        counter.current = setInterval(() => {
            setCount((v) => {
                const update = v - 1;
                return update;
            });
        }, 1000);
    };

    useEffect(() => {
        if (count === 0) {
            setCount(60);
            clearInterval(counter.current);
            setDisabled(false);
        }
    }, [count]);

    useEffect(() => {
        if (tenantUser) {
            if (tenantUser.isActive) {
                window.localStorage.setItem(
                    LOCALSTORAGE.CLOSER_ACCOUNT_ACTIVE,
                    tenantUser.isActive.toString(),
                );
                setIsActive(true);
                navigate("/");
            }
        }
    }, [tenantUser]);

    if (!isAuth) return <Navigate to="/login" />;

    return (
        <div className="w-full h-full grid place-items-center">
            <div className="w-full max-w-lg flex flex-col justify-center items-center p-4 rounded-md border-2 border-gray-400">
                <h1 className="text-2xl">Your account is not activated</h1>
                <h2 className="mt-3">Please check your email and verify</h2>
                <Button
                    className="w-full mt-4"
                    disabled={disabled}
                    onClick={onClickHandle}
                >
                    Resend verification email {disabled ? `(${count}'s)` : null}
                </Button>
            </div>
        </div>
    );
};
