import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Doc, applyUpdate } from "yjs";
import { useEffect, useRef, useState } from "react";
import { Q } from "@constant/query-key";
import { DocumentRecord, DocumentTemplateRecord } from "types/backend/records";
import api, { guestApi } from "@libs/api";
import { AxiosError, AxiosResponse } from "axios";
import { UserType } from "types/backend";
import { LOCALSTORAGE } from "@constant/localstorage-key";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { WEBSOCKET_ENDPOINT } from "@libs/tiptap/connection";
import { useHocuspocusStore } from "@store/hocuspocus-store";

export default function useGetSharedDocumentRawQuery(
    id: string | null | undefined,
    userType: Exclude<UserType, "tenant-user">,
) {
    const queryClient = useQueryClient();
    const [yjsDoc, setYjsDoc] = useState<Doc | null>(null);
    const hocuspocus = useRef<HocuspocusProvider>();

    const set = useHocuspocusStore.use.setHocuspocusProvider();
    const unset = useHocuspocusStore.use.unsetHocuspocusProvider();

    const { isLoading } = useQuery<any, AxiosError>({
        queryKey: [Q.GUEST_GET_DOCUMENT_RAW_DATA_BY_ID, id, userType],
        queryFn: async () => {
            let record: DocumentRecord | DocumentTemplateRecord;
            let response: AxiosResponse<
                DocumentRecord | DocumentTemplateRecord
            >;

            switch (userType) {
                case "shared-user":
                    response = await api.get<
                        DocumentRecord | DocumentTemplateRecord
                    >(`/documents/share/${id}/raw_data`);
                    record = response.data;
                    break;
                case "guest":
                    response = await guestApi.get<
                        DocumentRecord | DocumentTemplateRecord
                    >(`/documents/${id}/raw_data`);
                    record = response.data;
                    break;
            }

            const document = new Doc();

            if (record.data) {
                const state = new Uint8Array(record.data.data);
                applyUpdate(document, state);
            }

            const authToken = localStorage.getItem(
                userType === "guest"
                    ? LOCALSTORAGE.CLOSER_GUEST_JWT
                    : LOCALSTORAGE.CLOSER_JWT,
            );

            hocuspocus.current = new HocuspocusProvider({
                url: WEBSOCKET_ENDPOINT[process.env.NODE_ENV],
                name: `document/${id}`,
                token: authToken,
                preserveConnection: false,
                parameters: {
                    type: "document",
                },
                document,
                onStateless({ payload }) {
                    const { documentId, threadId } = JSON.parse(payload) as {
                        documentId: string;
                        threadId: string;
                    };
                    queryClient.invalidateQueries({
                        queryKey: [Q.GET_THREAD, documentId, threadId],
                    });
                },
            });
            setYjsDoc(hocuspocus.current.document);
            set(hocuspocus.current);

            return document;
        },
        enabled: !!id,
        gcTime: 0,
        staleTime: 0,
        retry: (_, error) => {
            if (error.response?.status === 401) {
                return false;
            }
            return true;
        },
    });

    useEffect(() => {
        return () => {
            hocuspocus.current?.disconnect();
            unset();
        };
    }, [id]);

    return { yjsDoc, hocuspocus: hocuspocus.current, isLoading };
}
