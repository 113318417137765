import { FORM_KEY } from "@constant/form";
import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { usePostHog } from "posthog-js/react";
import { CreateDocumentBody, Document } from "types/backend/document";

function validateForm(formData: FormData) {
    const title = formData.get(FORM_KEY.DOCUMENT.TITLE) as string;
    const documentTemplateId =
        (formData.get(FORM_KEY.DOCUMENT.TEMPLATE) as string) || undefined;
    const meetingId =
        (formData.get(FORM_KEY.DOCUMENT.MEETING) as string) || undefined;
    if (!title) throw new Error("Invalid title, please input a correct title");
    return {
        title,
        documentTemplateId,
        meetingId,
    };
}

export default function useCreateDocumentMutate() {
    const queryClient = useQueryClient();
    const { push } = usePopupNotification();
    const posthog = usePostHog();

    const mutation = useMutation({
        mutationKey: [M.CREATE_DOCUMENT],
        mutationFn: async (formData: FormData) => {
            const body = validateForm(formData);
            posthog?.capture(
                `document_created${body.documentTemplateId ? " from template" : ""}`,
            );
            const { data } = await api.post<
                Document,
                AxiosResponse<Document>,
                CreateDocumentBody
            >("/documents", body);
            return data;
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [Q.GET_ALL_DOCUMENT] });
            push({
                title: `Successfully create document ${data.title}`,
                description: "You can select the document to edit the content",
                type: "success",
            });
        },
        onError: (error) => {
            const errorMessage =
                error instanceof AxiosError
                    ? error.response?.data.message
                    : error.message;
            push({
                title: "Failed to create document",
                description: `Error occurred due to ${errorMessage}`,
                type: "destructive",
            });
        },
    });

    return mutation;
}
