import { HocuspocusProvider } from "@hocuspocus/provider";
import { BaseAttribute } from "@libs/tiptap/BaseAttribute";
import { BulletList } from "@tiptap/extension-bullet-list";
import { ListItem } from "@tiptap/extension-list-item";
import { TaskList } from "@tiptap/extension-task-list";
import { TaskItem } from "@tiptap/extension-task-item";
import { transformToCloserFormat } from "@libs/tiptap/utils";
import { Collaboration } from "@tiptap/extension-collaboration";
import { Document } from "@tiptap/extension-document";
import { Heading } from "@tiptap/extension-heading";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Text } from "@tiptap/extension-text";
import {
    Content,
    EditorContent,
    ReactNodeViewRenderer,
    useEditor,
} from "@tiptap/react";
import { FC } from "react";
import { Doc } from "yjs";
import ListItemComponent from "@component-func/tiptap/ListItem";
import TaskItemComponent from "@component-func/tiptap/TaskItem";
import SharedTaskItem from "./share/SharedTaskItemComponent";
import SharedListItem from "./share/SharedListItemComponent";
import { Thread } from "@libs/tiptap/Thread";
import CloserBubbleMenu from "./CloserBubbleMenu";
import { UserType } from "types/backend";

type TiptapEditorProps = {
    document: Doc;
    provider?: HocuspocusProvider;
};

const TiptapEditor: FC<TiptapEditorProps> = ({ document: YDocument }) => {
    const editor = useEditor(
        {
            editorProps: {
                transformPastedHTML(html) {
                    return transformToCloserFormat(html);
                },
                attributes: {
                    class: "h-full focus:outline-none",
                },
            },
            extensions: [
                Document.extend({
                    history: false,
                }),
                Text,
                Paragraph,
                Heading.configure({
                    levels: [1, 2, 3, 4],
                }),
                TaskList.extend({
                    content: `(${TaskItem.name}|${ListItem.name})+`,
                }),
                TaskItem.extend({
                    addNodeView: () => ReactNodeViewRenderer(TaskItemComponent),
                    addKeyboardShortcuts() {
                        return {
                            Enter: () =>
                                this.editor.commands.splitListItem(this.name),
                            Tab: () => {
                                this.editor.commands.sinkListItem(this.name);
                                return true;
                            },
                            "Shift-Tab": () =>
                                this.editor.commands.liftListItem(this.name),
                        };
                    },
                }).configure({
                    nested: true,
                }),
                BulletList.configure({
                    keepMarks: true,
                    keepAttributes: true,
                }),
                ListItem.extend({
                    addNodeView: () => ReactNodeViewRenderer(ListItemComponent),
                }),
                Collaboration.configure({
                    document: YDocument,
                }),
                BaseAttribute,
                Thread,
            ],
        },
        [YDocument],
    );

    return (
        editor && (
            <div className="grid grid-cols-[1fr,max-content] overflow-y-auto py-2 h-full">
                <EditorContent
                    editor={editor}
                    className="h-full overflow-y-auto overflow-x-clip"
                />
                <div
                    id="comments"
                    className="ml-2 w-6 flex flex-col overflow-y-auto"
                ></div>
                <CloserBubbleMenu editor={editor} userType="tenant-user" />
            </div>
        )
    );
};

type SharedTiptapEditorProps = {
    document: Content;
    userType: Exclude<UserType, "tenant-user">;
};

const SharedTiptapEditor: FC<SharedTiptapEditorProps> = ({
    document: YDocument,
    userType,
}) => {
    const editor = useEditor(
        {
            editable: false,
            editorProps: {
                transformPastedHTML(html) {
                    return transformToCloserFormat(html);
                },
                attributes: {
                    class: "h-full focus:outline-none",
                },
            },
            extensions: [
                Document.extend({
                    history: false,
                }),
                Text,
                Paragraph,
                Heading.configure({
                    levels: [1, 2, 3, 4],
                }),
                TaskList.extend({
                    content: `(${TaskItem.name}|${ListItem.name})+`,
                }),
                TaskItem.extend({
                    addNodeView: () => ReactNodeViewRenderer(SharedTaskItem),
                    addKeyboardShortcuts() {
                        return {
                            Enter: () =>
                                this.editor.commands.splitListItem(this.name),
                            Tab: () => {
                                this.editor.commands.sinkListItem(this.name);
                                return true;
                            },
                            "Shift-Tab": () =>
                                this.editor.commands.liftListItem(this.name),
                        };
                    },
                }).configure({
                    nested: true,
                }),
                BulletList.configure({
                    keepMarks: true,
                    keepAttributes: true,
                }),
                ListItem.extend({
                    addNodeView: () => ReactNodeViewRenderer(SharedListItem),
                }),
                Collaboration.configure({
                    document: YDocument,
                }),
                BaseAttribute,
                Thread,
            ],
        },
        [YDocument],
    );

    return (
        editor && (
            <div className="grid grid-cols-[1fr,max-content] py-2 overflow-y-auto h-full">
                <EditorContent
                    editor={editor}
                    className="h-full overflow-y-auto"
                />
                <div
                    id="comments"
                    className="ml-4 w-8 flex flex-col overflow-y-auto"
                ></div>
                <CloserBubbleMenu editor={editor} userType={userType} />
            </div>
        )
    );
};

export { TiptapEditor, SharedTiptapEditor };
