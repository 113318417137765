import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";

export function useWatchGoogleCalendarMutate() {
    const queryClient = useQueryClient();
    const posthog = usePostHog();
    const { push } = usePopupNotification();

    const mutation = useMutation({
        mutationKey: [M.WATCH_GOOGLE_CALENDAR],
        mutationFn: async (calendarId: string) => {
            posthog?.capture("google_calendar_subscribed");
            const { data } = await api.post("google-calendar/events/watch", {
                calendarId,
            });
            return data;
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [Q.GET_WATCHING_GOOGLE_CALENDARS],
            });
            push({
                title: "Successfully subscribed the changes of Google Calendar",
                description: "The bot will automatically join your meetings",
                type: "success",
            });
        },
        onError: (error) => {
            const errorMessage =
                error instanceof AxiosError
                    ? error.response?.data.message
                    : error.message;
            push({
                title: "Failed to subscribe the Google Calendar",
                description: `Error occurred due to ${errorMessage}`,
                type: "destructive",
            });
        },
    });
    return mutation;
}
