import { HocuspocusProvider } from "@hocuspocus/provider";
import { createSelectors } from "./helper";
import { create } from "zustand";

type HocuspocusStoreState = {
    hocuspocus: HocuspocusProvider | null;
};

type HocuspocusStoreAction = {
    setHocuspocusProvider: (provider: HocuspocusProvider) => void;
    unsetHocuspocusProvider: () => void;
};

const initialState: HocuspocusStoreState = {
    hocuspocus: null,
};

export const useHocuspocusStore = createSelectors(
    create<HocuspocusStoreState & HocuspocusStoreAction>((set) => {
        return {
            ...initialState,
            setHocuspocusProvider: (provider: HocuspocusProvider) => {
                set({ hocuspocus: provider });
            },
            unsetHocuspocusProvider: () => {
                set({ hocuspocus: null });
            },
        };
    }),
);
