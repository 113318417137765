import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { Media } from "types/backend/media";

export default function useShareMediaByMeetingQuery(id: string | undefined) {
    const query = useQuery({
        queryKey: [Q.GET_SHARED_MEDIA_BY_MEETING, id],
        queryFn: async ({ queryKey }) => {
            const [, id] = queryKey;
            const { data } = await api.get<Media[]>(
                `/meetings/share/${id}/media`,
            );
            return data;
        },
        enabled: !!id,
    });

    return query;
}
