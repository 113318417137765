import { LOCALSTORAGE } from "@constant/localstorage-key";
import { M } from "@constant/mutation-key";
import api from "@libs/api";
import { useMutation } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { TenantUserAuth } from "types/backend/tenant-user";

export default function useLogin() {
    const posthog = usePostHog();

    const mutation = useMutation<
        TenantUserAuth,
        unknown,
        { email: string; password: string }
    >({
        mutationKey: [M.LOGIN],
        mutationFn: async ({ email, password }) => {
            const { data } = await api.post<TenantUserAuth>("/auth/v2/login", {
                email,
                password,
            });
            return data;
        },
        onSuccess: ({ jwt, isActive, id }) => {
            api.defaults.headers["Authorization"] = "Bearer " + jwt;
            window.localStorage.setItem(LOCALSTORAGE.CLOSER_JWT, jwt);
            window.localStorage.setItem(
                LOCALSTORAGE.CLOSER_ACCOUNT_ACTIVE,
                isActive.toString(),
            );
            posthog?.capture("user_logged_in", { tenantUserId: id });
        },
    });

    return mutation;
}
