import { Button } from "@component-ui/utility/Button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@component-ui/utility/Dialog";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";
import { FORM_KEY } from "@constant/form";
import useCreateOrUpdateCustomerTagMutate from "@hooks/customer-tag/useCreateOrUpdateCustomerTagMutate";
import { Loader2 } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import {
    Dispatch,
    FC,
    FormEvent,
    MutableRefObject,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import { CustomerTag } from "types/backend/customer_tags";

type CustomerTagDialogProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    editingTagRef: MutableRefObject<CustomerTag | null>;
};

const CreateOrUpdateCustomerTagDialogCapture: FC<{
    customerTagId?: string;
}> = ({ customerTagId }) => {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("$pageview", {
            pageName: customerTagId ? "/tag/edit" : "/tags/new",
        });
    }, []);

    return null;
};

const CreateOrUpdateCustomerTagDialog: FC<CustomerTagDialogProps> = ({
    open,
    setOpen,
    editingTagRef,
}) => {
    const { mutate: createOrUpdateCustomerTag, isPending } =
        useCreateOrUpdateCustomerTagMutate(editingTagRef.current?.id);
    const [nameError, setNameError] = useState(false);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const name = formData.get(FORM_KEY.CREATE_CUSTOMER_TAG.NAME) as string;
        if (!name || name.length > 20) {
            setNameError(true);
            return;
        } else {
            setNameError(false);
        }
        createOrUpdateCustomerTag(formData, {
            onSuccess: () => {
                setOpen(false);
                if (editingTagRef.current) {
                    editingTagRef.current = null;
                }
            },
        });
    };
    return (
        <Dialog
            open={open}
            onOpenChange={(e) => {
                setOpen(e);
                if (!e) {
                    editingTagRef.current = null;
                }
            }}
        >
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {editingTagRef.current
                            ? "Update tag"
                            : "Create new tag"}
                    </DialogTitle>
                    <CreateOrUpdateCustomerTagDialogCapture
                        customerTagId={editingTagRef.current?.id}
                    />
                </DialogHeader>
                <form
                    id={FORM_KEY.CREATE_CUSTOMER_TAG.FORM_ID}
                    onSubmit={handleSubmit}
                >
                    <Label htmlFor={FORM_KEY.CREATE_CUSTOMER_TAG.NAME}>
                        Tag name
                    </Label>
                    <Input
                        id={FORM_KEY.CREATE_CUSTOMER_TAG.NAME}
                        name={FORM_KEY.CREATE_CUSTOMER_TAG.NAME}
                        type="text"
                        defaultValue={editingTagRef.current?.name}
                        className={nameError ? "border border-red-500" : ""}
                    />
                    {nameError && (
                        <div className="text-red-500 text-sm">
                            Name must be less than 20 characters
                        </div>
                    )}
                    <Label htmlFor={FORM_KEY.CREATE_CUSTOMER_TAG.DESCRIPTION}>
                        Tag description
                    </Label>
                    <Input
                        id={FORM_KEY.CREATE_CUSTOMER_TAG.DESCRIPTION}
                        name={FORM_KEY.CREATE_CUSTOMER_TAG.DESCRIPTION}
                        placeholder="Optional"
                        defaultValue={editingTagRef.current?.description}
                        type="text"
                    />
                </form>
                <Button
                    form={FORM_KEY.CREATE_CUSTOMER_TAG.FORM_ID}
                    type="submit"
                    disabled={isPending}
                >
                    {isPending && (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    {editingTagRef.current ? "Update Tag" : "Create Tag"}
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default CreateOrUpdateCustomerTagDialog;
