import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";
import { CustomerTag } from "types/backend/customer_tags";

export default function useRemoveCustomerTagMutate() {
    const queryClient = useQueryClient();
    const { push } = usePopupNotification();
    const posthog = usePostHog();

    const mutation = useMutation({
        mutationKey: [M.REMOVE_CUSTOMER_TAG],
        mutationFn: async (customerTagId: string) => {
            posthog?.capture("customer_tag_deleted");
            const { data } = await api.delete<CustomerTag>(
                "/customer-tags/" + customerTagId,
            );
            return data;
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [Q.GET_CUSTOMER_TAG] });
            queryClient.invalidateQueries({ queryKey: [Q.GET_MEETINGS] });
            queryClient.invalidateQueries({ queryKey: [Q.GET_ALL_DOCUMENT] });
            queryClient.invalidateQueries({
                queryKey: [Q.GET_ALL_DOCUMENT_TEMPLATE],
            });
            push({
                title: `Successfully removed tag ${data.name}`,
                description: "",
                type: "success",
            });
        },
        onError: (error) => {
            const errorMessage =
                error instanceof AxiosError
                    ? error.response?.data.message
                    : error.message;
            push({
                title: "Failed to remove tag",
                description: `Error occurred due to ${errorMessage}`,
                type: "destructive",
            });
        },
        gcTime: 60 * 60 * 1000,
    });
    return mutation;
}
