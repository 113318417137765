import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { isResourcePlatform } from "@constant/resource";
import { SEARCH_PARAMS } from "@constant/searchParams";
import { useAuth } from "@context/auth";
import useOAuthMutate from "@hooks/oauth/useOauthMutate";
import { usePostHog } from "posthog-js/react";
import { FC, useEffect, useState } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { ResourcePlatform } from "types/backend/resources";

type OauthPlatform = {
    platform: ResourcePlatform;
};

const OAuthError: FC = () => {
    return (
        <div className="h-full w-full flex flex-col items-center justify-center gap-2">
            <div className="text-3xl font-bold">
                An error occurred in the OAuth process.
            </div>
            <div className="text-xl font-semibold">
                Please retry again. Please contact us if this situation
                reoccurs.
            </div>
        </div>
    );
};

const OAuthContent: FC<{ platform: ResourcePlatform; code: string }> = ({
    platform,
    code,
}) => {
    const [displayText, setDisplayText] = useState("Handling OAuth");
    const { mutate, isPending, isError } = useOAuthMutate(platform);

    useEffect(() => {
        mutate(
            { code },
            {
                onSuccess: () => {
                    setDisplayText("OAuth success, you may close this window.");
                    setTimeout(() => {
                        window.close();
                    }, 10 * 1000);
                },
            },
        );
    }, []);

    if (isPending) {
        return <LoadingSpinnerCenter />;
    }

    if (isError) {
        return <OAuthError />;
    }

    return (
        <div className="h-full w-full flex flex-col items-center justify-center gap-2 text-2xl">
            {displayText}
        </div>
    );
};

const OAuthCapture: FC<OauthPlatform> = ({ platform }) => {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("$pageview", { pageName: `/oauth/${platform}` });
    }, []);

    return null
};

const OAuth: FC = () => {
    const { platform } = useParams<OauthPlatform>();
    const [search] = useSearchParams();
    const code = search.get(SEARCH_PARAMS.OAUTH_CODE);

    const { isAuth } = useAuth();
    if (!isAuth) {
        return (
            <Navigate
                to={`/login?redirect=oauth&platform=${platform}&code=${code}`}
            />
        );
    }

    if (!platform || !isResourcePlatform(platform) || !code) {
        return (
            <div className="h-full w-full flex flex-col items-center gap-2">
                <img
                    className="pt-6 w-[500px] mx-auto"
                    src="https://d1pnnwteuly8z3.cloudfront.net/images/30ba08ff-7870-4ce4-855e-cc07414e7915/b345644c-a350-44b9-bd80-4332b12161f5.png"
                    alt="Closer"
                />
                <OAuthError />
            </div>
        );
    }

    return (
        <div className="h-full w-full grid grid-rows-[max-content,1fr] justify-center gap-2">
            <img
                className="pt-6 w-[500px] mx-auto"
                src="https://d1pnnwteuly8z3.cloudfront.net/images/30ba08ff-7870-4ce4-855e-cc07414e7915/b345644c-a350-44b9-bd80-4332b12161f5.png"
                alt="Closer"
            />
            <OAuthCapture platform={platform} />
            <OAuthContent platform={platform} code={code} />
        </div>
    );
};

export default OAuth;
