import Empty from "@component-ui/Empty";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { SEARCH_PARAMS } from "@constant/searchParams";
import useGetThreadQuery from "@hooks/thread/useGetThreadQuery";
import { Mark } from "@tiptap/pm/model";
import { FC, KeyboardEvent, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Comment } from "./comments/Comment";
import { EditableContainer } from "@component-func/EditableContainer";
import useCreateCommentMutate from "@hooks/thread/comments/useCreateCommentMutate";
import { Thread } from "types/backend/thread";
import { UserType } from "types/backend";
import { captureException } from "@sentry/react";
import { Button } from "@component-ui/utility/Button";
import { ArrowUp } from "lucide-react";

type ThreadRootProps = {
    data: Thread;
    addComment: (content: string | undefined) => Promise<void>;
};

const ThreadRoot: FC<ThreadRootProps> = ({ data, addComment }) => {
    const inputRef = useRef<HTMLDivElement>(null);

    async function handleKeyDown(e: KeyboardEvent) {
        if (e.code === "Enter") {
            e.preventDefault();
            handleAddComment();
        }
    }

    async function handleAddComment() {
        if (!inputRef.current) return;
        addComment(inputRef.current.innerText);
        inputRef.current.innerText = "";
    }

    return (
        <div className="space-y-4">
            <div className="flex max-w-sm w-full">
                <div className="w-[2px] ml-1 rounded-sm mr-2 shrink-0 bg-yellow-300"></div>
                <div className="text-gray-400 text-sm break-words break-all">
                    {data.referenceText}
                </div>
            </div>
            <div className="flex flex-col gap-2">
                {data.comments &&
                    data.comments.map((comment) => (
                        <Comment key={comment.id} comment={comment} />
                    ))}
            </div>

            <div className="p-2 flex justify-end items-end">
                <div className="flex-1">
                    <EditableContainer
                        contentEditable
                        ref={inputRef}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <Button
                        variant="ghost"
                        size="sIcon"
                        onClick={handleAddComment}
                    >
                        <ArrowUp className="w-4 h-4" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

type ThreadProps = {
    mark: Mark;
};

const Thread: FC<ThreadProps> = ({ mark }) => {
    const [search] = useSearchParams();
    const { data, isLoading, isSuccess, isError } = useGetThreadQuery(
        search.get(SEARCH_PARAMS.CURRENT_DOCUMENT),
        mark.attrs["threadId"],
        "tenant-user",
    );
    const { mutateAsync: createComment } =
        useCreateCommentMutate("tenant-user");

    async function addComment(content: string | undefined) {
        if (!content) return;
        try {
            const documentId = search.get(SEARCH_PARAMS.CURRENT_DOCUMENT);
            const threadId = mark.attrs["threadId"];
            if (!documentId || !threadId) return;
            await createComment({
                documentId,
                threadId,
                content,
            });
        } catch (err) {
            captureException(err, {
                extra: {
                    message: "Cannot create comment",
                },
            });
        }
    }

    if (isLoading) {
        return (
            <div>
                <LoadingSpinnerCenter />
            </div>
        );
    }
    if (!isSuccess || isError) {
        return <Empty label="Cannot get thread" />;
    }

    return <ThreadRoot data={data} addComment={addComment} />;
};

const SharedThread: FC<
    ThreadProps & { userType: Exclude<UserType, "tenant-user"> }
> = ({ mark, userType }) => {
    const [search] = useSearchParams();
    const { data, isLoading, isSuccess, isError } = useGetThreadQuery(
        search.get(SEARCH_PARAMS.CURRENT_DOCUMENT),
        mark.attrs["threadId"],
        userType,
    );

    const { mutateAsync: createComment } = useCreateCommentMutate(userType);

    async function addComment(content: string | undefined) {
        if (!content) return;
        try {
            const documentId = search.get(SEARCH_PARAMS.CURRENT_DOCUMENT);
            const threadId = mark.attrs["threadId"];
            if (!documentId || !threadId) return;
            await createComment({
                documentId,
                threadId,
                content,
            });
        } catch (err) {
            captureException(err, {
                extra: {
                    message: "Cannot create comment",
                },
            });
        }
    }

    if (isLoading) {
        return (
            <div>
                <LoadingSpinnerCenter />
            </div>
        );
    }
    if (!isSuccess || isError) {
        return <Empty label="Cannot get thread" />;
    }

    return <ThreadRoot data={data} addComment={addComment} />;
};

export { Thread, SharedThread };
