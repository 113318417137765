import api from "@libs/api";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePostHog } from "posthog-js/react";
import {
    TranscriptionSegment,
    TranscriptionSegmentMetadata,
} from "types/backend/media";

export default function useTranscriptionSegmentUpdate(id: string) {
    const posthog = usePostHog();

    const mutation = useMutation<
        TranscriptionSegment,
        AxiosError,
        {
            metadata: TranscriptionSegmentMetadata;
        }
    >({
        mutationFn: async ({ metadata }) => {
            posthog?.capture("transcription_updated");
            const { data } = await api.put<TranscriptionSegment>(
                `/transcription-segments/${id}`,
                {
                    metadata,
                },
            );
            return data;
        },
    });

    return mutation;
}
