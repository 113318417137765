import { LOCALSTORAGE } from "@constant/localstorage-key";
import { Q } from "@constant/query-key";
import { useQuery } from "@tanstack/react-query";

export type Speaker = string;
export type SpeakerMap = Record<string, Speaker>;
export type MediaSpeakerMap = Record<string, SpeakerMap>;

export default function useSpeakerMapQuery(mediaId: string | undefined) {
    const query = useQuery({
        queryKey: [Q.GET_CLOSER_MEDIA_SPEAKER_MAP, mediaId],
        queryFn: () => {
            if (!mediaId) return {};
            const mediaSpeakerMap = window.localStorage.getItem(
                LOCALSTORAGE.CLOSER_MEDIA_SPEAKER_LIST_MAP,
            );
            if (!mediaSpeakerMap) return {};
            const targetedSpeakerMap = JSON.parse(mediaSpeakerMap)[
                mediaId
            ] as Record<string, Speaker>;
            if (!targetedSpeakerMap) return {};
            return targetedSpeakerMap;
        },
        initialData: {},
    });
    return query;
}
