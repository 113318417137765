import { Editor, isTextSelection } from "@tiptap/core";
import { BubbleMenu } from "@tiptap/react";
import { FC } from "react";
import CreateThreadPopover from "./thread/CreateThreadPopover";
import { UserType } from "types/backend";
import ListItem from "@tiptap/extension-list-item";
import TaskItem from "@tiptap/extension-task-item";

export type CloserBubbleMenuProps = {
    editor: Editor;
    userType: UserType;
};

function active(editor: Editor) {
    const active =
        editor.isActive(ListItem.name) || editor.isActive(TaskItem.name);
    return active;
}

const CloserBubbleMenu: FC<CloserBubbleMenuProps> = ({ editor, userType }) => {
    return (
        <BubbleMenu
            editor={editor}
            tippyOptions={{ duration: 250 }}
            shouldShow={({ editor, view, state, from, to }) => {
                const { doc, selection } = state;
                const { empty } = selection;
                const isEmptyTextBlock =
                    !doc.textBetween(from, to).length &&
                    isTextSelection(selection);

                const hasEditorFocus = view.hasFocus();

                if (
                    editor.isEditable &&
                    hasEditorFocus &&
                    !empty &&
                    !isEmptyTextBlock &&
                    active(editor)
                )
                    return true;

                if (
                    !editor.isEditable &&
                    !empty &&
                    !isEmptyTextBlock &&
                    active(editor)
                )
                    return true;

                return false;
            }}
        >
            <CreateThreadPopover userType={userType} editor={editor} />
        </BubbleMenu>
    );
};

export default CloserBubbleMenu;
