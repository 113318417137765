import { Badge } from "@component-ui/utility/Badge";
import { cn } from "@libs/utils";
import dayjs from "dayjs";
import { FC } from "react";
import { Link } from "react-router-dom";
import { GoogleMeetRecord } from "types/backend/google_meet";

interface GoogleMeetingListItemProps {
    item: GoogleMeetRecord;
    currentGoogleMeetingId: string | undefined;
}

const GoogleMeetingListItem: FC<GoogleMeetingListItemProps> = ({
    item,
    currentGoogleMeetingId,
}) => {
    const { recordings, startTime, name } = item;
    const id = name.split("/")[1];

    return (
        <Link
            to={`recording-download/google-meets/${id}`}
            state={{ ...item }}
        >
            <div
                className={cn(
                    currentGoogleMeetingId === name && "bg-gray-100/90",
                    "grid grid-flow-row w-full items-center rounded-md mb-2 px-3 py-1 hover:bg-gray-100/90 overflow-hidden shadow-sm",
                )}
            >
                <div className="flex justify-between gap-x-6 py-1">
                    <div className="flex min-w-0 gap-x-4">
                        <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                                {/* {space} */}
                                Google Meeting
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col items-end">
                        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                            <p className="mt-1 text-xs leading-5 text-gray-500">
                                <time dateTime="2023-01-23T13:23Z">
                                    {dayjs(startTime).fromNow()}
                                </time>
                            </p>
                        </div>
                        <Badge
                            variant={"noHover"}
                            className="text-sm cursor-default w-fit text-nowrap"
                        >
                            {recordings.length ?? 0}
                        </Badge>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default GoogleMeetingListItem;
