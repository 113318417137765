import api from "@libs/api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Doc, applyUpdate } from "yjs";
import { LOCALSTORAGE } from "@constant/localstorage-key";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { useEffect, useRef, useState } from "react";
import { WEBSOCKET_ENDPOINT } from "@libs/tiptap/connection";
import { Q } from "@constant/query-key";
import { DocumentRecord, DocumentTemplateRecord } from "types/backend/records";
import { useHocuspocusStore } from "@store/hocuspocus-store";

export default function useDocumentRawDataQuery(
    id: string | null | undefined,
    type: "document" | "documentTemplate",
) {
    const queryClient = useQueryClient();
    const [yjsDoc, setYjsDoc] = useState<Doc | null>(null);
    const hocuspocus = useRef<HocuspocusProvider>();

    const set = useHocuspocusStore.use.setHocuspocusProvider();
    const unset = useHocuspocusStore.use.unsetHocuspocusProvider();

    const { isLoading } = useQuery({
        queryKey: [
            type == "document"
                ? Q.GET_DOCUMENT_RECORD
                : Q.GET_DOCUMENT_TEMPLATE_RECORD,
            id,
        ],
        queryFn: async () => {
            const url =
                type === "document"
                    ? `/documents/${id}/raw_data`
                    : `/document-templates/${id}/raw_data`;

            const { data: rawData } = await api.get<
                DocumentRecord | DocumentTemplateRecord
            >(url);

            const document = new Doc();

            if (rawData.data) {
                const state = new Uint8Array(rawData.data.data);
                applyUpdate(document, state);
            }

            const authToken = localStorage.getItem(LOCALSTORAGE.CLOSER_JWT);
            hocuspocus.current = new HocuspocusProvider({
                url: WEBSOCKET_ENDPOINT[process.env.NODE_ENV],
                name: `${type}/${id}`,
                token: authToken,
                preserveConnection: false,
                parameters: {
                    type,
                },
                document,
                onStateless({ payload }) {
                    const { documentId, threadId } = JSON.parse(payload) as {
                        documentId: string;
                        threadId: string;
                    };
                    queryClient.invalidateQueries({
                        queryKey: [Q.GET_THREAD, documentId, threadId],
                    });
                },
            });
            setYjsDoc(hocuspocus.current.document);
            set(hocuspocus.current);

            return document;
        },
        enabled: !!id,
        gcTime: 0,
        staleTime: 0,
    });

    useEffect(() => {
        return () => {
            hocuspocus.current?.disconnect();
            unset();
        };
    }, [id]);

    return { yjsDoc, hocuspocus: hocuspocus.current, isLoading };
}
