import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@component-ui/utility/AlertDialog";
import { Button } from "@component-ui/utility/Button";
import { FC } from "react";

type DeleteRecordingAlertDialogProps = {
    handleDeleteCachedRecording: () => void;
    isDeleting: boolean;
};

const DeleteRecordingAlertDialog: FC<DeleteRecordingAlertDialogProps> = ({
    handleDeleteCachedRecording,
    isDeleting,
}) => {
    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button variant="destructive" disabled={isDeleting}>
                    Delete
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                {isDeleting ? (
                    <div>
                        <LoadingSpinnerCenter />
                    </div>
                ) : (
                    <>
                        <AlertDialogHeader>
                            <AlertDialogTitle>
                                Are you sure to delete the recording?
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                                This action cannot be undone. This will
                                permanently delete the cached recording
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                                onClick={handleDeleteCachedRecording}
                            >
                                Continue
                            </AlertDialogAction>
                        </AlertDialogFooter>
                    </>
                )}
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default DeleteRecordingAlertDialog;
