import { FORM_KEY } from "@constant/form";
import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { Document } from "types/backend/document";

function validateForm(formData: FormData) {
    const title = formData.get(FORM_KEY.DOCUMENT.TITLE) as string;
    if (!title) throw new Error("Invalid title, please input a correct title");
    return {
        title,
    };
}

export default function useUpdateDocumentMutate(documentId: string) {
    const queryClient = useQueryClient();
    const { push } = usePopupNotification();
    const posthog = usePostHog();

    return useMutation({
        mutationKey: [M.UPDATE_DOCUMENT, documentId],
        mutationFn: async (formData: FormData) => {
            const patchBody = validateForm(formData);
            posthog?.capture("document_updated");
            const { data } = await api.patch<Document>(
                "/documents/" + documentId,
                patchBody,
            );
            return data;
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [Q.GET_ALL_DOCUMENT] });
            queryClient.invalidateQueries({
                queryKey: [Q.GET_DOCUMENT, data.id],
            });
            push({
                title: `Successfully updated document ${data.title}`,
                description: "You have successfully updated the document",
                type: "success",
            });
        },
        onError: (err) => {
            push({
                title: "Failed to update document",
                description: `Error occurred due to ${(err as Error).message}`,
                type: "destructive",
            });
        },
    });
}
