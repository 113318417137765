import BasicSelect from "@component-func/BasicSelect";
import CreateMeetingDialog from "@component-func/meetings/CreateMeetingDialog";
import MeetingComboBox from "@component-func/meetings/MeetingComboBox";
import Empty from "@component-ui/Empty";
import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button, IconButtonWithTooltip } from "@component-ui/utility/Button";
import { DialogHeader, DialogTitle } from "@component-ui/utility/Dialog";
import { Label } from "@component-ui/utility/Label";
import { FORM_KEY } from "@constant/form";
import { languageOptions } from "@constant/language";
import { useCreateMediaMutate } from "@hooks/media/useCreateMediaMutate";
import useGetMediaDevices from "@hooks/recording/useGetMediaDevices";
import useTeamPermission from "@hooks/user/useTeamPermissionQuery";
import { useAudioRecorderStore } from "@store/audio-recorder-store";
import { Loader2, Plus } from "lucide-react";
import { Dispatch, FC, FormEvent, SetStateAction } from "react";

type StartRecordingDialogContentProps = {
    setInputDeviceId: Dispatch<SetStateAction<string>>;
    // useCloserAudio: boolean;
    // setUseCloserAudio: Dispatch<SetStateAction<boolean>>;
    setupStream: () => Promise<MediaStream | undefined>;
    startRecord: (mediaStream: MediaStream) => void;
};

const StartRecordingDialogContent: FC<StartRecordingDialogContentProps> = ({
    setInputDeviceId,
    setupStream,
    startRecord,
}) => {
    const [audioInputDevices] = useGetMediaDevices({
        dir: "audioinput",
        type: "audio",
    });
    const meetingId = useAudioRecorderStore.use.meetingId();
    const setMeetingId = useAudioRecorderStore.use.setMeetingId();
    const setMediaId = useAudioRecorderStore.use.setMediaId();
    const resetAudioStore = useAudioRecorderStore.use.resetInitialState();
    const { mutateAsync: createMedia, isPending: isCreatingMedia } =
        useCreateMediaMutate();
    const {
        data: permission,
        isLoading,
        isSuccess,
        isError,
    } = useTeamPermission();

    const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        formData.set(FORM_KEY.MEDIA.MIMETYPE, "audio/webm");
        formData.set(FORM_KEY.MEETING.ID, meetingId);
        const { id } = await createMedia(formData);
        setMediaId(id);
        const stream = await setupStream();
        if (!stream) {
            resetAudioStore();
            return;
        }
        startRecord(stream);
    };

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return <Empty label="Cannot get permission" />;
    }

    if (!permission.canCreateMedia) {
        return <Empty label="Reach media limit" />;
    }

    return (
        <>
            <DialogHeader>
                <DialogTitle>Start Recording</DialogTitle>
            </DialogHeader>
            <form className="space-y-4" onSubmit={handleOnSubmit}>
                <div>
                    <Label>Input Devices</Label>
                    <BasicSelect
                        name="audio-input"
                        placeholder="Select your input device"
                        options={audioInputDevices.map((d) => ({
                            label: d.label,
                            value: d.deviceId,
                        }))}
                        onValueChange={setInputDeviceId}
                    />
                </div>
                <div>
                    <Label>Meeting</Label>
                    <div className="flex gap-2 items-center">
                        <MeetingComboBox
                            selectedMeetingId={meetingId}
                            setSelectedMetingId={setMeetingId}
                            placeholder="Select a Meeting"
                            tooltipContent="Select the meeting of the recording"
                        />
                        <CreateMeetingDialog
                            ButtonElement={
                                <IconButtonWithTooltip
                                    size="sIcon"
                                    variant="ghost"
                                    content="Create new meeting"
                                    icon={<Plus className="w-4 h-4" />}
                                />
                            }
                        />
                    </div>
                </div>
                {/* <div className="flex justify-between items-center"> */}
                {/*     <Switch */}
                {/*         checked={useCloserAudio} */}
                {/*         onCheckedChange={setUseCloserAudio} */}
                {/*     /> */}
                {/*     <Label>Use Closer Audio</Label> */}
                {/* </div> */}
                <div>
                    <Label htmlFor={FORM_KEY.MEDIA.LANGUAGE}>Language</Label>
                    <BasicSelect
                        name={FORM_KEY.MEDIA.LANGUAGE}
                        placeholder="Pick a language"
                        options={languageOptions}
                    />
                </div>
                <Button disabled={isCreatingMedia}>
                    {isCreatingMedia && (
                        <Loader2 className="w-4 h-4 animate-spin" />
                    )}
                    Start
                </Button>
            </form>
        </>
    );
};

export default StartRecordingDialogContent;
