import { Q } from "@constant/query-key";
import api from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { GoogleCalendarEventsResponse } from "types/backend/google_calendar";

export function useGoogleCalendarEventsQuery(calendarId: string) {
    const query = useQuery({
        queryKey: [Q.GET_GOOGLE_CALENDAR_EVENTS, calendarId],
        queryFn: async () => {
            const { data } = await api.get<GoogleCalendarEventsResponse>(
                `google-calendar/${encodeURIComponent(calendarId)}/events`,
            );
            return data;
        },
        enabled: !!calendarId,
    });
    return query;
}
