import LoadingSpinnerCenter from "@component-ui/LoadingSpinnerCenter";
import { Button } from "@component-ui/utility/Button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@component-ui/utility/Dialog";
import useCustomerTags from "@hooks/customer-tag/useCustomerTagsQuery";
import { FC, useEffect, useState } from "react";
import CustomerTagBadge from "./CustomerTagBadge";
import { CustomerTag, CustomerTagItemType } from "types/backend/customer_tags";
import useTaggingMutate from "@hooks/customer-tag/useTaggingMutate";
import Empty from "@component-ui/Empty";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import { PlusCircle } from "lucide-react";
import { usePostHog } from "posthog-js/react";

type CustomerTagSelectDialogProps = {
    tagIds: string[];
    itemType: CustomerTagItemType;
    itemId: string;
    resetSelected?: () => void;
};
type CustomerTagSelectContentProps = Pick<
    CustomerTagSelectDialogProps,
    "tagIds"
> & {
    onTagClicked: (customerTag: CustomerTag) => void;
};

const CustomerTagSelectContent: FC<CustomerTagSelectContentProps> = ({
    tagIds,
    onTagClicked,
}) => {
    const {
        data: customerTags,
        isFetching,
        isSuccess,
        isError,
        isLoading,
        refetch,
    } = useCustomerTags();

    if (isLoading) {
        return <LoadingSpinnerCenter />;
    }

    if (!isSuccess || isError) {
        return (
            <div className="h-full grid place-items-center ">
                <h1 className="text-4xl text-foreground/50">
                    Cannot get the list of tags, please try again
                </h1>
                <Button onClick={() => refetch()}>Retry</Button>
            </div>
        );
    }

    const displayTags = customerTags.filter(
        (_tag) => !tagIds.includes(_tag.id),
    );
    return (
        <>
            {isFetching ? (
                <LoadingSpinnerCenter />
            ) : (
                <>
                    {displayTags.length <= 0 ? (
                        <Empty label="Do not have available tags" />
                    ) : (
                        <div className="flex flex-wrap gap-2">
                            {displayTags.map((customerTag) => (
                                <div
                                    key={customerTag.id}
                                    className="cursor-pointer"
                                    onClick={() => onTagClicked(customerTag)}
                                >
                                    <CustomerTagBadge
                                        customerTag={customerTag}
                                        className="cursor-pointer hover:opacity-70"
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

const CustomerTagSelectDialogCapture: FC<{
    itemType: CustomerTagItemType;
}> = ({ itemType }) => {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("$pageview", { pageName: `/${itemType}/tags/new` });
    }, []);

    return null;
};

const CustomerTagSelectDialog: FC<CustomerTagSelectDialogProps> = ({
    tagIds,
    itemType,
    itemId,
    resetSelected,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { mutate: tagging, isPending } = useTaggingMutate(itemType);
    const onTagClicked = (customerTag: CustomerTag) => {
        tagging(
            { customerTagId: customerTag.id, itemId },
            {
                onSuccess: () => {
                    resetSelected && resetSelected();
                    setIsOpen(false);
                },
            },
        );
    };

    return (
        <Dialog
            open={isOpen}
            onOpenChange={(e) => {
                resetSelected && resetSelected();
                setIsOpen(e);
            }}
        >
            <TooltipProvider delayDuration={200}>
                <Tooltip>
                    <DialogTrigger asChild onClick={(e) => e.stopPropagation()}>
                        <TooltipTrigger asChild>
                            <Button
                                size="sIcon"
                                variant="ghost"
                                className="rounded-full"
                                id="tagging-button"
                            >
                                <PlusCircle className="w-4 h-4 text-gray-500" />
                            </Button>
                        </TooltipTrigger>
                    </DialogTrigger>
                    <TooltipContent side="bottom">Add Tag</TooltipContent>
                </Tooltip>
            </TooltipProvider>
            <DialogContent onClick={(e) => e.stopPropagation()}>
                <DialogHeader>
                    <DialogTitle>Select Tag</DialogTitle>
                    <CustomerTagSelectDialogCapture itemType={itemType} />
                </DialogHeader>
                {isPending ? (
                    <LoadingSpinnerCenter />
                ) : (
                    <CustomerTagSelectContent
                        tagIds={tagIds}
                        onTagClicked={onTagClicked}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default CustomerTagSelectDialog;
