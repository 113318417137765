import { Q } from "@constant/query-key";
import api, { guestApi } from "@libs/api";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { UserType } from "types/backend";
import { Thread } from "types/backend/thread";

export default function useGetThreadQuery(
    documentId: string | null,
    threadId: string,
    userType: UserType
) {
    const query = useQuery({
        queryKey: [Q.GET_THREAD, documentId, threadId, userType],
        queryFn: async ({ queryKey }) => {
            const [, documentId, threadId] = queryKey;
            let response: AxiosResponse<Thread>;
            switch (userType) {
                case "tenant-user":
                    response = await api.get<Thread>(
                        `/documents/${documentId}/threads/${threadId}`,
                    );
                    break;
                case "shared-user":
                    response = await api.get<Thread>(
                        `/documents/share/${documentId}/threads/${threadId}`,
                    );
                    break;
                case "guest":
                    response = await guestApi.get<Thread>(
                        `/documents/${documentId}/threads/${threadId}`,
                    );
                    break;
            }
            return response.data;
        },
        enabled: !!documentId && !!threadId,
    });
    return query;
}
