import BasicSelect from "@component-func/BasicSelect";
import CreateMeetingDialog from "@component-func/meetings/CreateMeetingDialog";
import MeetingComboBox from "@component-func/meetings/MeetingComboBox";
import { Button, IconButtonWithTooltip } from "@component-ui/utility/Button";
import {
    DialogHeader,
    DialogTitle,
    DialogDescription,
    Dialog,
    DialogTrigger,
    DialogContent,
} from "@component-ui/utility/Dialog";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";
import { FORM_KEY } from "@constant/form";
import { languageOptions } from "@constant/language";
import { usePopupNotification } from "@context/popup-notification";
import useDeleteCachedRecording from "@hooks/recording/useDeleteCachedRecordingMutate";
import useHandleCachedRecording from "@hooks/recording/useHandleCachedRecordingUploadMutate";
import { Plus } from "lucide-react";
import { FormEvent, useState } from "react";

const HandleCachedRecordingDialog = () => {
    const [meetingId, setMeetingId] = useState("");
    const { push } = usePopupNotification();
    const {
        mutateAsync: deleteCachedRecording,
        isPending: isDeleteingCachedRecording,
    } = useDeleteCachedRecording();
    const {
        mutateAsync: handleCachedRecording,
        isPending: isHandlingCachedRecording,
    } = useHandleCachedRecording();

    const handleDelete = () => {
        deleteCachedRecording();
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        formData.set(FORM_KEY.MEETING.ID, meetingId);
        formData.set(FORM_KEY.MEDIA.MIMETYPE, "audio/webm")
        try {
            await handleCachedRecording(formData);
        } catch (err) {
            push({
                title: "Failed to proceed upload",
                description: (err as Error).message,
                type: "destructive",
            });
        }
    };

    return (
        <>
            <DialogHeader>
                <DialogTitle>Recording</DialogTitle>
                <DialogDescription>
                    Previous recording cache is not handled, please click proceed
                    to handle the recording
                </DialogDescription>
            </DialogHeader>
            <div className="flex justify-end gap-2">
                <Button
                    variant="destructive"
                    onClick={handleDelete}
                    disabled={
                        isDeleteingCachedRecording || isHandlingCachedRecording
                    }
                >
                    Delete
                </Button>
                <Dialog>
                    <DialogTrigger asChild>
                        <Button disabled={isDeleteingCachedRecording || isHandlingCachedRecording}>Proceed</Button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Upload cached recording</DialogTitle>
                        </DialogHeader>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <div>
                                    <Label>Meeting</Label>
                                    <div className="flex gap-2 items-center">
                                        <MeetingComboBox
                                            selectedMeetingId={meetingId}
                                            setSelectedMetingId={setMeetingId}
                                            placeholder="Select a Meeting"
                                            tooltipContent="Select the meeting of the recording"
                                        />
                                        <CreateMeetingDialog
                                            ButtonElement={
                                                <IconButtonWithTooltip
                                                    size="sIcon"
                                                    variant="ghost"
                                                    content="Create new meeting"
                                                    icon={
                                                        <Plus className="w-4 h-4" />
                                                    }
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Label htmlFor={FORM_KEY.MEDIA.NAME}>
                                        Media name
                                    </Label>
                                    <Input
                                        id={FORM_KEY.MEDIA.NAME}
                                        name={FORM_KEY.MEDIA.NAME}
                                        placeholder="media name..."
                                    />
                                </div>
                                <div>
                                    <Label htmlFor={FORM_KEY.MEDIA.LANGUAGE}>
                                        Language
                                    </Label>
                                    <BasicSelect
                                        name={FORM_KEY.MEDIA.LANGUAGE}
                                        placeholder="Pick a language"
                                        options={languageOptions}
                                    />
                                </div>
                            </div>
                            <Button
                                className="w-full"
                                type="submit"
                            >
                                Upload
                            </Button>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    );
};

export default HandleCachedRecordingDialog;
