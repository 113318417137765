import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Document } from "types/backend";

export default function useSoftDeleteDocumentMutate() {
    const { push } = usePopupNotification();
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationKey: [M.DELETE_DOCUMENT],
        mutationFn: async (id: string) => {
            return api.delete<Document>(`/documents/${id}`);
        },
        onSuccess: ({ data }) => {
            queryClient.invalidateQueries({
                queryKey: [Q.GET_DOCUMENT, data.id],
                exact: true,
            });
            queryClient.invalidateQueries({
                queryKey: [Q.GET_ALL_DOCUMENT],
                exact: false,
            });
        },
        onError: (err) => {
            push({
                title: "Cannot delete documen",
                description: err.message,
                type: "destructive",
            });
        },
    });

    return mutate;
}
