import { FORM_KEY } from "@constant/form";
import { M } from "@constant/mutation-key";
import { Q } from "@constant/query-key";
import { usePopupNotification } from "@context/popup-notification";
import api from "@libs/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { DocumentTemplate } from "types/backend/document_template";

function validateForm(formData: FormData) {
    const title = formData.get(FORM_KEY.DOCUMENT_TEMPLATE.TITLE) as string;
    if (!title) throw new Error("Invalid title, please input a correct title");
    return { title };
}

export default function useUpdateDocumentTemplateMutate(
    documentTemplateId: string,
) {
    const queryClient = useQueryClient();
    const { push } = usePopupNotification();
    const posthog = usePostHog();

    return useMutation({
        mutationKey: [M.UPDATE_DOCUMENT_TEMPLATE, documentTemplateId],
        mutationFn: async (formData: FormData) => {
            const patchBody = validateForm(formData);
            posthog?.capture("document_template_created");
            const { data } = await api.patch<DocumentTemplate>(
                "/document-templates/" + documentTemplateId,
                patchBody,
            );
            return data;
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({
                queryKey: [Q.GET_ALL_DOCUMENT_TEMPLATE],
            });
            await queryClient.invalidateQueries({
                queryKey: [Q.GET_DOCUMENT_TEMPLATE, documentTemplateId],
            });
            push({
                title: `Successfully updated template ${data.title}`,
                description: "You have successfully updated the template",
                type: "success",
            });
        },
        onError: (err) => {
            push({
                title: "Failed to update template",
                description: `Error occurred due to ${(err as Error).message}`,
                type: "destructive",
            });
        },
    });
}
