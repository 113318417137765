import { Button } from "@component-ui/utility/Button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@component-ui/utility/Dialog";
import { Input } from "@component-ui/utility/Input";
import { Label } from "@component-ui/utility/Label";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@component-ui/utility/Tooltip";
import { FORM_KEY } from "@constant/form";
import useUpdateMeetingMutate from "@hooks/meeting/useUpdateMeetingMutate";
import { Loader2, Pencil } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { FC, FormEvent, ReactElement, useEffect, useState } from "react";
import { Meeting } from "types/backend/meetings";

type UpdateMeetingDialogProps = {
    meeting: Meeting;
    ButtonElement?: ReactElement;
    onClose?: () => void;
};

const UpdateMeetingDialogCapture: FC = () => {
    const posthog = usePostHog();
    useEffect(() => {
        posthog?.capture("$pageview", { pageName: "/meeting/edit" });
    }, []);

    return null;
};

const UpdateMeetingDialog: FC<UpdateMeetingDialogProps> = ({
    meeting,
    ButtonElement = (
        <Button variant={"ghost"} size={"icon"}>
            <Pencil className="w-4 h-4" />
        </Button>
    ),
    onClose,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { mutate: updateMeeting, isPending: isUpdating } =
        useUpdateMeetingMutate(meeting.id);

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        updateMeeting(formData, {
            onSuccess: () => {
                setIsOpen(false);
            },
        });
    }

    return (
        <Dialog
            open={isOpen}
            onOpenChange={(e) => {
                setIsOpen(e);
                if (!e && onClose) {
                    onClose();
                }
            }}
        >
            <TooltipProvider delayDuration={200}>
                <Tooltip>
                    <DialogTrigger asChild onClick={(e) => e.stopPropagation()}>
                        <TooltipTrigger asChild>{ButtonElement}</TooltipTrigger>
                    </DialogTrigger>
                    <TooltipContent side="bottom">Update name</TooltipContent>
                </Tooltip>
            </TooltipProvider>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Update meeting</DialogTitle>
                    <UpdateMeetingDialogCapture />
                </DialogHeader>
                <form onSubmit={handleSubmit}>
                    <Label htmlFor={FORM_KEY.MEETING.NAME}>Meeting Name</Label>
                    <Input
                        name={FORM_KEY.MEETING.NAME}
                        defaultValue={meeting.label}
                    />
                    <Button
                        type="submit"
                        disabled={isUpdating}
                        className="w-full"
                    >
                        {isUpdating && (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Update
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default UpdateMeetingDialog;
